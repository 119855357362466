import React, {useState} from 'react';
import {MdAddCircle} from 'react-icons/md';

import {TAGS} from '../../../constants/tags';
import useGetIsrcBatches from '../../../hooks/ISRCManagement/useGetIsrcBatches';
import useTablePageState from '../../../hooks/reusable/useTablePageState';
import SkeletonLoading from '../../../pages/SkeletonPage';
import {isrcColumnMeta} from '../../../tableColumns/ISRCManagement/tableData';
import Button from '../../../components/reusable/Button/Button';
import FlyMenu from '../../../components/reusable/FlyMenu/FlyMenu';
import NoRecords from '../../../components/reusable/NoRecords/NoRecords';
import Table from '../../../components/reusable/Table/TablePagination';

const ISRC = () => {
  const [searchStr, setSearchStr] = useState('');

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    resolvedData,
    setPage,
    page,
    isLastPage,
    totalCount,
    rangeB,
    rangeA,
    lastPage,
    setLimit,
    limit,
    sError,
    sIsError,
    sIsFetching,
    sIsLoading,
    isCustomLoading,
    refetch,
  } = useGetIsrcBatches({searchStr});

  const {
    isOpen,
    setNewFormTag,
    newFormTag,
    setIsOpen,
    setSelectedData,
    selectedData,
  } = useTablePageState();

  const isrc = React.useMemo(() => data, [data]);
  const columnData = React.useMemo(() => isrcColumnMeta, [isrcColumnMeta]);

  const openNewFormSidebarHandler = () => {
    setIsOpen(true);
    setNewFormTag('isrc');
  };
  if (isLoading) {
    return <SkeletonLoading />;
  }

  if (resolvedData?.totalPage === 0) {
    return (
      <NoRecords
        page="isrc"
        btnLabel="Generate ISRC"
        onClick={openNewFormSidebarHandler}
      />
    );
  }
  return (
    <>
      <Table
        columns={columnData}
        data={isrc ? isrc : []}
        tag={TAGS.ISRC}
        setSidebarData={setSelectedData}
        onClickRow={setIsOpen}
        setPage={setPage}
        page={page}
        isLastPage={isLastPage}
        totalCount={totalCount}
        rangeB={rangeB}
        rangeA={rangeA}
        lastPage={lastPage}
        setLimit={setLimit}
        limit={limit}
        resolvedData={resolvedData}
        isLoading={isCustomLoading}
        isFetching={isFetching}
        isError={isError || sIsError}
        error={error}
        sError={sError}
        sIsError={sIsError}
        sIsFetching={sIsFetching}
        sIsLoading={sIsLoading}
        setSearchStr={setSearchStr}
        refetchHandler={refetch}
        actionBtn={
          <>
            <Button
              label="Generate ISRC"
              onClick={openNewFormSidebarHandler}
              shape="round"
              endEnhancer={() => <MdAddCircle />}
            />
          </>
        }
      />

      {isOpen && (
        <FlyMenu
          refetch={refetch}
          setSelectedData={setSelectedData}
          selectedData={selectedData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          tag={TAGS.ISRC}
          setNewFormTag={setNewFormTag}
          newFormTag={newFormTag}
        />
      )}
    </>
  );
};

export default ISRC;
