import axiosMain from 'axios';
import jwt from 'jsonwebtoken';
import React, {useContext, useEffect, useState} from 'react';
import {UseGoogleLoginProps} from 'react-google-login';
import {useMutation} from 'react-query';

import axios from '../../config/Axios';
import {GlobalContext} from '../../Contexts/GlobalProvider';
import useLocalStorage from './useLocalStorage';
import useToast from './useToast';

export const clientId: UseGoogleLoginProps['clientId'] = process.env
  .REACT_APP_CLIENT_ID as string;

const validateUser = async (data) => {
  try {
    const response = await axios(`/login`, {
      method: 'POST',
      data: data,
    });

    return response.data;
  } catch (error) {
    return error.response?.data;
  }
};

const useLogin = () => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const {setToastMsg, toastMsg} = useToast();
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);

  const [localToken, setLocalToken] = useLocalStorage('token', null);
  const [expiry, setExpiry] = useLocalStorage('expiry', null);
  const {state, dispatch}: any = useContext(GlobalContext);

  const [
    validateUserFn,
    {status, data: loginData, error, isLoading: validateUserLoading},
  ] = useMutation(validateUser);

  useEffect(() => {
    if (currentUser) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [currentUser]);

  const onSuccess = async (res) => {
    if (res) {
      setToastMsg({
        type: 'none',
        message: {error: '', message: ''},
      });
      try {
        const data = await validateUserFn({tokenId: res.tokenId});

        if (data.statusCode === 200) {
          const token = data.data?.token;
          setLocalToken(token);
          dispatch({
            type: 'TOKEN_ID',
            payload: {
              token: token,
            },
          });
          axios.defaults.headers.common['access_token'] = `bearer ${token}`;

          const decoded = jwt.verify(
            token,
            process.env.REACT_APP_Secret_Key,
            (err, decoded) => {
              setExpiry(decoded.exp);
              if (err) {
                setIsLoggedIn(false);
              }
              const UserObj = {...res.profileObj, ...decoded.data};
              setIsLoggedIn(true);
              setCurrentUser(UserObj);
            },
          );
        } else if (data.statusCode === 404) {
          setIsLoggedIn(false);
          window.localStorage.clear();
          setToastMsg({
            type: 'error',
            message: {error: data.error[0], message: data.message},
          });
        } else {
          setIsLoggedIn(false);
          setToastMsg({
            type: 'error',
            message: {error: 'Invalid User', message: 'Invalid User'},
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onFailure = (res) => {
    setToastMsg({
      type: 'error',
      message: {error: 'Login Failed', message: res.error},
    });
  };

  return {
    isLoggedIn,
    setIsLoggedIn,
    toastMsg,
    setToastMsg,
    validateUserLoading,
    currentUser,
    onSuccess,
    onFailure,
    setCurrentUser,
  };
};

export default useLogin;
