import styled from '@emotion/styled';
import {StatefulPopover} from 'baseui/popover';
import {PLACEMENT} from 'baseui/toast';
import {toaster} from 'baseui/toast';
import React, {useEffect, useState} from 'react';
import {FiDownload} from 'react-icons/fi';

import {TAGS} from '../../../constants/tags';
import {GlobalContext} from '../../../Contexts/GlobalProvider';
import {capitalizeFirstLetter} from '../../../helpers/capitalizeFirstLetter';
import {camelToSentence} from '../../../helpers/convertSnakeCaseToUpperCase';
import {apiDate, formatDate, hrFormatDate} from '../../../helpers/covertDate';
import {downloadCsv, exportToExcel} from '../../../helpers/downloadCSV';
import {removeEmptyArrayFromObj} from '../../../helpers/removeEmptyArrayFromObj';
import useExportExcel from '../../../hooks/reusable/useExportExcel';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import {albumsColumnMeta} from '../../../tableColumns/AlbumsManagement/albumsColumnMetaData';
import {dspColumnMeta} from '../../../tableColumns/DSPManagement/tableData';
import {isrcColumnMeta} from '../../../tableColumns/ISRCManagement/tableData';
import {labelColumnMeta} from '../../../tableColumns/LabelManagement/tableData';
import {upcColumnMeta} from '../../../tableColumns/UPCManagement/tableData';
import {userColumnMeta} from '../../../tableColumns/UserManagement/tableData';
import {tracksColumnMeta} from '../../AlbumTracksApproval/tableData';
import {manualOrderColData} from '../../../pages/OrderManagement/tables/tableData';
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import {renderPlaceholders} from './TableFilter';
import useListLanguages from '../../../hooks/Language/useListLanguage';

const ExportToExcelContainer = styled.div`
  background-color: white;
  width: 375px;
  border-radius: 5px;
  padding: 10px;
  .export-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    button {
      &:disabled {
        background-color: #cccccc;
        color: #666666;
      }
    }
    .export-btn {
      background-color: #dc3831;
      color: white;
      padding: 8px 12px;
      border-radius: 100px;
      cursor: pointer;
      outline: none;
      border: none;
      font-weight: bold;
    }
    .inside {
      display: flex;
    }
    .export-text {
      margin-right: 5px;
    }
  }

  ul {
    margin: 0;
    padding: 0px;
    display: flex;
    flex-direction: column;
    list-style-type: none;

    li {
      &:hover {
        cursor: pointer;
      }
      padding: 10px 8px;
      label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }
      .export-option-container {
        display: flex;
        flex-direction: column;
      }
      .label {
        font-size: 14px;
        line-height: 19px;
        color: #575757;
        margin-bottom: 5px;
      }
      .desc {
        font-size: 12px;
        line-height: 14px;
        color: #aeaeae;
      }
      &:hover {
        background-color: #f4f4fb;
        cursor: pointer;
      }
    }
  }
`;

export const renderResources = (tag) => {
  switch (tag) {
    case TAGS.USER:
      return TAGS.USER;
    case TAGS.ALBUMS:
      return 'album';
    case TAGS.LABEL:
      return TAGS.LABEL;
    case TAGS.ALBUM_APPROVAL:
      return 'album_review';
    case TAGS.UPC:
      return TAGS.UPC;
    case TAGS.DSP:
      return TAGS.DSP;
    case TAGS.ISRC:
      return TAGS.ISRC;
    case TAGS.ORDER_MANAGEMENT:
      return 'order';
    default:
      return TAGS.ALBUMS;
  }
};

const ExportComponent = (props) => {
  const {
    exportData,
    isOpen,
    exportVal,
    onRadioChange,
    data,
    tag,
    currentUser,
    close,
    searchString,
    allData,
    dataFilter,
    type,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const {exportExcelFn} = useExportExcel();
  const {listLanguages} = useListLanguages();
  const {dispatch, state}: any = React.useContext(GlobalContext);

  const onClickExportHandler = async () => {
    setIsLoading(true);
    if (exportVal === 'current') {
      const formattedData = formatDataFn();
      const finalData = formatArrayFields(formattedData);
      exportToExcel(finalData, `${tag}-current-page`);
      setIsLoading(false);
      toaster.show(`Exported Sucessfully`, {
        autoFocus: true,
        closeable: false,
        autoHideDuration: 5000,
        kind: 'positive',
      });
      close();
    } else {
      if (tag === TAGS.ORDER_MANAGEMENT) {
        let data;
        if (tag === TAGS.ORDER_MANAGEMENT) {
          const formatedData = {
            dsp_name: state.selectedDsp,
            upc: state.upc_id,
            order_name: state.order_id,
            order_status: state.order_status,
            album_name: searchString,
            start_date: state.order_start_date,
            end_date: state.order_end_date,
            type: type === 'single' ? 'single' : 'bulk',
          };
          const newData = removeEmptyArrayFromObj(formatedData);
          data = {
            resource: renderResources(tag),
            user_id: currentUser.id,
            data: {
              ...newData,
            },
          };
        } else {
          data = {
            resource: renderResources(tag),
            user_id: currentUser.id,
            search_string: searchString,
          };
        }

        const res = await exportExcelFn(data);

        dispatch({
          type: 'SET_FILEID',
          payload: {
            fileId: res.data.id,
          },
        });
      } else {
        let data;
        if (tag === TAGS.ALBUMS) {
          const formatedData = {
            actors_name: allData.actors.join(','),
            actress_name: allData.actress.join(','),
            album_name: allData.album.join(','),
            artist_name: allData.artist.join(','),
            product_type: allData.product_type.join(','),
            status: allData.status.join(','),
            label_name: allData.label.join(','),
            music_director: allData.director.join(','),
            album_type: allData.content_type.join(','),
            upc: state?.upc_id?.map((element) => element).join(','),
            start_date: state?.order_start_date,
            end_date: state?.order_end_date,
            start_release_date: state?.start_release_date,
            end_release_date: state?.end_release_date,
            migrated:
              dataFilter === 'all'
                ? ''
                : dataFilter === 'migrated'
                ? 'true'
                : 'false',
          };

          // const newData = {};
          // Object.keys(formatedData).forEach((property) => {
          //   if (formatedData[property] && formatedData[property].length > 0) {
          //     newData[property] = formatedData[property];
          //   } else {
          //     return null;
          //   }
          // });
          const newData = removeEmptyArrayFromObj(formatedData);

          data = {
            resource: renderResources(tag),
            user_id: currentUser.id,
            data: {
              ...newData,
            },
          };
        } else {
          data = {
            resource: renderResources(tag),
            user_id: currentUser.id,
          };
        }
        const res = await exportExcelFn(data);

        dispatch({
          type: 'SET_FILEID',
          payload: {
            fileId: res.data.id,
          },
        });
      }
      setIsLoading(false);
      close();
    }
  };

  // fn convert array of objects to human readable format in excel
  const formatArrayFields = (data) => {
    if (tag === TAGS.USER) {
      const formattedData = data.map((item) => {
        const formatedDate = formatDate(item['Created At']);
        delete item['Created At'];
        return {
          ...item,
          'Created On': formatedDate,
          Roles: item?.Roles.map((role) => camelToSentence(role.name)).join(
            ', ',
          ),
          Type: item.Type?.name,
        };
      });

      return formattedData;
    } else if (tag === TAGS.UPC) {
      const formattedData = data.map((item) => {
        const formatedDate = formatDate(item['Created At']);
        const UPC = item.Upc;
        delete item['Created At'];
        delete item.Upc;

        return {
          ...item,
          'UPC Count': UPC.length,
          UPC: UPC.map((upc) => upc.upc).join(', '),
          'Created On': formatedDate,
        };
      });

      return formattedData;
    } else if (tag === TAGS.ISRC) {
      const formattedData = data.map((item) => {
        const formatedDate = formatDate(item['Created At']);
        const ISRC = item.Isrc;
        delete item['Created At'];
        delete item.Isrc;

        return {
          ...item,
          'ISRC Count': ISRC.length,
          ISRC: ISRC.map((upc) => upc.isrc).join(', '),
          'Created On': formatedDate,
        };
      });

      return formattedData;
    } else if (
      tag === TAGS.ALBUMS ||
      tag === TAGS.ALBUM_APPROVAL ||
      tag === TAGS.DSP ||
      TAGS.ORDER_MANAGEMENT
    ) {
      return data;
    } else {
      const formattedData = data.map((item) => {
        const formatedDate = formatDate(item['Created At']);
        delete item['Created At'];
        return {
          ...item,
          'Created On': formatedDate,
        };
      });
      return formattedData;
    }
  };

  const formatDataFn = () => {
    switch (tag) {
      case TAGS.USER:
        return choosePropertFromTableFn(userColumnMeta, data, TAGS.USER);
      case TAGS.ALBUMS:
        return choosePropertFromTableFn(albumsColumnMeta, data, TAGS.ALBUMS);
      case TAGS.LABEL:
        return choosePropertFromTableFn(labelColumnMeta, data, TAGS.LABEL);
      case TAGS.TRACKS:
        return choosePropertFromTableFn(tracksColumnMeta, data, TAGS.TRACKS);
      case TAGS.ALBUM_APPROVAL:
        return choosePropertFromTableFn(
          albumsColumnMeta,
          data,
          TAGS.ALBUM_APPROVAL,
        );
      case TAGS.TRACK_APPROVAL:
        return choosePropertFromTableFn(
          tracksColumnMeta,
          data,
          TAGS.TRACK_APPROVAL,
        );
      case TAGS.UPC:
        return choosePropertFromTableFn(upcColumnMeta, data, TAGS.UPC);
      case TAGS.DSP:
        return choosePropertFromTableFn(dspColumnMeta, data, TAGS.DSP);
      case TAGS.ISRC:
        return choosePropertFromTableFn(isrcColumnMeta, data, TAGS.ISRC);
      case TAGS.ORDER_MANAGEMENT:
        return choosePropertFromTableFn(
          manualOrderColData,
          data,
          TAGS.ORDER_MANAGEMENT,
        );
      default:
        return choosePropertFromTableFn(albumsColumnMeta, data, tag);
    }
  };

  const choosePropertFromTableFn = (columns, data, tag) => {
    if (tag === TAGS.ALBUMS || tag === TAGS.ALBUM_APPROVAL) {
      const result = data.map((dataEle) => {
        const {
          album_name,
          upc,
          label,
          product_type,
          album_type,
          created_at,
          album_release_date,
          status,
          p_line_name,
          label_c_name,
          language,
          ...rest
        } = dataEle;

        const formatedCreationDate = formatDate(created_at);
        const formatedReleaseDate = formatDate(album_release_date);

        return {
          'ALBUM NAME': album_name,
          UPC: upc,
          LABEL: label?.label_name,
          'C-LINE': label_c_name,
          'P-LINE': p_line_name,
          LANGUAGE: language?.language,
          'Product Type': capitalizeFirstLetter(product_type),
          'CONTENT TYPE':
            album_type === 'non-film'
              ? 'Non-Film'
              : album_type === 'film'
              ? 'Film'
              : album_type,
          'CREATED ON': formatedCreationDate,
          'ALBUM RELEASE DATE': formatedReleaseDate,
          STATUS:
            status === 'waiting_approval'
              ? 'In Review'
              : capitalizeFirstLetter(status),
        };
      });
      return result;
    } else if (tag === TAGS.DSP) {
      const result = data.map((dataEle) => {
        const {
          dsp_name,
          dsp_type,
          point_of_contact,
          email_poc,
          phone_poc,
          delivery_type,
          created_at,
        } = dataEle;
        return {
          'DSP Name': dsp_name,
          'DSP Type':
            dsp_type === 'ott' ? 'OTT' : capitalizeFirstLetter(dsp_type),
          'Point of Contact': point_of_contact,
          Email: email_poc.map((email) => email).join(', '),
          Phone: phone_poc,
          'Delivery Method':
            delivery_type === 'sftp'
              ? 'SFTP'
              : capitalizeFirstLetter(delivery_type),
          'Created On': formatDate(created_at),
        };
      });
      return result;
    } else if (tag === TAGS.ORDER_MANAGEMENT) {
      const result = data.map((dataEle) => {
        const renderStatus = (status) => {
          if (status === 0) {
            return 'Ordered';
          } else if (status === 1) {
            return 'In Progress';
          } else if (status === 2) {
            return 'Paused';
          } else if (status === 3) {
            return 'Delivered';
          } else if (status === 4) {
            return 'Error';
          }
        };
        const {order_name, updated_at, order_status, created_at} = dataEle;
        const album_name = dataEle?.album[0]?.album_name;
        const upc = dataEle?.album[0]?.upc;
        const userName = dataEle?.updated_by?.name;
        const dsp_name = dataEle?.dsp?.dsp_name;
        return {
          'ORDER ID': order_name,
          DSP: dsp_name,
          UPC: upc,
          'PRODUCT NAME': album_name,
          'ORDER DATE': hrFormatDate(created_at),
          'LAST UPDATED DATE': !updated_at
            ? hrFormatDate(created_at)
            : hrFormatDate(updated_at),
          'USER NAME': userName,
          STATUS: renderStatus(order_status),
        };
      });
      return result;
    } else {
      // Below code helps to filter out only the content from table columns
      const accessor = columns.map((col) => col.accessor);

      const result = data.map((dataEle) => {
        const newObject = {};
        const accessorKeys = accessor.filter((ele) =>
          Object.keys(dataEle).includes(ele),
        );
        accessorKeys.forEach((key) => {
          if (accessor.includes(key)) {
            newObject[camelToSentence(key)] = dataEle[key];
          }
        });
        return newObject;
      });
      return result;
    }
  };

  return (
    <ExportToExcelContainer>
      <ul>
        {exportData.map((l) => (
          <li>
            <label>
              <div className="export-option-container">
                <div className="label">{l.label}</div>
                <div className="desc">{l.desc}</div>
              </div>
              <input
                type="radio"
                value={l.key}
                checked={exportVal === l.key}
                onChange={onRadioChange}
              />
            </label>
          </li>
        ))}
      </ul>
      <div className={`export-btn-container`}>
        <button
          onClick={onClickExportHandler}
          disabled={!exportVal}
          className={`export-btn ${isOpen && 'active'} inside`}
        >
          <div className="export-text">
            {isLoading ? 'Exporting' : 'Export now'}
          </div>
          {isLoading && <ButtonLoading />}
        </button>
      </div>
    </ExportToExcelContainer>
  );
};

const ExportToExcel = (props) => {
  const {data, tag, searchString, allData, dataFilter, type} = props;
  const [isOpen, setIsOpen] = useState(false);
  const [exportVal, setExportVal] = useState(null);
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const onRadioChange = (event) => {
    setExportVal(event.target.value);
  };

  const exportData = [
    {
      label: 'Export all pages',
      desc: 'This option exports the entire data.',
      key: 'all',
    },
    {
      label: 'Export current page',
      desc: 'This option exports the current page data.',
      key: 'current',
    },
  ];
  return (
    <StatefulPopover
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      dismissOnClickOutside
      focusLock={true}
      placement={PLACEMENT.bottomLeft}
      content={({close}) => (
        <ExportComponent
          exportData={exportData}
          isOpen={isOpen}
          exportVal={exportVal}
          onRadioChange={onRadioChange}
          data={data}
          tag={tag}
          close={close}
          currentUser={currentUser}
          searchString={searchString}
          allData={allData}
          dataFilter={dataFilter}
          type={type}
        />
      )}
    >
      <div
        className={`export-btn-container ${data.length === 0 && 'disabled'}`}
      >
        <button className={`export-btn ${isOpen && 'active'}`}>
          Export as excel <FiDownload />
        </button>
      </div>
    </StatefulPopover>
  );
};
export default ExportToExcel;
