/* eslint-disable react-hooks/exhaustive-deps */
import {Form, Formik} from 'formik';
import React from 'react';

import useIsrcForm from '../../../../hooks/ISRCManagement/useIsrcForm';
import {isrcDetailsValidationSchema} from '../../../../validation/ISRCManagement/isrcDetails';
import FormikControl from '../../../../components/reusable/Formik/FormikControl';
import {SubmitButton} from '../../../../components/reusable/SubmitButton/SubmitButton';

export default function IsrcForm(props) {
  const {setIsOpen, refetch} = props;
  const {initialValues, onSubmit, isGenerateIsrcLoading} = useIsrcForm({
    setIsOpen,
    refetch,
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={isrcDetailsValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Form>
            <FormikControl
              control="baseinput"
              type="text"
              label="Batch Name"
              name="batch_name"
              initialValue={formik.initialValues.batch_name}
              setFieldValue={formik.setFieldValue}
              setFieldTouched={formik.setFieldTouched}
            />
            <FormikControl
              control="baseinput"
              type="text"
              label="No. of ISRCs to generate"
              name="count"
              initialValue={formik.initialValues.count}
              setFieldValue={formik.setFieldValue}
              setFieldTouched={formik.setFieldTouched}
            />

            <SubmitButton
              disabled={formik.dirty && formik.isValid ? false : true}
              type="submit"
              label={`${
                isGenerateIsrcLoading ? 'Generating ISRC' : 'Generate ISRC'
              } `}
              isLoading={isGenerateIsrcLoading}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
