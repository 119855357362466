/** @format */

import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Form, Formik} from 'formik';
import React, {useContext, useEffect, useState} from 'react';

import Button from '../../../../components/reusable/Button/Button';
import {GlobalContext} from '../../../../Contexts/GlobalProvider';
import {capitalizeFirstLetter} from '../../../../helpers/capitalizeFirstLetter';
import useCreateAlbum from '../../../../hooks/AlbumsManagement/useCreateAlbum';
import useListLanguages from '../../../../hooks/Language/useListLanguage';
import useLocalStorage from '../../../../hooks/reusable/useLocalStorage';
import useGenerateUpc from '../../../../hooks/UPCManagement/useGenerateUpc';
import {createAlbumStep1} from '../../../../validation/CreateAlbum/createAlbumValidation';
import {COLUMN_LABELS} from '../../../../validation/CreateAlbum/FilmType';
import FormikControl from '../../../reusable/Formik/FormikControl';
import {albumReleaseType, albumType, filmZone, nonFilmZone} from './optionData';

const AlbumDetailsBody = (props) => {
  const {
    project,
    setStep,
    step,
    initialState,
    setInitialState,
    setUpcCode,
    upcRef,
  } = props;
  const [css, theme] = useStyletron();
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const {listLanguages} = useListLanguages();
  const [langOptions, setLangOption] = useState([]);
  const {state, dispatch} = useContext(GlobalContext);
  const [step1InitialState, setStep1InitialState] = useState({
    album_type: initialState.album_type,
    product_type: initialState.product_type,
    album_name: initialState.album_name,
    upc: initialState.upc,
    actors_name: initialState.actors_name,
    actress_name: initialState.actress_name,
    language_id: initialState.language_id,
    album_director_name: initialState.album_director_name,
    album_producer_name: initialState.album_producer_name,
    banner_production_name: initialState.banner_production_name,
    label_c_name: initialState.label_c_name,
    p_line_name: initialState.p_line_name,
    film_zone: initialState.film_zone,
    album_release_date: initialState.album_release_date,
    label_id: state.label_name,
  });

  const {
    generateUpcMutation,
    isLoading: isGenerateUpcLoading,
    error: generateUpcError,
  } = useGenerateUpc();

  useEffect(() => {
    if (listLanguages) {
      const languageOptions = listLanguages
        ? listLanguages.map((language) => ({
            value: capitalizeFirstLetter(language.language),
            id: language.id,
          }))
        : [];
      setLangOption(languageOptions);
    }
  }, [listLanguages]);

  const [currentUPC, setCurrentUPC] = useLocalStorage('currentUPC', null);

  const generateUPC = async () => {
    const generateRandom = () => Math.random().toFixed(8).split('.')[1];
    const data = {
      user_id: currentUser.id,
      batch_name: 'sickomode-' + generateRandom(),
      count: 1,
    };
    const upcResponse = await generateUpcMutation({data});
    const upc = upcResponse.data[0];
    upcRef.current = upc;
    setUpcCode(upc);
    setCurrentUPC(upc);
    if (upcResponse?.error) {
      return upcResponse;
    }
    return upc;
  };
  const {
    createAlbumMutation,
    isLoading: isCreateAlbumLoading,
    error: createAlbumError,
  } = useCreateAlbum();
  const onSubmit = async (values) => {
    setInitialState({...values});
    setStep(2);
  };

  return (
    <AlbumDetailsBody.Container bgColor={theme.bg} textColor={theme.textColor}>
      <Formik
        initialValues={step1InitialState}
        validationSchema={createAlbumStep1}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            <div className={`${project ? '' : 'disable'}`}>
              <FormikControl
                control="detailsSelect"
                name="album_type"
                label={COLUMN_LABELS.ALBUM_TYPE}
                options={albumType}
                placeholder="Select album type"
                initialValue={formik.initialValues.album_type}
              />
            </div>
            <div
              className={
                ['Film', 'Non-Film'].includes(formik.values.album_type)
                  ? ''
                  : 'disabled'
              }
            >
              <FormikControl
                control="radioButtons"
                name="product_type"
                label={COLUMN_LABELS.ALBUM_PRODUCT_TYPE}
                options={albumReleaseType}
                placeholder="Select album product type"
                initialValues={formik.initialValues.product_type}
              />
              <FormikControl
                control="detailsInput"
                name="album_name"
                label={COLUMN_LABELS.ALBUM_NAME}
                placeholder="e.g Album EP"
                initialValue={formik.initialValues.album_name}
              />
              <div>
                <FormikControl
                  control="inputWithButton"
                  name="upc"
                  label={COLUMN_LABELS.UPC}
                  storeName="currentUPC"
                  placeholder="Generate UPC"
                  value={formik.values.upc}
                  generateCode={() => generateUPC()}
                  setFieldValue={formik.setFieldValue}
                />
              </div>
              {formik.values.album_type === 'Film' && (
                <>
                  <FormikControl
                    control="multipleInput"
                    name="actors_name"
                    label={COLUMN_LABELS.ACTOR_MALE_NAME}
                    buttonName="Add "
                    placeholder="e.g John Doe"
                    isRequired={formik.values.album_type === 'Film'}
                    initialValue={formik.initialValues.actors_name}
                  />

                  <FormikControl
                    control="multipleInput"
                    name="actress_name"
                    label={COLUMN_LABELS.ACTRESS_FEMALE_NAME}
                    buttonName="Add "
                    placeholder="e.g Jane Doe"
                    isRequired={formik.values.album_type === 'Film'}
                    initialValue={formik.initialValues.actress_name}
                  />
                </>
              )}
              {['Film', 'Non-Film'].includes(formik.values.album_type) && (
                <FormikControl
                  control="detailsSelect"
                  name="film_zone"
                  label={COLUMN_LABELS.FILM_ZONE}
                  placeholder="Select film zone"
                  initialValue={formik.initialValues.film_zone}
                  options={
                    formik.values.album_type === 'Film'
                      ? filmZone
                      : formik.values.album_type === 'Non-Film'
                      ? nonFilmZone
                      : null
                  }
                />
              )}
              <FormikControl
                control="detailsSelect"
                placeholder="Select language"
                name="language_id"
                label={COLUMN_LABELS.ALBUM_LANGUAGE}
                options={langOptions}
                initialValue={formik.initialValues.language_id}
              />
              {formik.values.album_type === 'Film' && (
                <>
                  <FormikControl
                    control="multipleInput"
                    name="album_director_name"
                    label={
                      formik.values.album_type === 'Film'
                        ? COLUMN_LABELS.FILM_DIRECTOR_NAME
                        : COLUMN_LABELS.ALBUM_DIRECTOR_NAME
                    }
                    buttonName="Add "
                    placeholder="e.g Jane Doe"
                    isRequired={formik.values.album_type === 'Film'}
                    initialValue={formik.initialValues.album_director_name}
                  />
                  <FormikControl
                    control="multipleInput"
                    name="album_producer_name"
                    label={
                      formik.values.album_type === 'Film'
                        ? COLUMN_LABELS.FILM_PRODUCER_NAME
                        : COLUMN_LABELS.ALBUM_PRODUCER_NAME
                    }
                    buttonName="Add "
                    placeholder="e.g John Doe"
                    isRequired={formik.values.album_type === 'Film'}
                    initialValue={formik.initialValues.album_producer_name}
                  />
                  <FormikControl
                    control="multipleInput"
                    name="banner_production_name"
                    label={COLUMN_LABELS.PRODUCTION_HOUSE_NAME}
                    buttonName="Add "
                    placeholder="e.g Jane's House"
                    isRequired={formik.values.album_type === 'Film'}
                    initialValue={formik.initialValues.banner_production_name}
                  />
                </>
              )}
              <FormikControl
                control="detailsInput"
                name="label_id"
                disabled={true}
                label="Label Name"
                placeholder="e.g Divo music"
                initialValue={formik.values.label_id}
                isRequired={true}
                labelName={state.label_name}
                setFieldValue={formik.setFieldValue}
              />
              <FormikControl
                control="detailsInput"
                name="label_c_name"
                label={COLUMN_LABELS.C_LINE}
                placeholder="e.g Divo music"
                initialValue={formik.initialValues.label_c_name}
                isRequired={true}
                helpText="If you are not sure, fill in the Label Name"
              />
              <FormikControl
                control="detailsInput"
                name="p_line_name"
                label={COLUMN_LABELS.P_LINE}
                placeholder="e.g Divo music"
                initialValue={formik.initialValues.p_line_name}
                isRequired={true}
                helpText="If you are not sure, fill in the Label Name"
              />
              <FormikControl
                control="detailsInput"
                name="album_release_date"
                type="date"
                label="Album Release Date"
                initialValue={formik.initialValues.album_release_date}
              />
            </div>

            <Container2>
              <button disabled={formik.dirty && formik.isValid ? false : true}>
                Next
              </button>
              {/* <Button
                label="Next"
                disabled={formik.dirty && formik.isValid ? false : true}
              /> */}
            </Container2>
          </Form>
        )}
      </Formik>
    </AlbumDetailsBody.Container>
  );
};

AlbumDetailsBody.Container = styled.div`
  padding: 1rem 0;

  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  .disable {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
  }
`;
const Container2 = styled.div`
  background: #f8f8f8;
  width: 100%;
  height: 70px;
  margin-left: -30px;
  padding-left: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -46px;
  position: sticky;
  bottom: 0;
  // z-index: 9999;
  button {
    width: 80px;
    padding: 12px 20px;
    margin-right: 20px;
    background: #d21919;
    border-radius: 100px;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    outline: none;
    border: none;
  }
  button:disabled,
  button[disabled] {
    background-color: #cccccc;
    color: #666666;
  }
`;

export default AlbumDetailsBody;
