/** @format */

import React from 'react';
import {useNavigate} from 'react-router';

import {formatDate} from '../../helpers/covertDate';
import useLocalStorage from '../../hooks/reusable/useLocalStorage';
import {
  sortAlphabeticalOrderWithoutCase,
  sortStatusAlphabeticalOrderWithoutCase,
} from '../AlbumsManagement/albumColumnHelper';
import {StatusBox} from '../AlbumsManagement/albumsColumnMetaData';
import {albumData} from '../AlbumsManagement/albumTable';
import {ButtonContainer} from '../AlbumsManagement/styled-components/TableComponents';
import {TextWrap} from '../components/TableComponents';

export const albumsApprovalColumnMeta = [
  {
    Header: 'Album Name',
    accessor: 'album_name', // accessor is the "key" in the data
    sortType: (rowA, rowB) => {
      return sortAlphabeticalOrderWithoutCase(
        rowA.original.album_name,
        rowB.original.album_name,
      );
    },
  },
  {
    Header: albumData.upc.Header,
    accessor: albumData.upc.accessor, // accessor is the "key" in the data
  },
  {
    Header: albumData.label.Header,
    accessor: albumData.label.accessor,
    Cell: (data) => {
      return <span> {data && data.row?.original?.label?.label_name} </span>;
    },
    sortType: (rowA, rowB) => {
      return sortStatusAlphabeticalOrderWithoutCase(
        rowA.original.label?.label_name,
        rowB.original.label?.label_name,
      );
    },
  },
  {
    Header: albumData.product_type.Header,
    accessor: albumData.product_type.accessor,
    Cell: (data) => {
      return <TextWrap> {data && data.value} </TextWrap>;
    },
    sortType: (rowA, rowB) => {
      return sortAlphabeticalOrderWithoutCase(
        rowA.original.product_type,
        rowB.original.product_type,
      );
    },
  },
  {
    Header: albumData.album_type.Header,
    accessor: albumData.album_type.accessor,
    Cell: (data) => {
      return <TextWrap> {data && data.value} </TextWrap>;
    },
    sortType: (rowA, rowB) => {
      return sortAlphabeticalOrderWithoutCase(
        rowA.original.album_type,
        rowB.original.album_type,
      );
    },
  },
  {
    Header: albumData.created_on.Header,
    accessor: albumData.created_on.accessor,
    Cell: (data) => {
      return <span> {data && formatDate(data.value)} </span>;
    },
  },
  {
    Header: albumData.status.Header,
    accessor: albumData.status.accessor,
    Cell: (data) => {
      return <StatusBox data={data} />;
    },
    sortType: (rowA, rowB) => {
      return sortStatusAlphabeticalOrderWithoutCase(
        rowA.original.status,
        rowB.original.status,
      );
    },
  },
  {
    Header: '',
    accessor: 'complete',
    disableSortBy: true,
    Cell: (data) => {
      const navigate = useNavigate();
      const [projectId, setProjectId] = useLocalStorage('projectId', null);

      const onClickDetail = (data) => {
        navigate(`${data?.row?.original?.id}`);
        setProjectId(data?.row?.original?.project_id);
      };
      return (
        <ButtonContainer>
          <button onClick={() => onClickDetail(data)}>View Tracks </button>
        </ButtonContainer>
      );
    },
  },
];
