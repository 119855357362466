import {createBrowserHistory} from 'history';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router';

import {bulkOrdersTableData} from '../../components/OrderManagement/tables/bulkOrdersTableData';
import Tab from '../../components/reusable/Tab/Tab';
import useListDsps from '../../hooks/DSPManagement/useListDsps';
import useCallDispatch from '../../hooks/FIlter/useCallDispatch';
import OrderComponent from './OrderComponent';
import {manualOrderColData} from './tables/tableData';
const OrderManagement = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {state}: any = useLocation();

  useEffect(() => {
    if (state?.canNavigateToBulkOrders) {
      // setActiveKey('1');
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    }
  }, [state]);

  const [activeKey, setActiveKey] = useState<string | number>(
    state?.canNavigateToBulkOrders ? '1' : '0',
  );

  const {callDispatch} = useCallDispatch();

  const [searchString, setSearchString] = useState(); // this is for Album Search

  const ORDER_STATUS = [
    {id: 0, label: 'Ordered'},
    {id: 1, label: 'In Progress'},
    {id: 3, label: 'Delivered'},
    {id: 4, label: 'Error'},
    {id: 2, label: 'Paused'},
  ];

  const {listDsps} = useListDsps();

  const dspData = [];
  listDsps?.map((item) => {
    dspData.push({
      id: item.id,
      label: item.dsp_name,
      checked: false,
    });
  });

  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // this is for DSP drop own search
  useEffect(() => {
    if (searchTerm.length > 0) {
      const results = [];
      if (dspData) {
        for (const index in dspData) {
          if (
            dspData[index].label
              .toLowerCase()
              .includes(searchTerm.toLowerCase() || searchTerm)
          ) {
            results.push(dspData[index]);
          }
        }
      }
      setSearchResults(results);
    } else if (searchTerm.length === 0) {
      setSearchResults(dspData);
    }
  }, [searchTerm]);

  useEffect(() => {
    // Specify how to clean up after this effect:
    return function cleanup() {
      callDispatch('CHANGE_DSP_DROP', 'selectedDsp', []);
      callDispatch('CHANGE_UPC_DROP', 'upc_id', []);
      callDispatch('CHANGE_ORDERID_DROP', 'order_id', []);
      callDispatch('CHANGE_START_DATE_DROP', 'order_start_date', '');
      callDispatch('CHANGE_END_DATE_DROP', 'order_end_date', '');
      callDispatch('CHANGE_STATUS_DROP', 'order_status', []);
    };
  }, []);

  const filterData = [
    {
      tag: 'dsp',
      type: 'checkbox',
      data: searchResults.length > 0 ? searchResults : dspData,
      label: 'DSP',
    },
    {
      tag: 'upc',
      type: 'multiline-input',
      data: [],
      label: 'UPC',
    },
    {
      tag: 'order_id',
      type: 'multiline-input',
      data: [],
      label: 'Order ID',
    },
    {
      tag: 'order_date',
      type: 'date',
      data: [],
      label: 'Order Creation Date',
    },
    {
      tag: 'order_status',
      type: 'checkbox',
      data: ORDER_STATUS,
      label: 'Order Status',
    },
  ];

  const handleChange = (event) => {
    event.preventDefault();

    setSearchTerm(event.target.value);
  };

  return (
    <>
      <Tab
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        content={[
          {
            title: 'Order Management',
            component: (
              <OrderComponent
                column={manualOrderColData}
                filterData={filterData}
                handleChange={handleChange}
                searchTerm={searchTerm}
                setSearchString={setSearchString}
                searchString={searchString}
                setSearchTerm={setSearchTerm}
                type="single"
              />
            ),
          },
          {
            title: 'Bulk Orders',
            component: (
              <OrderComponent
                column={bulkOrdersTableData}
                filterData={filterData}
                handleChange={handleChange}
                searchTerm={searchTerm}
                setSearchString={setSearchString}
                searchString={searchString}
                setSearchTerm={setSearchTerm}
                type="group"
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default OrderManagement;
