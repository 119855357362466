import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const fetchLabelHistory = async (key, labelId) => {
  if (labelId) {
    try {
      const res = await axios(`/label_history/${labelId}`, {
        method: 'GET',
      });

      return res.data?.data;
    } catch (error) {
      logError(error, 2);

      return error.response?.data;
    }
  }
};

const useGetLabelHistory = (props) => {
  const {id} = props;
  const {data: getLabelHistory, isLoading, error, isError} = useQuery(
    ['labelHistory', id],
    fetchLabelHistory,
    {
      enabled: id,
    },
  );

  return {
    getLabelHistory,
    isLoading,
    error,
    isError,
  };
};

export default useGetLabelHistory;
