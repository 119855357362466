/* eslint-disable import/no-anonymous-default-export */
import React, {useCallback, useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';

import sizeInMb from '../../../../helpers/sizeInMb';
import useUploadMetaFile from '../../../../helpers/useUploadMetaFile';
import useFile from '../../../../hooks/Files/useFile';
import useLocalStorage from '../../../../hooks/reusable/useLocalStorage';
import useCreateUploadStatus from '../../../../hooks/UploadStatus/useCreateUploadStatus';
import BaseModal from '../../../reusable/Modal/BaseModal';
import ModalBody from '../../../reusable/Modal/ModalBody';
import ModalFooter from '../../../reusable/Modal/ModalFooter';
import FileModalFooter from './FileModalFooter';
import NewFileModal from './NewFileModal';

export default ({
  isOpen,
  files: file_data,
  close,
  setIsComplete,
  project,
  name,
  filetype,
  setEnable,
  setTrackCount,
}) => {
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const {uploadFile, cancel} = useUploadMetaFile({
    metadata: {
      user_id: currentUser.id,
      action: 'CREATE',
      type: 'bulk-upload',
    },
  });
  const {files, setFiles} = useFile(undefined);
  const [progressInfos, setProgressInfos] = useState([]);
  const {createUploadStatus, status, error} = useCreateUploadStatus();
  const [fileId, setFileId] = useLocalStorage('fileId', null);

  const [progress, setProgress] = useState(false);

  useEffect(() => {
    if (file_data) {
      setFiles(file_data);
    }
  }, [file_data]);

  // Upload Files
  useEffect(() => {
    get_progress_info();
  }, [files]);

  // Add tracks by click
  const add_track = () => document.getElementById('new_track').click();

  // Remove tracks
  const remove_track = (track) => {
    setProgressInfos(progressInfos.filter((file) => !(file === track)));
    setFiles(files.filter((file) => !(file === track)));
  };

  // Handle new tracks added
  const handle_change = (new_files) => {
    new_files = Array.from(new_files);
    setFiles([...files, ...new_files]);
    get_progress_info();
  };

  // Get progress info
  const get_progress_info = useCallback(() => {
    const _progressInfos = [];

    files.forEach((file, i) => {
      _progressInfos.push({
        percentage: 0,
        fileName: file.name,
        fileSize: sizeInMb(file.size),
      });
    });

    setProgressInfos([..._progressInfos]);
  }, [files]);

  // Upload function
  const upload = async (idx, file) => {
    const _progressInfos = [...progressInfos];

    const project_id = project.id;

    let path = '';
    name === `audio` ? (path = 'audio') : (path = 'coverart/3000');

    if (project_id && currentUser && file && _progressInfos.length > 0) {
      try {
        const response = await uploadFile(file, project_id, path, (event) => {
          if (_progressInfos[idx]) {
            _progressInfos[idx].percentage = Math.round(
              (100 * event.loaded) / event.total,
            );
            setProgressInfos(_progressInfos);
          }
        });

        return response;
      } catch (error) {
        _progressInfos[idx].percentage = 0;
        setProgressInfos(_progressInfos);
      }
    }
  };

  let checkResponse: string;
  // Upload files
  const uploadFiles = async () => {
    for (let i = 0; i < files.length; i++) {
      const response = await upload(i, files[i]);

      if (!response?.status) {
        checkResponse = 'cancelled';
        break;
      }
    }
  };

  // Handle upload
  const handleUpload = async () => {
    setProgress(true);
    Promise.resolve(uploadFiles()).then(() => {
      if (checkResponse !== 'cancelled') {
        setTimeout(() => {
          setIsComplete(true);
          setEnable(true);
          setFileId(null);
          setProgress(false);
        }, 1000);
      } else {
        setIsComplete(false);
        setEnable(false);
        setFileId(null);
        setProgress(false);
      }
    });
  };

  const handleCancel = () => {
    cancel();
    close();
  };

  return (
    <BaseModal closeable={false} page="files" isOpen={isOpen} close={close}>
      <NewFileModal
        name={name}
        files={files}
        addTrack={add_track}
        removeTrack={remove_track}
        handleChange={handle_change}
        progress={progress}
        progressInfos={progressInfos}
        filetype={filetype}
        setTrackCount={setTrackCount}
      />
      <FileModalFooter
        disabled={files.length === 0 ? true : progress}
        handleUpload={() => handleUpload()}
        close={close}
        handleCancel={handleCancel}
        name={name}
      />
    </BaseModal>
  );
};
