import {Card} from 'baseui/card';
import React, {ReactElement} from 'react';

import {borderNone} from '../../../../styles/base-ui-overrides/common';
import ISRCForm from './ISRCForm';

const ISRCManagementForms = (props) => {
  const {setIsOpen, refetch} = props;
  return (
    <div>
      <Card
        overrides={{
          Root: {
            style: ({$theme}) => {
              return {
                backgroundColor: $theme.bgDefault,
                ...borderNone,
              };
            },
          },
          Body: {
            style: {
              display: 'flex',
              justifyContent: 'flex-end',
            },
          },
        }}
      ></Card>
      {/* Render the ISRC Form */}
      <ISRCForm setIsOpen={setIsOpen} refetch={refetch} />
    </div>
  );
};

export default ISRCManagementForms;
