/* eslint-disable no-prototype-builtins */
/**
 * /* eslint-disable react-hooks/rules-of-hooks
 *
 * @format
 */

/* eslint-disable react-hooks/exhaustive-deps */
import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Button as BaseButton, SHAPE} from 'baseui/button';
import {StatefulPopover} from 'baseui/popover';
import {PLACEMENT} from 'baseui/toast';
import React, {useContext, useEffect, useState} from 'react';
import {AiFillPlayCircle} from 'react-icons/ai';
import {BiRefresh} from 'react-icons/bi';
import {BsFillCaretLeftFill, BsFillCaretRightFill} from 'react-icons/bs';
import {
  FaSort,
  FaSortAlphaDown,
  FaSortAlphaDownAlt,
  FaSortAlphaUp,
  FaSortAmountDown,
  FaSortAmountDownAlt,
  FaSortNumericDown,
  FaSortNumericUpAlt,
} from 'react-icons/fa';
import {HiPause, HiPlay} from 'react-icons/hi';
import {IoMdArrowRoundDown, IoMdPause, IoMdPlay} from 'react-icons/io';
import {MdAddCircle} from 'react-icons/md';
import {
  useAsyncDebounce,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import {VariableSizeList} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import {Button} from '../../../components';
import {TAGS} from '../../../constants/tags';
import {GlobalContext} from '../../../Contexts/GlobalProvider';
import useCallDispatch from '../../../hooks/FIlter/useCallDispatch';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import useUPC from '../../../hooks/UPCManagement/useUPC';
import {SearchContainer} from '../../UserManagement/commonStyledComponents';
import LoadingMain from '../Loading/LoadingMain';
import NoRecords from '../NoRecords/NoRecords';
import Search from '../Search';
import TrackLoadingSpinner from '../TrackLoadingSpinner/TrackLoadingSpinner';

const AddTrackContainer = styled.div`
  // background-color: white;
  // border-radius: 8px;
  // width: 200px !important;
  // margin-left: -65px;
  // box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 5px;
  ul {
    margin: 0;
    padding: 0px;
    display: flex;
    flex-direction: column;
    list-style-type: none;

    li {
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      line-height: 29px;
      &:hover {
        cursor: pointer;
      }
      padding: 15px 15px;
      label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }
      .export-option-container {
        transition: 0.5s all ease-in;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        width: 100%;
        color: #333;
        &:hover {
          color: #dc3831;
          transition: none;
        }
        .icon {
          margin-left: 20px;
          display: flex;
          svg {
            font-size: 16px;
          }
        }
      }
      .label {
        font-size: 14px;
        line-height: 19px;
      }
      &:hover {
        background-color: #f4f4fb;
        cursor: pointer;
      }
    }
  }
`;

const AddTrackDropDown = (props) => {
  const {
    close,
    setDynamicTag,
    setIsOpen,
    mainActionHandler,
    reuseIsrcModalOpen,
  } = props;

  const exportData = [
    {
      label: 'New Track',
      key: 'newtrack',
      // icon: <FaFileExcel />,
    },
    {
      label: 'Existing Track',
      key: 'existingtrack',
      // icon: <FaFileUpload />,
    },
  ];

  const mainActionHandle = (keys) => {
    if (keys === 'newtrack') {
      // setDynamicTag(TAGS.BULK_UPLOAD);
      // setIsOpen(true);
      mainActionHandler();
    } else if (keys === 'existingtrack') {
      // setDynamicTag(TAGS.MANUAL_UPLOAD);
      if (reuseIsrcModalOpen) {
        reuseIsrcModalOpen();
      }
    }
    // else {
    //   setDynamicTag(TAGS.ALBUMS);
    // }
    // setIsOpen(true);
    close();
  };

  return (
    <AddTrackContainer>
      <ul>
        {exportData.map((l) => (
          <li
            key={l.key}
            onClick={() => {
              mainActionHandle(l.key);
            }}
            className="list"
          >
            <label>
              <div className="export-option-container">
                <div className="label">{l.label}</div>
                {/* <div className="icon">{l.icon}</div> */}
              </div>
            </label>
          </li>
        ))}
      </ul>
    </AddTrackContainer>
  );
};

function GlobalFilter({
  tag,
  mainActionHandler,
  mainAction,
  getAlbumDetail,
  noAddTrack,
  refetchHandler,
  isFetching
}) {
  return (
    <SearchContainer noAddTrack={noAddTrack}>
      {(tag === 'tracks') | (tag === 'albums') ? (
        <div className="group_btn">
          <div className="refresh-btn">
            {/* <Button
              disabled={
                ['approved', 'waiting_approval'].includes(mainActionHandler[2])
                  ? true
                  : false
              }
              label="Update Cover Art"
              onClick={mainActionHandler[1]}
              shape="round"
              endEnhancer={() => <MdAddCircle />}
            /> */}

              <BaseButton size="mini" onClick={refetchHandler} shape="circle">
                <span className={`svg-container ${isFetching && 'rotating'}`}>
                  <BiRefresh size={20} />
                </span>
              </BaseButton>

          </div>
          <StatefulPopover
            dismissOnClickOutside
            focusLock={true}
            placement={PLACEMENT.bottomLeft}
            content={({close}) => (
              <AddTrackDropDown
                close={close}
                // setDynamicTag={setDynamicTag}
                // setIsOpen={setIsOpen}
                mainActionHandler={mainActionHandler[0]}
                reuseIsrcModalOpen={mainActionHandler[4]}
              />
            )}
          >
            <div
              className={`btn-container`}
              style={{
                pointerEvents: `${
                  ['orderable', 'waiting_approval'].includes(
                    mainActionHandler[2],
                  ) || mainActionHandler[3]?.toLowerCase() === 'single'
                    ? 'none'
                    : getAlbumDetail?.Order_management?.length > 0
                    ? 'none'
                    : 'auto'
                }`,
              }}
            >
              {noAddTrack ? null : (
                <Button
                  disabled={
                    ['orderable', 'waiting_approval'].includes(
                      mainActionHandler[2],
                    ) || mainActionHandler[3]?.toLowerCase() === 'single'
                      ? true
                      : false || getAlbumDetail?.Order_management?.length > 0
                  }
                  label="Add Track"
                  // onClick={mainActionHandler[0]}
                  shape="round"
                  endEnhancer={() => <MdAddCircle />}
                />
              )}
            </div>
          </StatefulPopover>
        </div>
      ) : null}

      {tag === 'track_approval' && mainAction ? (
        <div className="group_btn">{mainAction()}</div>
      ) : null}
    </SearchContainer>
  );
}

const Table = (props) => {
  const {
    columns,
    data,
    onClickRow,
    setSidebarData,
    tag,
    setIsOpen,
    setNewFormTag,
    loadMore,
    hasNextPage,
    mainActionHandler,
    setTrackIsrc,
    footerComponent,
    playing,
    setSelectedValue,
    selectedValue,
    handlePlayPause,
    duration,
    trackIsrc,
    getAlbumDetail,
    noAddTrack,
    refetchHandler,
    isFetching
  } = props;

  const [css, theme] = useStyletron();
  const {callDispatch} = useCallDispatch();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    preGlobalFilteredRows,
    state,
    setGlobalFilter,
    prepareRow,
    selectedFlatRows,
    state: {selectedRowIds},
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: 'created_at',
            desc: true,
          },
          {
            id: 'track_order',
            desc: false,
          },
        ],
      },
    },
    useResizeColumns,
    useFlexLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.useInstanceBeforeDimensions.push(({headerGroups}) => {
        // fix the parent group of the selection button to not be resizable
        let lastItem = headerGroups[0].headers.length - 1;
        const selectionGroupHeader = headerGroups[0].headers[lastItem];
        // selectionGroupHeader.canResize = false;
      });
    },
  );
  const [showLeftChevron, setShowLeftChevron] = React.useState(false);
  const [showRightChevron, setShowRightChevron] = React.useState(true);
  const scrollWrapper = React.useRef();
  const tbodyRef = React.useRef();
  const [projectId, setProjectId] = useLocalStorage('projectId', null);
  const [fixedBarWidth, setFixedBarWidth] = useState(null);
  const [onHoverId, setOnHoverId] = useState(null);
  const {state: GlobalState, dispatch} = useContext(GlobalContext);

  React.useEffect(() => {
    if (scrollWrapper.current) {
      setFixedBarWidth(scrollWrapper.current?.clientWidth);
    }
  }, [scrollWrapper]);

  React.useEffect(() => {
    trackScroll();
  }, []);

  React.useEffect(() => {
    let isDifferent = false;
    //  To avoid infinite loop
    if (selectedValue) {
      if (selectedValue.length !== selectedFlatRows.length) {
        isDifferent = true;
      } else {
        selectedValue.forEach((data, i) => {
          if (data !== selectedFlatRows[i]) {
            isDifferent = true;
          }
        });
      }
      if (tag === 'track_approval' && isDifferent === true) {
        setSelectedValue(selectedFlatRows);
      }
    }
  }, [tag, selectedFlatRows]);

  const scrollRight = (number) => {
    scrollWrapper.current.scrollBy(number, 0);
  };
  const scrollLeft = (number) => {
    scrollWrapper.current.scrollBy(number, 0);
  };

  const trackScroll = () => {
    const bodyWidth = tbodyRef.current.clientWidth;
    const scrollLeft = scrollWrapper.current.scrollLeft;
    let scrollWidth = scrollWrapper.current.clientWidth;

    if (scrollWrapper.current.scrollLeft > 0) {
      setShowLeftChevron(true);
    } else {
      setShowLeftChevron(false);
    }
    if (bodyWidth > scrollLeft + scrollWidth) {
      setShowRightChevron(true);
    } else {
      setShowRightChevron(false);
    }
  };

  const getRowProps = (row) => {
    return {
      onMouseEnter: () => {
        setOnHoverId(row.index);
      },
      onMouseLeave: () => {
        setOnHoverId(null);
      },
      onClick: () => {
        // store the clicked row data
        if (row?.original?.project_id) {
          setProjectId(row?.original?.project_id);
        }
        if (row) {
          if (
            tag === 'user' ||
            tag === 'tracks' ||
            tag === 'dsp' ||
            tag === 'label' ||
            tag === 'albums' ||
            tag === 'album_approval' ||
            tag === 'track_approval'
          ) {
            setSidebarData((prevData) => ({prevData, ...row}));
          }
        }
      },

      style: {
        textAlign: 'right',
      },
    };
  };

  const setTrackIsrcFn = (data) => {
    onClickRow(false);

    // if (!playing) {
    //   setTrackIsrc(data);
    // } else if (playing && trackIsrc !== data) {
    //   setTrackIsrc(data);
    // } else if (playing && trackIsrc === data) {
    //   handlePlayPause();
    // }
    if (data.Reused_Track) {
      callDispatch(
        'EXISTING_PROJECT_ID',
        'existingProjectId',
        data.Reused_Track?.album?.project_id,
      );
    } else {
      callDispatch('EXISTING_PROJECT_ID', 'existingProjectId', null);
    }

    if (!playing) {
      setTrackIsrc(data.isrc);
      handlePlayPause();
    } else if (playing && trackIsrc !== data.isrc) {
      setTrackIsrc(data.isrc);
    } else if (playing && trackIsrc === data.isrc) {
      handlePlayPause();
    }

    // if (trackIsrc === id) {
    //   setTrackIsrc(id);
    // } else {
    //   setTrackIsrc(id);
    //   handlePlayPause();
    // }
  };

  const getHeaderProps = (column) => {
    return {
      onClick: () => (column.canSort ? column.toggleSortBy() : null),
    };
  };

  const getCellProps = (cell) => {
    return {
      onClick: () => {
        if (
          cell.column?.id === 'track_order' ||
          cell.column?.id === 'selection' ||
          tag === 'upc' ||
          tag === 'isrc' ||
          tag === 'role' ||
          tag === 'type'
        ) {
          onClickRow(false);
        } else {
          if (tag === 'track_approval') {
            // cell.row?.toggleRowSelected(false);
            cell.column?.preFilteredRows.map((data) => {
              data.toggleRowSelected(false);
            });
          }
          onClickRow(true);
        }
      },
    };
  };

  const getColumnWidth = (headerText) => {
    if (headerText === '#') {
      return 35;
    }
    const maxWidth = 350;
    const magicSpacing = 8;
    return Math.max(
      Math.min(maxWidth, headerText.length * magicSpacing) + 10,
      100,
    );
  };

  const itemCount = hasNextPage ? data.length + 1 : data.length;

  const RenderRow = React.useCallback(({index, style}) => {
    const row = rows[index];
    prepareRow(row);

    const renderPlayBtn = (isrc) => {
      if (!playing) {
        return <HiPlay />;
      } else if (playing && trackIsrc !== isrc) {
        return <HiPlay />;
      } else if (playing && trackIsrc === isrc) {
        return <HiPause />;
      }
    };

    return (
      <div
        style={row?.original?.is_disabled ? {...style, opacity: 0.4} : style}
        className="ListRow"
      >
        <div {...row.getRowProps(getRowProps(row))} className="tr">
          {row.cells.map((cell) => {
            return (
              <div
                {...cell.getCellProps(getCellProps(cell))}
                className={
                  cell?.column?.id === 'selection'
                    ? 'td selection'
                    : cell?.column?.id === 'track_order' &&
                      cell.row.index === onHoverId
                    ? 'td play'
                    : 'td'
                }
              >
                {cell?.column?.Header === '' ? (
                  cell.render('Cell')
                ) : cell?.column?.id === 'track_order' &&
                  cell.row.index === onHoverId ? (
                  <div
                    onClick={() => setTrackIsrcFn(cell?.row?.original)}
                    className="play-icon"
                  >
                    {renderPlayBtn(cell?.row?.original?.isrc)}

                  </div>
                ) : (
                  <p>{cell.render('Cell')}</p>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  });

  const renderSortIconDesc = (header) => {
    switch (header) {
      case 'Roles':
        return <FaSortNumericDown />;
      case 'Created On':
        return <FaSortAmountDown />;
      case 'Album Release Date':
        return <FaSortAmountDown />;
      case '':
        return null;
      default:
        return <FaSortAlphaDownAlt />;
    }
  };

  const renderSortIconAsc = (header) => {
    switch (header) {
      case 'Roles':
        return <FaSortNumericUpAlt />;
      case 'Created On':
        return <FaSortAmountDownAlt />;
      case 'Album Release Date':
        return <FaSortAmountDownAlt />;
      case '':
        return null;
      default:
        return <FaSortAlphaDown />;
    }
  };

  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        tag={tag}
        mainActionHandler={mainActionHandler}
        mainAction={footerComponent}
        getAlbumDetail={getAlbumDetail}
        noAddTrack={noAddTrack}
        refetchHandler={refetchHandler}
        isFetching={isFetching}
      />
      <TableStyleWrapper tag={tag} theme={theme} fixedBarWidth={fixedBarWidth}>
        <div className="table-container">
          {columns.length > 2 && showLeftChevron && (
            <div
              onClick={() => scrollLeft(-175)}
              className="table-left-chevron-container"
            >
              <BsFillCaretLeftFill />
            </div>
          )}
          <div
            className="table-style-wrapper"
            ref={scrollWrapper}
            onScroll={trackScroll}
          >
            <div className="table" {...getTableProps()}>
              <div className="thead">
                {
                  // Loop over the header rows
                  headerGroups.map((headerGroup) => (
                    // Apply the header row props
                    <div className="tr" {...headerGroup.getHeaderGroupProps()}>
                      {
                        // Loop over the headers in each row
                        headerGroup.headers.map((column) => (
                          // Apply the header cell props
                          <div
                            className="th"
                            {...column.getHeaderProps([getHeaderProps(column)])}
                          >
                            <span>
                              {
                                // Render the header
                                column.render('Header')
                              }
                            </span>

                            {column.canResize && (
                              <span
                                {...column.getResizerProps()}
                                className={`resizer ${
                                  column.isResizing ? 'isResizing' : ''
                                }`}
                              />
                            )}
                            <span
                              className="sort"
                              {...column.getSortByToggleProps()}
                            >
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  renderSortIconDesc(column.Header)
                                ) : (
                                  renderSortIconAsc(column.Header)
                                )
                              ) : column.Header === '' ||
                                column.id === 'selection' ? null : (
                                <FaSort />
                              )}
                            </span>
                          </div>
                        ))
                      }
                    </div>
                  ))
                }
              </div>
              {/* Apply the table body props */}
              <div className="tbody" {...getTableBodyProps()} ref={tbodyRef}>
                {data && data.length === 0 ? (
                  <NoRecords btnLabel={null} onClick={null} page="approval" />
                ) : (
                  <InfiniteLoader
                    isItemLoaded={(index) => index < data.length}
                    itemCount={itemCount}
                    loadMoreItems={false}
                  >
                    {({onItemsRendered, ref}) => (
                      <VariableSizeList
                        height={
                          tag === 'track_approval' || tag === 'tracks'
                            ? rows.length * 66 + 80
                            : tag === 'albums'
                            ? rows.length * 66 + 10
                            : rows.length * 66
                        }
                        itemCount={rows.length}
                        overscanCount={3}
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                        className="List"
                        itemSize={() => 65}
                      >
                        {RenderRow}
                      </VariableSizeList>
                    )}
                  </InfiniteLoader>
                )}
              </div>
            </div>
          </div>
          {columns.length > 4 && showRightChevron && (
            <div
              onClick={() => scrollRight(175)}
              className="table-right-chevron-container"
            >
              <BsFillCaretRightFill />
            </div>
          )}
        </div>
        {/* {footerComponent ? (
          <div className="footer-tab">{footerComponent()}</div>
        ) : null} */}
      </TableStyleWrapper>
    </>
  );
};

const TableStyleWrapper = styled.div`
  .footer-tab {
    position: fixed;
    width: ${(props) => props.fixedBarWidth && props.fixedBarWidth - 30}px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 80px;
    bottom: 0;
    padding-right: 30px;
    box-shadow: 0px -8px 20px rgba(0, 0, 0, 0.1);
  }
  .table-style-wrapper {
    display: block;
    overflow: auto;
    background: ${(props) => props.theme.bg && props.theme.bg};
  }
  .table-container {
    position: relative;
    overflow: hidden;
    .table-right-chevron-container,
    .table-left-chevron-container {
      position: absolute;
      height: 100%;
      cursor: pointer;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease-in;
    }
    .table-right-chevron-container {
      background: linear-gradient(
        to right,
        ${(props) =>
            props.theme.transparentTableGradient &&
            props.theme.transparentTableGradient}
          0%,
        ${(props) =>
            props.theme.opaqueTableGradient && props.theme.opaqueTableGradient}
          180%
      );
      top: 50px;
      bottom: 0;
      right: 0;
    }
    .table-left-chevron-container {
      background: linear-gradient(
        to right,
        ${(props) =>
            props.theme.opaqueTableGradient &&
            props.theme.opaqueTableGradient} -80%,
        ${(props) =>
            props.theme.transparentTableGradient &&
            props.theme.transparentTableGradient}
          100%
      );
      top: 50px;
      bottom: 0;
      left: 0;
    }
  }
  .table {
    font-size: 14px;
    border-spacing: 0;
    border-radius: 5px;
    color: ${(props) => props.theme.textColor && props.theme.textColor};
    width: 100%;
    .tr:hover {
      cursor: pointer;
      // background: ${(props) => props.theme.rowHover} !important;
      box-shadow: -4px 2px 10px rgba(0, 0, 0, 0.15);

      color: ${(props) => props.theme.rowHoverText} !important;
    }
    .List {
      background-color: #eff1f4;

      > div {
        background: white;
      }
    }
  }

  .thead {
    background: ${(props) =>
      props.theme.tableHeadBg && props.theme.tableHeadBg} !important;
    color: ${(props) =>
      props.theme.tableHeadColor && props.theme.tableHeadColor} !important;
    text-transform: uppercase;
    border: none !important;
    .tr {
      border-bottom: none;
    }
    .tr:hover {
      box-shadow: none;
      background: ${(props) =>
        props.theme.tableHeadBg && props.theme.tableHeadBg} !important;
      color: ${(props) =>
        props.theme.tableHeadColor && props.theme.tableHeadColor} !important;
    }
    .th {
      text-align: center;
      font-weight: bold;
      text-align: ${(props) =>
        props.tag === 'type' || props.tag === 'role' ? `left` : `left`};
    }
  }

  .th,
  .td {
    margin: 0;
    padding: 0.8rem;
    :last-child {
      border-right: 0;
    }

    .sort {
      display: inline-block;
      text-align: 'center';
      margin-left: 2px;
    }
    .resizer {
      right: 0;
      background: ${(props) =>
        props.theme.tableBorderColor && props.theme.tableBorderColor};
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      touch-action: none;

      &.isResizing {
        background: red;
      }
    }
  }

  .selection {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .play {
    background-color: white;
    .play-icon {
      svg {
        color: ${(props) => props.theme.divoRed && props.theme.divoRed};
        font-size: 34px;
      }
    }
  }

  .th[role='columnheader'] {
    display: flex;
    justify-content: space-between;
  }
  .tr[role='row'] {
    border-bottom: 1px solid
      ${(props) => props.theme.tableBorderColor && props.theme.tableBorderColor};
    align-items: center;
    height: 65px;
  }
  .td[role='cell'] {
    margin: 0;
    padding: 0.8rem;

    text-align: ${(props) =>
      props.tag === 'type' || props.tag === 'role' ? `left` : `left`};
    vertical-align: middle;

    transition: all 0.15s linear;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      max-height: 65px;
      line-height: 1.65 !important;
      margin: 0;
    }

    :last-child {
      .td {
        border-bottom: 0;
      }
    }
  }
`;

export default Table;
