import styled from '@emotion/styled';
import React, {useEffect, useRef, useState} from 'react';
import {MdEdit} from 'react-icons/md';
import {RiImageEditFill} from 'react-icons/ri';

import {rename_file} from '../../../helpers/renameFile';
import useUploadMetaFile from '../../../helpers/useUploadMetaFile';
import useCallDispatch from '../../../hooks/FIlter/useCallDispatch';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import Button from '../Button/Button';

export default function CustomFileUploader(props) {
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const {callDispatch, state} = useCallDispatch();
  const {
    data,
    setProgressInfo,
    setImageLoaded,
    progressInfo,
    currentIsrc,
    label,
    accept,
    refetch,
    imgRefetch,
  } = props;

  const {uploadFile} = useUploadMetaFile({
    metadata: {
      album_id: data?.selectedData?.id,
      user_id: currentUser?.id,
      action: data?.selectedData?.cover_art ? 'UPDATE' : 'CREATE',
    },
  });

  const [file, setFile] = useState(null);
  // const project_id = window.localStorage.getItem('projectId');
  const project_id = data?.selectedData?.project_id;
  const upc = data?.selectedData?.upc || data?.albumData?.track_upc;
  let isrc: string;

  if (data?.selectedData) {
    isrc = data?.selectedData?.isrc;
  } else {
    isrc = currentIsrc;
  }
  let fileRef = null;

  useEffect(() => {
    if (progressInfo === 100) {
      setImageLoaded(true);
      refetch();
      setTimeout(() => {
        imgRefetch();
      }, 2000);
    }
  }, [progressInfo, setImageLoaded]);

  const onFileSelected = async (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    const file = e.target.files[0];

    if (['audio/x-wav', 'audio/wav'].includes(file.type)) {
      const new_file = rename_file(file, isrc);
      await uploadFile(new_file, project_id, 'audio', (event) => {
        setProgressInfo(Math.round((100 * event.loaded) / event.total));
      });
    } else {
      const idxDot = file.name.lastIndexOf('.') + 1;
      const extFile = file.name.substr(idxDot, file.name.length).toLowerCase();
      if (extFile == 'jpg') {
        //do whatever want to do
        const img = new Image();
        img.onload = async () => {
          const {naturalWidth: width, naturalHeight: height} = img;
          if (height <= 3000 && width <= 3000) {
            const new_file = rename_file(file, upc);
            await uploadFile(new_file, project_id, 'coverart/3000', (event) => {
              setProgressInfo(Math.round((100 * event.loaded) / event.total));
            });
            const objectURL = URL.createObjectURL(file);
            callDispatch('TEMP_IMG', 'tempImg', {
              url: objectURL,
              id: data?.selectedData?.id,
            });
          } else {
            alert('You have uploaded image dimensions greater than 3000 px');
          }
        };
        img.src = URL.createObjectURL(file);
      } else {
        alert('Only jpg files are allowed!');
      }
    }
  };

  const handleBrowse = function (e) {
    e.preventDefault();
    fileRef.click();
  };

  return (
    <ReUploadContainer>
      <input
        style={{display: 'none'}}
        id="logo-input"
        ref={(r) => {
          fileRef = r;
        }}
        type="file"
        accept={accept}
        onChange={onFileSelected}
      />

      <div onClick={handleBrowse} className="icon-wrapper">
        <MdEdit />
      </div>
    </ReUploadContainer>
  );
}

const ReUploadContainer = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #00000087;
  cursor: pointer;
  border-radius: 50px;

  svg {
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: white;
  }
`;
