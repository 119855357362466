import styled from '@emotion/styled';
import {Block} from 'baseui/block';
import {StatefulPopover} from 'baseui/popover';
import {Tab, Tabs} from 'baseui/tabs';
import {StatefulTooltip} from 'baseui/tooltip';
import {data} from 'cypress/types/jquery';
import React, {useContext, useRef, useState} from 'react';
import {useEffect} from 'react';
import {BiChevronDown, BiChevronUp, BiReset} from 'react-icons/bi';
import {IoMdClose} from 'react-icons/io';

import OrderReleaseDateForm from '../../../OrderManagement/OrderReleaseDateForm';
import OrderUpcForm from '../../../OrderManagement/OrderUpcForm';
import ProductReleaseDateForm from './ProductReleaseDateForm';
import {GlobalContext} from '../../../../Contexts/GlobalProvider';
import useCallDispatch from '../../../../hooks/FIlter/useCallDispatch';
import useOnClickOutside from '../../../../hooks/reusable/useOnClickOutside';
import useSelectCheckBox from '../../../../hooks/reusable/useSelectCheckBox';
import CheckBoxComponent from '../../../../components/reusable/CheckBox/CheckBoxComponent';
import NoRecords from '../../../../components/reusable/NoRecords/NoRecords';
import Search from '../../../../components/reusable/Search';
import {
  rootStyle,
  TabBar,
  TabContent,
} from '../../../../components/reusable/Tab/TabOverrides';
import AlbumFilterTab from './AlbumFilterTab';
import StatusCheckBox from './CheckBox';
import TabPopOver from './TabPopOver';
import useLocalStorage from '../../../../hooks/reusable/useLocalStorage';
import {useLocation} from 'react-router';

function AlbumFilter(props) {
  const {
    actors,
    actress,
    director,
    albumName,
    artist,
    label,
    setAllData,
    toggleSearch,
    setBubble,
    allData,
    setPage,
    setToggleSearch,
    setDataFilter,
    dataFilter,
  } = props;
  // const {state, dispatch}:any = useContext(GlobalContext);
  const STATUS = [
    {id: 0, value: 'Orderable', key: 'orderable', isChecked: false},
    {id: 1, value: 'Complete', key: 'complete', isChecked: false},
    {id: 2, value: 'InComplete', key: 'incomplete', isChecked: false},
    {id: 3, value: 'In Review', key: 'waiting_approval', isChecked: false},
    {id: 4, value: 'Declined', key: 'declined', isChecked: false},
  ];
  const PRODUCT_TYPE = [
    {id: 0, value: 'Single', key: 'Single', isChecked: false},
    {id: 1, value: 'Album', key: 'Album', isChecked: false},
  ];
  const CONTENT_TYPE = [
    {id: 0, value: 'film', key: 'film', isChecked: false},
    {id: 1, value: 'non-film', key: 'non-film', isChecked: false},
  ];
  const {callDispatch, state} = useCallDispatch();
  const tabsData = [
    {
      name: 'Actor Name',
      data: actors,
      tag: 'actors',
    },
    {
      name: 'Actress Name',
      data: actress,
      tag: 'actress',
    },
    {
      name: 'Music Director Name',
      data: director,
      tag: 'director',
    },
    {
      name: 'Album Name',
      data: albumName,
      tag: 'album',
    },
    {
      name: 'Artist Name',
      data: artist,
      tag: 'artist',
    },
    {
      name: 'Label Name',
      data: label,
      tag: 'label',
    },
    {
      name: 'Product Status',
      data: STATUS,
      tag: 'status',
    },
    {
      name: 'Product Type',
      data: PRODUCT_TYPE,
      tag: 'product_type',
    },
    {
      name: 'Content Type',
      data: CONTENT_TYPE,
      tag: 'content_type',
    },
  ];

  // const [tabArr, setTabArr] = useState(tabsData);
  const [tabArr, setTabArr] = useLocalStorage('tabArr', tabsData);
  const [checkedAcrossTabs, setCheckedAcrossTabs] = useState([]);

  const [open, setOpen] = useState(false);
  const [startValue, setStartValue] = React.useState([new Date()]);
  const [endValue, setEndValue] = React.useState([new Date()]);
  const [startReleaseValue, setStartReleaseValue] = React.useState([
    new Date(),
  ]);
  const [endReleaseValue, setEndReleaseValue] = React.useState([new Date()]);

  const [activeKey, setActiveKey] = React.useState<string | number>('0');
  const [count, setCount] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const upc = state.upc_id?.length > 0 ? state.upc_id?.length : 0;
    const creationDate =
      state.order_start_date && state.order_end_date
        ? 2
        : state.order_start_date
        ? 1
        : state.order_end_date
        ? 1
        : 0;
    const releaseDate =
      state.start_release_date && state.end_release_date
        ? 2
        : state.start_release_date
        ? 1
        : state.end_release_date
        ? 1
        : 0;
    const count = upc + creationDate + releaseDate + checkedAcrossTabs?.length;
    setBubble(count);
    setCount(count);
    if (
      state?.upc_id?.length > 0 ||
      state.order_start_date?.length > 1 ||
      state.order_end_date?.length > 1 ||
      state.start_release_date?.length > 1 ||
      state.end_release_date?.length > 1
    ) {
      setPage(1);
    }
  }, [checkedAcrossTabs, state]);

  useEffect(() => {
    const localCheckedAcrossTabs = [];
    tabArr.forEach((tab) => {
      const checked = [];
      if (tab && tab.data) {
        tab.data.forEach((element) => {
          if (element.isChecked) {
            checked.push({
              ...element,
              tab: tab.name,
            });
          }
        });
      }
      localCheckedAcrossTabs.push(...checked);
    });
    setCheckedAcrossTabs(localCheckedAcrossTabs);
  }, [tabArr]);

  const handleAllCheckedClose = (element) => {
    const localTabArr = tabArr.map((tab) => {
      if (tab.name === element.tab) {
        // console.log('handleAllCheckedCloseeee');
        const localTabData = tab.data.map((tabdata) => {
          // console.log('handleAllCheckedCloseeee Tab', tab);
          if (tabdata.id === element.id) {
            return {
              ...tabdata,
              isChecked: false,
            };
          }
          return tabdata;
        });
        return {...tab, data: localTabData};
      }
      return tab;
    });

    setTabArr([...localTabArr]);
  };

  const setFilterDataFn = (name, data) => {
    setAllData((prevState) => ({
      ...prevState,
      [name]: data,
    }));
  };

  useEffect(() => {
    setFilterValuesFn();
  }, [tabArr]);

  const setFilterValuesFn = () => {
    tabArr
      .map((tab) => {
        if (tab && tab.data) {
          return {
            values: tab.data
              .filter((element) => {
                if (element.isChecked) {
                  return element;
                }
              })
              .map((ele) => ele.value),
            tag: tab.tag,
            key: tab.data
              .filter((element) => {
                if (element.isChecked) {
                  return element;
                }
              })
              .map((ele) => ele.key),
          };
        }
      })
      .map((item) => {
        if (item.tag === 'status') {
          setFilterDataFn(item.tag, item.key);
        } else {
          setFilterDataFn(item.tag, item.values);
        }
      });
  };
  const removeUpc = (id) => {
    const val = state?.upc_id?.filter((item, index) => index !== id);
    callDispatch('CHANGE_UPC_DROP', 'upc_id', val);
  };

  const resetHandler = () => {
    setActiveKey('0');
    setToggleSearch(false);
    setAllData([]);
    setStartValue([new Date()]);
    setEndValue([new Date()]);
    setStartReleaseValue([new Date()]);
    setEndReleaseValue([new Date()]);
    setDataFilter('all');
    callDispatch('CHANGE_START_DATE_DROP', 'order_start_date', '');
    callDispatch('CHANGE_END_DATE_DROP', 'order_end_date', '');
    callDispatch('CHANGE_START_RELEASE_DATE_DROP', 'start_release_date', '');
    callDispatch('CHANGE_END_RELEASE_DATE_DROP', 'end_release_date', '');
    callDispatch('CHANGE_UPC_DROP', 'upc_id', []);
    const localTabArr = tabArr.map((tab) => {
      const localTabData = tab.data.map((tabdata) => {
        return {
          ...tabdata,
          isChecked: false,
        };
      });
      return {...tab, data: localTabData};
    });
    setTabArr([...localTabArr]);
  };
  return (
    <>
      {toggleSearch && (
        <>
          {/* <TabBubble>
          {allData.actors?.length > 0 ? <span className='first'></span> : <span className='first1'></span>}
          {allData.actress?.length > 0 ? <span className='second'></span> : <span className='second2'></span>}
          {allData.director?.length > 0 ? <span className='third'></span> : <span className='third3'></span>}
          {allData.album?.length > 0 ? <span className='fifth'></span> : <span className='fifth5'></span>}
          {allData.artist?.length > 0 ? <span className='fourth'></span> : <span className='fourth4'></span>}
          {allData.label?.length > 0 ? <span className='sixth'></span> : <span className='sixth6'></span>}
          {allData.status?.length > 0 ? <span className='seventh'></span> : <span className='seventh7'></span>}
          {allData.product_type?.length > 0 ? <span className='eigth'></span> : <span className='eigth8'></span>}
          {allData.content_type?.length > 0 ? <span className='nine'></span> : <span className='nine9'></span>}
          {state?.upc_id?.length > 0 ? <span className='ten'></span> : <span className='ten10'></span>}
          {state.order_start_date ? <span className='eleven'></span> : <span className='eleven11'></span>}
        </TabBubble> */}
          <WholeContainer>
            <div className="migrated-tabs">
              <fieldset
                className={`tab-filter ${
                  dataFilter === 'migrated'
                    ? 'migrated'
                    : dataFilter === 'fresh'
                    ? 'fresh'
                    : 'all'
                }`}
              >
                <p className="tabs label">
                  <label onClick={() => setDataFilter('migrated')}>
                    MIGRATED
                  </label>
                </p>
                <p className="tabs label">
                  <label onClick={() => setDataFilter('fresh')}>FRESH</label>
                </p>
                <p className="tabs label">
                  <label onClick={() => setDataFilter('all')}>ALL</label>
                </p>
              </fieldset>
            </div>
            <Tabs
              overrides={{
                TabBar: {
                  style: {
                    backgroundColor: 'white',
                    paddingLeft: '24px',
                    display: 'flex',
                    flexWrap: 'wrap',
                  },
                },

                Tab: {
                  component: (data) => <AlbumFilterTab data={data} />,
                },
              }}
              activeKey={activeKey}
              onChange={({activeKey}) => {
                setActiveKey(activeKey);
              }}
            >
              {tabArr.map((tab) => {
                return (
                  <Tab
                    overrides={{
                      Tab: {
                        component: (data) => {
                          return (
                            <button
                              className={data.$active ? 'active' : 'no-active'}
                              onClick={data.onClick}
                            >
                              {tab.name}
                              {data.$active ? (
                                <BiChevronUp />
                              ) : (
                                <BiChevronDown />
                              )}
                            </button>
                          );
                        },
                      },
                    }}
                    title={tab.name}
                  >
                    {tab.data?.length > 0 ? (
                      <MainCheckBox
                        data={tab.data}
                        tag={tab.tag}
                        setOpen={setOpen}
                        open={open}
                        name={tab.tag}
                        setAllData={setAllData}
                        setPage={setPage}
                        setTabArr={setTabArr}
                      />
                    ) : (
                      <NoRecords name="filter" />
                    )}
                  </Tab>
                );
              })}

              <Tab
                title="UPC"
                overrides={{
                  Tab: {
                    component: (data) => (
                      <TabPopOver
                        data={data}
                        name="UPC"
                        content={() => <OrderUpcForm tag="upc" name="upc" />}
                      />
                    ),
                  },
                }}
              ></Tab>
              <Tab
                title="Creation date"
                overrides={{
                  Tab: {
                    component: (data) => (
                      <TabPopOver
                        data={data}
                        name="Creation date"
                        content={() => (
                          <OrderReleaseDateForm
                            tag="creation_date"
                            name="creation_date"
                            startValue={startValue}
                            setStartValue={setStartValue}
                            endValue={endValue}
                            setEndValue={setEndValue}
                          />
                        )}
                      />
                    ),
                  },
                }}
              ></Tab>
              <Tab
                title="Album Release Date"
                overrides={{
                  Tab: {
                    component: (data) => (
                      <TabPopOver
                        data={data}
                        name="Album Release Date"
                        content={() => (
                          <ProductReleaseDateForm
                            tag="album_release_date"
                            name="album_release_date"
                            startValue={startReleaseValue}
                            setStartValue={setStartReleaseValue}
                            endValue={endReleaseValue}
                            setEndValue={setEndReleaseValue}
                          />
                        )}
                      />
                    ),
                  },
                }}
              ></Tab>
            </Tabs>
          </WholeContainer>
        </>
      )}
      {count > 0 && (
        <Tags
          isTag={
            checkedAcrossTabs?.length > 0 ||
            state?.upc_id?.length > 0 ||
            state?.order_start_date ||
            state?.order_end_date ||
            state?.start_release_date ||
            state?.end_release_date
          }
        >
          {count > 0 && (
            <button className="reset" onClick={resetHandler}>
              Reset <BiReset />
            </button>
          )}
          {checkedAcrossTabs.map((checked) => (
            <FilterTag
              data={checked.value}
              handler={() => handleAllCheckedClose(checked)}
            />
          ))}
          {state?.upc_id?.map((item, id) => (
            <FilterTag data={item} handler={() => removeUpc(id)} />
          ))}
          {state?.order_start_date?.length > 0 && (
            <FilterTag
              data={`Start of Creation date : ${state?.order_start_date}`}
              handler={() =>
                callDispatch('CHANGE_START_DATE_DROP', 'order_start_date', '')
              }
            />
          )}
          {state?.order_end_date?.length > 0 && (
            <FilterTag
              data={`End of Creation date : ${state?.order_end_date}`}
              handler={() =>
                callDispatch('CHANGE_END_DATE_DROP', 'order_end_date', '')
              }
            />
          )}
          {state?.start_release_date?.length > 0 && (
            <FilterTag
              data={`Start of Release date : ${state?.start_release_date}`}
              handler={() =>
                callDispatch(
                  'CHANGE_START_RELEASE_DATE_DROP',
                  'start_release_date',
                  '',
                )
              }
            />
          )}
          {state?.end_release_date?.length > 0 && (
            <FilterTag
              data={`End of Release date : ${state?.end_release_date}`}
              handler={() =>
                callDispatch(
                  'CHANGE_END_RELEASE_DATE_DROP',
                  'end_release_date',
                  '',
                )
              }
            />
          )}
        </Tags>
      )}
    </>
  );
}

const FilterTag = ({data, handler}) => {
  return (
    <div className="tags">
      <span>{data}</span>
      <span className="icon" onClick={handler}>
        <IoMdClose />
      </span>
    </div>
  );
};

const MainCheckBox = (props) => {
  const {
    data,
    setOpen,
    open,
    name,
    setAllData,
    tag,
    setTabArr,
    setPage,
  } = props;
  const [searchData, setSearchData] = useState(data);

  const [isOpen, setIsOpen] = useState(open);
  const [searchStr, setSeartStr] = useState('');
  const [currentTabData, setCurrentTabData] = useState(data);
  const handleChange = (e) => {
    setSeartStr(e.target.value);
  };
  // useEffect(()=>{
  //   if(searchStr?.length === 0){
  //     const size = 20;
  //     const items = data.slice(0, size)
  //     setCurrentTabData(items);
  //   }
  // },[searchStr])
  useEffect(() => {
    handleSearch();
  }, [data]);

  useEffect(() => {
    handleSearch();
  }, [searchStr]);

  const handleSearch = () => {
    if (searchStr) {
      const filteredTabData = data.filter((option) =>
        option.value.toLowerCase().includes(searchStr.toLowerCase()),
      );

      setCurrentTabData([...filteredTabData]);
    } else {
      const size = 20;
      const items = data.slice(0, size);
      setCurrentTabData(items);
      // setCurrentTabData(data);
    }
  };

  // useEffect(() => {
  //   if (searchStr.length > 0) {
  //     const results = [];
  //     if (data) {
  //       console.log('Kuyups', data);
  //       for (const index in data) {
  //         if (
  //           data[index].value
  //             .toLowerCase()
  //             .includes(searchStr.toLowerCase() || searchStr)
  //         ) {
  //           results.push(data[index]);
  //         }
  //       }
  //     }
  //     setSearchData(results);
  //   } else if (searchStr.length === 0) {
  //     setSearchData(data);
  //   }
  // }, [searchStr]);

  const handleUpdateData = (data) =>
    setTabArr((prev) => {
      if (prev?.length) {
        const currentTab = prev.map((val) => {
          if (val.name === name) {
            return {
              ...val,
              data,
            };
          }
          return val;
        });
        return currentTab;
      }
    });

  const handleCheckbox = (event, data) => {
    let datas = data;
    setPage(1);
    datas = datas.map((item) => {
      if (item.value === event.target.value)
        item.isChecked = event.target.checked;
      return item;
    });
    handleUpdateData(datas);
  };
  const handleClose = (tag, data) => {
    let datas = data;
    datas = data.map((item) => {
      if (item.id === tag.id) {
        item.isChecked = false;
      }
      return item;
    });
    handleUpdateData(datas);
  };

  return (
    <>
      <Container>
        <div className="sub-container">
          <div className="wrapper-container">
            {/* <Tags> */}
            <div className="check-list">
              {tag === 'status' ||
              tag === 'product_type' ||
              tag === 'content_type' ? null : (
                <Search
                  value={searchStr}
                  onChangeHandler={(e) => handleChange(e)}
                  placeholder={`to see more ${tag}`}
                  tag="albumFilter"
                />
              )}
              <div className="list">
                {currentTabData?.map((item) => {
                  return (
                    <div className="container-checkbox">
                      <CheckBoxComponent
                        key={item.id}
                        id={item.id}
                        onClick={(event) => handleCheckbox(event, data)}
                        checked={item.isChecked}
                        value={item.value}
                      />
                    </div>
                  );
                })}
              </div>
              {searchStr ||
              currentTabData?.length < 20 ||
              tag === 'status' ||
              tag === 'product_type' ||
              tag === 'content_type' ? null : (
                <div className="msg">Showing first 20 results</div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease-in;
  height: auto;
  .sub-container {
    display: flex;
    margin: 10px 0;
  }
  .filter-icon {
    position: absolute;
    right: 30px;
    display: flex;
    margin-top: 2px;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    transition: all 0.1s ease-in;
    padding: 5px;
    color: #dc3831;
  }
  .wrapper-container {
    display: flex;
    align-items: center;

    .check-list {
      display: flex;
      max-height: 150px;
      overflow: auto;
      flex-direction: column;
      .css-1v2d52q {
        .css-3h7wc9 {
          width: 220px;
        }
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 5px;
      }
      .msg {
        position: absolute;
        right: 30px;
        font-size: 12px;
        color: #909090;
      }
    }
    .search-container {
      margin-left: 30px;
      margin-right: 6px;
    }
  }
  .svg-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .reset-btn {
    padding: 9px 22px;
    color: #fff;
    font-size: 14px;
    border: none;
    align-items: center;
    margin-left: 6px;
    border-radius: 25px 0px 0px 25px;
    outline: none;
    appearance: none;
    cursor: pointer;
    background: #dc3831;
    :focus {
      outline: none;
      box-shadow: 0 0 0 5px rgb(80 96 122 / 10%);
    }
  }
  .disable-btn {
    padding: 9px 22px;
    color: #272727;
    font-size: 14px;
    font-weight: 700;
    border: none;
    align-items: center;
    margin-left: 6px;
    border-radius: 25px;
    outline: none;
    appearance: none;
    cursor: pointer;
    background: #dddddd;
    opacity: 0.65;
    cursor: not-allowed;
  }
  .container-checkbox {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    -webkit-box-pack: start;
    justify-content: flex-start;
    cursor: pointer;
    font-size: 12px;
    color: rgb(102, 102, 102);
    user-select: none;
    padding: 10px;
  }
`;
const Tags = styled.div<{isTag}>`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    border: ${(props) => (props.isTag ? '1px solid #F2F2F2' : '')};
    padding: 10px;
    position:relative;
  .tags {
        color:#DC3831;
        background: #FCECEB;
        border-radius: 38px;
        padding: 2px 10px;
        font-size: 14px;
        display: flex;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;    
        align-items: center;
        .icon{
          margin-left: 5px;
          display: flex;
          cursor:pointer;
        }
  }
  .bubble{
    position: absolute;
    top: 4px;
    left: 21%;
    background: #3777d8;
    border-radius: 50%;
    width: 18px;
    height: 19px;
    font-size: 12px;
    display: flex;
    color: #edf2f9;
    justify-content: center;
    align-items: center;
  }
   .reset{
    position: absolute;
    top: 10px;
    right: 0;
    display:flex;
    padding: 9px 15px;
    color: #fff;
    font-size: 12px;
    border: none;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 6px;
    background: #DF4843;
   border-radius: 20px 0px 0px 20px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    svg{
      margin-left:5px;
    }
   }
}
`;
const WholeContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #8080802e;
  .gq {
    background-color: white;
  }
  .active {
    border: none;
    font-size: 13px;
    padding: 10px;
    margin-right: 5px;
    height: 14px;
    border-radius: 50px;
    color: #dc3831;
    cursor: pointer;
    background: rgba(220, 56, 49, 0.1);
    padding: 10px;
    display: flex;
    align-items: center;
    svg {
      margin-left: 5px;
    }
  }
  .no-active {
    margin-bottom: 10px;
    border: none;
    font-size: 13px;
    padding: 10px 5px;
    height: 14px;
    border-radius: 50px;
    color: #505f7a;
    cursor: pointer;
    background: #f4f5f7;
    margin-right: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    svg {
      margin-left: 5px;
    }
  }
  .migrated-tabs {
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;
    margin-bottom: 10px;
    padding-top: 10px;
    .tab-filter {
      border: 0;
      display: flex;
      flex-wrap: wrap;
      width: max-content;
      max-width: 100%;
      position: relative;
      background-color: rgb(244, 245, 247);
      border-radius: 3px;
      padding: 5px;
      font-size: 12px;
      line-height: 1.33333;
      text-transform: uppercase;
      font-weight: 600;
      color: rgb(37, 56, 88);
    }
    .label input {
      border: 0px !important;
      clip: rect(1px, 1px, 1px, 1px) !important;
      height: 1px !important;
      overflow: hidden !important;
      padding: 0px !important;
      position: absolute !important;
      width: 1px !important;
      white-space: nowrap !important;
    }
    .migrated::before {
      content: '';
      display: block;
      position: absolute;
      left: 5px;
      top: 5px;
      width: 70px;
      height: 20px;
      transition-property: left, top, width, height;
      transition-duration: 100ms;
      transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
      background-color: rgb(255, 255, 255) !important;
      border-radius: 3px !important;
      box-shadow: rgb(13 20 36 / 18%) 0px 3px 5px,
        rgb(13 20 36 / 29%) 0px 0px 1px !important;
    }
    .all::before {
      content: '';
      display: block;
      position: absolute;
      left: 133px;
      top: 5px;
      width: 30px;
      height: 20px;
      transition-property: left, top, width, height;
      transition-duration: 100ms;
      transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
      background-color: rgb(255, 255, 255) !important;
      border-radius: 3px !important;
      box-shadow: rgb(13 20 36 / 18%) 0px 3px 5px,
        rgb(13 20 36 / 29%) 0px 0px 1px !important;
    }
    .fresh::before {
      content: '';
      display: block;
      position: absolute;
      left: 80px;
      top: 5px;
      width: 47px;
      height: 20px;
      transition-property: left, top, width, height;
      transition-duration: 100ms;
      transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
      background-color: rgb(255, 255, 255) !important;
      border-radius: 3px !important;
      box-shadow: rgb(13 20 36 / 18%) 0px 3px 5px,
        rgb(13 20 36 / 29%) 0px 0px 1px !important;
    }

    .tab-filter > * {
      position: relative;
      margin: 0px 5px 0px 0px;
    }
    .tab-filter > :last-child {
      margin-right: 0px;
    }
    .label label {
      display: block;
      padding: 3px 5px;
      cursor: pointer;
      border-radius: 3px;
    }
    .label input + label:hover {
      background-color: rgb(235, 236, 240);
    }
  }
`;
const TabBubble = styled.div`
  display: flex;
  // width: 1291px;
  margin-left: 112px;
  position: absolute;
  .first {
    width: 5px;
    height: 5px;
    background: red;
    margin-right: 115px;
    background: #3778d7;
    border-radius: 50%;
  }
  .second {
    width: 5px;
    height: 5px;
    background: red;
    margin-right: 154px;
    background: #3778d7;
    border-radius: 50%;
  }
  .third {
    width: 5px;
    height: 5px;
    background: red;
    margin-right: 108px;
    background: #3778d7;
    border-radius: 50%;
  }
  .fourth {
    width: 5px;
    height: 5px;
    background: red;
    margin-right: 100px;
    background: #3778d7;
    border-radius: 50%;
  }
  .fifth {
    width: 5px;
    height: 5px;
    background: red;
    margin-right: 104px;
    background: #3778d7;
    border-radius: 50%;
  }
  .sixth {
    width: 5px;
    height: 5px;
    background: red;
    margin-right: 117px;
    background: #3778d7;
    border-radius: 50%;
  }
  .seventh {
    width: 5px;
    height: 5px;
    background: red;
    margin-right: 109px;
    background: #3778d7;
    border-radius: 50%;
  }
  .eigth {
    width: 5px;
    height: 5px;
    background: red;
    margin-right: 111px;
    background: #3778d7;
    border-radius: 50%;
  }
  .nine {
    width: 5px;
    height: 5px;
    background: red;
    margin-right: 60px;
    background: #3778d7;
    border-radius: 50%;
  }
  .ten {
    width: 5px;
    height: 5px;
    background: red;
    margin-right: 111px;
    background: #3778d7;
    border-radius: 50%;
  }
  .eleven {
    width: 5px;
    height: 5px;
    background: red;
    margin-right: 130px;
    background: #3778d7;
    border-radius: 50%;
  }
  .first1 {
    width: 5px;
    height: 5px;
    margin-right: 115px;
  }
  .second2 {
    width: 5px;
    height: 5px;
    margin-right: 154px;
  }
  .third3 {
    width: 5px;
    height: 5px;
    margin-right: 108px;
  }
  .fourth4 {
    width: 5px;
    height: 5px;
    margin-right: 100px;
  }
  .fifth5 {
    width: 5px;
    height: 5px;
    margin-right: 104px;
  }
  .sixth6 {
    width: 5px;
    height: 5px;
    margin-right: 117px;
  }
  .seventh7 {
    width: 5px;
    height: 5px;
    margin-right: 109px;
  }
  .eigth8 {
    width: 5px;
    height: 5px;
    margin-right: 111px;
  }
  .nine9 {
    width: 5px;
    height: 5px;
    margin-right: 60px;
  }
  .ten10 {
    width: 5px;
    height: 5px;
    margin-right: 111px;
  }
  .eleven11 {
    width: 5px;
    height: 5px;
    margin-right: 130px;
  }
`;
export default AlbumFilter;
