import moment from 'moment';
import React, {useState} from 'react';
import {useEffect} from 'react';
import {TAGS} from '../../../constants/tags';

import useGetDspHistory from '../../../hooks/DSPManagement/useGetDspHistory';
import useHistory from '../../../hooks/History/useHistory';
import HistorySkeltonLoading from '../../../components/reusable/HistorySkeltonLoading';
import NoRecords from '../../../components/reusable/NoRecords/NoRecords';
import UserCreateHistoryCard from '../../../components/reusable/history/UserCreateHistoryCard';
import UserUpdateHistoryCard from '../../../components/reusable/history/UserUpdateHistoryCard';

export default function DSPHistory(props) {
  const {dspId} = props;

  const {getDspHistory, isLoading} = useGetDspHistory({id: dspId});

  const {formatHistoryData, historyData} = useHistory();

  useEffect(() => {
    if (getDspHistory) {
      formatHistoryData(getDspHistory, 'dsp_name');
    }
  }, [getDspHistory]);

  const returnValues = (item, type) => {
    if (item.field && type) {
      if (
        item.field === 'content_type' ||
        item.field === 'email_poc' ||
        item.field === 'email_cc'
      ) {
        if (typeof item[type] === 'object') {
          return item[type].map((val) => val).join(', ');
        } else {
          return item[type];
        }
      } else if (item.field === 'language') {
        return item[type]
          .map((val) => val?.language?.replace('_', ' '))
          .join(', ');
      } else {
        return item[type];
      }
    } else {
      return null;
    }
  };

  const renderList = (item) => {
    const oldVal = returnValues(item, 'oldValue');
    const newVal = returnValues(item, 'newValue');

    if (item.action === 'UPDATE') {
      return (
        <UserUpdateHistoryCard
          userName={item.userName}
          field={item.field}
          oldValue={oldVal}
          newValue={newVal}
          date={item?.date}
          email={item?.email}
          moreFields={['email_poc', 'email_cc', 'language']}
        />
      );
    } else {
      if (item.newValue) {
        return (
          <UserCreateHistoryCard
            userName={item.userName}
            createdValue={item.newValue}
            date={item?.date}
            email={item?.email}
            action={item?.action}
            tag={TAGS.DSP}
          />
        );
      } else {
        return null;
      }
    }
  };

  if (isLoading) {
    return <HistorySkeltonLoading />;
  }

  return (
    <div>
      {historyData.length === 0 || Object.keys(historyData[0]).length === 0 ? (
        <NoRecords />
      ) : (
        historyData.sort((a, b) => b.id - a.id).map((item) => renderList(item))
      )}
    </div>
  );
}
