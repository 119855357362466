import styled from '@emotion/styled';
import {FileUploader} from 'baseui/file-uploader';
import React from 'react';
import {HiCloudUpload} from 'react-icons/hi';
import {IoMdRefresh} from 'react-icons/io';
import {MdAdd} from 'react-icons/md';

import FilesModal from './FilesModal/FilesModal';

const DragAndDrop = ({data, file}) => {
  return (
    <DragAndDropStyled>
      <div
        className="drag-container"
        onChange={data.onChange}
        onDrop={data.onDrop}
      >
        {/* {data.children} */}
        <div className="icon-container">
          <HiCloudUpload />
          <div className="content">
            <span className="text1">Drag files here or</span>
            <span
              className="text2"
              onClick={data?.children[0]?.props.children[2].props.onClick}
            >
              Browse
            </span>
          </div>
        </div>
        <div
          onClick={data?.children[0]?.props.children[2].props.onClick}
          className="fab"
        >
          {file.length ? <IoMdRefresh /> : <MdAdd />}
        </div>
      </div>
    </DragAndDropStyled>
  );
};

const DragAndDropStyled = styled.div`
  background: #faecea;
  height: 172px;
  padding: 20px;
  .drag-container {
    position: relative;
    width: 100%;
    height: 100%;
    border: 2px dashed rgba(220, 56, 49, 0.5);
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .fab {
      cursor: pointer;
      position: absolute;
      bottom: -28px;
      right: 45px;
      width: 60px;
      height: 60px;
      border-radius: 60px;
      background: #dc3831;
      box-shadow: 0px 4px 20px rgba(220, 56, 49, 0.25);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      svg {
        font-size: 24px;
      }
    }
    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .content {
        .text1 {
          color: #d7b0ae;
        }
        .text2 {
          color: #dc3831;
          margin-left: 5px;
          font-weight: bold;
          cursor: pointer;
        }
      }
      svg {
        font-size: 24px;
        color: #dc3831;
      }
    }
  }
`;

const FileUploadNewMultiple = ({
  accept,
  isMultiple,
  onCancel,
  onChange,
  errorMessage,
  isOpen,
  close,
  files,
  setIsComplete,
  setEnable,
  project,
  name,
  filetype,
}) => {
  return (
    <div>
      <FilesModal
        isOpen={isOpen}
        close={close}
        files={files}
        setIsComplete={setIsComplete}
        setEnable={setEnable}
        project={project}
        name={name}
        filetype={filetype}
      />
      <DragAndDropContainer>
        <FileUploader
          overrides={{
            FileDragAndDrop: {
              style: () => {
                return {
                  marginRight: '0px',
                };
              },
              component: (data) => <DragAndDrop data={data} file={files} />,
            },
          }}
          onCancel={onCancel}
          onChange={(file, rejected) => onChange(file, rejected)}
          onDrop={(file, rejected) => onChange(file, rejected)}
          accept={accept}
          multiple={isMultiple}
          errorMessage={errorMessage}
        />
        <div className="accepted-files">Accepted File Types: {accept} only</div>
      </DragAndDropContainer>
    </div>
  );
};

const DragAndDropContainer = styled.div`
  position: relative;
  width: 448px;
  height: 236px;
  .accepted-files {
    position: absolute;
    bottom: 0;
    font-size: 14px;
    color: rgba(103, 103, 103, 0.5);
  }
`;
export default FileUploadNewMultiple;
