export const upcData = {
  batch_name: {
    Header: 'Batch Name',
    accessor: 'batch_name',
  },
  upc_count: {
    Header: 'UPC Count',
    accessor: 'upc_count',
  },
  created_at: {
    Header: 'Created On',
    accessor: 'created_at',
  },
  upc_copy: {
    Header: '',
    accessor: 'upc',
  },
  upc_download: {
    Header: '',
    accessor: 'upc_download',
  },
};
