import {useEffect, useState} from 'react';

import useNotification from '../../Contexts/useNotification';
import {logError} from '../../errorlogger/logError';
import {capitalizeFirstLetter} from '../../helpers/capitalizeFirstLetter';
import useListDsps from '../DSPManagement/useListDsps';
import useCreateLabel from '../Labels/useCreateLabel';
import useLocalStorage from '../reusable/useLocalStorage';
import useUpdateLabel from './useUpdateLabel';

const useLabelForm = (props) => {
  const {selectedData, setIsOpen, setSelectedData, refetch} = props;
  const {listDsps} = useListDsps();
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const [dspOptions, setDspOptions] = useState([]);

  useEffect(() => {
    if (listDsps && listDsps.length > 0) {
      const dspOptions = listDsps.map((dsp) => ({
        id: dsp.id,
        value: capitalizeFirstLetter(dsp.dsp_name),
        isChecked: false,
      }));
      setDspOptions(dspOptions);
    }
  }, [listDsps]);

  const {showToast} = useNotification();
  const {
    createLabelMutation,
    isLoading: isCreateLabelLoading,
    error: createLabelError,
  } = useCreateLabel();
  // Test

  const {
    updateLabelMutation,
    isLoading: isUpdateLabelLoading,
    error: updateLabelError,
  } = useUpdateLabel();

  useEffect(() => {
    if (updateLabelError || createLabelError) {
      setIsOpen(true);
    }
  }, [updateLabelError, createLabelError]);

  const initialValues = {
    label_name: selectedData ? selectedData.label_name : '',
    percentage: selectedData ? selectedData.percentage : '',
    contact_name: selectedData ? selectedData.contact_name : '',
    contact_phone: selectedData ? selectedData.contact_phone : '',
    contact_email: selectedData ? selectedData.contact_email : '',
    address: selectedData ? selectedData.address : '',
    contract_start_date: selectedData
      ? selectedData.contract_start_date.split('T')[0]
      : '',
    contract_end_date: selectedData
      ? selectedData.contract_end_date.split('T')[0]
      : '',
    dsps: selectedData
      ? selectedData?.dsps.map((dsp) => ({
          value: capitalizeFirstLetter(dsp.dsp_name),
          id: dsp.id,
        }))
      : [],
  };

  const onSubmit = async (labelFormData) => {
    const data = {
      label_name: labelFormData.label_name,
      percentage: Number(labelFormData.percentage),
      contact_name: labelFormData.contact_name,
      contact_phone: labelFormData.contact_phone,
      contact_email: labelFormData.contact_email,
      address: labelFormData.address,
      user_id: currentUser.id,
      contract_start_date: new Date(
        labelFormData.contract_start_date,
      ).toISOString(),
      contract_end_date: new Date(
        labelFormData.contract_end_date,
      ).toISOString(),
      dsps: labelFormData.dsps.map((dsp) => dsp.id),
      updated_by: currentUser.id,
    };

    try {
      if (data) {
        if (selectedData?.id) {
          const id = selectedData.id;
          delete data.user_id;
          const res = await updateLabelMutation({data, id});

          if (res?.error && res.error?.length > 0) {
            showToast(res?.error, 'error');
          }
          closeModalOnSuccess(res);
        } else {
          delete data.updated_by;
          const res: any = await createLabelMutation({data});

          if (res?.error && res.error?.length > 0) {
            showToast(res.error, 'error');
          }
          closeModalOnSuccess(res);
        }
      }
    } catch (error) {
      logError(error, 5);
    }
  };

  const closeModalOnSuccess = (res) => {
    if (res?.statusCode === 201 || res?.statusCode === 200) {
      refetch();
      setIsOpen(false);
      setSelectedData({});
      showToast(
        `Label ${selectedData ? `updated` : `added`} successfully`,
        'success',
      );
    }
  };

  return {
    initialValues,
    onSubmit,
    dspOptions,
    isUpdateLabelLoading,
    isCreateLabelLoading,
  };
};

export default useLabelForm;
