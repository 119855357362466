import styled from '@emotion/styled';
import React, {useEffect, useState} from 'react';
import {AiOutlineCheckCircle, AiOutlineCloseCircle} from 'react-icons/ai';
import {useNavigate} from 'react-router';

import TracksTable from '../../../components/AlbumDetail/tables/TracksTable';
import SubmitModal from '../../../components/CreateProject/SubmitModal';
import Button from '../../../components/reusable/Button/Button';
import NoRecords from '../../../components/reusable/NoRecords/NoRecords';
import Table from '../../../components/reusable/Table/Table';
import {logError} from '../../../errorlogger/logError';
import usePreviewLink from '../../../hooks/AlbumDetail/usePreviewLink';
import useUpdateAlbum from '../../../hooks/AlbumsManagement/useUpdateAlbumStatus';
import usePublishAlbumFnn from '../../../hooks/AlbumTrackApproval/usePublishAlbum';
import useUpdateAlbumStatus from '../../../hooks/AlbumTrackApproval/useUpdateAlbumStatus';
import useUpdateTrackStatus from '../../../hooks/AlbumTrackApproval/useUpdateTrackStatus';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import useS3Key from '../../../hooks/reusable/useS3key';

export default function AlbumApproval(props) {
  const {
    columns,
    setIsOpen,
    setSelectedData,
    setNewFormTag,
    data,

    setTrackIsrc,
    tag,
    playing,
    handlePlayPause,
    duration,
    trackIsrc,

    getAlbumDetail,
    setFilter,
  } = props;

  const tracksData = React.useMemo(() => data, [data]);
  const columnData = React.useMemo(() => columns, [columns]);

  const [ready, setReady] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [action, setAction] = React.useState('');
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);

  const {albumStatusLoading, updateAlbumStatusFn} = useUpdateAlbumStatus();
  const {trackStatusLoading, updateTrackStatusFn} = useUpdateTrackStatus();

  const openNewFormSidebarHandler = () => {
    setIsOpen(true);
    setNewFormTag(true);
  };

  React.useEffect(() => {
    if (tracksData) {
      const filtered = tracksData?.filter(({status}) => status === 'approved');
      setFilter(filtered);
    }
  }, [tracksData]);

  const {
    publishAlbumMutation,
    isLoading: isPublishAlbumLoading,
    error: publishAlbumError,
  } = usePublishAlbumFnn();

  const {
    updateAlbumMutation,
    isLoading: isUpdateAlbumLoading,
    error: updateAlbumError,
  } = useUpdateAlbum();

  // const album_id = userData[0].album_id

  const updateStatus = async () => {
    const albumData = {
      id: getAlbumDetail?.id,
      data: {
        status: 'approved',
        updated_by: currentUser.id,
      },
    };

    const response = await updateAlbumMutation(albumData);
    if (response?.status === 200) {
      setIsOpen(false);
    }
  };

  const publish = async () => {
    const data = {
      project_id: getAlbumDetail?.project_id,
      album_type: getAlbumDetail?.album_type,
      album_id: [getAlbumDetail.id],
    };

    const res = await publishAlbumMutation(data);
    if (res?.status === 200) {
      setReady(true);
      navigate('/albums');
    }
  };

  const submitApproval = () => {
    setLoading(true);
    updateStatus();
    publish();
    setLoading(false);
  };

  const close = () => {
    setModalOpen(false);
  };

  const approveSelectedTracks = async (status) => {
    setAction('approve');
    if (selectedValue.length === 1) {
      const data = {
        trackId: selectedValue[0].original?.id,
        data: {
          status,
          updated_by: currentUser.id,
        },
      };

      await updateTrackStatusFn(data);
    } else {
      setModalOpen(true);
    }
  };

  const declineSelectedTracks = async (status) => {
    setAction('decline');
    if (selectedValue.length === 1) {
      const data = {
        trackId: selectedValue[0].original?.id,
        data: {
          status,
          updated_by: currentUser.id,
        },
      };
      const albumData = {
        albumId: tracksData.length && tracksData[0]?.album_id,
        data: {
          status,
          updated_by: currentUser.id,
        },
      };
      await updateTrackStatusFn(data);
      await updateAlbumStatusFn(albumData);
    } else {
      setModalOpen(true);
    }
  };

  const approvedMoreThanOneTrack = async (status) => {
    try {
      selectedValue.forEach(async (track, i) => {
        const trackData = {
          trackId: track.original?.id,
          data: {
            status,
            updated_by: currentUser.id,
          },
        };
        await updateTrackStatusFn(trackData);
        setModalOpen(false);
      });
    } catch (error) {
      logError(error, 11);
    }
  };

  const declineMoreThanOneTrack = (status) => {
    try {
      selectedValue.forEach(async (track) => {
        const trackData = {
          trackId: track.original?.id,
          data: {
            status,
            updated_by: currentUser.id,
          },
        };

        const albumData = {
          albumId: tracksData.length && tracksData[0]?.album_id,
          data: {
            status,
            updated_by: currentUser.id,
          },
        };

        await updateTrackStatusFn(trackData);
        await updateAlbumStatusFn(albumData);

        setModalOpen(false);
      });
    } catch (error) {
      logError(error, 11);
    }
  };
  return (
    <>
      {data?.length === 0 ? (
        <NoRecords
          page="tracks"
          btnLabel="Add Track"
          onClick={openNewFormSidebarHandler}
        />
      ) : (
        <Table
          columns={columnData}
          data={tracksData ? tracksData : []}
          onClickRow={setIsOpen}
          setSidebarData={setSelectedData}
          setSelectedValue={setSelectedValue}
          tag={tag}
          setIsOpen={setIsOpen}
          setNewFormTag={setNewFormTag}
          selectedValue={selectedValue}
          hasNextPage={false}
          setSearchStr={null}
          setTrackIsrc={setTrackIsrc}
          playing={playing}
          duration={duration}
          trackIsrc={trackIsrc}
          handlePlayPause={handlePlayPause}
          mainActionHandler={openNewFormSidebarHandler}
          footerComponent={() => (
            <FooterComponent
              action={action}
              getAlbumDetail={getAlbumDetail}
              submitApproval={submitApproval}
              isPublishAlbumLoading={isPublishAlbumLoading}
              trackData={tracksData}
              data={selectedValue}
              approveFn={approveSelectedTracks}
              declineFn={declineSelectedTracks}
              trackStatusLoading={trackStatusLoading}
              albumStatusLoading={albumStatusLoading}
            />
          )}
        />
      )}

      {modalOpen && (
        <SubmitModal
          isOpen={modalOpen}
          close={close}
          title={`Are you sure that you want to ${
            action === 'approve' ? 'approve' : 'decline'
          }  ${selectedValue.length} tracks`}
          renderBtn={
            <Button
              isLoading={trackStatusLoading || albumStatusLoading}
              onClick={
                action === 'approve'
                  ? () => approvedMoreThanOneTrack('approved')
                  : () => declineMoreThanOneTrack('declined')
              }
              label={action === 'approve' ? 'Approve' : 'Decline'}
              size="default"
            />
          }
        />
      )}
    </>
  );
}

export const FooterComponent = (props) => {
  const {
    getAlbumDetail,

    data,
    approveFn,
    declineFn,
    trackStatusLoading,
    action,
    albumStatusLoading,
  } = props;

  return (
    <>
      <ButtonContainer>
        {data.length > 0 && (
          <span
            style={{
              marginRight: '10px',
            }}
          >{`${data.length} ${
            data.length === 1 ? 'track' : 'tracks'
          } selected`}</span>
        )}

        <div className="btn-wrapper">
          <Button
            disabled={data.length === 0}
            shape="round"
            isLoading={trackStatusLoading && action === 'approve'}
            label="Approve"
            background="#34A139"
            onClick={() => approveFn('approved')}
            endEnhancer={() => <AiOutlineCheckCircle size={16} />}
          />
          <Button
            disabled={data.length === 0}
            shape="round"
            isLoading={
              (trackStatusLoading || albumStatusLoading) && action === 'decline'
            }
            label="Decline"
            onClick={() => declineFn('declined')}
            endEnhancer={() => <AiOutlineCloseCircle size={16} />}
          />
        </div>
      </ButtonContainer>
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > span {
    margin-left: 30px;
    background: #fdf0b3;
    padding: 2px 14px;
    color: #9f6101;
    font-size: 14px;
    border-radius: 5px;
  }
  button {
    + button {
      margin-left: 10px;
    }
  }
`;
