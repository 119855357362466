import React, {useEffect, useState} from 'react';
import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {TAGS} from '../../constants/tags';
import {logError} from '../../errorlogger/logError';

export const fetchUpcCount = async () => {
  try {
    const res = await axios(`/upc/count/`, {
      method: 'GET',
    });

    return res.data?.data;
  } catch (error) {
    logError(error, 8);
    return error.response?.data;
  }
};

const useUPC = (props) => {
  const {tag} = props;
  const {data: getUpcCount, refetch} = useQuery('upc', fetchUpcCount, {
    enabled: tag === TAGS.UPC,
  });
  const [content, setContent] = useState([]);

  useEffect(() => {
    if (getUpcCount) {
      const contentData = [
        {
          title: 'Total UPC',
          count: `${getUpcCount.assigned + getUpcCount.not_assigned}`,
          icon: 'scan',
          color: '#18A0ED',
          bg: 'rgba(25%, 160%, 237%, 0.25)',
        },
        {
          title: 'Available UPC',
          count: `${getUpcCount.not_assigned}`,
          icon: 'scan',
          color: '#00990F',
          bg: 'rgba(0, 153, 15, 0.25);',
        },
        {
          title: 'Used UPC',
          count: `${getUpcCount.assigned}`,
          icon: 'scan',
          color: '#DC3831',
          bg: 'rgba(220, 56, 49, 0.25);',
        },
      ];

      setContent(contentData);
    }
  }, [getUpcCount]);

  return {content, getUpcCount, refetch};
};

export default useUPC;
