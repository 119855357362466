/** @format */

import React from 'react';
import {FaCloudDownloadAlt} from 'react-icons/fa';

import Button from '../../components/reusable/Button/Button';
import {copyToClipboard} from '../../helpers/copyToClipboard';
import {formatDate} from '../../helpers/covertDate';
import {downloadCsv} from '../../helpers/downloadCSV';
import {Container} from '../UPCManagement/tableData';
import {isrcData} from './isrcTable';

export const isrcColumnMeta = [
  {
    Header: isrcData.batch_name.Header,
    accessor: isrcData.batch_name.accessor,
    sortType: (rowA, rowB) => {
      return sortAlphabeticalOrderWithoutCase(rowA.original, rowB.original);
    },
  },
  {
    Header: isrcData.isrc_count.Header,
    accessor: isrcData.isrc_count.accessor,
    Cell: (data) => {
      return <span>{data.row.original?.isrc.length}</span>;
    },
    sortType: (rowA, rowB) => {
      return sortIsrcCount(rowA.original, rowB.original);
    },
  },
  {
    Header: isrcData.created_at.Header,
    accessor: isrcData.created_at.accessor,
    Cell: (data) => {
      return <span> {data && formatDate(data.value)} </span>;
    },
  },
  {
    Header: isrcData.copy_isrc.Header,
    accessor: isrcData.copy_isrc.accessor,
    disableSortBy: true,
    Cell: (data) => {
      const isrcCodes = data.value.map((item) => item.isrc);
      return (
        <Container>
          <Button
            onClick={() => copyToClipboard('isrc', isrcCodes)}
            label="COPY"
            type="button"
            size="mini"
            kind="secondary"
          />
        </Container>
      );
    },
  },
  {
    Header: isrcData.download_isrc.Header,
    accessor: isrcData.download_isrc.accessor,
    disableSortBy: true,
    Cell: (data) => {
      const isrcCodes = data?.row?.original?.isrc.map((item) => item.isrc);

      return (
        <Container>
          <Button
            onClick={() =>
              downloadCsv('isrc', isrcCodes, data.row.original?.batch_name)
            }
            label="Download"
            type="button"
            size="mini"
            endEnhancer={() => <FaCloudDownloadAlt />}
          />
        </Container>
      );
    },
  },
];

export const sortAlphabeticalOrderWithoutCase = (row1, row2) => {
  return row1.batch_name
    .toLowerCase()
    .localeCompare(row2.batch_name.toLowerCase());
};

const sortIsrcCount = (row1, row2) => {
  let isrcCountR1 = parseInt(row1?.isrc.length);
  let isrcCountR2 = parseInt(row2?.isrc.length);

  if (isrcCountR1 < isrcCountR2) {
    return -1;
  }
  if (isrcCountR1 > isrcCountR2) {
    return 1;
  }
  return 0;
};
