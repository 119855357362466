import styled from '@emotion/styled';
import React, {useState} from 'react';

const SummaryComp = (props) => {
  const {data} = props;
  // const {setIsOpen,data} = props;
  // const [slide, setSlide] = useState(false);
  // const steps = data.step;
  return (
    <div>
      <SummaryComponent>
        <div className="container">
          <div className="step1">
            <h2>SUMMARY</h2>
            <p>Here are your uploads</p>
          </div>
          <div className="step2">
            <h2>
              {data?.Album?.length}{' '}
              <span>{`${data?.Album?.length === 1 ? 'album' : 'albums'}`}</span>
            </h2>
          </div>
          <div className="step3">
            <h2>
              {data?.Album?.length &&
                data?.Album.map((item) => item?.Track?.length).reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                )}
              <span> tracks</span>
            </h2>
          </div>
        </div>
      </SummaryComponent>
    </div>
  );
};

const SummaryComponent = styled.div`
  margin: 0 -30px;
  background: #f8f8f8;
  padding: 0 30px;
  .container {
    display: flex;
    width: 100%;
    line-height: 10px;
    padding: 20px 0px;
    .step1 {
      flex-basis: 33.333%;
      border-right: 2px solid #d21919;
      padding-right: 30px;
      h2 {
        font-weight: bold;
        font-size: 20px;
      }
      p {
        width: 107%;
        font-size: 14px;
      }
    }
    .step2 {
      flex-basis: 33.333%;
      display: flex;
      justify-content: center;
      align-items: center;
      h2 {
        font-size: 35px;
        span {
          font-size: 18px;
          font-weight: normal;
        }
      }
    }
    .step3 {
      flex-basis: 33.333%;
      display: flex;
      justify-content: center;
      align-items: center;
      h2 {
        font-size: 35px;
        span {
          font-size: 18px;
          font-weight: normal;
        }
      }
    }
  }
  .dothide {
    display: none;
  }
  .dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 10px;
    span {
      width: 6px;
      height: 6px;
      background: #9e9e9e;
      border-radius: 50%;
      margin-right: 10px;
      :nth-child(1) {
        background: #d21919;
      }
    }
  }
  .dots2 {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 10px;
    span {
      width: 6px;
      height: 6px;
      background: #9e9e9e;
      border-radius: 50%;
      margin-right: 10px;
      :nth-child(2) {
        background: #d21919;
      }
    }
  }
  .dots3 {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-bottom: 10px;
    span {
      width: 6px;
      height: 6px;
      background: #9e9e9e;
      border-radius: 50%;
      margin-right: 10px;
      :nth-child(3) {
        background: #d21919;
      }
    }
  }
`;
export default SummaryComp;
