import styled from '@emotion/styled';

export const SearchContainer = styled.div<{noAddTrack}>`
  display: flex;
  align-items: 'center';
  justify-content: flex-end;
  padding: ${(props) => (props.noAddTrack ? '10px 25px' : '10px 25px')};
  background: white;
  .group_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    .refresh-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      svg {
        display: flex;
      }
    }
  }
  .left {
    display: flex;
    .upc_status {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      border-radius: 5px;
      padding: 0.45rem 0.65rem;
      min-width: 10rem;
      p {
        margin: 0;
        font-weight: 650;
        font-size: 1.25rem;
      }
      span {
        font-weight: 650;
        font-size: 0.7rem;
      }
    }
    .available {
      color: #006510;
      background: rgba(0, 101, 16, 0.15);
      margin-right: 0.75rem;
    }
    .used {
      color: #dc3831;
      background: rgba(220, 56, 49, 0.15);
    }
  }
  .right {
    display: flex;
    justify-content: center;
    align-items: center;

    .download {
      margin-right: 0.95rem;
      text-decoration: none;
      font-weight: 650;
      color: black;
      & :active {
        color: darkblue;
      }
      span {
        vertical-align: middle;
      }
    }

    .first_btn {
      margin-right: 0.95rem;
    }
  }
`;
