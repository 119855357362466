import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Theme} from 'baseui/theme';
import * as React from 'react';
import {useRef} from 'react';
import {useState} from 'react';
import {AiFillCloseCircle} from 'react-icons/ai';
import {FaCheckCircle, FaInfoCircle} from 'react-icons/fa';
import {IoMdCloseCircle} from 'react-icons/io';
import {useLocation} from 'react-router-dom';

import {TAGS} from '../../../constants/tags';
import {logError} from '../../../errorlogger/logError';
import useRevertIsrc from '../../../hooks/ISRCManagement/useInvalidateIsrc';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import useLockBodyScroll from '../../../hooks/reusable/useLockBodyScroll';
import useOnClickOutside from '../../../hooks/reusable/useOnClickOutside';
import useRevertUpc from '../../../hooks/UPCManagement/useRevertUpc';
import {CustomThemeType} from '../../../styles/theme/colors';
import AlbumDetailForm from '../../../pages/AlbumsManagment/Albums/forms/AlbumDetailForm';
import AlbumApprovalForm from '../../../pages/AlbumsReviewManagement/AlbumsReview/forms/ApprovalManagementForms';
import AlbumForm from '../../../pages/AlbumsManagment/Albums/forms/AlbumForm';
import EditAlbumForm from '../../../pages/AlbumsManagment/Albums/forms/EditAlbumForm';
import TrackApprovalForm from '../../../pages/AlbumsManagment/Albums/forms/TrackApprovalForm';
import DownloadTemplateBtn from '../../BulkUpload/DownloadTemplateBtn';
import BulkUploadForm from '../../CreateProject/BulkUpload/BulkUploadForm';
import ManualUploadForm from '../../CreateProject/BulkUpload/ManualUploadForm';
import RevertModal from '../../CreateProject/RevertModal';
import DSPForm from '../../../pages/DSPManagement/forms/DSPForm';
import ISRCManagementForms from '../../../pages/ISRC-UPCManagement/IsrcManagement/forms/ISRCManagementForms';
import LabelForm from '../../../pages/Label-ArtistManagement/LabelManagement/forms/LabelForm';
import UPCManagementForms from '../../../pages/ISRC-UPCManagement/UpcManagement/forms/UPCManagementForms';
import UserManagementForms from '../../../pages/UserManagement/forms/UserManagementForms';
import {FiCheckSquare} from 'react-icons/fi';
import {RiFileDamageFill} from 'react-icons/ri';

const FlyMenu = (props) => {
  const {
    isOpen,
    setIsOpen,
    selectedData,
    tag,
    newFormTag,
    setSelectedData,
    setNewFormTag,
    refetch,
    setIsAlbumClick = null,
    setDynamicTag = null,
    closeable = true,
  } = props;

  const [css, theme] = useStyletron();
  const sidebarRef = useRef();
  const apiCalledRef = useRef(false);
  const upcRef = useRef(null);
  const isrcRef = useRef(null);
  const location = useLocation();

  const [isRevertModalOpen, setIsRevertModalOpen] = useState(false);
  const [isrcCode, setIsrcCode] = useState(null);
  const [upcCode, setUpcCode] = useState(null);
  const [trackCreated, setTrackCreated] = useState(false);
  const [isAlbumCreated, setIsAlbumCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {revertUPCFn} = useRevertUpc();
  const {revertISRCFn} = useRevertIsrc();

  useLockBodyScroll();

  // When navigating to another page immediately this fn is used. We cant use react state coz the state wont be available when navigation. So used ref;
  const revertcodes = () => {
    if (!apiCalledRef.current) {
      if (upcRef.current) {
        revertUpc(upcRef.current);
      }
      if (isrcRef.current) {
        revertIsrc(isrcRef.current);
      }
    }
  };

  const revertFn = async () => {
    if (upcCode) {
      setUpcCode(null);
      await revertUpc(upcCode);
    }

    if (isrcCode) {
      setIsrcCode(null);
      await revertIsrc(isrcCode);
    }
  };

  const revertUpc = async (upc) => {
    try {
      setIsLoading(true);
      await revertUPCFn(upc);
      apiCalledRef.current = true;
      setIsOpen(false);
    } catch (error) {
      setIsLoading(false);
      logError(error, 7);
    }
  };

  const revertIsrc = async (isrc) => {
    try {
      setIsLoading(true);
      await revertISRCFn(isrc);
      apiCalledRef.current = true;
      setIsOpen(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onCloseHandler = () => {
    if (closeable) {
      mainCloseHandler();
    } else {
      return;
    }
  };

  const mainCloseHandler = () => {
    if (setDynamicTag) {
      if (tag === TAGS.MANUAL_UPLOAD || tag === TAGS.BULK_UPLOAD) {
        setDynamicTag(TAGS.ALBUMS);
      }
      if (tag === TAGS.TRACKS) {
        setDynamicTag(TAGS.TRACKS);
      }
    }

    if (setIsAlbumClick) {
      setIsAlbumClick(false);
    }

    if ((isrcCode && !trackCreated) || (upcCode && !isAlbumCreated)) {
      setIsRevertModalOpen(true);
    } else {
      setNewFormTag(true);
      setIsOpen(false);
      setSelectedData({});
    }
  };

  useOnClickOutside(sidebarRef, () => onCloseHandler());

  const renderTitle = () => {
    switch (tag) {
      case TAGS.USER:
        return 'Manage User';
      case TAGS.DSP:
        return 'Manage DSP';
      case TAGS.LABEL:
        return 'Manage Label';
      case TAGS.BULK_UPLOAD:
        return 'Bulk Upload';
      case TAGS.MANUAL_UPLOAD:
        return 'Manual Upload';
      case TAGS.ISRC:
        return 'Generate ISRC';
      default:
        return null;
    }
  };

  return (
    <CustomFlyMenuContainer theme={theme} tag={tag}>
      <div
        ref={sidebarRef}
        className={`${isOpen ? 'flymenu flymenuopen' : 'flymenu'}`}
      >
        <div className="header-sidebar">
          <div className="close">
            <div className="wrapper">
              <div className="close-icon" onClick={mainCloseHandler}>
                <AiFillCloseCircle />
              </div>
              <div className="title">{renderTitle()}</div>
            </div>
            {tag === TAGS.BULK_UPLOAD && <DownloadTemplateBtn />}
          </div>
          {selectedData?.original?.status && newFormTag !== 'add-coverart' && (
            <div className="status-container">
              <StatusInForm value={selectedData?.original?.status} tag={tag} />
            </div>
          )}
        </div>
        <div className="form-container">
          {selectedData && tag === TAGS.USER && (
            <UserManagementForms
              refetch={refetch}
              useOnClickOutside={useOnClickOutside}
              sidebarRef={sidebarRef}
              onCloseHandler={onCloseHandler}
              setIsOpen={setIsOpen}
              selectedData={selectedData?.original}
              setSelectedData={setSelectedData}
            />
          )}

          {selectedData && tag === TAGS.DSP && (
            <DSPForm
              refetch={refetch}
              useOnClickOutside={useOnClickOutside}
              sidebarRef={sidebarRef}
              onCloseHandler={onCloseHandler}
              setIsOpen={setIsOpen}
              selectedData={selectedData?.original}
              setSelectedData={setSelectedData}
            />
          )}

          {selectedData && tag === TAGS.LABEL && (
            <LabelForm
              refetch={refetch}
              useOnClickOutside={useOnClickOutside}
              sidebarRef={sidebarRef}
              setSelectedData={setSelectedData}
              onCloseHandler={onCloseHandler}
              setIsOpen={setIsOpen}
              selectedData={selectedData?.original}
            />
          )}

          {newFormTag === TAGS.ISRC && (
            <ISRCManagementForms
              refetch={refetch}
              setIsOpen={setIsOpen}
              selectedData={selectedData?.original}
            />
          )}

          {selectedData && tag === TAGS.TRACKS && (
            <AlbumDetailForm
              useOnClickOutside={useOnClickOutside}
              sidebarRef={sidebarRef}
              onCloseHandler={onCloseHandler}
              setIsOpen={setIsOpen}
              selectedData={selectedData?.original}
              newFormTag={newFormTag}
              setNewFormTag={setNewFormTag}
              setIsModalOpen={setIsRevertModalOpen}
              setIsrcCode={setIsrcCode}
              setTrackCreated={setTrackCreated}
              refetchAlbum={refetch}
              isrcRef={isrcRef}
            />
          )}

          {selectedData && tag === TAGS.UPC && (
            <UPCManagementForms
              refetch={refetch}
              setIsOpen={setIsOpen}
              newFormTag={newFormTag}
            />
          )}

          {selectedData && tag === TAGS.ALBUMS && (
            <AlbumForm
              useOnClickOutside={useOnClickOutside}
              sidebarRef={sidebarRef}
              onCloseHandler={onCloseHandler}
              setIsOpen={setIsOpen}
              selectedData={selectedData?.original}
              refetch={refetch}
            />
          )}

          {selectedData && tag === TAGS.EDIT_ALBUMS && (
            <EditAlbumForm
              useOnClickOutside={useOnClickOutside}
              sidebarRef={sidebarRef}
              onCloseHandler={onCloseHandler}
              setIsOpen={setIsOpen}
              selectedData={selectedData?.original}
              refetch={refetch}
              setIsAlbumClick={setIsAlbumClick}
            />
          )}

          {selectedData && tag === TAGS.ALBUM_APPROVAL && (
            <AlbumApprovalForm selectedData={selectedData?.original} />
          )}

          {selectedData && tag === TAGS.TRACK_APPROVAL && (
            <TrackApprovalForm
              sidebarRef={sidebarRef}
              setIsOpen={setIsOpen}
              selectedData={selectedData?.original}
            />
          )}
          {tag === TAGS.BULK_UPLOAD && (
            <BulkUploadForm
              setIsOpen={setIsOpen}
              sidebarRef={sidebarRef}
              setDynamicTag={setDynamicTag}
              tag={TAGS.BULK_UPLOAD}
            />
          )}
          {tag === TAGS.MANUAL_UPLOAD && (
            <ManualUploadForm
              setIsOpen={setIsOpen}
              setIsAlbumCreated={setIsAlbumCreated}
              setUpcCode={setUpcCode}
              upcRef={upcRef}
              apiCalledRef={apiCalledRef}
            />
          )}
        </div>
      </div>

      {isRevertModalOpen && (
        <RevertModal
          isOpen={isRevertModalOpen}
          close={() => setIsRevertModalOpen(false)}
          isAlbum={upcCode}
          isTrack={isrcCode}
          revertFn={revertFn}
          isLoading={isLoading}
        />
      )}
      {/* {location.pathname === '/albums' ? (
        <Prompt
          when={(isrcCode && !trackCreated) || (upcCode && !isAlbumCreated)}
          message={`Are you sure you want to leave the page? The generated ${
            isrcCode ? 'ISRC' : 'UPC'
          } code will be reverted`}
        />
      ) : null} */}
    </CustomFlyMenuContainer>
  );
};

const CustomFlyMenuContainer = styled.div<{
  theme: Theme & CustomThemeType;
  tag;
}>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  bottom: 0;
  left: 0;
  z-index: 2;

  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.5);

  .flymenu {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 550px;
    overflow-x: hidden;
    overflow-y: scroll;
    max-width: 100%;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    animation: slideleft 0.25s cubic-bezier(0.2, 0.8, 0.4, 1);
    padding: 0px;
    margin: 0px;
    transform: translateX(550px);
    background-color: ${(props) =>
      props.theme.bgDefault && props.theme.bgDefault};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px;

    .header-sidebar {
      height: 50px;
      position: sticky;
      top: 0;
      z-index: 99;

      .close {
        height: 50px;
        display: flex;
        align-items: center;
        background-color: #df3732;
        justify-content: space-between;

        .wrapper {
          display: flex;
          align-items: center;
        }
        .title {
          margin-left: 10px;
          font-weight: bold;
          color: white;
          font-size: 18px;
        }
        .close-icon {
          background: #c7241f;
          padding: 12.5px;
          display: flex;
          svg {
            font-size: 25px;
            cursor: pointer;
            margin-top: 0px;
            color: #ffffff;
          }
        }
      }
    }
  }
  .form-container {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }
  .flymenuopen {
    transform: translateX(0px);
  }

  @keyframes slideleft {
    from {
      opacity: 0;
      transform: translateX(100%);
    }

    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }
`;

export default FlyMenu;

const StatusInForm = ({value, tag}) => {
  const [albumStatus, setAlbumStatus] = useLocalStorage('albumStatus', null);
  return (
    <StatusContainerInForm status={value} albumStatus={albumStatus} tag={tag}>
      <span>
        {value === 'incomplete'
          ? 'Incomplete'
          : value === 'waiting_approval' && albumStatus !== 'declined'
          ? 'In Review'
          : value === 'waiting_approval' &&
            albumStatus === 'declined' &&
            tag !== 'track_approval'
          ? 'Complete'
          : value === 'waiting_approval' &&
            albumStatus === 'declined' &&
            tag === 'track_approval'
          ? 'In Review'
          : value === 'declined'
          ? 'Declined'
          : value === 'complete'
          ? 'Complete'
          : value === 'pending' && tag === TAGS.TRACKS
          ? 'Pending'
          : value === 'approved' && tag === TAGS.TRACKS
          ? 'Approved'
          : value === 'approved' && tag === TAGS.TRACK_APPROVAL
          ? 'Approved'
          : 'Orderable'}

        {value === 'incomplete' ? (
          <RiFileDamageFill />
        ) : value === 'complete' ? (
          <FiCheckSquare />
        ) : value === 'pending' ? (
          <FaInfoCircle />
        ) : value === 'waiting_approval' ? (
          <FaInfoCircle />
        ) : value === 'declined' ? (
          <IoMdCloseCircle />
        ) : (
          <FaCheckCircle />
        )}
      </span>
    </StatusContainerInForm>
  );
};

export const StatusContainerInForm = styled.div<{
  status: string;
  albumStatus: string;
  tag: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    padding: 6px 12px;
    width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    svg {
      margin-left: 10px;
    }

    background: ${(props) =>
      props.status === 'waiting_approval' && props.albumStatus !== 'declined'
        ? '#BDE5F8'
        : props.status === 'pending'
        ? '#FEEFB3'
        : props.status === 'incomplete'
        ? '#FEEFB3'
        : props.status === 'complete'
        ? '#Fcd396'
        : props.status === 'waiting_approval' &&
          props.albumStatus === 'declined' &&
          props.tag !== 'track_approval'
        ? '#FEEFB3'
        : props.status === 'waiting_approval' &&
          props.albumStatus === 'declined' &&
          props.tag === 'track_approval'
        ? '#BDE5F8'
        : props.status === 'declined'
        ? '#FFD2D2'
        : '#DFF2BF'};
    color: ${(props) =>
      props.status === 'waiting_approval' && props.albumStatus !== 'declined'
        ? '#00529B'
        : props.status === 'pending'
        ? '#9F6000'
        : props.status === 'incomplete'
        ? '#9F6000'
        : props.status === 'complete'
        ? '#a16100'
        : props.status === 'waiting_approval' &&
          props.albumStatus === 'declined' &&
          props.tag !== 'track_approval'
        ? '#9F6000'
        : props.status === 'waiting_approval' &&
          props.albumStatus === 'declined' &&
          props.tag === 'track_approval'
        ? '#00529B'
        : props.status === 'declined'
        ? '#D8000C'
        : '#4F8A10'};
    line-height: 0.25rem;
  }
`;
