import React, {useEffect, useState} from 'react';

import invalidCoverArt from '../../assets/images/invalid.png';
import usePreviewLink from '../../hooks/AlbumDetail/usePreviewLink';

export default function AlbumArt(props) {
  const {url} = props;

  const [imgUrl, setImgUrl] = useState(null);

  useEffect(() => {
    if (url) {
      setImgUrl(url);
    }
  }, [url]);

  return (
    <>
      {imgUrl ? (
        <img src={imgUrl} height="100%" width="100%" alt="" />
      ) : (
        <img src={invalidCoverArt} height="100%" width="100%" alt="" />
      )}
    </>
  );
}
