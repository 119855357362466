/** @format */
import * as Yup from 'yup';
import {lazy} from 'yup';

export const albumDetailsValidationSchema = Yup.object({
  album_type: Yup.string().required('Required'),
  product_type: Yup.string().required('Required'),
  album_name: Yup.string()
    .required('Album name is required')
    .min(1, 'You need to enter a name')
    .max(120, 'You have exceeded the max character length'),
  upc: Yup.string()
    .trim()
    .matches(/^[0-9]+$/, 'UPC code must be a number')
    .min(12)
    .max(13)
    .required('UPC is required'),
  actors_name: Yup.array().when('album_type', {
    is: (album_type) => album_type?.toLowerCase() === 'film',
    then: Yup.array()
      .of(
        Yup.string()
          .matches(/^[^,]+$/, 'You might have entered a special character')
          .min(1, 'You need to enter a name')
          .max(120, 'You have exceeded the max character length')
          .required('Actor (Male) Name is required'),
      )
      .required('Actor (Male) Name is required'),
  }),
  actress_name: Yup.array().when('album_type', {
    is: (album_type) => album_type?.toLowerCase() === 'film',
    then: Yup.array()
      .of(
        Yup.string()
          .matches(/^[^,]+$/, 'You might have entered a special character')
          .min(1, 'You need to enter a name')
          .max(120, 'You have exceeded the max character length')
          .required('Actress (Female) Name is required'),
      )
      .required('Actor (Male) Name is required'),
  }),

  // actors_name: Yup.array().of(Yup.string().required('Required')),
  // actress_name: Yup.array().of(Yup.string().required('Required')),
  language_id: Yup.string().required('Album Language is required'),
  album_director_name: Yup.array().when('album_type', {
    is: (album_type) => album_type?.toLowerCase() === 'film',
    then: Yup.array()
      .of(
        Yup.string()
          .matches(/^[^,]+$/, 'You might have entered a special character')
          .min(1, 'You need to enter a name')
          .max(120, 'You have exceeded the max character length')
          .required('Film Director Name is required'),
      )
      .notRequired(),
  }),
  album_producer_name: Yup.array().when('album_type', {
    is: (album_type) => album_type?.toLowerCase() === 'film',
    then: Yup.array()
      .of(
        Yup.string()
          .matches(/^[^,]+$/, 'You might have entered a special character')
          .min(1, 'You need to enter a name')
          .max(120, 'You have exceeded the max character length')
          .required('Film Producer Name is required'),
      )
      .notRequired(),
  }),
  banner_production_name: Yup.array().when('album_type', {
    is: (album_type) => album_type?.toLowerCase() === 'film',
    then: Yup.array()
      .of(
        Yup.string()
          .matches(/^[^,]+$/, 'You might have entered a special character')
          .min(1, 'You need to enter a name')
          .max(120, 'You have exceeded the max character length')
          .required('Production House Name is required'),
      )
      .notRequired(),
  }),
  label_name: Yup.string()
    .min(1, 'You need to enter a label name')
    .max(120, 'You have exceeded the max character length'),
  label_c_name: Yup.string()
    .min(1, 'You need to enter a name')
    .max(120, 'You have exceeded the max character length')
    .required('C-Line is required'),
  p_line_name: Yup.string()
    .min(1, 'You need to enter a name')
    .max(120, 'You have exceeded the max character length')
    .required('P-Line is required'),
  film_zone: Yup.string().required('Film Zone is required'),
  album_release_date: Yup.string().required('Album Release Date is required'),
}).test(
  'at-least-one-property',
  'You must provide at least one',
  (value) => !!(value.actress_name || value.actors_name),
);

export const albumDetailsUpdateValidationSchema = Yup.object({
  album_type: Yup.string().required('Required'),
  product_type: Yup.string().required('Required'),
  album_name: Yup.string()
    .required('Album Name is required')
    .min(1, 'You need to enter a name')
    .max(120, 'You have exceeded the max character length'),
  // artist_id: Yup.string().required('Required'),
  actors_name: Yup.array().when('album_type', {
    is: (album_type) => album_type?.toLowerCase() === 'film',
    then: Yup.array()
      .of(
        Yup.string()
          .matches(/^[^,]+$/, 'You might have entered a special character')
          .min(1, 'You need to enter a name')
          .max(120, 'You have exceeded the max character length')
          .required('Actor (Male) Name is required'),
      )
      .required('Actor (Male) Name is required'),
    otherwise: Yup.array().notRequired(),
  }),
  actress_name: Yup.array().when('album_type', {
    is: (album_type) => album_type?.toLowerCase() === 'film',
    then: Yup.array()
      .of(
        Yup.string()
          .matches(/^[^,]+$/, 'You might have entered a special character')
          .min(1, 'You need to enter a name')
          .max(120, 'You have exceeded the max character length')
          .required('Actress (Female) Name is required'),
      )
      .required('Actress (Female) Name is required'),
  }),
  // actors_name: Yup.array().of(Yup.string().required('Required')),
  // actress_name: Yup.array().of(Yup.string().required('Required')),
  language_id: Yup.string().required('Album Language is required'),
  album_director_name: Yup.array().when('album_type', {
    is: (album_type) => album_type?.toLowerCase() === 'film',
    then: Yup.array()
      .of(
        Yup.string()
          .matches(/^[^,]+$/, 'You might have entered a special character')
          .min(1, 'You need to enter a name')
          .max(120, 'You have exceeded the max character length')
          .required('Film Director Name is required'),
      )
      .notRequired(),
  }),
  album_producer_name: Yup.array().when('album_type', {
    is: (album_type) => album_type?.toLowerCase() === 'film',
    then: Yup.array()
      .of(
        Yup.string()
          .matches(/^[^,]+$/, 'You might have entered a special character')
          .min(1, 'You need to enter a name')
          .max(120, 'You have exceeded the max character length')
          .required('Film Producer Name is required'),
      )
      .notRequired(),
  }),
  banner_production_name: Yup.array().when('album_type', {
    is: (album_type) => album_type?.toLowerCase() === 'film',
    then: Yup.array()
      .of(
        Yup.string()
          .matches(/^[^,]+$/, 'You might have entered a special character')
          .min(1, 'You need to enter a name')
          .max(120, 'You have exceeded the max character length')
          .required('Production House Name is required'),
      )
      .notRequired(),
  }),
  label_c_name: Yup.string()
    .required('C-Line is required')
    .min(1, 'You need to enter a name')
    .max(120, 'You have exceeded the max character length'),
  p_line_name: Yup.string()
    .required('P-Line is required')
    .min(1, 'You need to enter a name')
    .max(120, 'You have exceeded the max character length'),
  film_zone: Yup.string().required('Film Zone is required'),
  album_release_date: Yup.string().required('Album Release Date is required'),
}).test(
  'at-least-one-property',
  'You must provide at least one',
  (value) => !!(value.actress_name || value.actors_name),
);

const updateFilmSchema = Yup.object({
  album_type: Yup.string().required('Required'),
  product_type: Yup.string().required('Required'),
  album_name: Yup.string()
    .required('Album Name is required')
    .min(1, 'You need to enter a name')
    .max(120, 'You have exceeded the max character length'),
  // artist_id: Yup.string().required('Required'),
  actors_name: Yup.array()
    .of(
      Yup.string()
        .matches(/^[^,]+$/, 'You might have entered a special character')
        .min(1, 'You need to enter a name')
        .max(120, 'You have exceeded the max character length')
        .required('Actor (Male) Name is required'),
    )
    .required('Actor (Male) Name is required'),
  actress_name: Yup.array()
    .of(
      Yup.string()
        .matches(/^[^,]+$/, 'You might have entered a special character')
        .min(1, 'You need to enter a name')
        .max(120, 'You have exceeded the max character length')
        .required('Actress (Female) Name is required'),
    )
    .required('Actress (Female) Name is required'),
  language_id: Yup.string().required('Album Language is required'),
  album_director_name: Yup.array()
    .of(
      Yup.string()
        .matches(/^[^,]+$/, 'You might have entered a special character')
        .min(1, 'You need to enter a name')
        .max(120, 'You have exceeded the max character length')
        .required('Film Director Name is required'),
    )
    .required('Film Director Name is required'),
  album_producer_name: Yup.array()
    .of(
      Yup.string()
        .matches(/^[^,]+$/, 'You might have entered a special character')
        .min(1, 'You need to enter a name')
        .max(120, 'You have exceeded the max character length')
        .required('Film Producer Name is required'),
    )
    .required('Film Producer Name is required'),
  banner_production_name: Yup.array()
    .of(
      Yup.string()
        .matches(/^[^,]+$/, 'You might have entered a special character')
        .min(1, 'You need to enter a name')
        .max(120, 'You have exceeded the max character length')
        .required('Production House Name is required'),
    )
    .required('Production House Name is required'),
  label_c_name: Yup.string()
    .required('C-Line is required')
    .min(1, 'You need to enter a name')
    .max(120, 'You have exceeded the max character length'),
  p_line_name: Yup.string()
    .required('P-Line is required')
    .min(1, 'You need to enter a name')
    .max(120, 'You have exceeded the max character length'),
  film_zone: Yup.string().required('Film Zone is required'),
  territory: Yup.string().required('Required'),
  album_release_date: Yup.string().required('Album Release Date is required'),
  country: Yup.array().when('territory', {
    is: (territory) =>
      territory?.toLowerCase() === 'exclude' ||
      territory?.toLowerCase() === 'include',
    then: Yup.array()
      .of(Yup.number().required('Country is required'))
      .min(1)
      .notRequired(),
  }),
}).test(
  'at-least-one-property',
  'You must provide at least one',
  (value) => !!(value.actress_name || value.actors_name),
);

const updateNonFilmSchema = Yup.object({
  album_type: Yup.string().required('Required'),
  product_type: Yup.string().required('Required'),
  album_name: Yup.string()
    .required('Album Name is required')
    .min(1, 'You need to enter a name')
    .max(120, 'You have exceeded the max character length'),
  // artist_id: Yup.string().required('Required'),
  actors_name: Yup.array().of(
    Yup.string()
      .matches(/^[^,]+$/, 'You might have entered a special character')
      .min(1, 'You need to enter a name')
      .max(120, 'You have exceeded the max character length'),
  ),
  actress_name: Yup.array().of(
    Yup.string()
      .matches(/^[^,]+$/, 'You might have entered a special character')
      .min(1, 'You need to enter a name')
      .max(120, 'You have exceeded the max character length'),
  ),
  language_id: Yup.string().required('Album Language is required'),
  album_director_name: Yup.array().of(
    Yup.string()
      .matches(/^[^,]+$/, 'You might have entered a special character')
      .min(1, 'You need to enter a name')
      .max(120, 'You have exceeded the max character length'),
  ),
  album_producer_name: Yup.array().of(
    Yup.string()
      .matches(/^[^,]+$/, 'You might have entered a special character')
      .min(1, 'You need to enter a name')
      .max(120, 'You have exceeded the max character length'),
  ),
  banner_production_name: Yup.array().of(
    Yup.string()
      .matches(/^[^,]+$/, 'You might have entered a special character')
      .min(1, 'You need to enter a name')
      .max(120, 'You have exceeded the max character length'),
  ),
  label_c_name: Yup.string()
    .required('C-Line is required')
    .min(1, 'You need to enter a name')
    .max(120, 'You have exceeded the max character length'),
  p_line_name: Yup.string()
    .required('P-Line is required')
    .min(1, 'You need to enter a name')
    .max(120, 'You have exceeded the max character length'),
  film_zone: Yup.string().required('Film Zone is required'),
  territory: Yup.string().required('Required'),
  country: Yup.array().when('territory', {
    is: (territory) =>
      territory?.toLowerCase() === 'exclude' ||
      territory?.toLowerCase() === 'include',
    then: Yup.array()
      .of(Yup.number().required('Country is required'))
      .min(1)
      .notRequired(),
  }),
  album_release_date: Yup.string().required('Album Release Date is required'),
}).test(
  'at-least-one-property',
  'You must provide at least one',
  (value) => !!(value.actress_name || value.actors_name),
);

const returnCorrectUpdateSchema = (album_type) => {
  if (album_type.length && album_type[0]?.value?.toLowerCase() === 'film') {
    return updateFilmSchema;
  } else {
    return updateNonFilmSchema;
  }
};

export const updateAlbumValidationSchema = lazy(({album_type}) =>
  returnCorrectUpdateSchema(album_type),
);
