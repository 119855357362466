import styled from '@emotion/styled';
import {Accordion, Panel} from 'baseui/accordion';
import {ArrowUp} from 'baseui/icon';
import React, {useMemo} from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io';
import {MdAddCircle, MdKeyboardArrowRight} from 'react-icons/md';
import {useLocation, useParams} from 'react-router';

import {TAGS} from '../../constants/tags';
import useGetSingleProject from '../../hooks/Project/useGetSingleProject';
import {bulkAdminColumn} from '../../pages/BulkUploadAdminManagement/tableData';
import Button from '../reusable/Button/Button';
import TableAccordion from '../reusable/Table/TableAccordion';
import Table from '../reusable/Table/TablePagination';
const Container = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
  .panel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .arrow {
      display: flex;
      .count {
        background: #f1eeee;
        border-radius: 20px;
        margin-right: 10px;
        width: 43px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
export default function BulkUploadAdmin(props) {
  const {
    columnData,
    renderData,
    accrodianData,
    albumExpand,
    setAlbumExpand,
    isLoading,
    isFetching,
    pendingAlbums,
  } = props;

  return (
    <>
      <Container>
        <TableAccordion
          columns={columnData}
          tag={TAGS.BULK_ADMIN}
          data={renderData(albumExpand)}
          accrodianData={accrodianData}
          setAlbumExpand={setAlbumExpand}
          isLoading={isLoading}
          isFetching={isFetching}
          actionBtn={() => null}
          pendingAlbums={pendingAlbums}
        />
      </Container>
    </>
  );
}
