/** @format */

import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Button} from 'baseui/button';
import {Spinner} from 'baseui/spinner';
import {ErrorMessage, Field} from 'formik';
import React, {useState} from 'react';

import useNotification from '../../../Contexts/useNotification';
import {logError} from '../../../errorlogger/logError';
import useShowCodeErrorMessage from '../../../hooks/reusable/useShowCodeErrorMessage';
import DetailsError from './DetailsError';
import {CustomInputText} from './InputWithButton';
import TextError from './TextError';

const btnOveride = (theme) => {
  return {
    BaseButton: {
      style: {
        background: theme.tabInactiveBorderColor,
        display: 'inline-block',
        color: 'black',
        // margin: '0 0.5rem',
        marginLeft: '0px',
        width: '5rem',
        // height: '1.5rem',
        height: '33px',
        lineHeight: '1.5rem',
        paddingLeft: '0.3rem',
        paddingRight: '0.3rem',
        paddingTop: '0.3rem',
        paddingBottom: '0.3rem',
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: '5px',
        borderTopRightRadius: '5px',
        fontSize: '0.95rem',
        fontWeight: '400',
        boxShadow: '3px 3px 5px 0px rgba(230,230,230, 0.75)',
        ':hover': {
          color: theme.bg,
        },
      },
    },
  };
};

function InputWithButton(props) {
  const [css, theme] = useStyletron();
  const {
    label,
    name,
    initialValue,
    setFieldValue,
    generateCode,
    setIsrcCode,
    setIsValidApiCalled,
    setIsValid,
    ...rest
  } = props;
  const [value, setValue] = useState(initialValue);
  const [generating, setGenerating] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [codeEmpty, setCodeEmpty] = useState(false);
  const {setMessage, element, message} = useShowCodeErrorMessage();
  const {showToast} = useNotification();
  const [isValidateLoading, setIsValidateLoading] = useState(false);

  const handleClick = async (value) => {
    setGenerating(true);
    try {
      const code = await generateCode();

      if (code.error?.length > 0) {
        showToast('no code in the system right now', 'error');
        setGenerating(false);
      } else {
        setGenerating(false);
        setDisabled(true);
        setValue(value);
        setIsrcCode(code?.data[0]);
        setFieldValue(name, code?.data[0]);
      }
    } catch (error) {
      logError(error, 7);
    }
  };

  const onChangeHandler = (event) => {
    setMessage(null);
    setValue(event.target.value);
    setFieldValue(name, event.target.value);
  };

  React.useEffect(() => {
    if (name === 'isrc') {
      if (!message) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [message]);

  return (
    <InputWithButton.Container
      borderColor={theme.tabInactiveBorderColor}
      bgColor={theme.inputBg}
      textColor={theme.textColor}
    >
      <div
        style={{pointerEvents: disabled && 'none', opacity: disabled && '0.7'}}
      >
        <div className="input_item">
          <label htmlFor={name}>{label}</label>
          <div className="right upc_code">
            <Field
              id={name}
              name={name}
              value={value}
              onChange={(e) => onChangeHandler(e)}
              {...rest}
              setMessage={setMessage}
              setIsValidateLoading={setIsValidateLoading}
              setIsValidApiCalled={setIsValidApiCalled}
              component={CustomInputText}
            />
            {/* {isValidateLoading && (
              <div>
                <Spinner size={26} />
              </div>
            )} */}
            <Button
              disabled={disabled}
              isLoading={generating || isValidateLoading}
              onClick={({value}) => handleClick(value)}
              type="button"
              overrides={btnOveride(theme)}
            >
              Get code
            </Button>
          </div>
        </div>
        <div className="input_item">
          <div className="left"></div>
          <div className="right">
            {element && element}
            {codeEmpty && (
              <DetailsError hideDuration={3000}>
                no code available in the system right now
              </DetailsError>
            )}
            <ErrorMessage name={name} component={TextError} />
          </div>
        </div>
      </div>
    </InputWithButton.Container>
  );
}

InputWithButton.Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.85rem;

  .input_item {
    flex-direction: column;
    .right {
    }
  }

  .upc_code {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    width:620px;
    button{
      display: flex;
    justify-content: center;
    align-items: center;
    }
  }

  label {
    font-size: 0.85rem;
    font-weight: 600;
    color: ${(props) => props.textColor};
  }

  input {
    font-size: 0.85rem;
    border-radius: 5px 0 0 5px;
    padding: 0.86rem;
    background: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};
    border: none;
    min-width: 60%;

    &:focus {
      outline: none;
    }
  }
`;

export default InputWithButton;
