import styled from '@emotion/styled';
import React, {useContext, useEffect, useState} from 'react';
import {AiOutlineInfoCircle} from 'react-icons/ai';

import Button from '../../../components/reusable/Button/Button';
import {GlobalContext} from '../../../Contexts/GlobalProvider';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import MusicIcons from '../../../pages/AlbumsManagment/AlbumDetail/divo/Frame 564.svg';
import {UploadCoverArt} from '../../AlbumDetail/form/UploadCoverArt';
import BulkUploadTerritory from '../../BulkUpload/BulkUploadTerritory';
import SelectLabelComponent from '../../CreateProject/SelectLabelComponent';
import FormikControl from '../../reusable/Formik/FormikControl';
import LoadingSpinnerTransparent from '../../reusable/LoadingSpinnerTransparent/LoadingSpinnerTransparent';
import Tooltip from '../../reusable/ToolTip/ToolTip';
import BulkUploadCoverArts from './BulkUploadCoverArts';
import UploadExcel from './BulkUploadExcel';
import BulkUploadExcelNew from './BulkUploadExcelNew';
import BulkUploadTracks from './BulkUploadTracks';
import StepProgressComp from './StepProgressComp';
import UploadCover from './UploadCover';
import WholeSummary from './WholeSummary';

const data = [
  {
    title1: 'STEP 1',
    title2: 'STEP 2',
    subtitle1: 'Choose Territory',
    desc: 'Choose Territory to sell your albums',
    subtitle2: 'Upload Audio Tracks',
    step: '1',
  },
  {
    title1: 'STEP 2',
    title2: 'STEP 3',
    subtitle1: 'Upload Audio Tracks',
    desc: 'Drag and drop  / select multiple tracks to upload',
    subtitle2: 'Upload Cover Arts',
    step: '2',
  },
  {
    title1: 'STEP 3',
    title2: 'STEP 4',
    subtitle1: 'Upload Cover Arts',
    desc: 'Drag and drop  / select multiple coverart files to upload',
    subtitle2: 'Upload Excel',
    step: '3',
  },
  {
    title1: 'STEP 4',
    title2: 'SUMMARY',
    subtitle1: 'Upload Excel Files',
    desc: 'Drag and drop  / select excel file to upload',
    subtitle2: 'summary of your uploads',
    step: '4',
  },
];
const BulkUploadForm = (props) => {
  const {setIsOpen, setDynamicTag, tag} = props;
  const [project, setProject] = useState(null);
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);

  const [enable, setEnable] = useState(false);
  const [step, setStep] = useState(1);
  const [stepperData, setStepperData] = useState(data[0]);
  const {state, dispatch}: any = useContext(GlobalContext);
  const [selectedValue, setSelectedValue] = useState('worldwide');
  const [excludeValue, setExcludeValue] = useState([]);
  const [includeValue, setIncludeValue] = useState([]);
  const [metadata, setMetadata] = useState(null);

  const goToNextHandler = () => {
    if (step === 1) {
      const territory = selectedValue;
      const country =
        selectedValue === 'exclude'
          ? excludeValue.map((item) => item.id)
          : selectedValue === 'include'
          ? includeValue.map((item) => item.id)
          : [];

      const metadata = {
        territory: territory,
        country: country,
      };
      setMetadata(metadata);
    }
    setStep(step + 1);
  };

  useEffect(() => {
    if (step === 1) {
      setEnable(true);
    } else if (step === 2) {
      setEnable(false);

      setStepperData(data[1]);
    } else if (step === 3) {
      setStepperData(data[2]);
    } else if (step === 4) {
      setStepperData(data[3]);
    } else {
      return;
    }
  }, [step]);

  React.useEffect(() => {
    return () => {
      dispatch({
        type: 'CHANGE_STATUS',
        payload: {
          uploadStatus: null,
          duplicate_upc: null,
          duplicate_upc_count: null,
          duplicate_isrc: null,
          error: null,
          valid_upc_count: null,
          invalid_upc: null,
          type: null,
        },
      });
    };
  }, []);

  const renderForm = (currentStep) => {
    if (currentStep === 1) {
      return (
        <BulkUploadTerritory
          selectedValue={selectedValue}
          setExcludeValue={setExcludeValue}
          excludeValue={excludeValue}
          setSelectedValue={setSelectedValue}
          includeValue={includeValue}
          setIncludeValue={setIncludeValue}
        />
      );
    }
    if (currentStep === 2) {
      return (
        <>
          <div className="info">
            <Tooltip
              isOpen={true}
              content="Rename your track to ISRC codes"
              direction="right"
            >
              <AiOutlineInfoCircle />
            </Tooltip>
          </div>
          <BulkUploadTracks
            project={project}
            setEnable={setEnable}
            handleNext={() => handleNext()}
          />
        </>
      );
    } else if (currentStep === 4) {
      return (
        <BulkUploadExcelNew
          project={project}
          setEnable={setEnable}
          handleNext={() => handleNext()}
          metadata={metadata}
        />
      );
    } else if (currentStep === 3) {
      return (
        <BulkUploadCoverArts
          project={project}
          setEnable={setEnable}
          handleNext={() => handleNext()}
        />
      );
    } else {
      return;
    }
  };
  const dis = () => {
    if (selectedValue === 'worldwide') {
      return false;
    } else if (selectedValue === 'exclude' && excludeValue.length > 0) {
      return false;
    } else if (selectedValue === 'include' && includeValue.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <BulkUploadFormContainer className="bulkuploadcontainer">
      <div className="bulkupload-Content">
        {step > 4 ? (
          <>
            <WholeSummary
              project={project}
              setIsOpen={setIsOpen}
              setDynamicTag={setDynamicTag}
              currentUser={currentUser}
            />
          </>
        ) : (
          <>
            <StepProgressComp step={step} dataArr={data} data={stepperData} />

            <div className="container">
              <SelectLabelComponent setProject={setProject} tag={tag} />
            </div>
            <div className="group-container">
              <div className={`container-mid ${project ? '' : 'disable'}`}>
                {renderForm(step)}
              </div>
            </div>
          </>
        )}
      </div>
      {step > 4 ? null : (
        <div className={`container-2 ${project && enable ? '' : 'disable'}`}>
          <Button label="Next" onClick={goToNextHandler} disabled={dis()} />
        </div>
      )}
    </BulkUploadFormContainer>
  );
};

const BulkUploadFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  .bulkupload-Content {
    max-height: calc(100% - 70px);
    overflow: auto;
    margin: 0 -30px -30px;
    padding: 0 30px 30px;
  }
  .container-mid {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 35px;
    height: calc(50vh - 50px);
    .info {
      position: relative;
      display: flex;
      left: -36px;
      top: -138px;
      color: #333333;
      font-size: 18px;
    }
  }
  .disable {
    filter: grayscale(1);
    cursor: not-allowed;
    pointer-events: none;
  }
  .group-container {
    margin: 0 -30px;
    border-top: 1px solid #ececec;
    // height: calc(55vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .container {
    background: url('${MusicIcons}');
    background-position: 100% 0;
    background-size: auto;
    form {
      display: flex;
      flex-direction: column;
    }
    .tooltip {
      position: absolute;
      margin-top: -75px;
      margin-left: -15px;
    }

    .line {
      width: 100%;
      height: 0.5px;
      background: #eff1f4;
      margin-left: -30px;
      padding-left: 60px;
      margin-bottom: 90px;
    }
  }

  .container-2 {
    background: #f8f8f8;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;

    left: 0;
    bottom: 0;
    z-index: 99;
    button {
      width: 80px;
      // height: 35px;
      margin-right: 20px;
      background: #d21919;
      border-radius: 100px;
      color: #ffffff;
      font-weight: 500;
      font-size: 16px;
      outline: none;
      border: none;
      cursor: pointer;
    }
    button:disabled,
    button[disabled] {
      background-color: #cccccc;
      color: #666666;
    }
    .disable {
      filter: grayscale(1);
      cursor: not-allowed;
      pointer-events: none;
    }
  }
`;

export default BulkUploadForm;

function handleNext() {
  throw new Error('Function not implemented.');
}
