/** @format */

import React, {useContext} from 'react';
import {
  FaCheckCircle,
  FaFile,
  FaFileExport,
  FaFileImport,
  FaInfoCircle,
} from 'react-icons/fa';
import {FiCheckSquare} from 'react-icons/fi';
import {IoMdCloseCircle} from 'react-icons/io';
import {RiFileDamageFill} from 'react-icons/ri';
import {useNavigate} from 'react-router';
import {GlobalContext} from '../../Contexts/GlobalProvider';
import {formatDate} from '../../helpers/covertDate';
import useLocalStorage from '../../hooks/reusable/useLocalStorage';
import {TextWrap} from '../components/TableComponents';
import {
  sortAlphabeticalOrderWithoutCase,
  sortStatusAlphabeticalOrderWithoutCase,
} from './albumColumnHelper';
import {albumData} from './albumTable';
import {
  AlbumNameWrapper,
  ButtonContainer,
  CoverArt,
  StatusContainer,
} from './styled-components/TableComponents';

const ImageContainer = ({imgurl}) => {
  return <CoverArt imgSrc={imgurl ? imgurl : null}></CoverArt>;
};

export const albumsColumnMeta = [
  {
    Header: albumData.album_name.Header,
    accessor: albumData.album_name.accessor,
    sortType: (rowA, rowB) => {
      return sortAlphabeticalOrderWithoutCase(
        rowA.original.album_name,
        rowB.original.album_name,
      );
    },
    Cell: (data) => {
      const {state, dispatch}: any = useContext(GlobalContext);
      const renderImg = (data) => {
        if (data?.id === state.tempImg?.id) {
          return state?.tempImg?.url;
        } else {
          return data?.cover_art;
        }
      };
      return (
        <AlbumNameWrapper>
          <ImageContainer imgurl={() => renderImg(data.row?.original)} />
          <div> {data && data.value} </div>
        </AlbumNameWrapper>
      );
    },
  },
  {
    Header: albumData.upc.Header,
    accessor: albumData.upc.accessor,
  },
  {
    Header: albumData.label.Header,
    accessor: albumData.label.accessor,
    Cell: (data) => {
      return <span> {data && data.row?.original?.label?.label_name} </span>;
    },
    sortType: (rowA, rowB) => {
      return sortStatusAlphabeticalOrderWithoutCase(
        rowA.original.label?.label_name,
        rowB.original.label?.label_name,
      );
    },
  },
  {
    Header: albumData.product_type.Header,
    accessor: albumData.product_type.accessor,
    Cell: (data) => {
      return <TextWrap> {data && data.value} </TextWrap>;
    },
    sortType: (rowA, rowB) => {
      return sortAlphabeticalOrderWithoutCase(
        rowA.original.product_type,
        rowB.original.product_type,
      );
    },
  },
  {
    Header: albumData.album_type.Header,
    accessor: albumData.album_type.accessor,
    Cell: (data) => {
      return <TextWrap> {data && data.value} </TextWrap>;
    },
    sortType: (rowA, rowB) => {
      return sortAlphabeticalOrderWithoutCase(
        rowA.original.album_type,
        rowB.original.album_type,
      );
    },
  },
  {
    Header: albumData.created_on.Header,
    accessor: albumData.created_on.accessor,
    Cell: (data) => {
      return <span> {data && formatDate(data.value)} </span>;
    },
  },
  {
    Header: albumData.album_release_date.Header,
    accessor: albumData.album_release_date.accessor,
    Cell: (data) => {
      return <span> {data && formatDate(data.value)} </span>;
    },
  },
  {
    Header: albumData.status.Header,
    accessor: albumData.status.accessor,
    Cell: (data) => {
      return <StatusBox data={data} />;
    },
    sortType: (rowA, rowB) => {
      return sortStatusAlphabeticalOrderWithoutCase(
        rowA.original.status,
        rowB.original.status,
      );
    },
  },
  {
    Header: albumData.action_btn.Header,
    accessor: albumData.action_btn.accessor,
    Cell: (data) => {
      const navigate = useNavigate();
      const [projectId, setProjectId] = useLocalStorage('projectId', null);
      const [albumData, setAlbumData] = useLocalStorage(
        'track_album_data',
        null,
      );

      const onClickDetail = (data) => {
        navigate(`${data?.row?.original?.id}`);
        setProjectId(data?.row?.original?.project_id);
        setAlbumData({
          album_id: data?.row?.original?.id,
          track_upc: data?.row?.original?.upc,
          user_id: data?.row?.original?.user_id,
          project_id: data?.row?.original?.project_id,
        });
      };
      return (
        <ButtonContainer>
          <button onClick={() => onClickDetail(data)}>View Tracks</button>
        </ButtonContainer>
      );
    },
    disableSortBy: true,
  },
];

export const StatusBox = (props) => {
  const {
    data: {value},
    albumStatus,
    track,
  } = props;
  return (
    <>
      <StatusContainer status={value} albumStatus={albumStatus}>
        <span>
          {value === 'incomplete'
            ? 'Incomplete'
            : value === 'waiting_approval' && albumStatus !== 'declined'
            ? 'In Review'
            : value === 'waiting_approval' && albumStatus === 'declined'
            ? 'Complete'
            : value === 'declined'
            ? 'Declined'
            : track && value === 'pending'
            ? 'Pending'
            : value === 'complete'
            ? 'Complete'
            : !track
            ? 'Orderable'
            : 'Approved'}

          {value === 'incomplete' ? (
            <RiFileDamageFill />
          ) : value === 'waiting_approval' ? (
            <FaInfoCircle />
          ) : value === 'pending' ? (
            <FaInfoCircle />
          ) : value === 'declined' ? (
            <IoMdCloseCircle />
          ) : value === 'complete' ? (
            <FiCheckSquare />
          ) : (
            <FaCheckCircle />
          )}
        </span>
      </StatusContainer>
    </>
  );
};
