/* eslint-disable react-hooks/exhaustive-deps */
import styled from '@emotion/styled';
import {TriangleLeft, TriangleRight} from 'baseui/icon';
import {ErrorMessage, Field, FieldArray} from 'formik';
import React, {useEffect, useState} from 'react';
import {FiSearch} from 'react-icons/fi';
import {MdCancel} from 'react-icons/md';

import Button from '../../reusable/Button/Button';
import TextError from './TextError';

const MultiSelect = (props) => {
  const {
    label,
    name,
    options,
    initialValue,
    setFieldValue,
    labelKey,
    valueKey,
    values,
    ...rest
  } = props;

  const [acceptedOptions, setAcceptedOptions] = useState(initialValue);
  const [optionsToRemove, setOptionsToRemove] = useState([]);
  const [currentOptions, setCurrentOptions] = useState(options);
  const [isChecked, setIsChecked] = useState('not-checked');
  const [isAdded, setIsAdded] = useState<any>('random');
  const [isRemove, setIsRemove] = useState<any>('random');
  const [select, setSelect] = useState('not-checked');
  const [select2, setSelect2] = useState('not-checked');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTerm2, setSearchTerm2] = useState('');
  const [searchResults, setSearchResults] = useState(options);
  const [searchResults2, setSearchResults2] = useState(initialValue);
  const [enable, setEnable] = useState(false);
  const [enable2, setEnable2] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (initialValue?.length > 0 && options) {
      setCurrentOptions(
        options?.filter((a) => !initialValue.some((b) => b.id === a.id)),
      );
      // setFieldValue(name, '')
    } else {
      setCurrentOptions(options);
    }
  }, [options, initialValue]);

  useEffect(() => {
    if (acceptedOptions?.length === 0) {
      setFieldValue(name, '');
    } else {
      setFieldValue(
        name,
        acceptedOptions?.map(({id}) => id),
      );
    }
  }, [acceptedOptions]);

  const handleAdd = (e) => {
    setFieldValue(name, '');
    const key = 'id';
    const resp = options?.filter((item) =>
      values[name].find((itemObject) => itemObject === item.id),
    );
    setCurrentOptions(
      currentOptions?.filter((a) => !resp.some((b) => b.id === a.id)),
    );
    setAcceptedOptions([
      ...new Map(
        [...acceptedOptions, ...resp].map((item) => [item[key], item]),
      ).values(),
    ]);
    setIsChecked('not-checked');
    setIsAdded(true);
    setEnable(false);
    setTimeout(() => {
      setIsAdded(false);
    }, 500);
    setSearchTerm('');
  };

  const handleRemove = (e) => {
    if (optionsToRemove.length !== 0) {
      setCurrentOptions(
        [...currentOptions, ...optionsToRemove].sort((a, b) =>
          a?.value?.localeCompare(b.value),
        ),
      );
      setAcceptedOptions(
        acceptedOptions?.filter(
          (a) => !optionsToRemove.some((b) => b.id === a.id),
        ),
      );
    }
    setOptionsToRemove([]);
    // setIsAdded(false);
    setIsChecked('not-checked');
    setIsRemove(true);
    setEnable2(false);
    setTimeout(() => {
      setIsRemove(false);
    }, 500);
  };
  useEffect(() => {
    if (searchTerm.length > 0) {
      const results = [];
      if (currentOptions) {
        for (const index in currentOptions) {
          if (currentOptions[index]?.value) {
            if (
              currentOptions[index]?.value
                .toLowerCase()
                .includes(searchTerm.toLowerCase() || searchTerm)
            ) {
              results.push(currentOptions[index]);
            }
          } else if (currentOptions[index]?.dsp_name) {
            if (
              currentOptions[index]?.dsp_name
                .toLowerCase()
                .includes(searchTerm.toLowerCase() || searchTerm)
            ) {
              results.push(currentOptions[index]);
            }
          }
        }
      }
      setSearchResults(results);
    } else if (searchTerm.length === 0) {
      setSearchResults(currentOptions);
    }
    // }
  }, [searchTerm, currentOptions, options, initialValue]);

  useEffect(() => {
    if (searchTerm2.length > 0) {
      const results = [];
      if (acceptedOptions) {
        for (const index in acceptedOptions) {
          if (acceptedOptions[index]?.value) {
            if (
              acceptedOptions[index]?.value
                .toLowerCase()
                .includes(searchTerm2.toLowerCase() || searchTerm2)
            ) {
              results.push(acceptedOptions[index]);
            }
          } else if (acceptedOptions[index]?.dsp_name) {
            if (
              acceptedOptions[index]?.dsp_name
                .toLowerCase()
                .includes(searchTerm2.toLowerCase() || searchTerm2)
            ) {
              results.push(acceptedOptions[index]);
            }
          }
        }
      }
      setSearchResults2(results);
    } else if (searchTerm2.length === 0) {
      setSearchResults2(acceptedOptions);
    }
    // }
  }, [searchTerm2, acceptedOptions, options, initialValue]);
  useEffect(() => {
    if (count < 1) {
      setIsChecked('not-checked');
    }
  }, [count]);
  const searchList = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
    //  if(searchTerm.length>0){
    //   const searchResult = currentOptions.filter(result=> {
    //     return result.value.toLowerCase().includes(searchTerm.toLowerCase())
    //   })
    //   setCurrentOptions(searchResult);
    // }
  };
  const searchList2 = (e) => {
    e.preventDefault();
    setSearchTerm2(e.target.value);
  };
  const selectAll = (event, arrayHelpers) => {
    currentOptions.map((option, i) => {
      if (event.target.checked) {
        setCount(currentOptions?.length - initialValue?.length);
        setEnable(true);
        arrayHelpers.push(option[valueKey]);
        (document.getElementById(option.id) as HTMLInputElement).checked = true;
        setIsChecked('is-checked');
        setSelect('checked');
      } else if (!event.target.checked) {
        setCount(0);
        setEnable(false);
        const idx = values[name].indexOf(option[valueKey]);
        arrayHelpers.remove(currentOptions.indexOf(option[valueKey]));
        setIsChecked('not-checked');
        setSelect('not-checked');
        (document.getElementById(
          option.id,
        ) as HTMLInputElement).checked = false;
      }
    });
  };
  const selectAll2 = (event) => {
    const selected = [];
    acceptedOptions.map((option, i) => {
      if (event.target.checked) {
        setEnable2(true);
        setSelect2('checked');
        selected.push(option);
        setOptionsToRemove(selected);
      } else if (!event.target.checked) {
        setEnable2(false);
        setSelect2('not-checked');
        setOptionsToRemove(selected.filter((item) => item.id !== option.id));
      }
    });
  };
  return (
    <Wrapper>
      <label className="label">
        {label}
        <span style={{color: '#dc3832'}}> *</span>
      </label>
      <section className="main">
        <div className="available-options">
          <h5 className="head">Available {name}</h5>
          <div className="checkbox-control">
            <FieldArray
              name={name}
              render={(arrayHelpers) => (
                <>
                  <div className="all-select">
                    <div className="search">
                      <input
                        type="text"
                        value={searchTerm}
                        placeholder="Search"
                        onChange={(e) => searchList(e)}
                      />
                    </div>
                    <div className="check-all">
                      <label className="container">
                        Select all
                        <input
                          type="checkbox"
                          checked={enable}
                          onChange={(event) => selectAll(event, arrayHelpers)}
                        />
                        <span className="check-mark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="option-container">
                    <div className="options">
                      {searchResults?.map((option) => (
                        <CheckBoxList
                          option={option}
                          valueKey={valueKey}
                          name={name}
                          values={values}
                          arrayHelpers={arrayHelpers}
                          isChecked={isChecked}
                          labelKey={labelKey}
                          setIsChecked={setIsChecked}
                          isAdded={isAdded}
                          setIsAdded={setIsAdded}
                          select={select}
                          setEnable={setEnable}
                          enable={enable}
                          setSelect={setSelect}
                          count={count}
                          setCount={setCount}
                        />
                      ))}
                    </div>
                  </div>
                </>
              )}
            />
            <ErrorMessage component={TextError} name={name} />
          </div>
        </div>
        <div className="check-buttons">
          <Button
            color="#34A139"
            type="button"
            margin="0.95rem 0"
            label="Add"
            size="mini"
            endEnhancer={() => <TriangleRight size={24} />}
            disabled={isChecked === 'is-checked' ? false : true}
            background="rgba(52, 161, 57, 0.2)"
            onClick={(e) => handleAdd(e)}
          />
          <Button
            color="#DC3831"
            type="button"
            margin="0.95rem 0"
            label="Remove"
            startEnhancer={() => <TriangleLeft size={24} />}
            disabled={optionsToRemove?.length > 0 ? false : true}
            size="mini"
            background="rgba(220, 56, 49, 0.2)"
            onClick={(e) => handleRemove(e)}
          />
        </div>
        <div className="accepted-options">
          <h5 className="select-head">Selected {name}</h5>
          <div className="accepted">
            <div className="accept">
              <div className="all-select">
                <div className="search">
                  <input
                    type="text"
                    value={searchTerm2}
                    placeholder="Search"
                    onChange={(e) => searchList2(e)}
                  />
                </div>
                <div className="check-all">
                  <label className="container">
                    Select all
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={enable2}
                      onChange={(event) => selectAll2(event)}
                    />
                    <span className="check-mark"></span>
                  </label>
                </div>
              </div>
              <div className="option-container">
                <div className="options">
                  {searchResults2?.map((option) => (
                    <CheckAcccept
                      option={option}
                      valueKey={valueKey}
                      labelKey={labelKey}
                      optionsToRemove={optionsToRemove}
                      setOptionsToRemove={setOptionsToRemove}
                      isChecked={isChecked}
                      setIsChecked={setIsChecked}
                      isRemove={isRemove}
                      setIsRemove={setIsRemove}
                      setEnable2={setEnable2}
                      enable2={enable2}
                      select2={select2}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};
export const CheckAcccept = (props) => {
  const {
    option,
    valueKey,
    labelKey,
    optionsToRemove,
    setOptionsToRemove,
    isChecked,
    setIsChecked,
    isRemove,
    setIsRemove,
    enable2,
    setEnable2,
    select2,
  } = props;
  const [check1, setCheck1] = useState(isChecked);
  useEffect(() => {
    if (isRemove) {
      setCheck1('not-checked');
    }
  }, [isRemove]);
  return (
    <label key={option[valueKey]} className="container-checkbox">
      {/* <div className="checkbox-values"> */}
      <input
        type="checkbox"
        value={option[valueKey]}
        checked={optionsToRemove?.includes(option) ? true : false}
        onChange={(e) => {
          if (e.target.checked) {
            setOptionsToRemove([...new Set([...optionsToRemove, option])]);
            setCheck1('is-checked');
            // setIsChecked('not-checked');
          } else {
            setOptionsToRemove(
              optionsToRemove.filter((item) => item.id !== option.id),
            );
            setCheck1('not-checked');
            // setIsChecked('is-checked');
            setEnable2(false);
          }
        }}
      />
      <span className="checkmark"></span>
      <span className="option-name">{option[labelKey]}</span>
      {/* </div> */}
    </label>
  );
};
const CheckBoxList = (props) => {
  const {
    option,
    valueKey,
    name,
    values,
    arrayHelpers,
    isChecked,
    labelKey,
    setIsChecked,
    isAdded,
    setIsAdded,
    select,
    setEnable,
    enable,
    setSelect,
    count,
    setCount,
  } = props;
  const [check, setCheck] = useState(isChecked);

  useEffect(() => {
    if (isAdded) {
      setCheck('not-checked');
    }
  }, [isAdded]);

  return (
    <label key={option[valueKey]} className="container-checkbox">
      {/* <div className="checkbox-values"> */}

      <input
        name={name}
        type="checkbox"
        id={option[valueKey]}
        value={option[valueKey]}
        checked={values[name]?.includes(option[valueKey])}
        onChange={(e) => {
          if (e.target.checked) {
            arrayHelpers.push(option[valueKey]);
            setCheck('is-checked');
            setIsChecked('is-checked');
            setCount(count + 1);
          } else {
            const idx = values[name].indexOf(option[valueKey]);
            arrayHelpers.remove(idx);
            setCheck('not-checked');
            // setIsChecked('not-checked');
            // setEnable(false);
            setSelect('not-checked');
            setCount(count - 1);
          }
        }}
      />
      <span className="checkmark"></span>
      <span className="option-name">{option[labelKey]}</span>
      {/* </div> */}
    </label>
  );
};

const Wrapper = styled.section`
.label {
  margin-bottom: 0.35rem;
  display: inline-block;
  font-size: 14px;
  font-weight: 700
  }
 
  .main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem
  }
 
  .check-buttons {
  display: flex;
  flex-direction: column;
  padding: 1rem
  }
 
  .available-options,
  .accepted-options {
  : : -webkit-scrollbar {
  display: none
  }
  .head {
  z-index: 9;
  background: #efefef;
  padding: 5px 20px;
 
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0
  }
  .select-head {
  z-index: 9;
  background: #efefef;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0
  }
 
  background: #ffffff;
  border: 1px solid #eae4e4;
  border-radius: 15px;
  .checkbox-control,
  .accepted {
  width: 150px;
  .option-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center
  }
  .options {
  width: 90%
  }
  .all-select {
  .search {
  display: flex;
  width: 90%;
  input[type='text'] {
  width: 100%;
  margin-left: 0;
  height: 28px
  }
  }
  .check-all {
  display: flex;
  width: 100%;
  padding-left: 12px;
  .container {
  border-radius: 5px;
  display: flex;
  align-items: center;
  // display: block;
  position: relative;
  padding-left: 30px;
  // margin-top: 5px;
  // margin-bottom: 5px;
  cursor: pointer;
  font-size: 12px;
  color: #8d8d8d;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
  position: absolute;
  opacity: 0;
  cursor: pointer
  }
 
 
  .check-mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee
  }
  }
 
 
  .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0
  }
 
 
  .check-mark {
  margin-top: 4px;
  margin-left: 5px;
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 5px;
  &: after {
  content: '';
  left: 5px;
  top: 3px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg)
  }
  }
 
 
  .container: hover input ~ .check-mark {
  background-color: #ccc
  }
 
 
  .container input: checked ~ .check-mark {
  background-color: #df3732
  }
 
 
  .check-mark: after {
  content: '';
  position: absolute;
  display: none
  }
 
 
  .container input: checked ~ .check-mark: after {
  display: block
  }
  }
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0px;
  z-index: 9;
  background: #FFFFFF;
  align-items: center;
  padding: 10px 0px;
  justify-content: center;
 
  input[type='text'] {
  width: 135px;
  border: none;
  margin-left: 18px;
  margin-bottom: 5px;
  background-color: rgb(239, 241, 243);
  border-radius: 50px;
  height: 20px;
  padding-left: 10px;
  z-index: 9;
  outline: none;
  : : placeholder {
  font-size: 11px
  }
  }
 
  .select {
  margin-left: 5px;
  margin-right: 45px;
  color: #666666;
  font-size: 13px
  }
  svg {
  margin-right: -15px;
  z-index: 9;
  background: #ecebeb;
  color: #9a9090;
  padding: 3px;
  border-radius: 50%
  }
  }
  .container-checkbox {
  background: #f8f8f8;
  border-radius: 5px;
  padding: 5px 34px;
  display: flex;
  align-items: center;
  // display: block;
  position: relative;
  padding-left: 30px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 12px;
  color: #8d8d8d;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
  position: absolute;
  opacity: 0;
  cursor: pointer
  }
  .checkmark: after {
  content: '';
  position: absolute;
  display: none
  }
  .checkmark {
  margin-top: 10px;
  margin-left: 5px;
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 5px;
  &: after {
  content: '';
  left: 5px;
  top: 3px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg)
  }
  }
  : hover input ~ .checkmark {
  background-color: #ccc
  }
  input: checked ~ .checkmark {
  background-color: #df3732
  }
  input: checked ~ .checkmark: after {
  display: block
  }
  input: checked ~ .option-name {
  color: #dc3831
  }
  }
  // .checked {
  // background: #fff4f3;
  // color: #dc3831;
  // }
  }
  }
  .option-name {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-height: 65px
  }
  .available-options,
  .accepted-options {
  .checkbox-values {
  margin: 0.35rem 0;
 
  span {
  // margin-left: 0.25rem
  }
  }
 
  h5 {
  margin: 0px;
  font-size: 12px;
  color: #666
  }
 
  height: 300px;
  width: 150px;
  overflow: auto;
 
  border-radius: 0.75rem
  }
`;

export default MultiSelect;
