/* eslint-disable react-hooks/exhaustive-deps */
import {ErrorMessage, Field, FieldArray} from 'formik';
import React, {useState, useEffect} from 'react';
import TextError from './TextError';
import styled from '@emotion/styled'
import Button from '../../reusable/Button/Button';
import {camelToSentence} from '../../../helpers/convertSnakeCaseToUpperCase';
import {TriangleRight, TriangleLeft} from 'baseui/icon'

const UserMultiSelect = (props) => {
	const {
		label,
		name,
		options,
		initialValue,
		values,
		segregatedRoleOptionData,
    setFieldValue,
    typeValue,
    labelKey,
    valueKey,
    currentRoles,
    roleIndex,
		...rest
	} = props;

	const [acceptedOptions, setAcceptedOptions] = useState(initialValue)
	const [optionsToRemove, setOptionsToRemove] = useState([])
	const [currentOptions, setCurrentOptions] = useState([])
	const [isChecked, setIsChecked] = useState('not-checked')

	useEffect(() => {
		if(acceptedOptions?.length === 0) {
			setFieldValue(name, '')
		} else {
			setFieldValue(name, acceptedOptions?.map(({id}) => id))
		}
	}, [acceptedOptions])

	const [roleO, setRoleO] = React.useState([]);

  useEffect(() => {
    if (currentRoles) {
      const data = currentRoles.map((role) => {
        return {
          id: role.id,
          name: role.name,
          label: camelToSentence(role.name),
        };
      });

      setAcceptedOptions(data);
      setFieldValue(name, data);
    }
  }, [currentRoles]);

  useEffect(() => {
    const id = typeValue[0]?.id;
    if (options && id) {
      const filteredData = options.filter((option) => option.id === id);
      const data = filteredData[0]?.roles;
      setRoleO(data);

      if (roleIndex === -1) {
        if (data) {
					setAcceptedOptions(data);
					setCurrentOptions([])
          setFieldValue(name, data);
        }
      }
    }
	}, [typeValue, options, roleIndex]);
	
	useEffect(() => {
		if(optionsToRemove.length > 0 && roleO) setCurrentOptions(roleO)
	}, [roleO])

	const handleAdd = (e) => {
		setFieldValue(name, '')
		const key = "id"
		const resp = roleO?.filter(item => values[name].find(itemObject => itemObject === item.id))
		setCurrentOptions(currentOptions?.filter( a => !resp.some( b => b.id === a.id)))
		setAcceptedOptions([...new Map([...acceptedOptions, ...resp].map(item => [item[key], item])).values()])
		setIsChecked('not-checked')
	}

	const handleRemove = (e) => {
		if(optionsToRemove.length !== 0) {
			setCurrentOptions([...currentOptions, ...optionsToRemove].sort((a, b) => a?.value?.localeCompare(b.value)))
			setAcceptedOptions(acceptedOptions?.filter( a => !optionsToRemove.some( b => b.id === a.id)))
		}
		setOptionsToRemove([])
	}

  return (
		<Wrapper>
			<label className="label">{label}</label>
			<section className="main">
				<div className="available-options">
					<h5>Available {name}</h5>
					<div className="checkbox-control">
						{currentOptions?.length === 0 &&
							<div className="notif-area">
								<p className="notification">
									{acceptedOptions === undefined ? 'Please select a user type' : 'Start removing roles' }
								</p>
							</div>
						}
						<FieldArray
							name={name}
							render={arrayHelpers => (
								<div>
									{ 
										currentOptions?.map(option => (
										<label key={option[valueKey]}>
											<div className="checkbox-values">
												<input
													name = {name}
													type="checkbox"
													id={option[valueKey]}
													value={option[valueKey]}
													checked={values[name]?.includes(option[valueKey])}
													onChange={e => {
														if (e.target.checked) {
															arrayHelpers.push(option[valueKey]);
															setIsChecked('is-checked')
														} else {
															const idx = values[name].indexOf(option[valueKey]);
															arrayHelpers.remove(idx);
															setIsChecked('not-checked')
														}
													}}
												/>
												<span>{option[labelKey]}</span>
											</div>
										</label>
									))}
								</div>
							)}
						/>
						<ErrorMessage component={TextError} name={name} />
					</div>
				</div>
				<div className="check-buttons">
					<Button
						color="#34A139"
						type="button"
						margin="0.95rem 0"
						label="Add"
						size="mini"
						endEnhancer={() => <TriangleRight size={24} />}
						background="rgba(52, 161, 57, 0.2)"
						disabled={isChecked === 'is-checked' ? false : true}
						onClick={(e) => handleAdd(e)}
					/>
					<Button
						color="#DC3831"
						type="button"
						margin="0.95rem 0"
						label="Remove"
						size="mini"
						startEnhancer={() => <TriangleLeft size={24} />}
						background="rgba(220, 56, 49, 0.2)"
						disabled={optionsToRemove?.length > 0 ? false : true}
						onClick={(e) => handleRemove(e)}
					/>
				</div>
				<div className="accepted-options">
					<h5>Selected {name}</h5>
					<div className="accepted">
						{
							acceptedOptions?.map((option) => 
								<div className="checkbox-values">
									<input
										type="checkbox"
										value={option[valueKey]}
										checked={optionsToRemove?.includes(option) ? true : false}
										onChange={e => {
											if(e.target.checked) {
												setOptionsToRemove([...new Set([...optionsToRemove, option])])
											} else {
												setOptionsToRemove(optionsToRemove.filter((item) => item !== option.id))
											}
										}}
									/>
									<span>{ option["label"] }</span>
								</div>
							)
						}
					</div>
				</div>
			</section>
    </Wrapper>
  );
};

const Wrapper = styled.section`
	.label {
		margin-bottom: 0.35rem;
    display: inline-block;
    font-size: small;
    font-weight: 600;
	}

	.main {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;			
		margin-bottom: 1.2rem;
	}

	.check-buttons {
		display: flex;
		flex-direction: column;
		padding: 1rem;
	}

	.checkbox-control {
		.notif-area {
			margin-top: 5rem;
			.notification {
				display: block;
				font-size: 0.75rem;
				color: grey;
			}
		}
	}

	.available-options, .accepted-options {
		.checkbox-values {
			margin: 0.35rem 0;

			span {
				margin-left: 0.25rem;
			}
		}

		h5 {
			margin: 0 0 0.55rem 0;
		}

		height: 300px;
		width: 150px;
		overflow: auto;

		border-radius: 0.75rem;
		border: black 1px solid;
		padding: 0.85rem;
	}
`

export default UserMultiSelect;
