import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {Tab} from '../../../components';

import Album from '../AlbumDetail/Album';
import RequestModal from '../../../components/AlbumDetail/RequestModal';
import {tracksColumnMeta} from '../../../components/AlbumDetail/tableData';
import DownloadZipModal from '../../../components/AlbumsInReview/DownloadZipModal';
import FlyMenu from '../../../components/reusable/FlyMenu/FlyMenu';
import LoadingSpinnerTransparent from '../../../components/reusable/LoadingSpinnerTransparent/LoadingSpinnerTransparent';
import {TAGS} from '../../../constants/tags';
import useNotification from '../../../Contexts/useNotification';
import useGetAlbumsData from '../../../hooks/AlbumDetail/useGetAlbumsData';
import useStatusUpdateHandler from '../../../hooks/AlbumDetail/useStatusUpdateHandler';
import useSendRequest from '../../../hooks/AlbumsManagement/useSendRequest';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import useMusicPlayer from '../../../hooks/reusable/useMusicPlayer';
import useS3Key from '../../../hooks/reusable/useS3key';
import useTablePageState from '../../../hooks/reusable/useTablePageState';
import SkeletonAlbumDetail from '../../../pages/SkeletonAlbumDetail';
import AlbumInfo from '../AlbumDetail/AlbumInfo';
import MusicPlayerNew from '../AlbumDetail/MusicPlayerNew';
import AlbumHistory from '../Albums/AlbumHistory/AlbumHistory';
import ActionButtonDropDownContent from './ActionButtonDropDownContent';
import ReuseIsrcModal from '../../../components/AlbumDetail/ReuseIsrcModal';
import useConnectIsrc from '../../../hooks/AlbumsManagement/useConnectIsrc';
import useGetReuseIsrc from '../../../hooks/AlbumDetail/useGetReuseIsrc';
import useCallDispatch from '../../../hooks/FIlter/useCallDispatch';

const AlbumDetailNew = () => {
  const {albumId} = useParams();
  const [activeKey, setActiveKey] = React.useState<string | number>('0');
  const [tracks, setTracks] = useState(null);
  const [trackData, setTrackData] = useState(null);
  const [getTime, setGetTime] = useState([]);
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const [trackIsrc, setTrackIsrc] = useState(null);
  const [toggleDspModal, setToggleDspModal] = useState(false);
  const [musicDirectors, setMusicDirectors] = useState([]);

  const {getAlbumDetail, refetch, isFetching, isLoading} = useGetAlbumsData({
    albumId: albumId,
  });
  const [collapsePlayer, setCollapsePlayer] = useState(false);

  useEffect(() => {
    const md = getAlbumDetail?.Track.map((track) => track?.music_director);
    const mergedArr = Array.prototype.concat.apply([], md);
    const unique = [...new Set(mergedArr)];
    setMusicDirectors(unique);
  }, [getAlbumDetail]);

  useEffect(() => {
    if (getAlbumDetail) {
      setTracks(getAlbumDetail.Track);
      const d = getAlbumDetail.Track.find(
        (track, i) => track.isrc === trackIsrc,
      );
      setTrackData(d);
    }
  }, [getAlbumDetail, trackIsrc]);

  useEffect(() => {
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (window.location.pathname.indexOf('/albums') === -1) {
        window.localStorage.removeItem('tabArr');
      }
    };
  }, []);

  const {
    status,
    productType,
    updateStatusHandler,
    albumLoading,
    trackLoading,
  } = useStatusUpdateHandler({data: getAlbumDetail?.Track});

  const {
    isOpen,
    setNewFormTag,
    newFormTag,
    setIsOpen,
    setSelectedData,
    selectedData,
  } = useTablePageState();

  const {
    played,
    seeking,
    volume,
    handleSeekMouseDown,
    handleSeekChange,
    handleSeekMouseUp,
    handleVolumeChange,
    playerRef,
    handleStop,
    handlePlayPause,
    setPlayed,
    setCurrentTime,
    msToTime,
    setDuration,
    currentTime,
    duration,
    playing,
    handleMute,
    muted,
  } = useMusicPlayer({isrcCode: trackIsrc});

  const {s3KeyGenerator} = useS3Key({projectId: getAlbumDetail?.project_id});

  const {callDispatch} = useCallDispatch();
  const [dynamicTag, setDynamicTag] = useState(TAGS.TRACKS);
  const [isAlbumClick, setIsAlbumClick] = useState(false);
  const [requestOpen, setRequestOpen] = useState(false);
  const [reuseIsrcOpen, setReuseIsrcOpen] = useState(false);
  const [editComment, setEditComment] = useState('');
  const [metaData, setMetaData] = useState([]);
  const [trackOrder, setTrackOrder] = useState();
  const [searchStr, setSearchStr] = useState('');
  const [trackUpc, setTrackUpc] = useState();
  const [trackId, setTrackId] = useState();
  const [reuseIsrcOpen2, setReuseIsrcOpen2] = useState(false);

  const data = {
    album_id: getAlbumDetail?.id ? getAlbumDetail?.id : albumId,
    raised_by: currentUser.id,
    metadata: metaData,
    comment: editComment,
  };
  const connectIsrc = {
    track_id: trackId,
    data: {
      user_id: currentUser.id,
      track_order: trackOrder,
      album_id: getAlbumDetail?.id ? getAlbumDetail?.id : albumId,
      track_upc: trackUpc,
    },
  };
  useEffect(() => {
    if (getAlbumDetail) {
      setTrackUpc(getAlbumDetail?.upc);
      setTrackOrder(getAlbumDetail?.Track?.length + 1);
    }
  }, [getAlbumDetail]);
  const {sendEditRequest, error, isLoading: load} = useSendRequest();
  const {sendIsrc, reuseIsrcLoad} = useConnectIsrc();
  const {reuseIsrc, searchLoad} = useGetReuseIsrc({
    searchStr,
  });
  const {showToast} = useNotification();

  const reuseIsrcModalOpen = () => {
    setReuseIsrcOpen(true);
  };
  const content = [
    {
      title: `Track Details`,
      component: (
        <Album
          currentUser={currentUser}
          columns={tracksColumnMeta}
          setIsOpen={setIsOpen}
          data={getAlbumDetail?.Track}
          setSelectedData={setSelectedData}
          setNewFormTag={setNewFormTag}
          tag={dynamicTag}
          setTrackIsrc={setTrackIsrc}
          handlePlayPause={handlePlayPause}
          playing={playing}
          duration={duration}
          trackIsrc={trackIsrc}
          hasCoverArtValue={getAlbumDetail?.cover_art ? true : false}
          status={status}
          productType={productType}
          reuseIsrcModalOpen={reuseIsrcModalOpen}
          getAlbumDetail={getAlbumDetail}
          refetchHandler={refetch}
          isFetching={isFetching}
        />
      ),
    },
    {
      title: `History`,
      component: <AlbumHistory />,
    },
  ];

  const openAddCoverArtHandler = () => {
    setIsOpen(true);
    setNewFormTag('add-coverart');
  };

  const requestModal = () => {
    setRequestOpen(true);
  };

  const handleSendRequest = async () => {
    const res = await sendEditRequest(data);
    if (res?.statusCode === 200) {
      setRequestOpen(false);
      showToast('Request sent successfully.', 'success');
    }
    if (res.error && res.error?.length > 0) {
      showToast(res.error, 'error');
    }
  };
  const handleReuseIsrc = async () => {
    const res = await sendIsrc(connectIsrc);
    if (res?.statusCode === 200) {
      refetch();
      showToast('Track added successfully.', 'success');
      setReuseIsrcOpen2(false);
    }
    if (getAlbumDetail?.product_type === 'single') {
      setReuseIsrcOpen(false);
    }
    if (res.error && res.error?.length > 0) {
      showToast(res.error, 'error');
    }
  };
  const openAlbumForm = () => {
    if (getAlbumDetail) {
      setIsAlbumClick(true);
      setIsOpen(true);
      setSelectedData({original: getAlbumDetail});
    }
  };

  const changeTrack = (type) => {
    const index = tracks
      .sort((a, b) => {
        return a.track_order - b.track_order;
      })
      .findIndex((track, i) => track.isrc === trackIsrc);
    callDispatch('EXISTING_PROJECT_ID', 'existingProjectId', null);
    if (type === 'next') {
      if (index === tracks.length - 1) {
        setTrackIsrc(tracks[0].isrc);
        setTrackData(tracks[0]);
      } else {
        setTrackIsrc(tracks[index + 1].isrc);
        setTrackData(tracks[index + 1]);
      }
    } else {
      if (index === 0) {
        setTrackIsrc(tracks[tracks.length - 1].isrc);
        setTrackData(tracks[tracks.length - 1]);
      } else {
        setTrackIsrc(tracks[index - 1].isrc);
        setTrackData(tracks[index - 1]);
      }
    }
  };
  if (isLoading) {
    return <SkeletonAlbumDetail />;
  }
  return (
    <>
      {(albumLoading || trackLoading) && <LoadingSpinnerTransparent />}

      <AlbumInfo
        isOpen={isOpen}
        getAlbumDetail={getAlbumDetail}
        musicDirectors={musicDirectors}
        // openFlyMenu={openFlyMenu}
        openAddCoverArtHandler={openAddCoverArtHandler}
        setToggleDspModal={setToggleDspModal}
        updateStatusHandler={updateStatusHandler}
        hasCoverArtValue={getAlbumDetail?.cover_art ? true : false}
        hasNoTracks={getAlbumDetail?.Track?.length === 0 ? true : false}
        actionDropDownContent={
          <ActionButtonDropDownContent
            getAlbumDetail={getAlbumDetail}
            hasCoverArtValue={getAlbumDetail?.cover_art ? true : false}
            updateStatusHandler={updateStatusHandler}
            hasNoTracks={getAlbumDetail?.Track?.length === 0 ? true : false}
            requestModal={requestModal}
          />
        }
        setIsAlbumClick={setIsAlbumClick}
        setIsOpen={setIsOpen}
        tag={dynamicTag}
        openAlbumForm={openAlbumForm}
      />
      <Tab
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        content={content}
      />

      {toggleDspModal && (
        <DownloadZipModal
          id={getAlbumDetail?.id}
          showModal={toggleDspModal}
          onCloseHandler={() => setToggleDspModal(false)}
          sidebarRef={null}
        />
      )}

      {isOpen && (
        <FlyMenu
          refetch={refetch}
          setSelectedData={setSelectedData}
          newFormTag={newFormTag}
          selectedData={selectedData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setNewFormTag={setNewFormTag}
          setDynamicTag={setDynamicTag}
          tag={isAlbumClick ? TAGS.EDIT_ALBUMS : TAGS.TRACKS}
          setIsAlbumClick={setIsAlbumClick}
          closeable={false}
        />
      )}

      {trackIsrc && (
        <MusicPlayerNew
          played={played}
          seeking={seeking}
          volume={volume}
          handleSeekMouseDown={handleSeekMouseDown}
          handleSeekChange={handleSeekChange}
          handleSeekMouseUp={handleSeekMouseUp}
          handleVolumeChange={handleVolumeChange}
          playerRef={playerRef}
          handleStop={handleStop}
          handlePlayPause={handlePlayPause}
          setPlayed={setPlayed}
          setCurrentTime={setCurrentTime}
          msToTime={msToTime}
          setDuration={setDuration}
          currentTime={currentTime}
          duration={duration}
          playing={playing}
          getAlbumDetail={getAlbumDetail}
          data={trackData}
          s3Key={s3KeyGenerator(
            'audio',
            trackIsrc,
            trackData?.Reused_Track
              ? trackData?.Reused_Track?.album?.project_id
              : null,
          )}
          muted={muted}
          handleMute={handleMute}
          isOpen={isOpen}
          setCollapsePlayer={setCollapsePlayer}
          collapsePlayer={collapsePlayer}
          changeTrack={changeTrack}
        />
      )}

      {requestOpen ? (
        <RequestModal
          setRequestOpen={setRequestOpen}
          close={() => setRequestOpen(false)}
          requestOpen={requestOpen}
          setHasCloseClicked={null}
          setIsSidebarOpen={null}
          handleSendRequest={handleSendRequest}
          setEditComment={setEditComment}
          editComment={editComment}
          setMetaData={setMetaData}
          metaData={metaData}
          isLoading={load}
        />
      ) : null}
      {reuseIsrcOpen ? (
        <ReuseIsrcModal
          reuseIsrcOpen={reuseIsrcOpen}
          setReuseIsrcOpen={setReuseIsrcOpen}
          close={() => setReuseIsrcOpen(false)}
          setSearchStr={setSearchStr}
          reuseIsrc={reuseIsrc}
          searchStr={searchStr}
          setTrackId={setTrackId}
          handleReuseIsrc={handleReuseIsrc}
          trackDetail={getAlbumDetail?.Track}
          setReuseIsrcOpen2={setReuseIsrcOpen2}
          reuseIsrcOpen2={reuseIsrcOpen2}
          reuseIsrcLoad={reuseIsrcLoad}
          searchLoad={searchLoad}
        />
      ) : null}
    </>
  );
};
export default AlbumDetailNew;
