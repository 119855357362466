import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Theme} from 'baseui/theme';
import * as React from 'react';

import {CustomThemeType} from '../../styles/theme/colors';

const CustomInputContainer = styled.input<{theme: Theme & CustomThemeType}>`
  border: 1px solid #dfe1e6;
  border-radius: 100px;
  outline: none;
  padding: 10px 10px 10px 15px;
  background: #ebecf1;
  min-width: 220px;
  color: ${(props) => props.theme.textColor};
  font-size: 14px;
  transition: all 0.05s ease-in;
  ::placeholder {
    color: #909090;
  }
  &:focus {
    background: white;
  }
  &:hover:not(:focus) {
    background: #f0f1f5;
  }
`;

interface SearchProps {
  placeholder: string;
  value?: string;
  onChangeHandler?: any;
  tag?: any;
}

const Search = ({placeholder, value, onChangeHandler, tag}: SearchProps) => {
  const [isShow, setIsShow] = React.useState(false);
  const [css, theme] = useStyletron();

  const onBlurInput = (e) => {
    setIsShow(true);
  };
  return (
    <SearchContainer>
      <CustomInputContainer
        theme={theme}
        type="search"
        value={value || ''}
        onChange={onChangeHandler}
        onFocus={(e) => onBlurInput(e)}
        placeholder={`Search ${placeholder}`}
      />

      {!tag && isShow && value?.length < 2 ? (
        <div>Please enter at least 2 characters to search...</div>
      ) : null}
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  div {
    font-size: 10px;
    color: #909090;
    // margin-top: -6px;
    margin-top: 33px;
    position: absolute;
    width: 100%;
  }
`;

export default Search;
