import {useEffect, useState} from 'react';

import useNotification from '../../Contexts/useNotification';
import {logError} from '../../errorlogger/logError';
import {capitalizeFirstLetter} from '../../helpers/capitalizeFirstLetter';
import useListLanguages from '../Language/useListLanguage';
import useCreateDSP from './useCreateDSP';
import useGetSingleDsp from './useSingleDsp';
import useUpdateDSP from './useUpdateDSP';

const methodOfDeliveryOptions = [
  {id: 1, value: 'email', label: 'Email'},
  {id: 2, value: 'sftp', label: 'SFTP'},
];

const dspTypeOptions = [
  {id: 1, value: 'ott', label: 'OTT'},
  {id: 2, value: 'radio', label: 'Radio'},
  {id: 3, value: 'publisher', label: 'Publisher'},
];
const contentTypeOptions = [
  {id: 1, value: 'film', label: 'Film'},
  {id: 2, value: 'non-film', label: 'Non-Film'},
];

const useDspForm = (props) => {
  const {
    selectedData,
    setIsOpen,
    currentUser,
    setSelectedData,
    refetch,
  } = props;

  const {showToast} = useNotification();
  const {listLanguages} = useListLanguages();
  const [langOptions, setLangOption] = useState([]);

  useEffect(() => {
    if (listLanguages && listLanguages.length > 0) {
      const languageOptions = listLanguages.map((language) => ({
        value: capitalizeFirstLetter(language.language),
        id: language.id,
        isChecked: false,
      }));
      setLangOption(languageOptions);
    }
  }, [listLanguages]);

  const {getSingleDsp, isLoading} = useGetSingleDsp({
    dspId: selectedData ? selectedData.id : null,
  });

  const {
    createDSPMutation,
    isLoading: isCreateDSPLoading,
    error: createDSPError,
  } = useCreateDSP();

  const {
    updateDSPMutation,
    isLoading: isUpdateDSPLoading,
    error: updateDSPError,
  } = useUpdateDSP();

  useEffect(() => {
    if (updateDSPError || createDSPError) {
      setIsOpen(true);
    }
  }, [updateDSPError, createDSPError]);
  // useEffect(() => {
  //   console.log('getSingleDsp', getSingleDsp);
  //   const newdsp = langOptions.filter(lang => getSingleDsp.language.some(lang2 => lang.id === lang2.id))
  //   console.log("newdsp",newdsp)
  // },[getSingleDsp,langOptions])
  // if (getSingleDsp && getSingleDsp?.language.length > 0) {
  //   const langage = getSingleDsp?.language.filter((lang) =>
  //     langOptions.some((lang2) => lang2.id === lang.id),
  //   );
  //   console.log('langage', langage);
  // }

  const initialValues = {
    dsp_name: getSingleDsp ? getSingleDsp.dsp_name : '',
    point_of_contact: getSingleDsp ? getSingleDsp.point_of_contact : '',
    email_poc: getSingleDsp ? getSingleDsp.email_poc : [''],
    phone_poc: getSingleDsp ? getSingleDsp.phone_poc : '',
    delivery_type: getSingleDsp
      ? [
          getSingleDsp.delivery_type === 'sftp'
            ? methodOfDeliveryOptions[1]
            : methodOfDeliveryOptions[0],
        ]
      : [methodOfDeliveryOptions[0]],
    dsp_type: getSingleDsp
      ? dspTypeOptions.filter((dsp) => dsp.value === getSingleDsp.dsp_type)
      : [dspTypeOptions[0]],
    content_type: getSingleDsp
      ? contentTypeOptions.filter(
          (content) =>
            content.value === getSingleDsp.content_type[0] ||
            getSingleDsp.content_type[1],
        )
      : [],
    sftp_user_name: getSingleDsp ? getSingleDsp.sftp_user_name : '',
    sftp_password: getSingleDsp ? getSingleDsp.sftp_password : '',
    url_file_upload: getSingleDsp ? getSingleDsp.url_file_upload : '',
    delivery_email: getSingleDsp?.delivery_email.length
      ? getSingleDsp?.delivery_email.length === 0
        ? ['']
        : getSingleDsp?.delivery_email
      : [''],
    description: getSingleDsp ? getSingleDsp.description : '',
    email_cc: getSingleDsp ? getSingleDsp?.email_cc : [''],
    sftp_host: getSingleDsp ? getSingleDsp?.sftp_host : '',
    sftp_port: getSingleDsp ? getSingleDsp?.sftp_port : null,
    // languages: getSingleDsp ? langOptions.filter(lang => getSingleDsp.language.some(lang2 => lang.id === lang2.id)) : [],
    languages: getSingleDsp
      ? getSingleDsp.language.map((language) => ({
          value: capitalizeFirstLetter(language.language),
          id: language.id,
        }))
      : [],
  };

  // if (initialValues.languages && initialValues.languages.length > 0) {
  //   const tempLanguages = initialValues.languages.map((language) => ({
  //     value: capitalizeFirstLetter(language.language),
  //     id: language.id,
  //   }));
  //   initialValues.languages = tempLanguages;
  // }

  const onSubmit = async (dspFormData) => {
    // console.log('dspFormData', dspFormData);

    if (currentUser) {
      const data = {
        email_poc: dspFormData.email_poc,
        phone_poc: dspFormData.phone_poc,
        delivery_type:
          dspFormData.dsp_type[0].value === 'publisher'
            ? null
            : dspFormData.delivery_type[0].value,
        dsp_type: dspFormData.dsp_type[0].value,
        content_type: dspFormData.content_type.map((value) => value.value),
        language: dspFormData.languages.map((lang) => lang.id),
        sftp_user_name:
          dspFormData.dsp_type[0].value === 'publisher'
            ? null
            : dspFormData.delivery_type[0].value === 'email'
            ? null
            : dspFormData.sftp_user_name,
        sftp_password:
          dspFormData.dsp_type[0].value === 'publisher' ||
          dspFormData.delivery_type[0].value === 'email'
            ? null
            : dspFormData.sftp_password,
        url_file_upload:
          dspFormData.dsp_type[0].value === 'publisher' ||
          dspFormData.delivery_type[0].value === 'email'
            ? null
            : dspFormData.url_file_upload,
        delivery_email:
          dspFormData.dsp_type[0].value === 'publisher' ||
          dspFormData.delivery_type[0].value === 'sftp'
            ? null
            : dspFormData.delivery_email,
        dsp_name: dspFormData.dsp_name,
        point_of_contact: dspFormData.point_of_contact,
        description: dspFormData.description ? dspFormData.description : null,
        email_cc: dspFormData.email_cc,
        updated_by: currentUser.id,
        created_by: currentUser.id,
        sftp_host:
          dspFormData.dsp_type[0].value === 'publisher' ||
          dspFormData.delivery_type[0].value === 'email'
            ? null
            : dspFormData.sftp_host,
        sftp_port:
          dspFormData.dsp_type[0].value === 'publisher' ||
          dspFormData.delivery_type[0].value === 'email'
            ? null
            : parseInt(dspFormData.sftp_port),
      };

      if (dspFormData.dsp_type[0].value === 'publisher') {
        delete data.delivery_type;
      }
      if (dspFormData.delivery_type[0].value === 'sftp') {
        delete data.delivery_email;
      }

      if (data) {
        if (selectedData?.id) {
          try {
            const id = selectedData.id;

            const res = await updateDSPMutation({data, id});

            if (res?.error && res.error?.length > 0) {
              showToast(res?.error, 'error');
            }
            closeModalOnSuccess(res);
            setTimeout(() => {
              refetch();
            }, 1000);
          } catch (error) {
            logError(error, 8);
          }
        } else {
          delete data.updated_by;
          try {
            const res = await createDSPMutation({data});

            if (res?.error && res.error?.length > 0) {
              showToast(res?.error, 'error');
            }
            closeModalOnSuccess(res);
            setTimeout(() => {
              refetch();
            }, 1000);
          } catch (error) {
            logError(error, 8);
          }
        }
      }
    }
  };

  const closeModalOnSuccess = (res) => {
    if (res?.statusCode === 201 || res?.statusCode === 200) {
      refetch();
      setIsOpen(false);
      setSelectedData({});
      showToast(
        `DSP ${selectedData ? `updated` : `added`} successfully`,
        'success',
      );
    }
  };

  return {
    isLoading,
    initialValues,
    onSubmit,
    dspTypeOptions,
    contentTypeOptions,
    methodOfDeliveryOptions,
    langOptions,
    getSingleDsp,
    isUpdateDSPLoading,
    isCreateDSPLoading,
  };
};

export default useDspForm;
