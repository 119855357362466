import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';
import {camelToSentence} from '../../helpers/convertSnakeCaseToUpperCase';

const fetchUserTypes = async () => {
  try {
    const res = await axios(`/user/types`, {
      method: 'GET',
    });

    return res.data?.data;
  } catch (error) {
    logError(error, 2);
    return error.response?.data;
  }
};

const useListTypes = () => {
  const {data: userTypes} = useQuery('userTypes', fetchUserTypes);
  const [roleOptions, setRoleOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (userTypes) {
      const roleData = userTypes.map((val) => {
        return {
          ...val,
          roles: val.roles.map((data) => {
            return {
              id: data.id,
              name: data.name,
              label: camelToSentence(data.name),
            };
          }),
          roleIds: val.roles
            .map((data) => {
              const ids = [];
              ids.push(data.id);
              return ids;
            })
            .flat(),
        };
      });

      setRoleOptions(roleData);
    }
    setIsLoading(false);
  }, [userTypes]);

  return {
    userTypes,
    roleOptions,
    isLoading,
  };
};

export default useListTypes;
