/** @format */

import axios from 'axios';
import {useContext} from 'react';
import {queryCache, useMutation} from 'react-query';

import {GlobalContext} from '../../Contexts/GlobalProvider';
import {logError} from '../../errorlogger/logError';
const BASE_URL = process.env.REACT_APP_Base_URL;

const createTrack = async (data) => {
  try {
    const response = await axios(`${BASE_URL}/track/`, {
      method: 'POST',
      data,
    });
    return response.data;
  } catch (error) {
    logError(error, 12);
    return error.response?.data;
  }
};

const useCreateTrack = () => {
  const {state, dispatch}: any = useContext(GlobalContext);

  const [
    createTrackMutation,
    {status, data, error, isLoading, isSuccess},
  ] = useMutation(createTrack, {
    onSuccess: () => {
      queryCache.invalidateQueries('singleAlbum');
      dispatch({
        type: 'SET_TRACK_UPDATED',
        payload: {
          isTrackUpdated: true,
        },
      });
    },
  });

  return {createTrackMutation, isLoading, status, error};
};

export default useCreateTrack;
