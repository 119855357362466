import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const fetchDspHistory = async (key, dspId) => {
  if (dspId) {
    try {
      const res = await axios(`/dsp_history/${dspId}`, {
        method: 'GET',
      });

      return res.data?.data;
    } catch (error) {
      logError(error, 2);

      return error.response?.data;
    }
  }
};

const useGetDspHistory = (props) => {
  const {id} = props;
  const {data: getDspHistory, isLoading, error, isError} = useQuery(
    ['dspHistory', id],
    fetchDspHistory,
    {
      enabled: id,
    },
  );

  return {
    getDspHistory,
    isLoading,
    error,
    isError,
  };
};

export default useGetDspHistory;
