import {useEffect, useState} from 'react';

import usePreviewLink from '../AlbumDetail/usePreviewLink';
import useS3Key from './useS3key';

const useGetImagePreviewLink = (props) => {
  const {upc, projectId} = props;
  const {s3KeyGenerator} = useS3Key({projectId: projectId});
  const [s3Key, setS3Key] = useState(null);
  const {downloadLink} = usePreviewLink({key: s3Key});

  useEffect(() => {
    const img = s3KeyGenerator('coverart', upc, null);
    setS3Key(img);
  }, [upc]);

  return {downloadLink};
};

export default useGetImagePreviewLink;
