import styled from '@emotion/styled';
import {Button as BaseButton} from 'baseui/button';
import React, {useState} from 'react';
import {BiRefresh} from 'react-icons/bi';
import {IoIosArrowForward} from 'react-icons/io';
import {MdAddCircle} from 'react-icons/md';
import {useNavigate} from 'react-router';
import {useAsyncDebounce} from 'react-table';

import {TAGS} from '../../../constants/tags';
import AlbumFilter from '../../../pages/AlbumsManagment/Albums/AlbumFilter/AlbumFilter';
import AlbumAdvancedSearch from '../AlbumAdvancedSearch/AlbumAdvancedSearch';
import Button from '../Button/Button';
import Search from '../Search';

const TableContainer = styled.div<{pendingAlbums}>`
  padding: 10px 25px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  .search-container,
  .button-container {
    align-items: center;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    .export-btn {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 10px;
      margin-right: 10px;
      color: #333;
      background-color: white;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        background: #ebecf1;
      }
      svg {
        margin-left: 5px;
      }
    }
  }
  .group_btn {
    display: flex;
    .go-to-package {
      filter: ${(props) => (props.pendingAlbums > 0 ? 'grayscale(1)' : '')};
      pointer-events: ${(props) => (props.pendingAlbums > 0 ? 'none' : '')};
    }
  }
  .svg-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .refresh-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
`;

export const renderPlaceholders = (tag) => {
  switch (tag) {
    case TAGS.USER:
      return TAGS.USER;
    case TAGS.ALBUMS:
      return TAGS.ALBUMS;
    case TAGS.LABEL:
      return TAGS.LABEL;
    case TAGS.TRACKS:
      return TAGS.TRACKS;
    case TAGS.ALBUM_APPROVAL:
      return TAGS.ALBUMS;
    case TAGS.TRACK_APPROVAL:
      return TAGS.TRACKS;
    case TAGS.UPC:
      return TAGS.UPC;
    case TAGS.DSP:
      return TAGS.DSP;
    case TAGS.ISRC:
      return TAGS.ISRC;
    default:
      return TAGS.ALBUMS;
  }
};

const TableFilter = (props) => {
  const {
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    tag,
    refetchHandler,
    mainActionHandler,
    upc,
    isFetching,
    actionBtn,
    setSearchStr,
    setSearchString,
    actors,
    actress,
    director,
    albumName,
    artist,
    label,
    setAllData,
    allData,
    setPage,
    pendingAlbums,
    setDataFilter,
    dataFilter,
  } = props;

  const count = preGlobalFilteredRows.length;
  const [toggleSearch, setToggleSearch] = useState(false);
  const [value, setValue] = React.useState(globalFilter);
  const [searchValue, setSearchValue] = useState('');
  const [bubble, setBubble] = useState(0);
  const navigate = useNavigate();
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const onSearchChange = useAsyncDebounce((value) => {
    setSearchStr(value);
    setSearchString(value);
  }, 200);

  const onChangeHandler = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  const onSearchHandler = (e) => {
    setSearchValue(e.target.value);
    onSearchChange(e.target.value);
    setPage(1);
  };

  return (
    <>
      <TableContainer pendingAlbums={pendingAlbums}>
        <div className="search-container">
          {tag !== TAGS.PRODUCT_HISTORY && tag !== TAGS.BULK_ADMIN && (
            <div className="search-wrapper">
              {tag === TAGS.ALBUMS ? (
                <AlbumAdvancedSearch
                  value={searchValue || ''}
                  onChangeHandler={onSearchHandler}
                  placeholder={renderPlaceholders(tag)}
                  setToggleSearch={setToggleSearch}
                  toggleSearch={toggleSearch}
                  bubble={bubble}
                  dataFilter={dataFilter}
                />
              ) : (
                <Search
                  value={searchValue || ''}
                  onChangeHandler={onSearchHandler}
                  placeholder={renderPlaceholders(tag)}
                />
              )}
            </div>
          )}
        </div>

        <div className="group_btn">
          {refetchHandler ? (
            <div className={`refresh-btn`}>
              <BaseButton size="mini" onClick={refetchHandler} shape="circle">
                <span className={`svg-container ${isFetching && 'rotating'}`}>
                  <BiRefresh size={20} />
                </span>
              </BaseButton>
            </div>
          ) : null}
          <div className="button-container">{actionBtn}</div>
          {tag === TAGS.BULK_ADMIN && (
            <div className="go-to-package">
              {' '}
              <Button
                label="Go to Package Manager"
                onClick={() => navigate('package-manager')}
                shape="round"
                endEnhancer={() => <IoIosArrowForward />}
              />
            </div>
          )}
        </div>
      </TableContainer>
      {tag === TAGS.ALBUMS && (
        <AlbumFilter
          actors={actors}
          actress={actress}
          director={director}
          albumName={albumName}
          artist={artist}
          label={label}
          setAllData={setAllData}
          toggleSearch={toggleSearch}
          setBubble={setBubble}
          allData={allData}
          setPage={setPage}
          setToggleSearch={setToggleSearch}
          setDataFilter={setDataFilter}
          dataFilter={dataFilter}
        />
      )}
    </>
  );
};

export default TableFilter;
