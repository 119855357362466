import React from 'react';
import {ModalButton} from 'baseui/modal';
import Button from '../../../reusable/Button/Button';
import styled from '@emotion/styled';
import {FaCloudUploadAlt} from 'react-icons/fa';

export default ({close, handleCancel, disabled, handleUpload, name}) => (
  <React.Fragment>
    <Container>
      <div className="cancel-btn">
        <ModalButton
          size="compact"
          kind="tertiary"
          onClick={() => handleCancel()}
        >
          Cancel
        </ModalButton>
      </div>
      <div className={disabled ? 'icon' : 'upload-btn'}>
        <Button
          label={name == 'audio' ? 'Upload' : 'Upload Cover Arts'}
          disabled={disabled}
          onClick={handleUpload}
          size="mini"
          kind="compact"
        />
        <FaCloudUploadAlt />
      </div>
    </Container>
  </React.Fragment>
);

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  align-items: baseline;
  background: #f8f8f8;
  border-radius: 5px;
  .cancel-btn {
    button {
      background: #ffffff;
      border-radius: 75px;
      margin-right: 22px;
      padding: 10px 25px;
    }
  }
  .icon {
    background: #cccccc;
    color: #666666;
    border-radius: 50px;
    display: flex;
    align-items: center;
    button {
      margin-right: -12px;
      border-radius: 75px;
    }
    svg {
      margin-right: 10px;
    }
  }
  .upload-btn {
    border-radius: 50px;
    color: #ffffff;
    display: flex;
    align-items: center;
    background: #dc3831;
    svg {
      margin-right: 10px;
    }

    button {
      margin-right: -12px;
      border-radius: 75px;
    }
  }
`;
