import React from 'react';
import {useState} from 'react';
import Table from '../../components/reusable/Table/TablePagination';
import SkeletonLoading from '../SkeletonPage';
import {adminUploadsData} from './tableDataAdminUploads';
import useGetAdminUploads from './useGetAdminUploads';

const AdminUploads = () => {
  const [searchStr, setSearchStr] = useState('');

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    resolvedData,
    setPage,
    page,
    isLastPage,
    totalCount,
    rangeB,
    rangeA,
    lastPage,
    setLimit,
    limit,
    isCustomLoading,
    refetch,
  } = useGetAdminUploads({searchStr});

  const albums = React.useMemo(() => data, [data]);
  const columnData = React.useMemo(() => adminUploadsData, [adminUploadsData]);

  if (isLoading) {
    return <SkeletonLoading />;
  }
  return (
    <>
      <Table
        columns={columnData}
        data={albums ? albums : []}
        tag="admin-uploads"
        setPage={setPage}
        page={page}
        isLastPage={isLastPage}
        totalCount={totalCount}
        rangeB={rangeB}
        rangeA={rangeA}
        lastPage={lastPage}
        setLimit={setLimit}
        limit={limit}
        resolvedData={resolvedData}
        isLoading={isCustomLoading}
        isFetching={isFetching}
        setSearchStr={setSearchStr}
        actionBtn={null}
        refetchHandler={refetch}
      />
    </>
  );
};

export default AdminUploads;
