export const sortRoleData = (row1, row2) => {
  if (row1.roles.length < row2.roles.length) {
    return -1;
  } else {
    return 1;
  }
};

export const sortAlphabeticalOrder = (row1, row2) => {
  if (row1?.type?.name < row2.type?.name) {
    return -1;
  }
  if (row1.type?.name > row2.type?.name) {
    return 1;
  }
  return 0;
};
