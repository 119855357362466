import styled from '@emotion/styled';
import React, {useState} from 'react';
import {
  BiChevronLeft,
  BiChevronRight,
  BiFirstPage,
  BiLastPage,
} from 'react-icons/bi';

import {TAGS} from '../../../constants/tags';
import useUPC from '../../../hooks/UPCManagement/useUPC';
import ExportToExcel from './ExportToExcel';
import LimitSetter from './LimitSetter';

const PaginationBarContainer = styled.div`
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  .left {
    display: flex;

    .upc_status {
      display: flex;
      border-radius: 5px;
      padding: 0.45rem 0.65rem;
      min-width: 10rem;
      p {
        margin: 0;
        font-weight: 650;
        font-size: 1.25rem;
      }
      span {
        margin-left: 5px;
        font-size: 14px;
      }
    }
    .available {
      color: #006510;
      background: rgba(0, 101, 16, 0.15);
      margin-right: 0.75rem;
    }
    .used {
      color: #dc3831;
      background: rgba(220, 56, 49, 0.15);
    }
  }
  .export-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .export-btn {
      background-color: white;
      border: 1px solid #dc3831;
      color: #dc3831;
      padding: 8px 12px;
      border-radius: 100px;
      cursor: pointer;
      outline: none;
      @media screen and (max-width: 1000px) {
        width: max-content;
      }
    }
    .active {
      background: rgba(220, 56, 49, 0.2);
      color: #dc3831;
      border: none;
    }
  }
  .section-wrapper {
    display: flex;
    width: calc(100% - 50px);

    .section1,
    .section2 {
      display: flex;
      height: 100%;
      justify-content: flex-end;
      .limit-container {
        display: flex;
        align-items: center;
        span {
          margin-right: 10px;
          @media screen and (max-width: 1000px) {
            width: max-content;
          }
        }

        span,
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
        }
      }

      .limit-drop-down {
        background-color: #f4f4fb;
        margin-right: 10px;
        cursor: pointer;
        padding: 0 5px;
      }
      .navigation-container {
        display: flex;
        align-items: center;

        justify-content: space-between;
        .disabled {
          opacity: 0.3;
          pointer-events: none;
        }
        .icon {
          align-items: center;
          display: flex;
          justify-content: center;
          width: 45px;
          height: 45px;
          border-radius: 100px;
          margin-left: 5px;
          svg {
            font-size: 18px;
          }
          &:hover {
            background-color: #f4f4fb;
            cursor: pointer;
          }
        }
      }
      .page-count-container {
        align-items: center;
        display: flex;
        justify-content: center;
        span {
          font-size: 14px;
          @media screen and (max-width: 1000px) {
            width: max-content;
          }
        }
      }
    }
    .section1 {
      justify-content: flex-start;
      width: 30%;
    }
    .section2 {
      width: 70%;
    }
  }
  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  .divider {
    width: 1px;
    background-color: #f4f4fb;
    margin: 0 15px;
  }
`;

const PaginationBar = (props) => {
  const {
    setPage,
    page,
    isLastPage,
    totalCount,
    rangeB,
    rangeA,
    lastPage,
    limit,
    setLimit,
    resolvedData,
    data,
    position,
    tag,
    searchString,
    allData,
    dataFilter,
    type,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const {getUpcCount} = useUPC({tag});

  return (
    <PaginationBarContainer>
      <div className="section-wrapper">
        <div className="section1">
          {tag === TAGS.UPC && (
            <div className="left">
              <div className="upc_status available">
                {/* <p>{upc.not_assigned}</p> */}
                <p>{getUpcCount?.not_assigned}</p>
                <span>Available UPC</span>
              </div>
              <div className="upc_status used">
                {/* <p>{upc.assigned}</p> */}
                <p>{getUpcCount?.assigned}</p>
                <span>Used UPC</span>
              </div>
            </div>
          )}
        </div>

        <div className="section2">
          {position === 'top' &&
            tag !== 'request-edit' &&
            tag !== 'admin-uploads' && (
              <ExportToExcel
                data={data}
                tag={tag}
                searchString={searchString}
                allData={allData}
                dataFilter={dataFilter}
                type={type}
              />
            )}
          <div className="divider"></div>
          <div className="limit-container">
            <span>Rows per page</span>

            <LimitSetter
              limit={limit}
              setLimit={setLimit}
              setIsOpen={setIsOpen}
              totalCount={totalCount}
              resolvedData={resolvedData}
              setPage={setPage}
            />
          </div>
          {data.length !== 0 && (
            <div className="page-count-container">
              <span>
                {rangeA}-{rangeB} of {totalCount}
              </span>
            </div>
          )}

          <div className="navigation-container">
            <div
              onClick={() => setPage(1)}
              className={`icon first ${page === 1 && 'disabled'}`}
            >
              <BiFirstPage />
            </div>
            <div
              onClick={() => setPage((old) => Math.max(old - 1, 0))}
              className={`icon prev ${page === 1 && 'disabled'}`}
            >
              <BiChevronLeft />
            </div>
            <div
              onClick={() => setPage((old) => old + 1)}
              className={`icon next ${isLastPage && 'disabled'}`}
            >
              <BiChevronRight />
            </div>
            <div
              onClick={() => setPage(lastPage)}
              className={`icon last ${isLastPage && 'disabled'}`}
            >
              <BiLastPage />
            </div>
          </div>
        </div>
      </div>
    </PaginationBarContainer>
  );
};

export default PaginationBar;
