import styled from '@emotion/styled';
import React, {useEffect, useState} from 'react';
import {CgClose} from 'react-icons/cg';

import sizeInMb from '../../../../helpers/sizeInMb';
import Button from '../../../reusable/Button/Button';

const NewFileModal = ({
  files,
  progressInfos,
  addTrack,
  removeTrack,
  progress,
  handleChange,
  name,
  filetype,
  setTrackCount
}) => {
   const [progressData, setProgressData] = useState([])
  useEffect(() => {
    const progressInterval = setInterval(() => {
           setProgressData(progressInfos)
    }, 1000);

    if(progressInfos.percentage === 100){
      clearInterval(progressInterval)
    }
    
  }, [progressInfos]);

  return (
    <React.Fragment>
      <BodyHeader>
        <p>
          <span>{progressInfos.length} </span>
          {name === 'audio' ? 'tracks selected' : 'images selected'}
        </p>
        <Button
          label={name === 'audio' ? 'Add Track' : 'Add Cover Art'}
          type="button"
          size="mini"
          kind="compact"
          disabled={progress}
          onClick={() => addTrack()}
        />
        <input
          id="new_track"
          type="file"
          accept={
            name === 'audio' ? 'audio/wav,audio/x-wav' : 'image/jpg,image/jpeg'
          }
          hidden
          multiple
          onChange={(e) => handleChange(e.target.files)}
        />
      </BodyHeader>
      <BodyContent>
        {progressInfos.length > 1 &&
          progressInfos.map((item, index) => (
            <ProgressBarList
              item={item}
              data={files[index]}
              index={index}
              filetype={filetype}
              progress={progress}
              removeTrack={removeTrack}
              progressInfos={progressInfos}
              setTrackCount={setTrackCount}
            />
          ))}
        {progressInfos.length === 1 && (
          <ProgressBarList
            item={progressInfos[0]}
            index={1}
            filetype={filetype}
            progress={progress}
            progressInfos={progressInfos}
            removeTrack={removeTrack}
            setTrackCount={setTrackCount}
          />
        )}
      </BodyContent>
    </React.Fragment>
  );
};

const ProgressBarC = (props) => {
  const {progressInfo} = props;
  return (
    <ProgressBarStyled percent={progressInfo}>
      <div
        className="innerprogress-bar"
        style={{width: `${progressInfo}%`}}
      ></div>
    </ProgressBarStyled>
  );
};

const ProgressBarList = (props) => {
  const {item, index, filetype, progressInfos, removeTrack, progress,setTrackCount,data} = props;
useEffect(()=>{
  if(setTrackCount){
    setTrackCount(progressInfos.length)
  }
},[progressInfos])
  const renderStatus = (percentage) => {
    if (percentage === 100) {
      return 'uploaded';
    }
    if (percentage > 0 && percentage < 100) {
      return 'uploading';
    } else {
      return 'pending';
    }
  };

  const FileStatus = (props) => {
    const {status} = props;

    return (
      <FileStatusStyled status={status}>
        <div>{status}</div>
      </FileStatusStyled>
    );
  };

  return (
    <div className="fileupload" key={index}>
      <div className="file-ext">{filetype}</div>
      <div className="file-detailes">
        <div className="basic-detailes">
          <div className="left">
            <div className="file-name">{item.fileName}</div>
            <div className="file-size">{item.fileSize}MB</div>
          </div>
          <div className="right">
            {progressInfos && (
              <div className="file-progress">{item.percentage}%</div>
            )}
            <FileStatus status={renderStatus(item.percentage)} />
          </div>
        </div>
        <ProgressBarC progressInfo={item.percentage} />
      </div>
      {!progress && (
        <div className="close-btn" onClick={() => removeTrack(data)}>
          <CgClose />
        </div>
      )}
    </div>
  );
};

const BodyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  // margin-top: 0.75rem;

  padding: 20px;
  background: #f8f8f8;
  border-radius: 5px;
  p {
    margin: 0;
    background: rgba(210, 25, 25, 0.1);
    border-radius: 100px;
    color: #d21919;

    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    padding: 8px 15px;
  }
  button {
    border-radius: 75px;
  }
`;
const FileStatusStyled = styled.div`
  background: ${(props) =>
    props.status === 'uploaded'
      ? '#d2ffce'
      : props.status === 'pending'
      ? '#FFEDD2'
      : '#CEFFF0'};

  color: ${(props) =>
    props.status === 'uploaded'
      ? '#3db320'
      : props.status === 'pending'
      ? '#E2800C'
      : '#00A198'};
  border-radius: 3px;
  text-align: center;
  // width: 110px;
  padding: 0px 12px;
  height: 20px;
  font-size: 14px;
  margin-right: 5px;
  // line-height: 1;
`;
const ProgressBarStyled = styled.div`
  height: 8px;
  background: #cecece;
  width: 100%;
  border-radius: 50px;
  .innerprogress-bar {
    background-color: #d21919;
    height: 8px;
    border-radius: 50px;
    width: ${(props) => (props.percent ? props.percent : 0)}%;
    transition: width 2s;
  }
`;
const BodyContent = styled.div`
  max-height: 50vh;
  // overflow-y: auto;
  // margin-top: 2rem;
  padding: 0 3rem;
  padding-bottom:10px;
  overflow:auto;
  .fileupload {
    background: #ffffff;
    // box-shadow: 0px 12.0708px 75.4427px rgba(0, 0, 0, 0.15);
    border-radius: 15.0885px;
    padding: 10px;
    display: flex;
    // margin-top: 15px;
    margin-top: 10px;
    margin-bottom: 3px;
 box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    .file-ext {
      background: #d21919;
      border-radius: 6.03542px;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
    .file-detailes {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: calc(100% - 70px);
      margin-left: 10px;
      .basic-detailes {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .left {
          display: flex;
          .file-name {
            border-right: 1px solid red;
            margin-right: 10px;
            padding-right: 10px;
            overflow: hidden;
            text-overflow: ellipsis;

            font-style: normal;
            font-size: 14px;
            height: 24px;
          }
          .file-size {
            color: rgba(0, 0, 0, 0.4);
            font-size: 14px;
            display: flex;
            align-items: center;
          }
        }
        .right {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          .file-progress {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.4);
          }
          :nth-child(2) {
            text-align: center;
            width: 90px;
            height: 20px;
            font-size: 14px;
            margin-right: 5px;
            justify-content: center;
            line-height: 18px;
          }
        }
      }
    }
    .close-btn {
      background: #d21919;
      transform: translateY(-18px);
      cursor: pointer;
      width: 25px;
      height: 24px;
      position: relative;
      right: -17px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
  }

  .item {
    border-radius: 5px;
    border: #e5e5e5 solid 1px;
    padding: 0.85rem;
    margin-bottom: 0.75rem;
    &:hover {
      box-shadow: 0px 0px 7px 0px rgba(91, 91, 91, 0.35);
    }
    .item_info {
      display: flex;
      justify-content: space-between;
      .track_n_size {
        p {
          display: inline-block;
          margin-right: 0.25rem;
          font-weight: 600;
          font-size: 0.9rem;
          margin: 0;
        }
        span {
          margin: 1.5rem;
          color: red;
          font-size: 0.75rem;
        }
      }
      .close {
        height: 16px;
        width: 16px;
        text-align: center;
        line-height: 11.5px;
        border-radius: 50%;
        cursor: pointer;
        background: red;
        color: whitesmoke;
      }
    }
    .progress_bar {
      /* display: none; */
    }
  }
`;

export default NewFileModal;
