import {queryCache, useMutation} from 'react-query';

import axios from '../../config/Axios';
import useNotification from '../../Contexts/useNotification';
import {logError} from '../../errorlogger/logError';

const updateTrackFn = async (params) => {
  const {id, data} = params;
  try {
    const response = await axios({
      method: 'PUT',
      url: `/track/${id}`,
      data: data,
    });
    return response.data;
  } catch (error) {
    logError(error, 12);

    if (error.response) {
      return error.response.data;
    }
  }
};

const useUpdateTrack = () => {
  const [
    updateTrackMutation,
    {status: trackStatus, isLoading: trackLoading, error: trackError},
  ] = useMutation(updateTrackFn, {
    onSuccess: () => {
      queryCache.invalidateQueries('track');
      queryCache.invalidateQueries('album');
      queryCache.invalidateQueries('albums');
      queryCache.invalidateQueries('singleAlbum');
    },
  });

  return {
    updateTrackMutation,

    trackLoading,
    trackError,
  };
};

export default useUpdateTrack;
