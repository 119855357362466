import styled from '@emotion/styled';
import React from 'react';

const Tab = styled.div<{isActive}>`
  margin-right: 10px;
  background-color: ${(props) => (props.isActive ? '#F8D7D6' : ' #f1f1f1')};
  color: ${(props) => (props.isActive ? '#DC3831' : '#505F7A')};
  margin-right: 10px;
  padding: 5px 11px;
  font-size: 14px;
  border-radius: 100px;
  cursor: pointer;
`;

export default function AlbumFilterTab(props) {
  const {data} = props;

  return (
    <Tab isActive={data.$active} onClick={data.onClick}>
      <span>{data.children}</span>
    </Tab>
  );
}
