import {useState} from 'react';

const useHistory = () => {
  const [historyData, setHistoryData] = useState([]);

  const formatHistoryData = (mainData, createdKey) => {
    let createObj = {};
    let createObj2 = {};
    const updateArr = [];
    if (mainData?.length > 0) {
      mainData.map((labelHis) => {
        if (labelHis.action === 'CREATE') {
          createObj = {
            id: labelHis.id,
            action: labelHis.action,
            userName: labelHis?.updated_by?.name,
            date: labelHis?.timestamp,
            email: labelHis?.updated_by?.email,
            field: null,
            newValue: JSON.parse(labelHis.curr)[createdKey],
            oldValue: null,
          };
        } else if (labelHis.action === 'DELETE') {
          createObj2 = {
            id: labelHis.id,
            action: labelHis.action,
            userName: labelHis?.updated_by?.name,
            date: labelHis?.timestamp,
            email: labelHis?.updated_by?.email,
            field: null,
            newValue: JSON.parse(labelHis.curr)[createdKey],
            oldValue: null,
          };
        } else {
          const prevValues = JSON.parse(labelHis.prev);
          const currentValueObj = JSON.parse(labelHis.curr);

          const currentValues = Object.keys(currentValueObj);

          currentValues.map((key) => {
            const obj = {
              id: labelHis.id,
              action: labelHis.action,
              userName: labelHis?.updated_by?.name,
              date: labelHis?.timestamp,
              email: labelHis?.updated_by?.email,
              field: key,
              newValue: currentValueObj[key],
              oldValue: prevValues[key],
            };
            updateArr.push(obj);
          });
        }
      });
    }

    const data = [...updateArr, createObj, createObj2];
    setHistoryData(data);
  };

  return {
    historyData,
    formatHistoryData,
  };
};

export default useHistory;
