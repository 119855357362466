import React from 'react';
import {FaCheckCircle, FaInfoCircle} from 'react-icons/fa';
import {IoMdCloseCircle} from 'react-icons/io';
import {useNavigate} from 'react-router';

import {formatDate} from '../../helpers/covertDate';
import useLocalStorage from '../../hooks/reusable/useLocalStorage';
import {StatusBox} from '../../tableColumns/AlbumsManagement/albumsColumnMetaData';
import {
  AlbumNameWrapper,
  ButtonContainer,
  CoverArt,
  StatusContainer,
} from '../../tableColumns/AlbumsManagement/styled-components/TableComponents';

export const ImageContainer = ({imgurl}) => {
  return <CoverArt imgSrc={imgurl ? imgurl : null}></CoverArt>;
};

export const bulkAdminColumn = [
  {
    Header: 'Album Name',
    accessor: 'album_name',
    Cell: (data) => {
      return (
        <AlbumNameWrapper>
          <ImageContainer imgurl={data.row?.original?.cover_art} />
          <div> {data && data.value} </div>
        </AlbumNameWrapper>
      );
    },
  },

  {
    Header: 'UPC',
    accessor: 'upc',
  },
  {
    Header: 'Label',
    accessor: 'label_name',
    Cell: (data) => {
      return <span> {data?.row?.original?.label_name} </span>;
    },
  },
  {
    Header: 'Content Type',
    accessor: 'album_type',
  },
  {
    Header: 'Product Type',
    accessor: 'product_type',
  },
  {
    Header: 'Created On',
    accessor: 'created_at',
    Cell: (data) => {
      return <span> {data && formatDate(data.value)} </span>;
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (data) => {
      return <StatusBox data={data} />;
    },
  },
  {
    Header: '',
    accessor: 'complete',
    Cell: (data) => {
      const navigate = useNavigate();
      const [projectId, setProjectId] = useLocalStorage('projectId', null);
      const [albumData, setAlbumData] = useLocalStorage(
        'track_album_data',
        null,
      );

      const onClickDetail = (data) => {
        navigate(`${data?.row?.original?.id}`);
        setProjectId(data?.row?.original?.project_id);
        setAlbumData({
          album_id: data?.row?.original?.id,
          track_upc: data?.row?.original?.upc,
          user_id: data?.row?.original?.user_id,
          project_id: data?.row?.original?.project_id,
        });
      };
      return (
        <ButtonContainer>
          <button onClick={() => onClickDetail(data)}>View Tracks</button>
        </ButtonContainer>
      );
    },
  },
];
// export const StatusBox = (props) => {
//   const {data, albumStatus, status} = props;
//   console.log('daaaa', props);

//   return (
//     <>
//       <StatusContainer status={status}>
//         <span>
//           {status === 'incomplete'
//             ? 'InComplete'
//             : status === 'waiting_approval'
//             ? 'In Review'
//             : status === 'complete'
//             ? 'Complete'
//             : status === 'declined'
//             ? 'Declined'
//             : 'Orderable'}

//           {status === 'incomplete' ? (
//             <FaInfoCircle />
//           ) : status === 'waiting_approval' ? (
//             <FaInfoCircle />
//           ) : status === 'declined' ? (
//             <IoMdCloseCircle />
//           ) : (
//             <FaCheckCircle />
//           )}
//         </span>
//       </StatusContainer>
//     </>
//   );
// };
