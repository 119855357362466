import styled from '@emotion/styled';
import {PLACEMENT, StatefulPopover} from 'baseui/popover';
import React, {useEffect} from 'react';
import {BiCaretDown} from 'react-icons/bi';

const LimitContainer = styled.div`
  background-color: white;
  width: 100px;
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  ul {
    margin: 0;
    padding: 0px;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    li {
      padding: 10px 8px;
      font-size: 14px;
      &:hover {
        background-color: #f4f4fb;
        cursor: pointer;
      }
    }
  }
`;

const LimitSetter = (props) => {
  const {limit, setIsOpen, setLimit, totalCount, resolvedData, setPage} = props;

  const onClickLimitHandler = (l, close) => {
    setPage(1);
    setLimit(l);
    close();
  };

  useEffect(() => {
    if (resolvedData?.currentCountPerPage === 0) {
      setPage(1);
    }
  }, [resolvedData]);

  return (
    <StatefulPopover
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      dismissOnClickOutside
      focusLock={true}
      placement={PLACEMENT.bottomLeft}
      content={({close}) => (
        <LimitContainer>
          <ul>
            {['20', '50', '100', '200'].map((l) => (
              <li
                className={l > totalCount && `disabled`}
                onClick={() => onClickLimitHandler(l, close)}
              >
                {l}
              </li>
            ))}
          </ul>
        </LimitContainer>
      )}
    >
      <div className="limit-drop-down">
        {limit}
        <BiCaretDown />
      </div>
    </StatefulPopover>
  );
};

export default LimitSetter;
