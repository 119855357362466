import * as XLSX from 'xlsx';

import {
  COLUMN_LABELS,
  ColumnHeaders,
  MANDATORY_FILM_TYPE,
  MANDATORY_NON_FILM_TYPE,
} from '../../validation/CreateAlbum/FilmType';

const useValidateInputFile = () => {
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, {type: 'buffer'});
        const wsname = wb.SheetNames[0];

        const worksheet = wb.Sheets[wsname];

        // Store the column Header meta
        let columnHeaders = [];

        for (let key in worksheet) {
          let regEx = new RegExp('^(\\w)(1){1}$');
          if (regEx.test(key) === true) {
            let data = {col: key, value: worksheet[key].v};
            columnHeaders.push(data);
          }
        }
        // Validate Headers
        let invalidHeaders = compareHeaders(columnHeaders);

        if (invalidHeaders.length === 0) {
          // Validate mandatory Fields
          const data = XLSX.utils.sheet_to_json(worksheet);

          const dateData = XLSX.utils.sheet_to_json(worksheet, {raw: false});

          if (data.length > 0) {
            // get the film type value from the xl file
            const FILM_TYPE = data[0][COLUMN_LABELS.ALBUM_TYPE]
              ? data[0][COLUMN_LABELS.ALBUM_TYPE].toLowerCase()
              : null;

            // Check whether its film or non-film
            if (FILM_TYPE === 'film') {
              let emptyFilmFields = checkMandatoryFieldsForFilm(
                columnHeaders,
                data,
              );
              if (emptyFilmFields.length > 0) {
                reject({
                  msg: 'Mandatory Fields Missing Film Type',
                  fields: emptyFilmFields,
                });
              } else {
                checkForValidCode(resolve, reject, data, dateData);
              }
            } else if (FILM_TYPE === 'non-film') {
              let emptyNonFilmFields = checkMandatoryFieldsForNonFilm(
                columnHeaders,
                data,
              );
              if (emptyNonFilmFields.length > 0) {
                reject({
                  msg: 'Mandatory Fields Missing in NonFilm Type',
                  fields: emptyNonFilmFields,
                });
              } else {
                // Success
                checkForValidCode(resolve, reject, data, dateData);
              }
            } else if (!FILM_TYPE) {
              reject({
                msg: 'INVALID FILM TYPE',
              });
            } else {
              reject({
                msg: 'FILM TYPE VALUE MISMATCH',
              });
            }
          } else {
            reject({
              msg: 'No Data',
              fields: [],
            });
          }
        } else {
          reject({
            msg: 'Invalid Headers Present',
            fields: invalidHeaders,
          });
        }
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    return promise;
  };

  const checkForValidCode = async (resolve, reject, data, dateData) => {
    let invalidUpc = await checkUPCCodeFormat(data);

    let invalidIsrc = await checkISRCCodeFormat(data);

    if (invalidUpc.length === 0 && invalidIsrc.length === 0) {
      //  Check for song order
      let invalidSongOrder = checkSongOrder(data);

      let invalidProductType = await checkInvalidProductType(data);

      if (invalidProductType.length > 0) {
        reject({
          msg: 'Invalid UPC/ISRC Present ',
          fields: invalidProductType,
        });
      } else {
        if (invalidSongOrder.length > 0) {
          reject({
            msg: 'Invalid Song Order Present',
            fields: invalidSongOrder,
          });
        } else {
          let invalidReleaseDate = checkReleaseDate(dateData);
          if (invalidReleaseDate.length > 0) {
            reject({
              msg: 'Invalid Release Date Present',
              fields: invalidReleaseDate,
            });
          } else {
            //  Validate Release Date
            let invalidatedReleaseDateData = validateReleaseDate(dateData);
            if (invalidatedReleaseDateData.length > 0) {
              reject({
                msg: 'Invalid Release Date Format Present',
                fields: invalidatedReleaseDateData,
              });
            } else {
              resolve();
            }
          }
        }
      }
    } else {
      if (invalidUpc.length > 0) {
        reject({
          msg: 'Invalid UPC Present',
          fields: invalidUpc,
        });
      }
      if (invalidIsrc.length > 0) {
        reject({
          msg: 'Invalid ISRC Present',
          fields: invalidIsrc,
        });
      }
    }
  };

  const validateReleaseDate = (datas) => {
    let dateReg = /^\d{2}\/(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)\/\d{4}$/;

    let invalidatedData = [];
    if (datas.length) {
      datas.map((data, i) => {
        let date = data[COLUMN_LABELS.ALBUM_RELEASE_DATE].toLowerCase();

        if (dateReg.test(date)) {
          return [];
        } else {
          let value = {
            row: `row ${2 + i}`,
            value: data[COLUMN_LABELS.ALBUM_RELEASE_DATE],
          };
          invalidatedData.push(value);
        }
      });
    }
    return invalidatedData;
  };

  const checkReleaseDate = (datas) => {
    if (datas.length > 0) {
      let obj = {};
      let inconsistancyList = [];
      datas.forEach((val, i) => {
        if (!obj[val.UPC]) {
          obj[val.UPC] = val[COLUMN_LABELS.ALBUM_RELEASE_DATE];
        } else {
          if (obj[val.UPC] !== val[COLUMN_LABELS.ALBUM_RELEASE_DATE]) {
            inconsistancyList.push(val.UPC);
          }
        }
      });
      let uniq = [...new Set(inconsistancyList)];
      let upcList = [];
      uniq.map((upc) => {
        upcList.push({value: upc});
        return upcList;
      });

      return upcList;
    }
  };

  // Song Order
  const checkSongOrder = (datas) => {
    let currentOrderIndices = {};
    let issues = [];

    datas.forEach((song, i) => {
      currentOrderIndices[song.UPC] = currentOrderIndices[song.UPC]
        ? currentOrderIndices[song.UPC] + 1
        : 1;
      if (song[COLUMN_LABELS.SONG_ORDER] !== currentOrderIndices[song.UPC]) {
        issues.push({
          row: `row ${i + 2}`,
          value: song[COLUMN_LABELS.SONG_ORDER],
          correct_value: currentOrderIndices[song.UPC],
        });
      }
    });
    return issues;
  };

  // Product Type Validation

  const checkInvalidProductType = async (datas) => {
    let issues = [];
    let issueUpcList = [];
    let issueIsrcList = [];
    let singleUpcList = [];
    let singleIsrcList = [];
    let albumUpcList = [];
    let albumIsrcList = [];
    datas.forEach((album, i) => {
      if (
        !issueUpcList.includes(album.UPC) &&
        !issueIsrcList.includes(album.ISRC)
      ) {
        if (album['Product Type'] === 'Single') {
          if (singleUpcList.includes(album.UPC)) {
            issues.push({
              row: 2 + i,
              value: `Found Duplicate UPC in Single Product Type`,
            });
            issueUpcList.push(album.UPC);
          } else if (albumUpcList.includes(album.UPC)) {
            issues.push({
              row: 2 + i,
              value: 'Found Duplicate UPC in Album and Single Product Type',
            });
            issueUpcList.push(album.UPC);
          } else if (singleIsrcList.includes(album.ISRC)) {
            issues.push({
              row: 2 + i,
              value: 'Found Duplicate ISRC in Single Product Type',
            });
            issueUpcList.push(album.UPC);
          } else if (albumIsrcList.includes(album.ISRC)) {
            issues.push({
              row: 2 + i,
              value: 'Found Duplicate ISRC in Album and Single Product Type',
            });
            issueUpcList.push(album.UPC);
          } else {
            singleUpcList.push(album.UPC);
            singleIsrcList.push(album.ISRC);
          }
        } else {
          if (singleUpcList.includes(album.UPC)) {
            issues.push({
              row: 2 + i,
              value: 'Found Duplicate UPC in Single and Album Product Type',
            });
            issueUpcList.push(album.UPC);
          } else if (singleIsrcList.includes(album.ISRC)) {
            issues.push({
              row: 2 + i,
              value: 'Found Duplicate ISRC in Single and Album Product Type',
            });
            issueUpcList.push(album.UPC);
          } else {
            if (!albumUpcList.includes(album.UPC)) {
              albumUpcList.push(album.UPC);
            }
            if (!albumIsrcList.includes(album.ISRC)) {
              albumIsrcList.push(album.ISRC);
            }
          }
        }
      }
    });

    return issues;
  };

  // UPC Code Validation
  const checkUPCCodeFormat = async (datas) => {
    let upcReg = /^[0-9]{12,13}$/;
    let invalidUpcData = [];
    if (datas.length) {
      datas.map((data, i) => {
        if (!upcReg.test(data.UPC)) {
          let value = {row: `row ${2 + i}`, value: data.UPC};
          invalidUpcData.push(value);
        }
      });
    }
    return invalidUpcData;
  };

  // ISRC Code Validation
  const checkISRCCodeFormat = async (datas) => {
    let isrcReg = /^.{12}$/;
    let invalidIsrcData = [];
    if (datas.length) {
      datas.map((data, i) => {
        if (!isrcReg.test(data.ISRC)) {
          let value = {row: `row ${2 + i}`, value: data.ISRC};
          invalidIsrcData.push(value);
        }
      });
    }
    return invalidIsrcData;
  };

  const compareHeaders = (file_headers) => {
    // Check if all headers are strictly valid
    let unmatchCol = [];
    file_headers.map((header, index) => {
      if (!ColumnHeaders.includes(header.value)) {
        unmatchCol.push(file_headers[index]);
      }
    });
    return unmatchCol;
  };

  const checkMandatoryFieldsForFilm = (headers, data) => {
    //  validate mandatory fields for flilm type
    let invalidNonFilmFields = [];
    data.map((currentObj, index) => {
      MANDATORY_FILM_TYPE.map((field) => {
        if (!currentObj[field]) {
          const getColData = headers.filter((header) => header.value === field);
          const data = {...getColData[0], row: `row${2 + index}`};
          invalidNonFilmFields.push(data);
        }
      });
    });
    return invalidNonFilmFields;
  };

  const checkMandatoryFieldsForNonFilm = (headers, data) => {
    //  validate mandatory fields for non-flilm type
    let invalidFilmFields = [];
    data.map((currentObj, index) => {
      MANDATORY_NON_FILM_TYPE.map((field) => {
        if (!currentObj[field]) {
          const getColData = headers.filter((header) => header.value === field);
          const data = {...getColData[0], row: `row${2 + index}`};
          invalidFilmFields.push(data);
        }
      });
    });
    return invalidFilmFields;
  };

  return {readExcel};
};

export default useValidateInputFile;
