import styled from '@emotion/styled';
import React from 'react';

import FileUploadNew from '../../../../components/CreateProject/BulkUpload/FileUploadNew';
import {GlobalContext} from '../../../../Contexts/GlobalProvider';
import useUpcFileUpload from '../../../../hooks/UPCManagement/useUpcFileUpload';

const UpcUpload = (props) => {
  const {setIsOpen, refetch} = props;
  const {state, dispatch}: any = React.useContext(GlobalContext);

  React.useEffect(() => {
    dispatch({
      type: 'CHANGE_STATUS',
      payload: {
        uploadStatus: null,
        duplicate_upc: null,
        duplicate_upc_count: null,
        duplicate_isrc: null,
        error: null,
        valid_upc_count: null,
        invalid_upc: null,
        type: null,
      },
    });
  }, []);
  const {
    onDropHandler,
    onCancelHandler,
    errorMessage,
    file,
    onRemoveFileHandler,
    progressInfo,
    handleUpload,
    isUploadStarted,
    complete,
  } = useUpcFileUpload({setIsOpen, refetch});

  return (
    <UpcUploadContainer>
      <FileUploadNew
        accept=".xlsx"
        isMultiple={false}
        onCancel={onCancelHandler}
        onChange={onDropHandler}
        errorMessage={errorMessage}
        file={file}
        onRemoveFileHandler={onRemoveFileHandler}
        tag="upc"
        onClickUpload={handleUpload}
        progressInfo={progressInfo}
        isUploadStarted={isUploadStarted}
        complete={complete}
        value={null}
        label="XLSX"
      />
    </UpcUploadContainer>
  );
};

export const UpcUploadContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export default UpcUpload;
