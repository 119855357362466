export const albumData = {
  album_name: {
    Header: 'Album Name',
    accessor: 'album_name',
  },

  upc: {
    Header: 'UPC',
    accessor: 'upc',
  },
  label: {
    Header: 'Label',
    accessor: 'label_name',
  },
  album_type: {
    Header: 'Content Type',
    accessor: 'album_type',
  },
  product_type: {
    Header: 'Product Type',
    accessor: 'product_type',
  },
  created_on: {
    Header: 'Created On',
    accessor: 'created_at',
  },
  album_release_date: {
    Header: 'Release Date',
    accessor: 'album_release_date',
  },
  status: {
    Header: 'Status',
    accessor: 'status',
  },
  action_btn: {
    Header: '',
    accessor: 'complete',
  },
};
