import {Modal, ModalBody, ModalFooter, ModalHeader} from 'baseui/modal';
import React from 'react';

const SubmitModal = ({isOpen, close, renderBtn, title}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      overrides={{
        Dialog: {
          style: {
            borderRadius: '5px',
          },
        },
      }}
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody></ModalBody>
      <ModalFooter>{renderBtn}</ModalFooter>
    </Modal>
  );
};

export default SubmitModal;
