/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';

import useNotification from '../../../Contexts/useNotification';
// import FileUploadNew from '../../../FileUploadNew';
import useUploadMetaFile from '../../../helpers/useUploadMetaFile';
import useGetAlbumsData from '../../../hooks/AlbumDetail/useGetAlbumsData';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import {UpcUploadContainer as CoverArtContainer} from '../../../pages/ISRC-UPCManagement/UpcManagement/forms/UpcUpload';
import FileUploadNew from '../../CreateProject/BulkUpload/FileUploadNew';
import Uploader from '../../reusable/Formik/BaseSingleFIle';

export const UploadCoverArt = (props) => {
  const {setIsOpen, setNewFormTag,refetchAlbum} = props;
  const {albumId} = useParams();
  const albumData = JSON.parse(window.localStorage.getItem('track_album_data'));
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);

  const {getAlbumDetail, refetch} = useGetAlbumsData({
    albumId: albumId,
  });

  const [coverArtUrl, setCoverArtUrl] = useState(null);

  useEffect(() => {
    if (getAlbumDetail?.cover_art) {
      setCoverArtUrl(getAlbumDetail.cover_art);
    }
  }, [getAlbumDetail]);

  const {uploadFile, cancel} = useUploadMetaFile({
    metadata: {
      album_id: albumId,
      user_id: currentUser.id,
      action: coverArtUrl ? 'UPDATE' : 'CREATE',
    },
  });
  const [progressInfo, setProgressInfo] = useState(null);
  const [isUploadStarted, setIsUploadStarted] = useState(false);
  const [complete, setComplete] = useState(false);

  const [file, setFile] = useState(undefined);
  // console.log(albumData);
  const [errorMessage, setErrorMessage] = useState('');

  const {showToast} = useNotification();

  const handleUpload = async () => {
    setIsUploadStarted(true);
    try {
      if (file) {
        const project_id = getAlbumDetail.project_id;
        const fileExtension = '.' + file.name.split('.').pop();
        const new_file = new File([file], albumData.track_upc + fileExtension);

        const response = await uploadFile(
          new_file,
          project_id,
          `coverart/3000`,
          (event) => {
            setProgressInfo(Math.round((100 * event.loaded) / event.total));
          },
        );
        if (response?.status === 200) {
          setComplete(true);
          setNewFormTag(true);
          setIsOpen(false);
          refetchAlbum();
        } else {
          setIsUploadStarted(false);
          showToast('Could not upload the file', 'error');
          setIsOpen(false);
          setProgressInfo(0);
        }
      }
    } catch (error) {
      setIsUploadStarted(false);
    }
  };

  const closeHandler = () => {
    console.log('Code to close');
  };

  const onDropHandler = (file) => {
    const selectedFile = file[0];

    const idxDot = selectedFile.name.lastIndexOf('.') + 1;
    const extFile = selectedFile.name
      .substr(idxDot, selectedFile.name.length)
      .toLowerCase();
    if (extFile == 'jpg') {
      //do whatever want to do
      var img = new Image();
      img.onload = function () {
        const height = this.height;
        const width = this.width;
        if (height <= 3000 && width <= 3000) {
          setFile(file[0]);
        } else {
          alert('You have uploaded image greater than 3000 px');
        }
      };
      img.src = URL.createObjectURL(selectedFile);
    } else {
      alert('Only jpg files are allowed!');
    }
  };

  const onRemoveFileHandler = () => {
    setFile(null);
  };

  const onCancelHandler = () => {
    console.log('Cancelled');
  };

  return (
    <CoverArtContainer>
      <FileUploadNew
        accept="image/jpg,image/jpeg"
        isMultiple={false}
        onCancel={onCancelHandler}
        onChange={onDropHandler}
        errorMessage={errorMessage}
        file={file}
        onRemoveFileHandler={onRemoveFileHandler}
        tag="cover"
        onClickUpload={handleUpload}
        progressInfo={progressInfo}
        isUploadStarted={isUploadStarted}
        complete={complete}
        value={null}
        label="JPG"
      />
    </CoverArtContainer>
  );
};

// <UploadCoverArt.Container>
//   {isComplete ? (
//     <UploadCoverArt.Message>
//       <span>Cover Art has been uploaded successfully</span>
//       <Check
//         size={20}
//         overrides={{
//           Svg: {
//             style: () => {
//               return {
//                 color: 'white',
//                 backgroundColor: 'blue',
//                 borderRadius: '50%',
//               };
//             },
//           },
//         }}
//       />
//     </UploadCoverArt.Message>
//   ) : progress ? (
//     progressInfo && (
//       <ProgressWrapper>
//         <div className="item_info">
//           <div className="track_n_size">
//             <p>{file.name}</p>
//             <span>{sizeInMB(file.size)}MB</span>
//           </div>
//           <span className="close" onClick={closeHandler}>
//             x
//           </span>
//         </div>
//         <div className="progress_bar">
//           <ProgressBar value={progressInfo} successValue={100} />
//         </div>
//       </ProgressWrapper>
//     )
//   ) : (
//     <>
//       <span className="title">Cover Art</span>
//       <div className="uploadfile">
//         <UploadFile
//           file={file}
//           isShowButton={false}
//           setFile={setFile}
//           setIsComplete={setIsComplete}
//           handleUpload={(f) => handleUpload(f)}
//           cancelUpload={cancel}
//         />
//       </div>
//     </>
//   )}
// </UploadCoverArt.Container>
