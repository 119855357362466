import React, {useEffect, useState} from 'react';

import useTablePageState from '../../../hooks/reusable/useTablePageState';
import useListTypes from '../../../hooks/UserManagement/useListTypes';
import {typeColumnMeta} from '../../../tableColumns/UserManagement/tableData';
import LoadingMain from '../../../components/reusable/Loading/LoadingMain';
import {TypeTable} from '../tables';

const Type = () => {
  const {userTypes, isLoading} = useListTypes();
  const [typeData, setTypeData] = useState([]);
  const {setIsOpen, setSelectedData} = useTablePageState();

  useEffect(() => {
    if (userTypes) {
      setTypeData(userTypes);
    }
  }, [userTypes]);

  return (
    <>
      {isLoading ? (
        <LoadingMain />
      ) : (
        <TypeTable
          columns={typeColumnMeta}
          data={typeData ? typeData : []}
          onClickRow={setIsOpen}
          setSidebarData={setSelectedData}
          tag="type"
        />
      )}
    </>
  );
};

export default Type;
