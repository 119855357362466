import styled from '@emotion/styled';
import React, {useState} from 'react';
import image from '../../../assets/placeholder.jpg';

const FileUploadDetailComp = (props) => {
  const {item} = props;
  return (
    <div>
      <FileUploadDetailComponent>
        <div className="detail-card">
          <div className="info">
            <div className="image">
              {item.cover_art === null ? (
                <img src={image} alt="Null" />
              ) : (
                <img src={item.cover_art} alt="Img" />
              )}
            </div>
            <div className="titles">
              <div className="title-name">{item.album_name}</div>
              <div className="upc">{item.upc}</div>
            </div>
          </div>
          <div className="tracks">
            <p>{item.Track?.length} tracks</p>
          </div>
        </div>
      </FileUploadDetailComponent>
    </div>
  );
};

const FileUploadDetailComponent = styled.div`
  margin: 0 -30px;
  padding: 0 30px;
  .detail-card {
    color: #333333;
    margin-top: 30px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    border: 1px solid #b9b9b9;
    border-radius: 10px;
    padding: 10px 10px;
    .info {
      display: flex;
      justify-content: center;
      align-items: center;

      .image {
        width: 50px;
        height: 50px;
        background: grey;
        border-radius: 10px;
        margin-right: 15px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
      .titles {
        line-height: 20px;
        .title-name {
          font-weight: 600;
          font-size: 16px;
        }
        .upc {
          color: #666666;
        }
      }
    }
    .tracks {
      color: #666666;
    }
  }
`;

export default FileUploadDetailComp;
