import {useMutation} from 'react-query';

import axios from '../../config/Axios';

const createUploadStatusFn = async (data) => {
  try {
    const response = await axios(`/upload-status`, {
      method: 'POST',
      data: data.data,
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

const useCreateUploadStatus = () => {
  const [createUploadStatus, {status, data, error, isLoading}] = useMutation(
    createUploadStatusFn,
  );
  return {
    createUploadStatus,
    isLoading,
    status,
    error,
  };
};

export default useCreateUploadStatus;
