import {Form, Formik} from 'formik';
import React from 'react';

import {COLUMN_LABELS} from '../../../../validation/CreateAlbum/FilmType';
import {updateAlbumValidationSchema} from '../../../../validation/ProjectManagement/albumDetails';
import {
  albumReleaseType,
  filmZone,
  nonFilmZone,
} from '../../../../components/CreateProject/AlbumUpload/AlbumDetails/optionData';
import TerritoryAlbumUpdate from '../../../../components/CreateProject/BulkUpload/TerritoryAlbumUpdate';
import FormikControl from '../../../../components/reusable/Formik/FormikControl';
import {SubmitButton} from '../../../../components/reusable/SubmitButton/SubmitButton';

const albumTypeOption = [
  {id: 'Film', value: 'Film'},
  {id: 'Non-film', value: 'Non-Film'},
];

export default function AlbumFormikForm(props) {
  const {
    initialValues,
    onSubmit,
    languageOptions,
    excludeValue,
    setExcludeValue,
    includeValue,
    setIncludeValue,
    selectedValue,
    setSelectedValue,
    getAlbumDetail,
    open,
    albumLoading,
    setOpen,
  } = props;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={updateAlbumValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <div className="edit-form">
            <Form>
              <div style={{pointerEvents: 'none', opacity: '0.7'}}>
                <FormikControl
                  labelKey="value"
                  valueKey="id"
                  control="baseselect"
                  isSearchable={false}
                  label={COLUMN_LABELS.ALBUM_TYPE}
                  name="album_type"
                  placeholder="e.g Film"
                  options={albumTypeOption}
                  value={formik.values.album_type}
                  onChange={(name, value) => {
                    formik.setFieldValue(name, value);
                    formik.setFieldValue('film_zone', '');
                    formik.setFieldTouched(name, true);
                  }}
                />

                <FormikControl
                  control="radio"
                  name="product_type"
                  label={COLUMN_LABELS.ALBUM_PRODUCT_TYPE}
                  options={albumReleaseType}
                  placeholder="Select album product type"
                  initialValue={formik.initialValues.product_type}
                />
              </div>
              <FormikControl
                control="baseinput"
                type="text"
                label={COLUMN_LABELS.ALBUM_NAME}
                name="album_name"
                placeholder="e.g Album EP"
                initialValue={formik.initialValues.album_name}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
              />
              {formik.values?.album_type[0]?.id.toLowerCase() === 'film' && (
                <>
                  <FormikControl
                    control="dynamicInput"
                    buttonName="Add Actor"
                    type="text"
                    label={COLUMN_LABELS.ACTOR_MALE_NAME}
                    name="actors_name"
                    placeholder="e.g John Doe"
                    initialValue={formik.values.actors_name}
                    isRequired={
                      formik.values?.album_type.length &&
                      formik.values.album_type[0].id.toLowerCase() === 'film'
                    }
                    setFieldTouched={formik.setFieldTouched}
                    setFieldValue={formik.setFieldValue}
                  />
                  <FormikControl
                    control="dynamicInput"
                    buttonName="Add Actress"
                    type="text"
                    label={COLUMN_LABELS.ACTRESS_FEMALE_NAME}
                    name="actress_name"
                    placeholder="e.g Jane Doe"
                    initialValue={formik.values.actress_name}
                    isRequired={
                      formik.values?.album_type.length &&
                      formik.values.album_type[0].id.toLowerCase() === 'film'
                    }
                    setFieldTouched={formik.setFieldTouched}
                    setFieldValue={formik.setFieldValue}
                  />
                  <FormikControl
                    control="dynamicInput"
                    buttonName="Add Director"
                    label={
                      formik.values?.album_type.length &&
                      formik.values.album_type[0].id.toLowerCase() === 'film'
                        ? COLUMN_LABELS.FILM_DIRECTOR_NAME
                        : COLUMN_LABELS.ALBUM_DIRECTOR_NAME
                    }
                    name="album_director_name"
                    placeholder="e.g Jane Doe"
                    isRequired={
                      formik.values?.album_type.length &&
                      formik.values.album_type[0].id.toLowerCase() === 'film'
                    }
                    setFieldTouched={formik.setFieldTouched}
                    setFieldValue={formik.setFieldValue}
                  />
                  <FormikControl
                    control="dynamicInput"
                    buttonName="Add Producer"
                    label={
                      formik.values?.album_type.length &&
                      formik.values.album_type[0].id.toLowerCase() === 'film'
                        ? COLUMN_LABELS.FILM_PRODUCER_NAME
                        : COLUMN_LABELS.ALBUM_PRODUCER_NAME
                    }
                    name="album_producer_name"
                    placeholder="e.g John Doe"
                    isRequired={
                      formik.values?.album_type.length &&
                      formik.values.album_type[0].id.toLowerCase() === 'film'
                    }
                    setFieldTouched={formik.setFieldTouched}
                    setFieldValue={formik.setFieldValue}
                  />
                  <FormikControl
                    control="dynamicInput"
                    buttonName="Add Banner"
                    label={COLUMN_LABELS.PRODUCTION_HOUSE_NAME}
                    name="banner_production_name"
                    placeholder="e.g Jane's House"
                    isRequired={
                      formik.values?.album_type.length &&
                      formik.values.album_type[0].id.toLowerCase() === 'film'
                    }
                    setFieldTouched={formik.setFieldTouched}
                    setFieldValue={formik.setFieldValue}
                  />
                </>
              )}
              <FormikControl
                labelKey="language"
                valueKey="id"
                control="baseselect"
                label="Language"
                name="language_id"
                onChange={(name, value) => {
                  formik.setFieldTouched(name, true);
                  formik.setFieldValue(name, value);
                }}
                isSearchable={false}
                options={languageOptions}
                value={formik.values.language_id}
                setFieldValue={formik.setFieldValue}
                initialValue={formik.initialValues.language_id}
                setFieldTouched={formik.setFieldTouched}
              />
              <FormikControl
                control="baseinput"
                type="text"
                label={COLUMN_LABELS.C_LINE}
                name="label_c_name"
                placeholder="e.g Divo music"
                initialValue={formik.initialValues.label_c_name}
                setFieldValue={formik.setFieldValue}
                isRequired={true}
                setFieldTouched={formik.setFieldTouched}
              />
              <FormikControl
                control="baseinput"
                type="text"
                label={COLUMN_LABELS.P_LINE}
                name="p_line_name"
                placeholder="e.g Divo music"
                initialValue={formik.initialValues.p_line_name}
                setFieldValue={formik.setFieldValue}
                isRequired={true}
                setFieldTouched={formik.setFieldTouched}
              />
              {!formik.initialValues?.album_type[0]?.value.includes(
                'Select',
              ) ? (
                <FormikControl
                  labelKey="value"
                  valueKey="id"
                  control="baseselect"
                  label={COLUMN_LABELS.FILM_ZONE}
                  name="film_zone"
                  options={
                    formik.values.album_type[0]?.value.toLowerCase() === 'film'
                      ? filmZone
                      : formik.values.album_type[0]?.value.toLowerCase() ===
                        'non-film'
                      ? nonFilmZone
                      : null
                  }
                  onChange={(name, value) => {
                    formik.setFieldTouched(name, true);
                    formik.setFieldValue(name, value);
                  }}
                  value={formik.values.film_zone}
                  setFieldValue={formik.setFieldValue}
                  setFieldTouched={formik.setFieldTouched}
                />
              ) : null}
              <FormikControl
                control="baseDatePicker"
                type="date"
                label="Album Release Date"
                name="album_release_date"
                initialValue={formik.initialValues.album_release_date}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
              />
              {formik.values?.territory ? (
                <>
                  <p className="territory-head">
                    Territory <span> *</span>
                  </p>
                  <div className="territory" onClick={() => setOpen(!open)}>
                    <div>{formik.values?.territory} </div>
                    <div className="button">{open ? 'Hide' : 'Show'}</div>
                  </div>
                  {open && (
                    <TerritoryAlbumUpdate
                      excludeValue={excludeValue}
                      setExcludeValue={setExcludeValue}
                      includeValue={includeValue}
                      setIncludeValue={setIncludeValue}
                      selectedValue={selectedValue}
                      setSelectedValue={setSelectedValue}
                      setFieldValue={formik.setFieldValue}
                      getAlbumDetail={getAlbumDetail}
                      value={formik.values.territory}
                      setFieldTouched={formik.setFieldTouched}
                    />
                  )}
                </>
              ) : null}
              <SubmitButton
                isLoading={albumLoading}
                disabled={
                  !formik.isValid ||
                  (Object.keys(formik.touched).length === 0 &&
                    formik.touched.constructor === Object)
                }
                type="submit"
                label="Update Album"
              />
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
