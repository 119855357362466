import React from 'react';

// Components
import Tab from '../../components/reusable/Tab/Tab';
import {Role, Type, User} from '../../components/UserManagement';
// Custom Hooks

const UserManagement = () => {
  const [activeKey, setActiveKey] = React.useState<string | number>('0');

  return (
    <>
      <Tab
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        content={[
          {
            title: 'Users',
            component: <User />,
          },
          {
            title: 'Roles',
            component: <Role />,
          },
          {
            title: 'Types',
            component: <Type />,
          },
        ]}
      />
    </>
  );
};

export default UserManagement;
