import React, {useState} from 'react';
import {useQueryCache} from 'react-query';

import useNotification from '../../Contexts/useNotification';
import {logError} from '../../errorlogger/logError';
import useUploadMetaFile from '../../helpers/useUploadMetaFile';

const useUpcFileUpload = (props) => {
  const {setIsOpen, refetch} = props;
  const [progressInfo, setProgressInfo] = useState(null);
  const [isUploadStarted, setIsUploadStarted] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [file, setFile] = useState(null);
  const [complete, setComplete] = useState(false);

  const queryCache = useQueryCache();

  const {showToast} = useNotification();
  const {uploadFile, cancel} = useUploadMetaFile({metadata: null});

  const handleUpload = async () => {
    setIsUploadStarted(true);
    try {
      if (file) {
        const response = await uploadFile(file, '', 'upc', (event) => {
          setProgressInfo(Math.round((100 * event.loaded) / event.total));
        });
        if (response?.status === 200) {
          setComplete(true);
          setTimeout(() => {
            refetch();
            setIsOpen(false);
          }, 4000);
        } else {
          setIsUploadStarted(false);
          showToast('Could not upload the file', 'error');
          setIsOpen(false);
          setProgressInfo(0);
        }
      }
    } catch (error) {
      logError(error, 8);
      setIsUploadStarted(false);
    }
  };

  const onDropHandler = (file) => {
    setFile(file[0]);
  };

  const onRemoveFileHandler = () => {
    setFile(null);
  };

  const onCancelHandler = () => {
    console.log('Cancelled');
  };

  return {
    handleUpload,
    progressInfo,
    onCancelHandler,
    onRemoveFileHandler,
    onDropHandler,
    errorMessage,
    file,
    isUploadStarted,
    complete,
  };
};

export default useUpcFileUpload;
