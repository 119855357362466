import axios from '../../config/Axios';
import {useMutation} from 'react-query';
import {logError} from '../../errorlogger/logError';

const updateProductUnlockRequest = async (data) => {
  try {
    return await axios({
      method: 'PUT',
      url: `/product_unlock_requests/${data.id}/${data.status}`,
      data: data.data,
    });
  } catch (error) {
    logError(error, 17);
    return error.response.data;
  }
};

const useProductUnlockRequest = () => {
  const [updateProductUnlockRequestMutation, {isLoading, error}] = useMutation(
    updateProductUnlockRequest,
    {},
  );
  return {
    updateProductUnlockRequestMutation,
    isLoading,
    error,
  };
};

export default useProductUnlockRequest;
