import styled from '@emotion/styled';
import {PLACEMENT, StatefulPopover} from 'baseui/popover';
import React from 'react';
import {IoIosArrowForward, IoMdArrowRoundDown} from 'react-icons/io';
import {MdAddCircle, MdFileUpload} from 'react-icons/md';
import Lottie from 'react-lottie';
import {useNavigate} from 'react-router';

import useUPC from '../../../hooks/UPCManagement/useUPC';
import animationData from '../../../lotties/no-data.json';
import Button from '../Button/Button';

export default function NoRecords(props) {
  const {btnLabel, onClick, page, tag = null, name, reuseIsrcModalOpen} = props;
  const {getUpcCount} = useUPC({tag});
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const AddTrackDropDown = (props) => {
    const {
      close,
      setDynamicTag,
      setIsOpen,
      onClick,
      reuseIsrcModalOpen,
    } = props;

    const dropDownLabel = [
      {
        label: 'New Track',
        key: 'newtrack',
      },
      {
        label: 'Existing Track',
        key: 'existingtrack',
      },
    ];

    const mainActionHandle = (keys) => {
      if (keys === 'newtrack') {
        onClick();
      } else if (keys === 'existingtrack') {
        reuseIsrcModalOpen();
      }
      close();
    };

    return (
      <AddTrackContainer>
        <ul>
          {dropDownLabel.map((l) => (
            <li
              key={l.key}
              onClick={() => {
                mainActionHandle(l.key);
              }}
              className="list"
            >
              <label>
                <div className="export-option-container">
                  <div className="label">{l.label}</div>
                  {/* <div className="icon">{l.icon}</div> */}
                </div>
              </label>
            </li>
          ))}
        </ul>
      </AddTrackContainer>
    );
  };
  return (
    <NoRecordsContainer name={name}>
      <div>
        <span>No records found !</span>
      </div>

      <div className="lottie-container">
        <Lottie options={defaultOptions} height={150} width={150} />
      </div>
      {page === 'bulk-admin' && (
        <div>
          <Button
            label="Go to Package Manager"
            onClick={() => navigate('package-manager')}
            shape="round"
            endEnhancer={() => <IoIosArrowForward />}
          />
        </div>
      )}
      {page !== 'upc' && page !== 'bulk-admin' ? (
        btnLabel ? (
          <div>
            {page === 'tracks' ? (
              <StatefulPopover
                dismissOnClickOutside
                focusLock={true}
                content={({close}) => (
                  <AddTrackDropDown
                    close={close}
                    reuseIsrcModalOpen={reuseIsrcModalOpen}
                    onClick={onClick}
                  />
                )}
              >
                <div className={`btn-container`}>
                  <Button
                    label={btnLabel}
                    shape="round"
                    endEnhancer={() => <MdAddCircle />}
                  />
                </div>
              </StatefulPopover>
            ) : (
              <Button
                label={btnLabel}
                onClick={onClick}
                shape="round"
                endEnhancer={() => <MdAddCircle />}
              />
            )}
          </div>
        ) : null
      ) : null}

      {page === 'upc' ? (
        <div className="upc-container">
          <Button
            label={btnLabel[0]}
            onClick={onClick[0]}
            shape="round"
            disabled={getUpcCount && getUpcCount.not_assigned === 0}
            endEnhancer={() => <MdAddCircle />}
          />

          <a href="https://divo-source-staging.s3.ap-south-1.amazonaws.com/UPC_Blank.xlsx">
            <Button
              label="Download Template"
              shape="round"
              href="https://divo-source-staging.s3.ap-south-1.amazonaws.com/UPC_Blank.xlsx"
              endEnhancer={() => <IoMdArrowRoundDown />}
            />
          </a>

          <Button
            label={btnLabel[1]}
            onClick={onClick[1]}
            shape="round"
            endEnhancer={() => <MdFileUpload />}
          />
        </div>
      ) : null}
    </NoRecordsContainer>
  );
}

const NoRecordsContainer = styled.div<{name}>`
  .upc-container {
    display: flex;
    flex-direction: column;
    button {
      margin-bottom: 15px;
    }
  }
  .lottie-container {
    margin-bottom: 15px;
  }
  width: 100%;
  height: ${(props) => (props.name === 'filter' ? '' : 'calc(100vh - 180px)')};
  flex-direction: column;
  display: flex;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  justify-content: center;
  align-items: center;
`;

const AddTrackContainer = styled.div`
  // background-color: white;
  // border-radius: 8px;
  // width: 200px !important;
  // margin-left: -65px;
  // box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 5px;
  ul {
    margin: 0;
    padding: 0px;
    display: flex;
    flex-direction: column;
    list-style-type: none;

    li {
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      line-height: 29px;
      &:hover {
        cursor: pointer;
      }
      padding: 15px 15px;
      label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }
      .export-option-container {
        transition: 0.5s all ease-in;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        width: 100%;
        color: #333;
        &:hover {
          color: #dc3831;
          transition: none;
        }
        .icon {
          margin-left: 20px;
          display: flex;
          svg {
            font-size: 16px;
          }
        }
      }
      .label {
        font-size: 14px;
        line-height: 19px;
      }
      &:hover {
        background-color: #f4f4fb;
        cursor: pointer;
      }
    }
  }
`;
