import styled from '@emotion/styled';
import React from 'react';
import {IoIosArrowForward} from 'react-icons/io';
import {useNavigate} from 'react-router';

import {formatDate} from '../../helpers/covertDate';

const ButtonContainer = styled.div`
  button {
    border: none;
    padding: 10px 20px;
    background: rgba(210, 25, 25, 0.1);
    border-radius: 5px;
    appearance: none;
    cursor: pointer;
    color: #d21919;
    font-size: 14px;
    display: flex;
    align-items: center;
    outline: none;
    svg {
      margin-left: 5px;
      margin-top: 1px;
    }
  }
`;
export const adminUploadsData = [
  {
    Header: 'Upload Name',
    accessor: 'project_name',
  },

  {
    Header: 'UPC Count',
    accessor: 'upc_count',
  },
  {
    Header: 'Uploaded By',
    accessor: 'created_by',
    Cell: (data) => {
      return <span> {data?.row?.original?.created_by?.name} </span>;
    },
  },
  {
    Header: 'Updated By',
    accessor: 'updated_name',
    Cell: (data) => {
      return <span> {data?.row?.original?.updated_by?.name} </span>;
    },
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    Cell: (data) => {
      return <span> {formatDate(data?.row?.original?.created_at)} </span>;
    },
  },
  {
    Header: '',
    accessor: 'complete',
    Cell: (data) => {
      const navigate = useNavigate();
      const onClickDetail = (data) => {
        navigate(`/bulk-admin/${data?.row?.original?.id}`);
      };
      return (
        <ButtonContainer>
          {data?.row?.original?.is_complete ? null : (
            <button onClick={() => onClickDetail(data)}>
              Continue {<IoIosArrowForward />}
            </button>
          )}
        </ButtonContainer>
      );
    },
  },
];
