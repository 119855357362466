import React, {useContext, useEffect, useMemo} from 'react';

import NoRecords from '../../components/reusable/NoRecords/NoRecords';
import OrderTablePagination from '../../components/reusable/Table/OrderTablePagination';
import {TAGS} from '../../constants/tags';
import {GlobalContext} from '../../Contexts/GlobalProvider';
import useOrderManagementFilter from '../../hooks/OrderManagement/useOrderManagementFilter';
import useTablePageState from '../../hooks/reusable/useTablePageState';
import SkeletonLoading from '../../pages/SkeletonPage';

export default function OrderComponent(props) {
  const {state, dispatch}: any = useContext(GlobalContext);
  const {
    column,
    filterData,
    handleChange,
    searchTerm,
    searchString,
    setSearchString,
    setSearchTerm,
    type,
  } = props;
  const {
    isOpen,
    newFormTag,
    selectedData,
    setIsOpen,
    setNewFormTag,
    setSelectedData,
  } = useTablePageState();

  const {
    isLoading,
    isError,
    error,
    resolvedData,
    isFetching,
    data,
    page,
    setPage,
    isLastPage,
    totalCount,
    rangeA,
    rangeB,
    lastPage,
    limit,
    setLimit,
    isCustomLoading,
    refetch,
  } = useOrderManagementFilter({str: null, searchString, type: type});

  const openNewFormSidebarHandler = () => {
    setIsOpen(true);
    setNewFormTag(true);
  };

  const columnData = useMemo(() => column, [column]);
  const OrderManagementData = useMemo(() => data, [data]);

  if (isLoading) {
    return <SkeletonLoading />;
  }
  if (resolvedData?.totalPage === 0) {
    return <h1>error</h1>;
  }

  return (
    <>
      <OrderTablePagination
        columns={columnData}
        data={OrderManagementData ? OrderManagementData : []}
        tag={TAGS.ORDER_MANAGEMENT}
        setPage={setPage}
        page={page}
        isLastPage={isLastPage}
        totalCount={totalCount}
        rangeB={rangeB}
        rangeA={rangeA}
        lastPage={lastPage}
        setLimit={setLimit}
        limit={limit}
        resolvedData={resolvedData}
        isLoading={isCustomLoading}
        isFetching={isFetching}
        isError={isError}
        error={error}
        sError={null}
        sIsError={null}
        sIsLoading={null}
        isSearching={null}
        isSearchingError={null}
        upcCount={null}
        refetchHandler={refetch}
        setSearchStr={null}
        loadMore={false}
        hasNextPage={false}
        filterData={filterData}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleChange={handleChange}
        setSearchString={setSearchString}
        searchString={searchString}
        type={type}
      />
    </>
  );
}
