import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const getDownloadUrl = async (key, s3Key) => {
  if (s3Key) {
    try {
      const response = await axios(`/album/download-url`, {
        method: 'GET',
        params: {
          key: s3Key,
          expireTime: 10000,
        },
      });
      return response.data?.data;
    } catch (error) {
      if (error.response?.status !== 404) {
        logError(error);
      }
      return error.response?.data;
    }
  }
};

const usePreviewLink = (props) => {
  const {key} = props;
  const {data: downloadLink, error} = useQuery(
    ['download_link', key],
    getDownloadUrl,
    {
      enabled: key,
      refetchOnWindowFocus: false,
    },
  );

  return {
    downloadLink,
  };
};

export default usePreviewLink;
