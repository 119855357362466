/** @format */

import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {ProgressBar} from 'baseui/progress-bar';
import {ErrorMessage, Field} from 'formik';
import React, {useState} from 'react';
import {MdCheckCircle} from 'react-icons/md';

import Button from '../Button/Button';
import DetailsError from './DetailsError';
import {LabelInAlbumCreate} from './DetailsSelect';

function Input(props) {
  const [css, theme] = useStyletron();
  const [disabled, setDisabled] = useState(true);
  const {
    label,
    name,
    value,
    uploaded,
    isLoading,
    handleClick,
    setFieldValue,
    progressInfo,
    fileValue,
    isRequired,
    ...rest
  } = props;

  const [fileInfo, setFileInfo] = useState(null);

  return (
    <Input.Container
      borderColor={theme.tabInactiveBorderColor}
      bgColor={theme.inputBg}
      textColor={theme.textColor}
    >
      <div className="input_item">
        <div className="left">
          <label>
            <LabelInAlbumCreate
              data={{children: label}}
              isRequired={isRequired}
            />
          </label>
        </div>
        <div className="right">
          <label className="file_input">
            <Field
              id={name}
              name={name}
              value={value}
              onChange={(e) => {
                if (
                  e.currentTarget.files?.length &&
                  e.currentTarget.files[0].size
                ) {
                  setFieldValue(name, e.currentTarget.files[0]);
                  setFileInfo(e.currentTarget.files[0]);
                  setDisabled(false);
                  handleClick(e.currentTarget.files[0]);
                }
              }}
              {...rest}
            />
            <span className="input">Select File</span>
            {fileValue ? (
              <span className="title">{fileValue}</span>
            ) : fileInfo ? (
              <span className="title">{fileInfo.name}</span>
            ) : null}
          </label>
          <div className="upload_success">
            {uploaded && <MdCheckCircle size={24} />}
          </div>
        </div>
      </div>

      <div className="input_item">
        <div className="left"></div>
        <div className="right error">
          {progressInfo && progressInfo > 0 ? (
            <div
              style={{width: 'inherit', paddingLeft: '0.75rem'}}
              className="progress"
            >
              <ProgressBar value={progressInfo} successValue={100} />
            </div>
          ) : null}
          <ErrorMessage name={name} component={DetailsError} />
        </div>
      </div>
    </Input.Container>
  );
}

Input.Container = styled.div`
  display: flex;
  flex-direction: column;

  .input_item {
    margin: 0.3rem 0.75rem;
    justify-content: center;
    align-items: center;
  
    .left {
      flex: 1;
      text-align: right;

      label {
        margin: 0;
      }
    }

    .right {
      display: flex;
      align-items: center;
      flex: 2;
      width: 55%;

      .file_input {
        padding: 10px;
        border-radius: 5px;
        background-color: rgb(239, 241, 243);
        align-items: center;
        margin: 0;
        width: 56%;
        display: inline-block;
        cursor: pointer;

        input {
          display: none;
        }
        .input {
          border: solid 1px #ccc;
          padding: 6px 12px;
          border-radius: 5px;
          font-size: 12px;
          width: inherit;
          box-shadow: 1px 1px 7px 0px rgba(50, 50, 50, 0.35);
        }
        .title {
          margin-left: 10px;
          display: inline-flex;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 200px;
        }
      }

      .progress {
        width: inherit;
      }

      .upload_success {
        display: inline-block;
        margin: 0 1rem;
        vertical-align: text-top;
        position: relative;
      }
    }
  }

  .upc_code {
    display: flex;
    align-items: center;
  }

  label {
    margin: 0 1.5rem;
    font-size: 0.85rem;
    font-weight: 600;
    color: ${(props) => props.textColor};
  }

  input {
    font-size: 0.85rem;
    border-radius: 5px;
    padding: 0.85rem;
    background: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};
    border: none;
    width: 55%;

    &:focus {
      outline: none;
    }
  }
`;

export default Input;
