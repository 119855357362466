import {useEffect} from 'react';
import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const fetchAlbumHistory = async (key, {albumId}) => {
  if (albumId) {
    try {
      const res = await axios(`/product_history/${albumId}`, {
        method: 'GET',
      });
      return res.data?.data;
    } catch (error) {
      logError(error, 9);
      return error.response?.data;
    }
  }
};

const useGetAlbumHistory = (props) => {
  const albumId = props;

  const {
    data: getAlbumHistory,
    refetch,
    isError,
    isFetching,
    isLoading,
  } = useQuery(['albumHistory', albumId], fetchAlbumHistory);

  return {getAlbumHistory, refetch, isError, isFetching, isLoading};
};

export default useGetAlbumHistory;
