import styled from '@emotion/styled';
import React, {useEffect, useState} from 'react';
import {FiShoppingBag} from 'react-icons/fi';
import {RiFileZipFill} from 'react-icons/ri';

import {Button} from '..';
import {TAGS} from '../../constants/tags';
import CheckBoxTable from '../reusable/Table/CheckBoxTable';

const FooterComponent = (props) => {
  const {
    selectedAlbumIds,
    publishFn,
    publishLoading,
    filteredAlbums,
    selectedTracks,
    gotoOrderScreen,
  } = props;

  const [hasPublished, setHasPublished] = useState(false);

  useEffect(() => {
    if (filteredAlbums?.length) {
      const val = filteredAlbums.filter(
        (item) => !item?.Order_management?.length,
      );
      if (val?.length) {
        setHasPublished(false);
      } else {
        setHasPublished(true);
      }
    }
  }, [filteredAlbums]);
  return (
    <>
      <ButtonContainer
        Enable={selectedAlbumIds?.length && selectedTracks <= 150}
      >
        {selectedAlbumIds?.length ? (
          <span>{`${selectedAlbumIds?.length} ${
            selectedAlbumIds?.length === 1 ? 'album' : 'albums'
          } selected`}</span>
        ) : null}

        {hasPublished ? (
          <div className="btn-wrapper">
            <Button
              isLoading={false}
              label="View Orders"
              background="#DC3831"
              shape="round"
              onClick={gotoOrderScreen}
              endEnhancer={() => <FiShoppingBag size={16} />}
            />
          </div>
        ) : (
          <div className="btn-wrapper disable">
            <Button
              isLoading={publishLoading}
              label="Create Package"
              background="#34A139"
              onClick={publishFn}
              shape="round"
              endEnhancer={() => <RiFileZipFill size={16} />}
            />
          </div>
        )}
      </ButtonContainer>
    </>
  );
};

const ButtonContainer = styled.div<{Enable}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .disable {
    pointer-events: ${(props) => (props.Enable ? '' : 'none')};
    > button {
      background: ${(props) => (props.Enable ? '' : '#cccccc')};
      color: ${(props) => (props.Enable ? '' : '#666666')};
    }
  }
  > span {
    margin-right: 20px;
    background: #fdf0b3;
    padding: 2px 14px;
    color: #9f6101;
    font-size: 14px;
    border-radius: 5px;
  }
  button {
    + button {
      margin-left: 10px;
    }
  }
`;

export default function BulkAdminPackager(props) {
  const {
    setSelectedValue,
    columnData,
    albumData,
    setIsOpen,
    selectedValue,
    selectedAlbumIds,
    isPublishAlbumLoading,
    publish,
    filteredAlbums,
    gotoOrderScreen,
    selectedTracks,
  } = props;

  return (
    <div>
      <CheckBoxTable
        isSearching={null}
        isSearchingError={null}
        upcCount={null}
        isFetching={null}
        setSelectedValue={setSelectedValue}
        refetchHandler={null}
        columns={columnData}
        data={albumData ? albumData : []}
        onClickRow={setIsOpen}
        setSidebarData={null}
        selectedValue={selectedValue}
        tag={TAGS.BULK_ADMIN_PACKAGER}
        setSearchStr={null}
        setIsOpen={null}
        setNewFormTag={null}
        loadMore={false}
        hasNextPage={false}
        mainActionHandler={null}
        footerComponent={() => (
          <FooterComponent
            selectedAlbumIds={selectedAlbumIds}
            publishFn={publish}
            publishLoading={isPublishAlbumLoading}
            filteredAlbums={filteredAlbums}
            gotoOrderScreen={gotoOrderScreen}
            selectedTracks={selectedTracks}
          />
        )}
      />
    </div>
  );
}
