import * as Yup from 'yup';

export const usermanagementValidationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email ID is required')
    .max(62, 'You have exceeded the max character length'),
  name: Yup.string()
    .required('Username is required')
    .matches(/^[a-zA-Z][a-zA-Z ]*$/, 'Please enter valid alphabets')
    .min(2, 'Name is too short')
    .max(120, 'You have exceeded the max character length'),
  phone: Yup.string()
    .matches(/^[0-9]\d{9}$/, {
      message: 'Please enter valid number',
      excludeEmptyString: false,
    })
    .max(10, 'You have exceeded the max character length')
    .notRequired(),
  type: Yup.array().required(),
  roless: Yup.array().required(),
});
