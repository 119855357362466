import styled from '@emotion/styled';
import React from 'react';
import {TiWarning} from 'react-icons/ti';

import Tooltip from '../../../components/reusable/ToolTip/ToolTip';

const ActionButtonDropDownContentInApprove = (props) => {
  const {status, submitApproval, isShowPublish} = props;

  const returnMessage = (status) => {
    if (status === 'orderable') {
      return 'This album has already been published';
    } else if (!isShowPublish) {
      return 'Tracks must be approved';
    } else {
      return null;
    }
  };

  return (
    <ActionContainer isDisabled={status === 'orderable' || !isShowPublish}>
      <ul>
        <li>
          <button
            className="new-relase-btn"
            disabled={status === 'orderable' || !isShowPublish}
            onClick={() => {
              if (status === 'orderable' || !isShowPublish) {
                return null;
              } else {
                submitApproval();
              }
            }}
          >
            {returnMessage(status) && (
              <Tooltip
                isOpen={true}
                content={returnMessage(status)}
                direction="left"
              >
                <div className="info">
                  <TiWarning />
                </div>
              </Tooltip>
            )}

            <div className="new-release-text">Publish Product</div>
          </button>
        </li>
      </ul>
    </ActionContainer>
  );
};

const ActionContainer = styled.div<{isDisabled}>`
  background-color: white;
  border-radius: 5px;
  .new-relase-btn {
    display: flex;
    align-items: center;
    background-color: white;
    cursor: pointer;

    background-color: ${(props) => (props.isDisabled ? 'whitesmoke' : 'white')};
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
    .new-release-text {
      opacity: ${(props) => (props.isDisabled ? '0.5' : '1')};
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: center;
      color: hsl(11deg 100% 62%);

      svg {
        margin-right: 5px;
        font-size: 18px;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    flex-direction: column;
    border-radius: 5px;

    li {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      + li {
        border-top: 1px solid #f1f1f1;
      }
    }
    button {
      border-radius: 5px;
      border: none;
      cursor: pointer;
      background-color: white;
      padding: 12px;
      font-size: 14px;
      color: #333;
      width: inherit;
      display: flex;
      &:hover {
        background-color: #f4f4fb;
      }
    }
  }
`;

export default ActionButtonDropDownContentInApprove;
