import styled from '@emotion/styled';
import React from 'react';
import {useNavigate} from 'react-router';

import {TAGS} from '../../../constants/tags';
import useGetSingleProject from '../../../hooks/Project/useGetSingleProject';
import useCheckForAdmin from '../../../hooks/reusable/useCheckForAdmin';
import Button from '../../reusable/Button/Button';
import FileUploadDetailComp from './FileUploadDetailComp';
import FooterComponent from './FooterComp';
import SummaryComp from './SummaryComp';

function WholeSummary(props) {
  const {project, setIsOpen, setDynamicTag, currentUser} = props;
  const navigate = useNavigate();

  const {getSingleProject} = useGetSingleProject({id: project.id});
  const {checkForAdmin} = useCheckForAdmin();

  return (
    <div>
      <Container>
        <SummaryComp data={getSingleProject} />
        {getSingleProject?.Album.map((item) => {
          return <FileUploadDetailComp item={item} />;
        })}
      </Container>

      <Container2>
        {/* <button onClick={()=> setIsOpen(false)}>Done</button> */}
        {checkForAdmin() ? (
          <Button
            label="Go to Packager"
            onClick={() => {
              navigate(`/bulk-admin/${project.id}`);
              setIsOpen(false);
              setDynamicTag(TAGS.ALBUMS);
            }}
          />
        ) : (
          <Button
            label="Done"
            onClick={() => {
              setIsOpen(false);
              setDynamicTag(TAGS.ALBUMS);
            }}
          />
        )}
      </Container2>
    </div>
  );
}
const Container = styled.div``;
const Container2 = styled.div`
  background: #f8f8f8;
  width: 100%;
  height: 70px;
  margin-left: -30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 1;
  button {
    // width: 80px;
    // height: 35px;
    margin-right: 20px;
    background: #d21919;
    border-radius: 100px;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
  }
`;
export default WholeSummary;
