import moment from 'moment';
import React, {useContext, useEffect} from 'react';
import {usePaginatedQuery, useQuery} from 'react-query';

import axios from '../../config/Axios';
import {GlobalContext} from '../../Contexts/GlobalProvider';
import {logError} from '../../errorlogger/logError';
import usePaginatedData from '../reusable/usePaginatedData';

const fetchOrders = async (
  dsp_name,
  upc,
  order_id,
  start_date,
  endDate,
  order_status,
  productSearch,
  page,
  limit,
  orderType,
  type,
) => {
  let API;
  if (type === 'single') {
    API = `/order/filter/page?${orderType}${dsp_name}${upc}${order_id}${start_date}${endDate}${order_status}${productSearch}&page=${page}&limit=${limit}`;
  } else {
    API = `/order/filter/page?${orderType}${dsp_name}${upc}${order_id}${start_date}${endDate}${order_status}&page=${page}&limit=${limit}`;
  }
  try {
    // if (order_status.length > 0) {
    const res = await axios(API, {
      method: 'GET',
    });

    return res.data?.data;
    // }
  } catch (error) {
    logError(error, 4);
    return error.response?.data;
  }
};

const useOrderManagementFilter = (props) => {
  const {str, searchString, type} = props;
  const {state, dispatch}: any = useContext(GlobalContext);
  const values = {
    selectedDsp: state?.selectedDsp,
    upc: state?.upc_id,
    order_id: state?.order_id,
    startDate: state?.order_start_date,
    endDate: state?.order_end_date,
    order_status: state?.order_status,
    type: type,
  };
  // const dsp_name = state?.selectedDsp?.length>0 ? `dsp_name=${state?.selectedDsp?.map(element => element).join(",")}` : ""
  const dsp_name =
    state?.selectedDsp?.length > 0
      ? state?.selectedDsp
          ?.map((element) => {
            const dsp = `dsp_name=${element}`;
            return dsp;
          })
          .join('&')
      : '';
  const upc =
    state?.upc_id?.length > 0
      ? state?.upc_id
          ?.map((element) => {
            const upc_id = `&upc=${element}`;
            return upc_id;
          })
          .join('&')
      : '';
  const order_id =
    state?.order_id?.length > 0
      ? state?.order_id
          ?.map((element) => {
            const order = `&order_name=${element}`;
            return order;
          })
          .join('&')
      : '';
  // const order_status =
  //   state?.order_status?.length > 0
  //     ? state?.order_status
  //         ?.map((element) => {
  //           const orderStatus = `&order_status=${element}`;
  //           return orderStatus;
  //         })
  //         .join('&')
  //     : '';
  const order_status =
    state?.order_status?.length > 0
      ? `&order_status=${state?.order_status
          ?.map((element) => element)
          .join(',')}`
      : '';
  const start_date = !state?.order_start_date
    ? ''
    : state?.order_start_date
    ? `&start_date=${state?.order_start_date}`
    : state?.order_start_date === state?.order_end_date
    ? `&start_date=${state?.order_start_date}`
    : '';
  const endDate = !state?.order_end_date
    ? ''
    : state?.order_start_date === state?.order_end_date
    ? ''
    : `&end_date=${state?.order_end_date}`;
  // const returnReleaseDate = () => {
  //   if (startDate === undefined) {
  //     return '';
  //   } else {
  //     return `&start_date=${state?.order_start_date}`;
  //   }
  // };
  // const start_date = returnReleaseDate();
  const productSearch =
    searchString?.length > 1 ? `&album_name=${searchString}` : '';

  const orderType = `&type=${type}&`;
  // console.log('startDate', start_date);
  // console.log("endDate",endDate)
  // console.log("productSearch",productSearch)

  const {
    page,
    setPage,
    data,
    isLastPage,
    totalCount,
    rangeA,
    rangeB,
    lastPage,
    limit,
    setLimit,
    setPaginationDataFn,
    isCustomLoading,
  } = usePaginatedData();

  const {
    isLoading,
    isError,
    error,
    resolvedData,
    isFetching,
    refetch,
  } = usePaginatedQuery(
    ['order-filter', values, str, page, limit, searchString],
    () =>
      fetchOrders(
        dsp_name,
        upc,
        order_id,
        start_date,
        endDate,
        order_status,
        productSearch,
        page,
        limit,
        orderType,
        type,
      ),
  );

  React.useEffect(() => {
    if (resolvedData) {
      setPaginationDataFn(resolvedData);
    }
  }, [resolvedData]);

  // React.useEffect(() => {
  //   if (sResolvedData) {
  //     setPaginationDataFn(sResolvedData);
  //   }
  //   if (searchStr === '') {
  //     if (resolvedData) {
  //       setPaginationDataFn(resolvedData);
  //     }
  //   }
  // }, [sResolvedData]);

  return {
    isLoading,
    isError,
    error,
    resolvedData,
    isFetching,
    data,
    page,
    setPage,
    isLastPage,
    totalCount,
    rangeA,
    rangeB,
    lastPage,
    limit,
    setLimit,
    isCustomLoading,
    refetch,
  };
};

export default useOrderManagementFilter;
