import styled from '@emotion/styled';
import {Block} from 'baseui/block';
import {StatefulTooltip, TRIGGER_TYPE} from 'baseui/tooltip';
import moment from 'moment';
import React from 'react';
import {FiMoreHorizontal} from 'react-icons/fi';
import {GrLinkNext} from 'react-icons/gr';

import Tooltip from '../ToolTip/ToolTip';

const UserHistoryCardContainer = styled.div`
  display: flex;
  font-size: 14px;
  padding: 20px 0px;
  border-bottom: 1px solid #efe7e7;
  .col1 {
    .dp {
      background-color: grey;
      width: 38px;
      height: 38px;
      border-radius: 100px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 100px;
      }
    }
  }
  .col2 {
    width: 100%;

    .row1 {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .desc-container {
        display: flex;
      }
      .date-container {
        color: #a2a2a2;
        font-size: 12.5px;
      }
    }
  }
  .row2 {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .from,
    .to {
      display: flex;
      background-color: #e7e7e7;
      border-radius: 3px;
      max-width: 50%;
      height: 30px;
      overflow: hidden;
      align-items: center;
      text-transform: capitalize;

      justify-content: space-between;
      padding: 0 10px;
      span {
        color: #000000;
      }
    }
    .arrow {
      margin: 0px 10px;
    }
  }
  .more {
    min-width: 30px;
    height: 19px;
    cursor: pointer;
    .more-child {
      background-color: rgb(0 0 0 / 82%);
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: 3px;
      svg {
        color: white;
        font-size: 20px;
      }
    }
  }
  .adjust {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    padding: 0px !important;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .role-style {
    width: 50%;
  }
`;

const PopOverContent = (props) => {
  const {value} = props;
  return (
    <StatefulTooltip
      content={() => <Block>{value}</Block>}
      focusLock
      returnFocus
      autoFocus
      overrides={{
        Body: {
          style: {
            zIndex: 999,
            maxWidth: '400px',
            textTransform: 'capitalize',
          },
        },
      }}
    >
      <div className="more">
        <div className="more-child">
          <FiMoreHorizontal />
        </div>
      </div>
    </StatefulTooltip>
  );
};

export default function UserUpdateHistoryCard(props) {
  const {userName, field, oldValue, newValue, date, email, moreFields} = props;

  const checkField = (field) => {
    if (moreFields.includes(field)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <UserHistoryCardContainer>
      {/* <div className="col1">
        <div className="dp">
          <img src="https://i.pravatar.cc/300" alt="img" />
        </div>
      </div> */}
      <div className="col2">
        <div className="row1">
          <div className="desc-container">
            <Tooltip isOpen={true} content={email} direction="top">
              <strong>{userName}</strong>
            </Tooltip>
            &nbsp; updated &nbsp;
            <strong
              style={{
                color: '#DC3831',
                textTransform: 'capitalize',
              }}
            >
              {field}
            </strong>
          </div>
          <div className="date-container">
            {moment(date).format('MMMM Do YYYY, h:mm a')}
          </div>
        </div>
        <div className="row2">
          <div className={`from ${checkField(field) && 'role-style'}`}>
            <span className={`${checkField(field) && 'adjust'}`}>
              {oldValue}
            </span>
            {checkField(field) && (
              <div className="roles-tooltip">
                <PopOverContent value={oldValue} />
              </div>
            )}
          </div>
          <div className="arrow">
            <GrLinkNext />
          </div>

          <div className={`to ${checkField(field) && 'role-style'}`}>
            <span className={`${checkField(field) && 'adjust'}`}>
              {newValue}
            </span>

            {checkField(field) && (
              <div className="roles-tooltip">
                <PopOverContent value={newValue} />
              </div>
            )}
          </div>
        </div>
      </div>
    </UserHistoryCardContainer>
  );
}
