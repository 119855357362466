import React, {useState} from 'react';
import {MdAddCircle} from 'react-icons/md';

import {TAGS} from '../../constants/tags';
import useGetDsps from '../../hooks/DSPManagement/useGetDsps';
import useTablePageState from '../../hooks/reusable/useTablePageState';
import SkeletonLoading from '../SkeletonPage';
import {dspColumnMeta} from '../../tableColumns/DSPManagement/tableData';
import Button from '../../components/reusable/Button/Button';
import FlyMenu from '../../components/reusable/FlyMenu/FlyMenu';
import NoRecords from '../../components/reusable/NoRecords/NoRecords';
import Table from '../../components/reusable/Table/TablePagination';

const DSP = () => {
  const [searchStr, setSearchStr] = useState('');
  const {
    isOpen,
    newFormTag,
    selectedData,
    setIsOpen,
    setNewFormTag,
    setSelectedData,
  } = useTablePageState();

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    resolvedData,
    setPage,
    page,
    isLastPage,
    totalCount,
    rangeB,
    rangeA,
    lastPage,
    setLimit,
    limit,
    sError,
    sIsError,
    sIsFetching,
    sIsLoading,
    isCustomLoading,
    refetch,
  } = useGetDsps({searchStr});

  const openNewFormSidebarHandler = () => {
    setIsOpen(true);
    setNewFormTag(true);
  };

  const dsps = React.useMemo(() => data, [data]);
  const columnData = React.useMemo(() => dspColumnMeta, [dspColumnMeta]);

  if (isLoading) {
    return <SkeletonLoading />;
  }

  if (resolvedData?.totalPage === 0) {
    return (
      <NoRecords
        page="dsp"
        btnLabel="Create DSP"
        onClick={openNewFormSidebarHandler}
      />
    );
  }

  return (
    <>
      <Table
        columns={columnData}
        data={dsps ? dsps : []}
        tag={TAGS.DSP}
        setSidebarData={setSelectedData}
        onClickRow={setIsOpen}
        setPage={setPage}
        page={page}
        isLastPage={isLastPage}
        totalCount={totalCount}
        rangeB={rangeB}
        rangeA={rangeA}
        lastPage={lastPage}
        setLimit={setLimit}
        limit={limit}
        resolvedData={resolvedData}
        isLoading={isCustomLoading}
        isFetching={isFetching}
        isError={isError || sIsError}
        error={error}
        sError={sError}
        sIsError={sIsError}
        sIsFetching={sIsFetching}
        sIsLoading={sIsLoading}
        setSearchStr={setSearchStr}
        refetchHandler={refetch}
        actionBtn={
          <Button
            label="Create DSP"
            onClick={openNewFormSidebarHandler}
            shape="round"
            endEnhancer={() => <MdAddCircle />}
          />
        }
      />

      {isOpen && (
        <FlyMenu
          refetch={refetch}
          setSelectedData={setSelectedData}
          selectedData={selectedData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          tag={TAGS.DSP}
          setNewFormTag={setNewFormTag}
          newFormTag={newFormTag}
        />
      )}
    </>
  );
};

export default DSP;
