import React from 'react';
import {StatefulTooltip, TRIGGER_TYPE} from 'baseui/tooltip';
import {Block} from 'baseui/block';

export const albumHistoryTableData = [
  {
    Header: 'Timestamp',
    accessor: 'timestamp',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'ISRC / UPC',
    accessor: 'code',
  },
  {
    Header: 'Previous Value',
    accessor: 'prev',
    Cell: (data) => {
      const length = data?.row?.original?.prev;
      return (
        <StatefulTooltip
          content={() => <Block>{data?.row?.original?.prev}</Block>}
          focusLock
          returnFocus
          autoFocus
          overrides={{
            Body: {
              style: {
                zIndex: length?.length > 25 ? 999 : -1,
                maxWidth: '400px',
                textTransform: 'capitalize',
              },
            },
          }}
        >
          <span>{data?.row?.original?.prev}</span>
        </StatefulTooltip>
      );
    },
  },
  {
    Header: 'Current Value',
    accessor: 'curr',
    Cell: (data) => {
      const length = data?.row?.original?.curr;
      return (
        <StatefulTooltip
          content={() => <Block>{data?.row?.original?.curr}</Block>}
          focusLock
          returnFocus
          autoFocus
          overrides={{
            Body: {
              style: {
                zIndex: length?.length > 25 ? 999 : -1,
                maxWidth: '400px',
                textTransform: 'capitalize',
              },
            },
          }}
        >
          <span>{data?.row?.original?.curr}</span>
        </StatefulTooltip>
      );
    },
  },
  {
    Header: 'Username',
    accessor: 'username',
  },

  {
    Header: 'Email',
    accessor: 'email',
  },
];
