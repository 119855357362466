import {queryCache, useMutation} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const updateAlbumFn = async (request) => {
  const {id, data} = request;
  if (id) {
    try {
      return await axios({
        method: 'PUT',
        url: `/album/update-status/${id}`,
        data: data,
      });
    } catch (error) {
      logError(error, 9);
    }
  }
};

const useUpdateAlbumStatus = () => {
  const [
    updateAlbumStatusMutation,
    {status: albumStatus, isLoading: albumStatusLoading, error: albumError},
  ] = useMutation(updateAlbumFn, {
    onSuccess: () => {
      queryCache.invalidateQueries('album');
      queryCache.invalidateQueries('albums');
      queryCache.invalidateQueries('track');
      queryCache.invalidateQueries('singleAlbum');
    },
  });

  const updateAlbumStatusFn = async (data) => {
    const albumData = {
      id: data.albumId,
      data: {
        status: data.data?.status,
        updated_by: data.data?.updated_by,
      },
    };

    try {
      await updateAlbumStatusMutation(albumData);
    } catch (error) {
      logError(error, 9);
    }
  };

  return {updateAlbumStatusFn, albumStatusLoading};
};

export default useUpdateAlbumStatus;
