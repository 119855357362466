/** @format */
import * as Yup from 'yup';

export const labelDetailsValidationSchema = Yup.object({
  label_name: Yup.string()
    .required('Label name is required')
    .min(2, 'Label name is too short')
    .max(120, 'You have exceeded the max character length'),
  percentage: Yup.string()
    .matches(
      /^((([1-9]([0-9])?)(\.[0-9]{1,2})?)|((100)(\.0{1,2})?)|(0\.(([1-9]([0-9])?)|([0-9][1-9])){1,2})?)$/,
      {
        message:
          'Percentage must be a numerical value & less than or equal to 100',
        excludeEmptyString: false,
      },
    )

    .required('Percentage is required'),
  contact_name: Yup.string()
    // .required('Contact name is required')
    .min(3, 'Contact name is too short')
    .max(120, 'You have exceeded the max character length')
    .notRequired(),
  contact_phone: Yup.string()
    .matches(/^[0-9]\d{9}$/, {
      message: 'Please enter valid number',
      excludeEmptyString: false,
    })
    .max(10, 'You have exceeded the max character length')
    .notRequired(),
  contact_email: Yup.string()
    .email('Invalid email format')
    // .required('Contact email is required')
    .max(120, 'You have exceeded the max character length')
    .notRequired(),
  address: Yup.string()
    .notRequired()
    .min(10, 'Address is too short')
    .max(120, 'You have exceeded the max character length'),
  contract_start_date: Yup.date().notRequired(),
  contract_end_date: Yup.date()
    .min(
      Yup.ref('contract_start_date'),
      'End date must be greater than start date',
    )
    .notRequired(),
  // .required('End date is required'),
  dsps: Yup.array().required('Required'),
});
