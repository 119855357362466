import styled from '@emotion/styled';
import React from 'react';
import {FaCheckCircle, FaInfoCircle} from 'react-icons/fa';
import {IoMdCloseCircle} from 'react-icons/io';
import {FcCancel} from 'react-icons/fc';
import {formatDate, hrFormatDate} from '../../../helpers/covertDate';
import {sortAlphabeticalOrderWithoutCase} from '../../../tableColumns/AlbumsManagement/albumColumnHelper';
import {manualOrderData} from '../../../tableColumns/OrderManagement/orderManagementTable';
import DownloadPackageButton from '../../../components/OrderManagement/DownloadPackageButton';

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    padding: 6px 12px;

    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    svg {
      margin-left: 10px;
    }

    background: ${(props) =>
      props.status === 'Ordered'
        ? '#BDE5F8'
        : props.status === 'In Progress'
        ? '#FEEFB3'
        : props.status === 'Error'
        ? '#FFD2D2'
        : '#DFF2BF'};
    color: ${(props) =>
      props.status === 'Ordered'
        ? '#00529B'
        : props.status === 'In Progress'
        ? '#9F6000'
        : props.status === 'Error'
        ? '#D8000C'
        : '#4F8A10'};
    line-height: 0.25rem;
  }
`;

export const ToolTip = styled.div`
  .tooltip {
    .tooltiptext {
      visibility: hidden;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 2px 8px;
      margin-left: 2px;
      // Position the tooltip
      position: absolute;
      z-index: 1;
    }
    :hover .tooltiptext {
      visibility: visible;
    }
  }
`;
export const StatusBox = (props) => {
  const {value, albumStatus} = props;
  return (
    <>
      <StatusContainer status={value} albumStatus={albumStatus}>
        <span>
          {value}

          {value === 'In Progress' || value === 'Paused' ? (
            <FaInfoCircle />
          ) : value === 'Error' ? (
            <IoMdCloseCircle />
          ) : (
            <FaCheckCircle />
          )}
        </span>
      </StatusContainer>
    </>
  );
};

export const manualOrderColData = [
  {
    Header: manualOrderData.order_id.Header,
    accessor: manualOrderData.order_id.accessor,
  },
  {
    Header: manualOrderData.dsp.Header,
    accessor: manualOrderData.dsp.accessor,
    Cell: (data) => {
      return (
        <span style={{display: 'flex', alignItems: 'center'}}>
          {data?.row?.original?.dsp?.dsp_name}
          {data?.row.original?.dsp?.is_disabled ? (
            <FcCancel style={{marginLeft: '5px'}} />
          ) : null}
        </span>
      );
    },
    sortType: (rowA, rowB) => {
      return sortAlphabeticalOrderWithoutCase(
        rowA.original.dsp?.dsp_name,
        rowB.original.dsp?.dsp_name,
      );
    },
  },
  {
    Header: manualOrderData.upc.Header,
    accessor: manualOrderData.upc.accessor,
    sortType: (rowA, rowB) => {
      return sortAlphabeticalOrderWithoutCase(
        rowA.original.album[0].upc,
        rowB.original.album[0].upc,
      );
    },
    Cell: (data) => {
      // const upc = data?.row?.original?.album?.map((a) => a.upc);

      return <span>{data?.row?.original?.album[0].upc}</span>;
    },
  },
  {
    Header: manualOrderData.product_name.Header,
    accessor: manualOrderData.product_name.accessor,
    sortType: (rowA, rowB) => {
      return sortAlphabeticalOrderWithoutCase(
        rowA.original.album[0].album_name,
        rowB.original.album[0].album_name,
      );
    },
    Cell: (data) => {
      return <span>{data?.row?.original?.album[0]?.album_name}</span>;
    },
  },
  {
    Header: manualOrderData.order_date.Header,
    accessor: manualOrderData.order_date.accessor,
    Cell: (data) => {
      return (
        <span>{data && hrFormatDate(data?.row?.original?.created_at)}</span>
      );
    },
  },
  {
    Header: manualOrderData.last_updated_date.Header,
    accessor: manualOrderData.last_updated_date.accessor,
    Cell: (data) => {
      return (
        <span>
          {data?.row?.original?.updated_at === null
            ? hrFormatDate(data?.row?.original?.created_at)
            : hrFormatDate(data?.row?.original?.updated_at)}
        </span>
      );
    },
  },
  {
    Header: manualOrderData.user_name.Header,
    accessor: manualOrderData.user_name.accessor,
    sortType: (rowA, rowB) => {
      return sortAlphabeticalOrderWithoutCase(
        rowA.original.updated_by?.name,
        rowB.original.updated_by?.name,
      );
    },
    Cell: (data) => {
      return (
        <ToolTip>
          <div className="tooltip">
            {data?.row?.original?.updated_by?.name}
            <span className="tooltiptext">
              {data?.row?.original?.updated_by?.email}
            </span>
          </div>
        </ToolTip>
      );
    },
  },
  {
    Header: manualOrderData.status.Header,
    accessor: manualOrderData.status.accessor,
    Cell: (data) => {
      const renderStatus = (status) => {
        if (status === 0) {
          return 'Ordered';
        } else if (status === 1) {
          return 'In Progress';
        } else if (status === 2) {
          return 'Paused';
        } else if (status === 3) {
          return 'Delivered';
        } else if (status === 4) {
          return 'Error';
        }
      };
      return (
        <StatusBox value={renderStatus(data?.row?.original?.order_status)} />
      );
    },
    sortType: (rowA, rowB) => {
      const renderStatus = (status) => {
        if (status === 0) {
          return 'Ordered';
        } else if (status === 1) {
          return 'In Progress';
        } else if (status === 2) {
          return 'Paused';
        } else if (status === 3) {
          return 'Delivered';
        } else if (status === 4) {
          return 'Error';
        }
      };
      const row1 = renderStatus(rowA.original.order_status);
      const row2 = renderStatus(rowB.original.order_status);
      return sortAlphabeticalOrderWithoutCase(row1, row2);
    },
  },
  {
    Header: manualOrderData.download_btn.Header,
    accessor: manualOrderData.download_btn.accessor,
    Cell: (data) => {
      return <DownloadPackageButton row={data?.row?.original} />;
    },
    width: manualOrderData.download_btn.width,
  },
];
