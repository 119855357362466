import {useMutation} from 'react-query';

import axios from '../../config/Axios';
import useNotification from '../../Contexts/useNotification';
import {logError} from '../../errorlogger/logError';

const retryEmailFn = async (data) => {
  try {
    return await axios(`/dsp/email-delivery`, {
      method: 'POST',
      data: data,
    });
  } catch (error) {
    logError(error, 8);
  }
};

const useRetryEmailFn = () => {
  const {showToast} = useNotification();

  const [retryEmailFnMutation, {status, data, error, isLoading}] = useMutation(
    retryEmailFn,
    {
      onSuccess: () => {
        showToast('Email sent successfully', 'success');
      },
    },
  );

  return {retryEmailFnMutation, isLoading, status, error};
};

export default useRetryEmailFn;
