/** @format */

import styled from '@emotion/styled';
import React from 'react';
import {FaCloudDownloadAlt} from 'react-icons/fa';

import Button from '../../components/reusable/Button/Button';
import {copyToClipboard} from '../../helpers/copyToClipboard';
import {formatDate} from '../../helpers/covertDate';
import {downloadCsv} from '../../helpers/downloadCSV';
import {upcData} from './upcTable';

export const upcColumnMeta = [
  {
    Header: upcData.batch_name.Header,
    accessor: upcData.batch_name.accessor,
    sortType: (rowA, rowB) => {
      return sortAlphabeticalOrderWithoutCase(rowA.original, rowB.original);
    },
  },
  {
    Header: upcData.upc_count.Header,
    accessor: upcData.upc_count.accessor,
    Cell: (data) => {
      return <span>{data.row.original?.upc?.length}</span>;
    },
    sortType: (rowA, rowB) => {
      return sortByUpcCount(rowA.original, rowB.original);
    },
  },
  {
    Header: upcData.created_at.Header,
    accessor: upcData.created_at.accessor,
    Cell: (data) => {
      return <span> {data && formatDate(data.value)} </span>;
    },
  },
  {
    Header: upcData.upc_copy.Header,
    accessor: upcData.upc_copy.accessor,
    Cell: (data) => {
      return (
        <Container>
          <Button
            onClick={() => copyToClipboard('upc', data.value)}
            label="COPY"
            type="button"
            size="mini"
            kind="secondary"
          />
        </Container>
      );
    },
    disableSortBy: true,
  },
  {
    Header: upcData.upc_download.Header,
    accessor: upcData.upc_download.accessor,
    Cell: (data) => {
      return (
        <Container>
          <Button
            onClick={() =>
              downloadCsv(
                'upc',
                data.row.original?.upc,
                data.row.original?.batch_name,
              )
            }
            label="Download"
            type="button"
            size="mini"
            endEnhancer={() => <FaCloudDownloadAlt />}
          />
        </Container>
      );
    },
    disableSortBy: true,
  },
];

export const sortAlphabeticalOrderWithoutCase = (row1, row2) => {
  return row1.batch_name
    .toLowerCase()
    .localeCompare(row2.batch_name.toLowerCase());
};

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const sortByUpcCount = (row1, row2) => {
  let upcCountR1 = parseInt(row1?.upc.length);
  let upcCountR2 = parseInt(row2?.upc.length);

  if (upcCountR1 < upcCountR2) {
    return -1;
  }
  if (upcCountR1 > upcCountR2) {
    return 1;
  }
  return 0;
};
