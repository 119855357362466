import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

export const fetchAllLanguages = async () => {
  try {
    const res = await axios(`/language`, {
      method: 'GET',
    });

    return res.data?.data;
  } catch (error) {
    logError(error, 14);
    return error.response?.data;
  }
};

const useListLanguages = () => {
  const {data: listLanguages} = useQuery('languages', fetchAllLanguages);

  return {
    listLanguages,
  };
};

export default useListLanguages;
