import {useContext} from 'react';
import {useQueryCache} from 'react-query';

import axios from '../../config/Axios';
import {GlobalContext} from '../../Contexts/GlobalProvider';
import {logError} from '../../errorlogger/logError';

export const useRevertUpc = () => {
  const {state, dispatch}: any = useContext(GlobalContext);
  const queryCache = useQueryCache();

  const revertUPCFn = async (upc) => {
    try {
      const res = await axios(`/upc/revert?upc=${upc}`, {
        method: 'GET',
      });
      queryCache.invalidateQueries('upc');
      queryCache.invalidateQueries('upc-batch');
      dispatch({
        type: 'SET_ALBUM_UPDATED',
        payload: {
          isAlbumUpdated: false,
        },
      });
      dispatch({
        type: 'SET_UPC',
        payload: {
          upc: null,
        },
      });

      return res.data;
    } catch (error) {
      logError(error, 8);
      return error.response.data;
    }
  };
  return {revertUPCFn};
};

export default useRevertUpc;
