import {useEffect} from 'react';
import {usePaginatedQuery, useQuery} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const fetchAlbums = async (str, page, limit) => {
  const trimedStr = str.trim();

  try {
    if (trimedStr.length > 1) {
      const res = await axios(
        `/album/filter?value=${trimedStr}&page=${page}&limit=${limit}`,
        {
          method: 'GET',
        },
      );

      return res.data?.data;
    }
  } catch (error) {
    logError(error, 4);
    return error.response?.data;
  }
};

const useSearchAlbums = (props) => {
  const {str, page, limit} = props;

  const {
    isLoading,
    isError,
    error,
    resolvedData,

    isFetching,
  } = usePaginatedQuery(['albums-search', str, page, limit], () =>
    fetchAlbums(str, page, limit),
  );

  return {isLoading, isError, error, resolvedData, isFetching};
};

export default useSearchAlbums;
