import {useEffect} from 'react';
import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const fetchAlbumMetaData = async () => {
  try {
    const res = await axios(`/album/filter_metadata`, {
      method: 'GET',
    });
    return res.data?.data;
  } catch (error) {
    logError(error, 9);
    return error.response?.data;
  }
};

const useGetAlbumFilterMeta = () => {
  //   const albumId = props;
  const {data: filterData} = useQuery('albumFilterData', fetchAlbumMetaData);
  // const {data: filterData, refetch, isError, isFetching, isLoading} = useQuery(
  //   ['albumFilterMetaData'],
  //   fetchAlbumFilterMetaData,
  // );
  return {
    filterData,
  };
};

export default useGetAlbumFilterMeta;
