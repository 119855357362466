import {createBrowserHistory} from 'history';
import React, {useEffect, useMemo, useState} from 'react';
import {Md3DRotation, MdAccessAlarm} from 'react-icons/md';
import {useParams} from 'react-router';

import albumicon from '../../assets/album.svg';
import approvedicon from '../../assets/approved.svg';
import pendingicon from '../../assets/pending.svg';
import trackicon from '../../assets/track.svg';
import {Tab} from '../../components';
import BulkSummaryCard from '../../components/BulkUploadAdminManagement/BulkSummaryCard';
import BulkUploadAdmin from '../../components/BulkUploadAdminManagement/BulkUploadAdmin';
import useGetSingleProject from '../../hooks/Project/useGetSingleProject';
import useLocalStorage from '../../hooks/reusable/useLocalStorage';
import {bulkAdminColumn} from './tableData';
export default function BulkUploadAdminManagement() {
  const [activeKey, setActiveKey] = React.useState<string | number>('0');

  const {projectId} = useParams();

  const {
    getSingleProject,
    isLoading,
    isFetching,
    refetch,
  } = useGetSingleProject({
    id: parseInt(projectId),
  });

  const [pendingAlbums, setPendingAlbums] = useState([]);
  const [manualyApprovedAlbums, setManualyApprovedAlbums] = useState([]);
  const [approvedAlbums, setApprovedAlbums] = useState([]);
  const [tracks, setTracks] = useState([]);

  const [albumExpand, setAlbumExpand] = useState(null);
  const columnData = useMemo(() => bulkAdminColumn, [bulkAdminColumn]);
  const history = createBrowserHistory();
  const backListener = history.listen((location) => {
    if (
      location.action === 'POP' &&
      location.location.pathname === '/bulk-admin'
    ) {
      // Do your stuff
      refetch();
    }
  });
  useEffect(() => {
    backListener();
  }, []);
  useEffect(() => {
    const label_name = getSingleProject?.label?.label_name;
    if (getSingleProject?.Album?.length) {
      const manualApprovedAlbums = getSingleProject?.Album?.filter(
        (item) => item.status === 'orderable' && !item?.is_auto_approved,
      ).map((manualAlbums) => {
        return {
          ...manualAlbums,
          label_name,
        };
      });
      setManualyApprovedAlbums(manualApprovedAlbums);

      const approvedAlbums = getSingleProject?.Album?.filter(
        (item) => item.status === 'orderable' && item?.is_auto_approved,
      ).map((approveAlbum) => {
        return {
          ...approveAlbum,
          label_name,
        };
      });
      setApprovedAlbums(approvedAlbums);

      const pendingAlbums = getSingleProject?.Album?.filter(
        (item) => item.status === 'incomplete' || item.status === 'complete',
      ).map((pendingAlbum) => {
        return {
          ...pendingAlbum,
          label_name,
        };
      });
      setPendingAlbums(pendingAlbums);

      const tracks = getSingleProject?.Album.map(
        (item) => item?.Track?.length,
      ).reduce((accumulator, currentValue) => accumulator + currentValue);

      setTracks(tracks);
      // refetch();
    }
  }, [getSingleProject]);

  const pending = useMemo(() => pendingAlbums, [pendingAlbums]);
  const approved = useMemo(() => approvedAlbums, [approvedAlbums]);
  const manuallyApproved = useMemo(() => manualyApprovedAlbums, [
    manualyApprovedAlbums,
  ]);

  const renderData = (albumExpand) => {
    if (albumExpand === '0') {
      return pending;
    } else if (albumExpand === '1') {
      return manuallyApproved;
    } else if (albumExpand === '2') {
      return approved;
    } else {
      return [];
    }
  };

  const accrodianData = [
    {title: 'Pending Albums', count: pending.length},
    {title: ' Manually Approved Albums', count: manuallyApproved.length},
    {title: 'Auto Approved Albums', count: approved.length},
  ];

  return (
    <>
      <div
        className="status-container"
        style={{display: 'flex', justifyContent: 'space-between'}}
      >
        <BulkSummaryCard
          title="Upload summary"
          section1={{
            value: getSingleProject?.Album?.length,
            label: 'Albums',
            icon: <img src={albumicon} alt="albums-icons" />,
            bg: '#ffd7d7',
          }}
          section2={{
            value: tracks,
            label: 'Tracks',
            icon: <img src={trackicon} alt="tracks-icons" />,
            bg: '#ffd7d7',
          }}
        />
        <BulkSummaryCard
          title="Album status"
          section1={{
            value: approved?.length + manuallyApproved?.length,
            label: 'Approved',
            icon: <img src={approvedicon} alt="approved-icons" />,
            bg: 'rgba(128, 226, 126, 0.7)',
          }}
          section2={{
            value: pending.length,
            label: 'Pending',
            icon: <img src={pendingicon} alt="pending-icons" />,
            bg: 'rgba(255, 176, 76, 0.7)',
          }}
        />
      </div>

      <Tab
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        content={[
          {
            title: `Review`,
            component: (
              <BulkUploadAdmin
                columnData={columnData}
                renderData={renderData}
                accrodianData={accrodianData}
                albumExpand={albumExpand}
                setAlbumExpand={setAlbumExpand}
                isLoading={isLoading}
                isFetching={isFetching}
                pendingAlbums={pending?.length}
              />
            ),
          },
        ]}
      />
    </>
  );
}
