import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const useValidateUpc = () => {
  const validateUPCFn = async (upc) => {
    try {
      const res = await axios(`/upc/validation?upc=${upc}`, {
        method: 'GET',
      });
      return res?.data;
    } catch (error) {
      logError(error, 8);

      return error?.response?.data;
    }
  };

  return {validateUPCFn};
};

export default useValidateUpc;
