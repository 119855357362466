import styled from '@emotion/styled';
import React, {useEffect, useRef} from 'react';
import {IoMdCloseCircle} from 'react-icons/io';

import warning from '../../assets/warning.svg';
import useAddItemsByPressingEnter from '../../hooks/FIlter/useAddItemsByPressingEnter';
const OrderIdFormContainer = styled.div<{screen}>`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: ${(props) => (props.screen === 'expandable' ? '100%' : '596px')};
  border-radius: 5px;
  .form-wrapper {
    overflow: scroll;
    label {
      margin-bottom: 10px;

      font-size: ${(props) =>
        props.screen === 'expandable' ? '16px' : '14px'};
      font-weight: ${(props) =>
        props.screen === 'expandable' ? 'bold' : 'normal'};
      color: #505f7a;
    }
    textarea {
      border-color: #cccccf;
      font-family: 'Sarabun', sans-serif;
      text-transform: uppercase;
      padding: 10px;
      font-size: 14px;
      margin-bottom: 10px;
      display: flex;
      ::placeholder {
        font-size: 12px;
        text-transform: initial;
        color: #cbcbcb;
      }
      &:focus {
        outline-color: #dc3831;
        outline-width: 0.5px;
      }
    }
    display: flex;
    flex-direction: column;
  }

  .no-filter {
    display: flex;
    align-items: center;
    .message {
      color: #cbcbcb;
      font-size: 14px;
      margin-left: 10px;
    }
  }
  .textpill-container {
    display: flex;
    margin-bottom: 10px;
    overflow: scroll;
    flex-wrap: wrap;
    .pill-container {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f7f7f7;
      border: 1px solid #dddddd;
      border-radius: 2px;
      padding: 2.5px 7px;

      margin-right: 10px;
      margin-bottom: 10px;
      font-size: 14px;
      .close {
        cursor: pointer;
        color: #dc3831;
      }
    }
    .text-pill {
      margin-right: 6px;
      text-transform: uppercase;
    }
  }
`;

export default function OrderIdForm(props) {
  const {screen, tag, setDropValue} = props;

  const textAreaRef = useRef(null);

  const {
    onChangeText,
    onRemoveItem,
    onKeyPressHandler,
    value,
    items,
    state,
  } = useAddItemsByPressingEnter({textAreaRef, tag});
  useEffect(() => {
    if (state.order_id?.length > 0) {
      const firstSelectedValue = state.order_id[0];
      const newDrop =
        state.order_id.length > 1
          ? firstSelectedValue + ` & ${state.order_id.length - 1} more`
          : firstSelectedValue;
      setDropValue(newDrop);
    } else {
      setDropValue('Order ID');
    }
  }, [state]);
  return (
    <OrderIdFormContainer screen={screen}>
      <div className="form-wrapper">
        <label htmlFor="upc">Filter by Order ID</label>
        <textarea
          onKeyPress={onKeyPressHandler}
          ref={textAreaRef}
          value={value}
          rows={3}
          cols={20}
          onChange={onChangeText}
          placeholder="Enter / Paste Order ID’s here..."
          autoFocus
        ></textarea>
      </div>
      <div className="textpill-container">
        {state?.order_id?.length > 0 ? (
          state?.order_id.map((item, i) => {
            return (
              <div className="pill-container">
                <div className="text-pill">{item}</div>
                <div onClick={() => onRemoveItem(i)} className="close">
                  <IoMdCloseCircle />
                </div>
              </div>
            );
          })
        ) : (
          <div className="no-filter">
            <img height="45px" src={warning} />
            <span className="message">
              Type or paste the filter values above and press Enter button to
              activate the filter.
            </span>
          </div>
        )}
      </div>
    </OrderIdFormContainer>
  );
}
