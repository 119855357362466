/* eslint-disable react/display-name */
/** @format */

import React from 'react';

import {camelToSentence} from '../../helpers/convertSnakeCaseToUpperCase';
import {formatDate} from '../../helpers/covertDate';
import {
  sortAlphabeticalOrderWithoutCase,
  sortUserNameAlphabeticalOrderWithoutCase,
} from '../AlbumsManagement/albumColumnHelper';
import {roleData} from './roleTable';
import {typeData} from './typeTable';
import {sortAlphabeticalOrder, sortRoleData} from './userColumnHelper';
import {userData} from './userTable';

export const userColumnMeta = [
  {
    Header: userData.name.Header,
    accessor: userData.name.accessor,
    width: 80,
    sortType: (rowA, rowB) => {
      return sortUserNameAlphabeticalOrderWithoutCase(
        rowA.original.name,
        rowB.original.name,
      );
    },
  },
  {
    Header: userData.email.Header,
    accessor: userData.email.accessor,
    width: 80,
  },
  {
    Header: userData.phone.Header,
    accessor: userData.phone.accessor,
    width: 70,
  },
  {
    Header: userData.roles.Header,
    accessor: userData.roles.accessor,
    Cell: ({value}) => {
      return value
        ? value.map((data, i) => {
            return <span key={i}> {camelToSentence(data.name)}, </span>;
          })
        : null;
    },
    width: 250,
    minWidth: 100,
    sortType: (rowA, rowB) => {
      return sortRoleData(rowA.original, rowB.original);
    },
    sortInverted: true,
  },
  {
    Header: userData.type.Header,
    accessor: userData.type.accessor,
    Cell: ({value}) => {
      return <span> {value && value.name} </span>;
    },
    width: 80,
    sortType: (rowA, rowB) => {
      return sortAlphabeticalOrder(rowA.original, rowB.original);
    },
  },
  {
    Header: userData.created_at.Header,
    accessor: userData.created_at.accessor,
    Cell: (data) => {
      return <span> {data && formatDate(data.value)} </span>;
    },
    width: 80,
  },
];

export const typeColumnMeta = [
  {
    Header: typeData.type_name.Header,
    accessor: typeData.type_name.accessor,
    width: 80,
  },
  {
    Header: typeData.roles.Header,
    accessor: typeData.roles.accessor,
    width: 400,
    Cell: ({value}) =>
      value.map((data, i) => {
        return <span key={i}> {camelToSentence(data.name)}, </span>;
      }),
    sortType: (rowA, rowB) => {
      return sortRoleData(rowA.original, rowB.original);
    },
    sortInverted: true,
  },
];

export const roleColumnMeta = [
  {
    Header: roleData.role_name.Header,
    accessor: roleData.role_name.accessor,
    width: 80,
    Cell: ({value}) => camelToSentence(value),
  },
  {
    Header: roleData.description.Header,
    accessor: roleData.description.accessor,
    width: 400,
  },
];
