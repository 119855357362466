import React from 'react';

import {Tab} from '../../../components';
import useCheckForAdmin from '../../../hooks/reusable/useCheckForAdmin';
import AdminUploads from '../../BulkUploadAdminManagement/AdminUploads';
import Albums from './Albums';

const AlbumsManagement = () => {
  const [activeKey, setActiveKey] = React.useState<string | number>('0');
  const {checkForAdmin} = useCheckForAdmin();

  return (
    <>
      <Tab
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        content={
          checkForAdmin()
            ? [
                {
                  title: `Albums`,
                  component: <Albums />,
                },
                {
                  title: `Bulk Uploads`,
                  component: <AdminUploads />,
                },
              ]
            : [
                {
                  title: `Albums`,
                  component: <Albums />,
                },
              ]
        }
      />
    </>
  );
};
export default AlbumsManagement;
