/** @format */

import styled from '@emotion/styled';
import React, {useState} from 'react';

import useNotification from '../../../Contexts/useNotification';
import useUploadMetaFile from '../../../helpers/useUploadMetaFile';
import useModalHook from '../../../hooks/Modal/useModal';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import SuccessAnimation from '../../reusable/SuccessAnimation/SuccessAnimation';
import {SuccessMessage} from '../../reusable/SuccessMessage/SuccessMessage';
import BulkFileUploadNewMultiple from './BulkFileUploadNewMultiple';

const UploadTrack = ({handleNext, project, setEnable}) => {
  const [isComplete, setIsComplete] = useState(false);
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const [files, setFiles] = useState([]);
  const {isOpen, setIsOpen, close} = useModalHook(false);
  const {showToast} = useNotification();
  const [trackCount, setTrackCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const {cancel} = useUploadMetaFile({
    metadata: {
      user_id: currentUser.id,
      action: 'CREATE',
      type: 'bulk-upload',
    },
  });

  const onDropHandler = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length !== 0) {
      setTrackCount(acceptedFiles.length);
      setFiles(acceptedFiles);
      setIsOpen(true);
      return;
    }
    const files_rejected = rejectedFiles.map(({name}) => name);
    files_rejected.length === 1
      ? showToast(
          `The file type "${rejectedFiles[0].type}" is not supported`,
          'warning',
        )
      : showToast(
          `The audio formats for these tracks are not supported - "${files_rejected.join(
            '\n ',
          )}"`,
          'warning',
        );
  };

  const onCancelHandler = () => {
    cancel();
    setProgress(0);
    setMessage('Canceled');
  };

  return (
    <UploadContainer>
      {isComplete ? (
        <SuccessMessage count={trackCount} tag="audio" />
      ) : (
        <BulkFileUploadNewMultiple
          accept="audio/wav,audio/x-wav"
          isMultiple={true}
          onCancel={onCancelHandler}
          onChange={onDropHandler}
          errorMessage={errorMessage}
          isOpen={isOpen}
          close={close}
          files={files}
          setIsComplete={setIsComplete}
          project={project}
          name="audio"
          filetype="WAV"
          setEnable={setEnable}
          setTrackCount={setTrackCount}
        />
      )}
    </UploadContainer>
  );
};

export const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left: -20px;
  .next_button-container {
    display: flex;
    justify-content: flex-end;
    margin: 2rem;
  }
`;

export default UploadTrack;
