import {queryCache, useMutation} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';
import useLocalStorage from '../reusable/useLocalStorage';

const updateTrackFn = async (request) => {
  const {id, data} = request;
  try {
    return await axios({
      method: 'PUT',
      url: `/track/update-status/${id}`,
      data: data,
    });
  } catch (error) {
    logError(error, 4);
  }
};

const updateAlbumFn = async (request) => {
  const {id, data} = request;

  try {
    return await axios({
      method: 'PUT',
      url: `/album/update-status/${id}`,
      data: data,
    });
  } catch (error) {
    logError(error, 4);

    return error.response?.error;
  }
};

const useBulkApproveAlbum = ({data}) => {
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);

  const [
    updateTrackMutation,
    {status: trackStatus, isLoading: trackLoading, error: trackError},
  ] = useMutation(updateTrackFn, {
    onSuccess: () => {
      queryCache.invalidateQueries('track');
      queryCache.invalidateQueries('album');
      queryCache.invalidateQueries('albums');
      queryCache.invalidateQueries('singleAlbum');
    },
  });

  const [
    updateAlbumMutation,
    {status: albumStatus, isLoading: albumLoading, error: albumError},
  ] = useMutation(updateAlbumFn, {
    onSuccess: () => {
      queryCache.invalidateQueries('album');
      queryCache.invalidateQueries('albums');
      queryCache.invalidateQueries('track');
      queryCache.invalidateQueries('singleAlbum');
    },
  });

  const updateStatus = async () => {
    if (data && data.length) {
      const albumId = data[0].album_id;
      const trackData2 = data.flatMap(({status, id}) =>
        !(status === 'approved') ? [{trackId: id}] : [],
      );

      const trackData = {
        status: 'approved',
        updated_by: currentUser.id,
      };
      const albumData = {
        id: albumId,
        data: {
          status: 'orderable',
          updated_by: currentUser.id,
          type: 'bulk',
        },
      };

      try {
        for await (const track of data) {
          const params = {id: track.id, data: trackData};
          const restrack = await updateTrackMutation(params);
        }
        const resalbum = await updateAlbumMutation(albumData);
      } catch (error) {
        logError(error, 4);
      }
    }
  };

  return {updateStatus, albumLoading, trackLoading};
};
export default useBulkApproveAlbum;
