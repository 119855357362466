/** @format */
import * as Yup from 'yup';

export const upcDetailsValidationSchema = Yup.object({
  batch_name: Yup.string()
    .required('Batch Name is required')
    .min(1, 'Please enter a name')
    .max(120, 'You have exceeded the max character length'),
  count: Yup.number()
    .positive()
    .required('Number of UPCs is required')
    .max(9999),
});
