export function removeEmptyArrayFromObj(data) {
  const newData = {};
  Object.keys(data).forEach((property) => {
    if (data[property] && data[property].length > 0) {
      newData[property] = data[property];
    } else {
      return null;
    }
  });
  return newData;
}
