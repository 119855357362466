import {useState} from 'react';

const useAlbumArtModal = () => {
  const [showImgModal, setShowImgModal] = useState(false);

  const onClickImageHandler = () => {
    setShowImgModal(true);
  };

  const onCloseHandler = () => {
    setShowImgModal(false);
  };
  return {
    showImgModal,
    onClickImageHandler,
    onCloseHandler,
  };
};
export default useAlbumArtModal;
