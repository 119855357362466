import useCallDispatch from '../FIlter/useCallDispatch';
import useLocalStorage from './useLocalStorage';

const useS3keyForGroupId = ({projectId}) => {
  // const [projectId, setProjectId] = useLocalStorage('projectId', null);
  const {callDispatch, state} = useCallDispatch();
  // console.log('state',state?.existingProjectId ? state?.existingProjectId : projectId)
  const s3KeyGeneratorForGroup = (path, val1, val2) => {
    if (path) {
      const ppl_uk = `${projectId}/${val1}/${path}/${val2}.csv`;
      const others = `${projectId}/${val1}/${path}/${val2}.xlsx`;
      const fuga = `${projectId}/${
        val1 ? val1 + '/' : ''
      }${path}/Fuga_Metadata.csv`;
      const sony = `${projectId}/${val1}/${path}/Sony_ATV_Metadata.xlsx`;

      switch (path) {
        case 'zip':
          return `${projectId}/${path}/${val1}/${val2}.zip`;
        default:
          return val2 === 'ppl_uk'
            ? ppl_uk
            : val2 === 'sony_atv'
            ? sony
            : val2 === 'fuga'
            ? fuga
            : others;
      }
    }
  };
  return {
    s3KeyGeneratorForGroup,
  };
};

export default useS3keyForGroupId;
