import styled from '@emotion/styled';
import {Button as BaseButton} from 'baseui/button';
import {ModalBody} from 'baseui/modal';
import React, {useEffect, useState} from 'react';
import {AiFillInfoCircle} from 'react-icons/ai';
import {IoMdRefreshCircle} from 'react-icons/io';

import ott from '../../assets/icons/ott.png';
import publisher from '../../assets/icons/publisher.png';
import radio from '../../assets/icons/radio.png';
import {logError} from '../../errorlogger/logError';
import sizeInMb from '../../helpers/sizeInMb';
import useGetAlbumsData from '../../hooks/AlbumDetail/useGetAlbumsData';
import usePreviewLink from '../../hooks/AlbumDetail/usePreviewLink';
import useRetryEmailFn from '../../hooks/DSPManagement/useRetryEmail';
import useRetrySftpFnn from '../../hooks/DSPManagement/useRetrySFTP';
import useLocalStorage from '../../hooks/reusable/useLocalStorage';
import useS3Key from '../../hooks/reusable/useS3key';
import useS3keyForGroupId from '../../hooks/reusable/useS3keyForGroupId';
import ButtonLoading from '../reusable/ButtonLoading/ButtonLoading';
import BaseModal from '../reusable/Modal/BaseModal';

export const ResendBtn = ({sftpLoading, emailLoading, resendHandler, dsp}) => {
  return (
    <div
      onClick={() => resendHandler(dsp.dsp_delivery_type)}
      className="resend-btn"
    >
      {sftpLoading || emailLoading ? (
        <ButtonLoading />
      ) : (
        <>
          <div className="text">Resend</div>
          <IoMdRefreshCircle />
        </>
      )}
    </div>
  );
};

export const ImageComponent = ({src}) => {
  return (
    <div className="img-container">
      <img src={src} width="100%" height="100%" alt="icon" />
    </div>
  );
};

export const DspList = (props) => {
  const {
    dsp,
    s3Key,
    status,
    upc,
    isEmailSendRequired,
    projectId,
    s3KeyGeneratorForGroup,
    groupId,
    mode,
  } = props;
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);

  const {downloadLink} = usePreviewLink({key: s3Key});
  const [zipUrl, setZipUrl] = useState(null);
  const {retrySftpFnMutation, isLoading: sftpLoading} = useRetrySftpFnn();
  const {retryEmailFnMutation, isLoading: emailLoading} = useRetryEmailFn();
  const {s3KeyGenerator} = useS3Key({projectId: projectId});

  useEffect(() => {
    if (downloadLink) {
      setZipUrl(downloadLink);
    }
  }, [downloadLink]);

  const retrySftpHandler = async () => {
    const data = {
      from: 'no_reply@divomusic.com',
      bucketName: 'divo-source-staging',
      fileName: s3Key, // `${project_id}/zip/${album_upc}/${dsp_name}`
      filepath: dsp.file_path, // `/home/ec2-user/${dsp_name}.zip` //this is temporary
      album_id: dsp.album_id,
      sftp_host: dsp.sftp_host, //this is temporary
      sftp_port: dsp.sftp_port, //this is temporary
      sftp_user_name: dsp.sftp_user_name,
      sftp_password: dsp.sftp_password,
      user_email: currentUser.email,
      album_dsp_id: dsp.id,
      dsp_name: dsp.dsp_name,
      album_dsp_email: dsp.dsp_email,
      album_dsp_email_cc: dsp.dsp_email_cc,
    };

    try {
      const res = await retrySftpFnMutation(data);
    } catch (error) {
      logError(error, 8);
    }
  };

  const retryEmailHandler = async () => {
    const data = {
      from: 'no_reply@divomusic.com',
      to: dsp.delivery_email,
      album_dsp_id: dsp.id,
      album_dsp_email_cc: dsp.dsp_email_cc,
      album_id: dsp.album_id,
      dsp_name: dsp.dsp_name,
      dsp_type: dsp.dsp_type,
      file_key: s3Key,
    };

    try {
      const res = await retryEmailFnMutation(data);
    } catch (error) {
      logError(error, 8);
    }
  };

  const renderIcons = (type) => {
    switch (type) {
      case 'ott':
        return <ImageComponent src={ott} />;
      case 'radio':
        return <ImageComponent src={radio} />;
      default:
        return <ImageComponent src={publisher} />;
    }
  };

  const resendHandler = (type) => {
    if (type === 'email') {
      retryEmailHandler();
    } else {
      retrySftpHandler();
    }
  };

  return (
    <>
      {status.length > 0 ? (
        <div className="processing">
          <div className="first_flex">
            {renderIcons(dsp.dsp_type)}
            {dsp.dsp_type === 'publisher' ? null : (
              <div className="method">
                <span className="tiny_text">Method</span>
                <p className="big_text">
                  {dsp?.dsp_delivery_type?.toUpperCase()}
                </p>
              </div>
            )}
            <div className="status">
              <span className="tiny_text">Status</span>

              <div className="big_text">
                <>
                  <span>{dsp?.dsp_name?.toUpperCase()} </span>
                  <span
                    className={
                      dsp.dsp_type === 'publisher' ||
                      status[0].delivery_status === 'completed'
                        ? `success`
                        : status[0].delivery_status === 'pending'
                        ? `warning`
                        : `error`
                    }
                  >
                    {status[0].delivery_status === 'completed' ? (
                      `| The package has been sent succesfully`
                    ) : status[0].delivery_status === 'failed' ? (
                      `| The package delivery has failed`
                    ) : dsp.dsp_type === 'publisher' ? (
                      <PublisherCheck downloadLink={downloadLink} />
                    ) : !isEmailSendRequired &&
                      dsp.dsp_delivery_type === 'email' ? null : (
                      `| Pending Delivery`
                    )}
                  </span>
                </>
              </div>
            </div>
          </div>

          <div className="action-btn-container">
            {dsp.dsp_type !== 'publisher' ? (
              dsp.dsp_delivery_type === 'sftp' ? (
                dsp.delivery_status === 'failed' && (
                  <ResendBtn
                    resendHandler={resendHandler}
                    dsp={dsp}
                    sftpLoading={sftpLoading}
                    emailLoading={emailLoading}
                  />
                )
              ) : !isEmailSendRequired &&
                dsp.dsp_delivery_type === 'email' ? null : (
                <ResendBtn
                  resendHandler={resendHandler}
                  dsp={dsp}
                  sftpLoading={sftpLoading}
                  emailLoading={emailLoading}
                />
              )
            ) : null}
            {dsp.dsp_name === 'Fuga' && (
              <DownloadMetadataBtn
                s3Key={s3KeyGeneratorForGroup(
                  mode === 'manual' ? upc : !groupId ? upc : 'output',
                  mode === 'manual' ? 'output' : groupId ? groupId : 'output',
                  'fuga',
                )}
              />
            )}

            <div className="btn-zip">
              {dsp.dsp_type === 'publisher' ? (
                <a href={zipUrl?.url}>
                  Download Excel |{' '}
                  {isNaN(zipUrl?.ContentLength) ? (
                    <ButtonLoading />
                  ) : (
                    ` ${sizeInMb(zipUrl?.ContentLength)} MB`
                  )}
                </a>
              ) : status[0].zipping_status === 'completed' ? (
                <a href={zipUrl?.url}>
                  Download Package |
                  {isNaN(zipUrl?.ContentLength) ? (
                    <ButtonLoading />
                  ) : (
                    ` ${sizeInMb(zipUrl?.ContentLength)} MB`
                  )}
                </a>
              ) : status[0].zipping_status === 'pending' ? (
                <a href="#">Zipping in progress</a>
              ) : (
                <>
                  <a href="#" style={{marginRight: '5px'}}>
                    Zipping Failed
                  </a>
                  <AiFillInfoCircle color="white" />
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="processing">
          <div className="first_flex">
            {renderIcons(dsp.dsp_type)}
            <div className="method">
              <span className="tiny_text"> Method</span>
              <p className="big_text">{dsp?.delivery_type?.toUpperCase()}</p>
            </div>
            <div className="status">
              <span className="tiny_text">Status</span>
              <p className="big_text error">Data unavailable</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const DownloadMetadataBtn = ({s3Key}) => {
  const {downloadLink} = usePreviewLink({key: s3Key});

  return (
    <a
      className="fuga-btn"
      style={{
        marginRight: '20px',
      }}
      href={downloadLink?.url}
    >
      Download Metadata |{` ${sizeInMb(downloadLink?.ContentLength)} MB`}
    </a>
  );
};

const PublisherCheck = ({downloadLink}) => {
  return (
    <>
      {downloadLink && downloadLink.statusCode === 404 ? (
        <span className="warning">| File is getting ready to download</span>
      ) : (
        <span>| File is ready to be downloaded</span>
      )}
    </>
  );
};

const DownloadZipModal = ({showModal, onCloseHandler, id, sidebarRef}) => {
  const {getAlbumDetail, refetch, isFetching} = useGetAlbumsData({albumId: id});

  const [dsps, setDsps] = useState([]);
  const [albumDspStatus, setAlbumDspStatus] = useState([]);
  const [ordersDsp, setOrderDsp] = useState([]);
  const [isEmailSendRequired, setIsEmailSendRequired] = useState(null);

  const [upc, setUpc] = useState();
  const {s3KeyGenerator} = useS3Key({projectId: getAlbumDetail?.project_id});
  const {s3KeyGeneratorForGroup} = useS3keyForGroupId({
    projectId: getAlbumDetail?.project_id,
  });

  useEffect(() => {
    if (getAlbumDetail && getAlbumDetail?.project) {
      setDsps(getAlbumDetail?.Album_dsp);
      setUpc(getAlbumDetail?.upc);
      setAlbumDspStatus(getAlbumDetail?.Album_dsp);

      setOrderDsp(getAlbumDetail?.Order_management);

      setIsEmailSendRequired(getAlbumDetail?.is_email_send_required);
    }
  }, [getAlbumDetail]);

  const getZipStatus = (id) => {
    const filteredData = ordersDsp.filter((data) => data.dsp_id === id);

    return filteredData;
  };

  const removeUnderScore = (str) => {
    return str.toLowerCase().replace(' ', '_');
  };

  const returnS3Key = (dsp, groupId = null) => {
    // group_albums_id
    if (dsp.dsp_type === 'publisher') {
      if (groupId) {
        return s3KeyGeneratorForGroup(
          'output',
          groupId,
          removeUnderScore(dsp.dsp_name),
        );
      } else {
        return s3KeyGenerator('output', upc, removeUnderScore(dsp.dsp_name));
      }
    } else {
      if (groupId) {
        return s3KeyGeneratorForGroup(
          'zip',
          groupId,
          removeUnderScore(dsp.dsp_name),
        );
      } else {
        return s3KeyGenerator('zip', upc, removeUnderScore(dsp.dsp_name));
      }
    }
  };

  return (
    <BaseModal page="dsp-delivery" isOpen={showModal} close={onCloseHandler}>
      <ModalBody style={{backgroundColor: 'white'}}>
        <Container ref={sidebarRef}>
          <div className="modal_header">DSP Delivery Details</div>
          <div className="refresh-btn">
            <BaseButton
              size="mini"
              onClick={() => {
                refetch();
              }}
              shape="circle"
            >
              <IoMdRefreshCircle
                className={`${isFetching && 'rotating'}`}
                size={20}
              />
            </BaseButton>
          </div>
          {dsps.length &&
            dsps.map((dsp) => (
              <>
                <DspList
                  dsp={dsp}
                  isEmailSendRequired={isEmailSendRequired}
                  s3Key={returnS3Key(dsp, getAlbumDetail?.group_albums_id)}
                  status={getZipStatus(dsp.dsp_id)}
                  upc={upc}
                  projectId={getAlbumDetail?.project_id}
                  groupId={getAlbumDetail?.group_albums_id}
                  s3KeyGeneratorForGroup={s3KeyGeneratorForGroup}
                  mode={getAlbumDetail?.mode_of_creation}
                />
              </>
            ))}
        </Container>
      </ModalBody>
    </BaseModal>
  );
};

const Container = styled.div`
  .modal_header {
    font-weight: 650;
    font-size: 20px;
    margin-bottom: 1.6rem;
  }

  .refresh-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
  .processing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    max-height: 40px;

    .first_flex {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }

    .tiny_text {
      color: #949393;
      font-size: 0.65rem;
    }
    .status {
      margin-left: 1.5rem;
      p {
        color: #229d18;
      }
      .fail {
        color: #dc3831;
      }
    }

    .method {
      margin-left: 1.5rem;
    }

    .big_text {
      margin: 0;
      font-weight: 650;
    }
    .success {
      color: #229d18;
      font-weight: normal;
    }
    .error {
      color: red !important;
      font-weight: normal;
    }

    .warning {
      color: #ff6f00;
      font-weight: normal;
    }

    .img-container {
      height: 60px;
      width: 60px;
      img {
        border-radius: 5px;
      }
    }
  }
  .btn-zip,
  .resend-btn {
    background-color: #dc3831;
    cursor: pointer;
    padding: 6px 11px;
    font-size: 12px;
    border-radius: 25px;
    .text {
      color: white;
    }
    a {
      display: flex;
    }
    .icon-refresh {
      svg {
        margin-top: 5px;
      }
    }
    a {
      color: #fff;
      text-decoration: none;
    }
  }
  .fuga-btn {
    background-color: #dc3831;
    cursor: pointer;
    padding: 6px 11px;
    font-size: 12px;
    border-radius: 25px;
    text-decoration: none;
    color: white;
  }

  .action-btn-container {
    display: flex;
    align-items: center;

    .resend-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      svg {
        margin-left: 5px;
        color: white;
      }
    }
  }

  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .rotating {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      -webkit-animation: rotating 2s linear infinite;
      -moz-animation: rotating 2s linear infinite;
      -ms-animation: rotating 2s linear infinite;
      -o-animation: rotating 2s linear infinite;
      animation: rotating 2s linear infinite;
    }
  }
`;

export default DownloadZipModal;
