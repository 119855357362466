/** @format */

import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Button} from 'baseui/button';
import {Spinner} from 'baseui/spinner';
import {ErrorMessage, Field} from 'formik';
import React, {useState} from 'react';

import useNotification from '../../../Contexts/useNotification';
import {logError} from '../../../errorlogger/logError';
import useValidateIsrc from '../../../hooks/ISRCManagement/useValidateIsrc';
import useShowCodeErrorMessage from '../../../hooks/reusable/useShowCodeErrorMessage';
import useValidateUpc from '../../../hooks/UPCManagement/useValidateUpc';
import DetailsError from './DetailsError';
import {LabelInAlbumCreate} from './DetailsSelect';

export const CustomInputText = (props) => {
  const {validateUPCFn} = useValidateUpc();
  const {validateISRCFn} = useValidateIsrc();

  const [localValue, setLocalValue] = useState(null);

  const {field, ...otherProps} = props;

  const validateUpc = async (upc) => {
    try {
      props.setIsValidateLoading(true);
      const res = await validateUPCFn(upc);

      props.setIsValidateLoading(false);
      return res.data?.status;
    } catch (error) {
      logError(error, 7);
      props.setIsValidateLoading(false);
    }
    props.setIsValidateLoading(false);
  };

  const validateIsrc = async (isrc) => {
    try {
      props.setIsValidateLoading(true);
      const res = await validateISRCFn(isrc);

      props.setIsValidApiCalled(true);
      props.setIsValidateLoading(false);
      return res.data?.status;
    } catch (error) {
      props.setIsValidApiCalled(false);
      logError(error, 7);
    }
    props.setIsValidateLoading(false);
    props.setIsValidApiCalled(false);
  };

  React.useEffect(() => {
    checkValidCode();
  }, [localValue]);

  const checkValidCode = async () => {
    if (localValue) {
      if (props.id === 'upc') {
        if (!props.form?.errors?.upc) {
          let status = await validateUpc(localValue);

          if (status) {
            props.setMessage('UPC has already been used');
          } else {
            props.setMessage(null);
          }
        } else {
          props.setMessage(null);
        }
      }

      if (props.id === 'isrc') {
        if (!props.form?.errors?.isrc) {
          let status = await validateIsrc(localValue);
          if (status) {
            props.setMessage('ISRC has already been used');
          } else {
            props.setMessage(null);
          }
        } else {
          props.setMessage(null);
        }
      }
    }
  };

  const handleBlur = async (e) => {
    let value = e.target?.defaultValue;
    let newValue = value.trim();
    setLocalValue(newValue);
    const {
      field: {name, onBlur},
    } = props;
    onBlur(e);

    // The latest touched and errors aren't available until after the
    // formik's onBlur handler has been called. Setting a timeout allows us
    // to access the proper touched and errors after onBlur event has
    // occurred.
  };

  return (
    <input
      className="floating-field"
      type="text"
      {...field}
      {...otherProps}
      onBlur={handleBlur}
    />
  );
};

const btnOveride = (theme) => {
  return {
    BaseButton: {
      style: {
        // background: theme.tabInactiveBorderColor,
        // display: 'inline-block',
        // color: 'black',
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        background: '#DF3732',
        // margin: '0 0.5rem',
        marginTop: '0 0.5rem',
        marginRight: '0 0.5rem',
        marginLeft: '0 0.5rem',
        marginBottom: '0 0.5rem',
        width: '5rem',
        height: '33px',
        lineHeight: '1.5rem',
        paddingTop: '0.3rem !important',
        paddingBottom: '0.3rem !important',
        paddingLeft: '0.3rem !important',
        paddingRight: '0.3rem !important',
        borderRadius: '0 5px 5px 0',
        borderBottomLeftRadius: '7px',
        borderBottomRightRadius: '7px',
        borderTopRightRadius: '7px',
        borderTopLeftRadius: '7px',
        // fontSize: '0.95rem',
        fontSize: '12px',
        fontWeight: '400',
        boxShadow: '3px 3px 5px 0px rgba(230,230,230, 0.75)',
        ':hover': {
          // color: theme.bg,
          background: '#C7241F',
        },
      },
    },
  };
};

function InputWithButton(props) {
  const [css, theme] = useStyletron();
  const {
    label,
    name,
    initialValue,
    setFieldValue,
    generateCode,
    storeName,
    isRequired,
    setIsValid,
    setIsValidApiCalled,
    ...rest
  } = props;
  const [value, setValue] = useState(initialValue);
  const [generating, setGenerating] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [codeEmpty, setCodeEmpty] = useState(false);
  const [isValidateLoading, setIsValidateLoading] = useState(false);
  const {setMessage, element, message} = useShowCodeErrorMessage();
  const {showToast} = useNotification();

  React.useEffect(() => {
    if (name === 'isrc') {
      if (!message) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [generating]);

  const handleClick = async (value) => {
    setGenerating(true);
    const code = await generateCode();

    if (code === undefined || (code?.error && code.error?.length > 0)) {
      showToast('no code in the system right now', 'error');
      setGenerating(false);
    } else {
      setGenerating(false);
      setDisabled(true);
      setValue(value);
      setFieldValue(name, code.data[0]);
    }
  };

  React.useEffect(() => {
    if (name === 'isrc') {
      if (!message) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  }, [message]);

  const onChangeHandler = (event) => {
    if (name === 'isrc') {
      setIsValidApiCalled(false);
    }
    setValue(event.target.value);
    setMessage(null);
    setFieldValue(name, event.target.value);
    window.localStorage.setItem(storeName, JSON.stringify(event.target.value));
  };

  return (
    <InputWithButton.Container
      borderColor={theme.tabInactiveBorderColor}
      bgColor={theme.inputBg}
      textColor={theme.textColor}
    >
      <div
        style={{pointerEvents: disabled && 'none', opacity: disabled && '0.7'}}
      >
        <div className="input_item">
          <div className="left">
            <label htmlFor={name}>
              <LabelInAlbumCreate
                data={{children: label}}
                isRequired={isRequired}
              />
            </label>
          </div>
          <div className="right upc_code">
            <Field
              id={name}
              name={name}
              value={value}
              onChange={(e) => onChangeHandler(e)}
              {...rest}
              setIsValidateLoading={setIsValidateLoading}
              setMessage={setMessage}
              setIsValidApiCalled={setIsValidApiCalled}
              component={CustomInputText}
            />
            {/* {isValidateLoading && (
              <div className="spinner">
                <Spinner size={26} />
              </div>
            )} */}
            <Button
              disabled={disabled}
              isLoading={generating || isValidateLoading}
              onClick={({value}) => handleClick(value)}
              type="button"
              overrides={btnOveride(theme)}
            >
              Get code
            </Button>
          </div>
        </div>
        <div className="input_item">
          <div className="left"></div>
          <div className="right">
            {element && element}
            {codeEmpty && (
              <DetailsError hideDuration={5000}>
                no code available in the system right now
              </DetailsError>
            )}
            <ErrorMessage name={name} component={DetailsError} />
          </div>
        </div>
      </div>
    </InputWithButton.Container>
  );
}

InputWithButton.Container = styled.div`
  display: flex;
  flex-direction: column;
  .spinner {
    margin-left: 10px;
  }
  .input_item {
    margin: 0.3rem 0.75rem;
    justify-content: center;
    align-items: center;

    .left {
      flex: 1;
      text-align: right;
      label {
        margin: 0;
      }
    }

    .right {
      flex: 2;
    }
  }

  .upc_code {
    display: flex;
    align-items: center;
  }

  label {
    margin: 0 1.5rem;
    font-size: 0.85rem;
    font-weight: 600;
    color: ${(props) => props.textColor};
  }

  input {
    font-size: 0.85rem;
    border-radius: 5px 0 0 5px;
    padding: 0.85rem;
    background: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};
    border: none;
    width: 420px;

    &:focus {
      outline: none;
    }
  }
`;

export default InputWithButton;
