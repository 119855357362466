/** @format */

import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {ErrorMessage, Field} from 'formik';
import React, {useEffect} from 'react';
import {FaInfoCircle} from 'react-icons/fa';

import Tooltip from '../ToolTip/ToolTip';
import DetailsError from './DetailsError';
import {LabelInAlbumCreate} from './DetailsSelect';

function TerritoryRadio(props) {
  const [css, theme] = useStyletron();
  const {
    label,
    name,
    isRequired,
    labelName,
    setFieldValue,
    helpText = null,
    options,
    ...rest
  } = props;

  useEffect(() => {
    if (labelName) {
      setFieldValue(name, labelName);
    }
  }, [labelName]);

  return (
    <RadioList>
      <h3>
        Can this release be sold worldwide
        <span style={{color: 'red'}}>*</span>
      </h3>
      <div className="radio-list">
        <label>
          <Field name={name}>
            {({field}) => {
              return options.map((option) => {
                return (
                  <React.Fragment key={option.key}>
                    <input
                      type="radio"
                      id={option.value}
                      {...field}
                      {...rest}
                      value={option.value}
                      checked={field.value === option.value}
                    />
                    <p>{option.key}</p>
                  </React.Fragment>
                );
              });
            }}
          </Field>
        </label>
      </div>

      <div className="input_item">
        <div className="left"></div>
        <div className="right error">
          <ErrorMessage name={name} component={DetailsError} />
        </div>
      </div>
    </RadioList>
  );
}

const RadioList = styled.div`
  color: #000000;
  padding-left: 20px;
  padding-bottom: 56px;
  h3 {
    font-style: normal;
    font-size: 14px;
    padding-left: 6px;
  }
  .radio-list {
    .tags {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      margin-left: 34px;
      flex-wrap: wrap;
      p {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 10px;
        background: rgba(210, 25, 25, 0.1);
        border-radius: 30px;
        color: #d21919;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        margin-right: 5px;
        margin-bottom: 5px;
      }
      span {
        display: flex;
        padding-left: 5px;
        cursor: pointer;
      }
      .read-more {
        a {
          font-size: 14px;
          border-bottom: 1px solid #666;

          font-color: #ccc;
          color: #666;
        }
      }
    }
    .sold {
      display: flex;
      font-size: 14px;
      font-style: normal;

      align-items: center;
      margin-left: 34px;
      padding-bottom: 20px;
      margin-top: -8px;
      p {
        margin: 0;
      }
      button {
        border: none;
        background: #d21919;
        border-radius: 60.1469px;
        font-size: 12px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        padding: 4px 8px;
        margin-left: 10px;
        cursor: pointer;
        svg {
          font-size: 12px;
          padding-left: 2px;
        }
      }
    }

    label {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      p {
        margin: 0;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        padding-left: 12px;
      }
      input[type='radio'] {
        -webkit-appearance: none;
        width: 13px;
        height: 13px;
        border: 1px solid darkgray;
        border-radius: 50%;
        outline: none;
      }
      input[type='radio']:before {
        content: '';
        display: block;
        width: 70%;
        height: 70%;
        margin: 16% auto;
        border-radius: 50%;
      }
      input[type='radio']:checked:before {
        background: #d21919;
      }
    }
  }
`;

export default TerritoryRadio;
