import styled from '@emotion/styled';
import React, {useContext, useEffect, useState} from 'react';
import {BsChevronDoubleDown} from 'react-icons/bs';
import {CgPushChevronDownO} from 'react-icons/cg';
import {GoMute, GoUnmute} from 'react-icons/go';
import {IoMdPause, IoMdPlay} from 'react-icons/io';
import Slider from 'react-input-slider';
import ReactPlayer from 'react-player/lazy';

import TrackLoadingSpinner from '../../../components/reusable/TrackLoadingSpinner/TrackLoadingSpinner';
import {GlobalContext} from '../../../Contexts/GlobalProvider';
import useGetAlbumsData from '../../../hooks/AlbumDetail/useGetAlbumsData';
import usePreviewLink from '../../../hooks/AlbumDetail/usePreviewLink';
import useMusicPlayer from '../../../hooks/reusable/useMusicPlayer';
import left from './left.svg';
import lable from './picture2.png';
import right from './right.svg';
import sound from './sound.svg';
import coverart_empty from './coverart_empty.svg';

const MusicPlayerNew = (props) => {
  const {
    s3Key,
    isrcCode,
    getAlbumDetail,
    played,
    seeking,
    volume,
    handleSeekMouseDown,
    handleSeekChange,
    handleSeekMouseUp,
    handleVolumeChange,
    playerRef,
    handleStop,
    handlePlayPause,
    setPlayed,
    setCurrentTime,
    msToTime,
    setDuration,
    currentTime,
    duration,
    playing,
    muted,
    handleMute,
    isOpen,
    setCollapsePlayer,
    collapsePlayer,
    changeTrack,
    data,
  } = props;

  const [audioUrl, setAudioUrl] = useState(null);

  const {downloadLink} = usePreviewLink({key: s3Key});

  useEffect(() => {
    if (downloadLink) {
      setAudioUrl(downloadLink);
    }
  }, [downloadLink]);

  return (
    <MusicPlayer isOpen={isOpen} collapsePlayer={collapsePlayer}>
      <div className="img">
        {getAlbumDetail?.cover_art ? (
          <img
            width="80px"
            height="80px"
            alt="cover-art"
            src={getAlbumDetail?.cover_art}
          />
        ) : (
          <img
            width="80px"
            height="80px"
            src={coverart_empty}
            alt="cover-art-empty"
          />
        )}
        <div>
          <div className="cont-1">{data?.track_name}</div>
          <div className="cont-2">
            <span>By</span> {data?.singers?.join(', ')}
          </div>
        </div>
      </div>
      <ReactPlayer
        muted={muted}
        ref={playerRef}
        url={audioUrl?.url}
        onError={(e) => console.log('onError', e)}
        width="0px"
        height="0px"
        playing={playing}
        volume={volume}
        onProgress={(e) => {
          if (!seeking) {
            setPlayed(parseFloat(e.played));
          }
          setCurrentTime(msToTime(e.playedSeconds * 1000));
        }}
        onDuration={(e) => {
          setDuration(msToTime(e * 1000));
        }}
      />
      <div className="player">
        <div className="player-icons">
          <img
            className="prev-icon"
            alt="prev"
            onClick={() => changeTrack('prev')}
            src={left}
          />
          <div className="play" onClick={!duration ? null : handlePlayPause}>
            {!duration ? (
              <TrackLoadingSpinner />
            ) : !playing ? (
              <IoMdPlay />
            ) : (
              <IoMdPause />
            )}
          </div>
          <img
            className="next-icon"
            onClick={() => changeTrack('next')}
            alt="next"
            src={right}
          />
        </div>
        <div className="timer">
          <div className="slider-container">
            <div className="time">{currentTime}</div>
            <div className="slider">
              <Slider
                axis="x"
                x={played * 100}
                onDragStart={handleSeekMouseDown}
                onDragEnd={handleSeekMouseUp}
                //   disabled={!playing}
                onChange={({x}) => handleSeekChange(x / 100)}
                styles={{
                  track: {
                    backgroundColor: '#CBCBCB',
                    height: 6,
                    width: '100%',
                  },
                  active: {
                    backgroundColor: '#DC3831',
                  },
                  thumb: {
                    width: 18,
                    height: 18,
                    backgroundColor: '#DC3831',
                    zIndex: 1,
                    border: 'none',
                    boxShadow: 'none',
                    marginLeft: 10,
                  },
                  disabled: {
                    opacity: 0.8,
                  },
                }}
              />
            </div>
            <div className="time">{duration}</div>
          </div>
        </div>
      </div>

      <div className="soundbar">
        {/* <img src={sound} /> */}
        <div onClick={handleMute} className="mute-icon">
          {muted ? <GoMute /> : <GoUnmute />}
        </div>
        <div className="player-slider">
          <input
            type="range"
            min={0}
            max={1}
            step="any"
            value={volume}
            onChange={handleVolumeChange}
          />
        </div>
      </div>

      <div
        onClick={() => setCollapsePlayer(!collapsePlayer)}
        className="collapse"
      >
        <BsChevronDoubleDown
          style={{
            transform: collapsePlayer ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        />
      </div>
    </MusicPlayer>
  );
};

const MusicPlayer = styled.div`
  width: calc(100%-10px);
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
  display: flex;
  padding: 10px;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0;
  left: 60px;
  right: 0;
  transition: transform 0.6s cubic-bezier(0.07, 0.84, 1, 1);
  transform: ${(props) =>
    props.isOpen
      ? 'translateY(125px)'
      : props.collapsePlayer
      ? 'translateY(100px)'
      : 'translateY(0px)'};

  .img {
    display: flex;
    align-items: center;
    width: 20%;
    img {
      margin-right: 20px;
      filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.3));
    }
    .cont-1 {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
    .cont-2 {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      span {
        color: #868686;
      }
    }
  }
  .player {
    width: 64%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    .player-icons {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .play {
        margin: 0 20px;
        width: 35px;
        height: 35px;
        background: #d21919;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
      }
    }
    .timer {
      width: 100%;
      .slider-container {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .time {
          font-size: 14px;
          margin-top: 4px;
        }
        .slider {
          height: 100%;
          width: 84%;
          .music-data-container {
            width: 100%;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              font-size: 14px;
            }
          }
        }
        .play {
          border: none;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          outline: none;
          border-radius: 50px;
          margin: 0;
          padding: 0;
          background-color: #dc3831;
          cursor: pointer;
          color: white;
          margin-bottom: 15px;
        }
      }
      .progress {
        background: #cbcbcb;
        height: 5px;
        width: 80%;
        border-radius: 50px;
        margin: 0 15px;
        .inner-progress {
          background: #d21919;
          height: 5px;
          width: 100%;
          border-radius: 50px;
          position: relative;
          transition: width 2s;
          span {
            width: 12px;
            height: 12px;
            background: #d21919;
            position: absolute;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 100%;
          }
        }
      }
    }
  }
  .soundbar {
    display: flex;
    align-self: flex-end;
    margin-left: 20px;
    margin-bottom: 8px;
    input[type='range'] {
      -webkit-appearance: none;
      position: relative;
      overflow: hidden;
      height: 6px;
      width: 120px;
      cursor: pointer;
      border-radius: 0;
      border-radius: 50px;

      ::-webkit-slider-runnable-track {
        background: #cbcbcb;
      }
      ::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 6px;
        width: 6px;
        background: #d21919;
        box-shadow: -100vw 0 0 100vw #d21919;
      }
    }

    input {
      width: 50px;
      border-radius: 50px;
    }
  }

  .mute-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    svg {
      color: #868686;
    }
  }
  .player-slider {
    display: flex;
    align-items: contents;
    align-items: center;
  }

  .collapse {
    background: #d21919;
    box-shadow: -2px -2px 10px rgb(0 0 0 / 10%);
    width: 52px;
    height: 25px;
    cursor: pointer;
    position: absolute;
    top: -25px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: white;
      font-size: 20px;
    }
  }
`;

export default MusicPlayerNew;
