import styled from '@emotion/styled';
import React from 'react';

export default function BulkSummaryCard(props) {
  const {title, section1, section2} = props;
  return (
    <BulkSummaryContainer>
      <h1>{title}</h1>
      <div className="card">
        <Part data={section1} />
        <div className="divider"></div>
        <Part data={section2} />
      </div>
    </BulkSummaryContainer>
  );
}

const Part = (props) => {
  const {data} = props;

  return (
    <div className="col">
      <div className="icon" style={{backgroundColor: data.bg}}>
        {data.icon}
      </div>
      <div className="data">
        <div className="heading">{data.value}</div>
        <div className="subheading">{data.label}</div>
      </div>
    </div>
  );
};

const BulkSummaryContainer = styled.div`
  margin-bottom: 30px;
  width: 49%;
  h1 {
    margin-bottom: 10px;
  }
  .card {
    position: relative;
    display: flex;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    align-items: center;
    display: flex;
    height: 120px;
    .divider {
      height: 64%;
      width: 1px;
      background: #dbdbdb;
    }
    .col {
      width: 50%;
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      .icon {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 70px;
        height: 70px;
        border-radius: 100px;
        margin-right: 20px;
        img {
          width: 30px;
        }
      }
      .data {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: flex-start;
        justify-content: center;
        .heading {
          font-weight: bold;
          font-size: 28px;
          color: #333333;
          margin-bottom: 10px;
        }
        .subheading {
          font-size: 20px;

          color: #898989;
        }
      }
    }
  }
`;
