import {Button} from 'baseui/button';
import {ChevronDown} from 'baseui/icon';
import {StatefulMenu} from 'baseui/menu';
import {PLACEMENT, StatefulPopover} from 'baseui/popover';
import React from 'react';

const DownloadTemplateBtn = () => {
  const ITEMS = [{label: 'Film'}, {label: 'Non-Film'}];

  const downloadUrl = (item) => {
    if (item.label === 'Film') {
      window.location.href =
        'https://divo-templates-staging.s3.ap-south-1.amazonaws.com/bulk_upload_master_film_v2.xlsx';
    } else {
      window.location.href =
        'https://divo-templates-staging.s3.ap-south-1.amazonaws.com/bulk_upload_master_non-film_v2.xlsx';
    }
  };
  return (
    <StatefulPopover
      focusLock
      placement={PLACEMENT.bottomRight}
      overrides={{
        Body: {
          style: {
            zIndex: 3,
          },
        },
      }}
      content={({close}) => (
        <StatefulMenu
          items={ITEMS}
          onItemSelect={({item}) => downloadUrl(item)}
          overrides={{
            List: {style: {height: 'auto', width: '138px'}},
          }}
        />
      )}
    >
      <Button
        overrides={{
          Root: {
            style: () => {
              return {
                fontSize: '14px',
                paddingTop: '8px',
                paddingBottom: '8px',
              };
            },
          },
        }}
        shape="pill"
        size="default"
        endEnhancer={() => <ChevronDown size={24} />}
      >
        Download Template
      </Button>
    </StatefulPopover>
  );
};

export default DownloadTemplateBtn;
