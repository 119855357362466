import {useState} from 'react';
import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

export const fetchAllCountries = async () => {
  try {
    const res = await axios(`/country`, {
      method: 'GET',
    });

    return res.data?.data;
  } catch (error) {
    logError(error, 14);
    return error.response?.data;
  }
};

const useGetAllCountries = () => {
  const [countries, setCountries] = useState([]);
  const {data: listCountries, isLoading} = useQuery(
    'countries',
    fetchAllCountries,
    {
      onSuccess: (data) => {
        const flattenedArr = data.flat();
        setCountries(flattenedArr);
      },
    },
  );

  return {
    countries,
    isLoading,
  };
};

export default useGetAllCountries;
