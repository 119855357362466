/** @format */

import React, {useState} from 'react';
import {useNavigate} from 'react-router';

import useNotification from '../../../Contexts/useNotification';
// import FileUploadNewMultiple from '../../../FileUploadNewMultiple';
import useUploadMetaFile from '../../../helpers/useUploadMetaFile';
import useModalHook from '../../../hooks/Modal/useModal';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import {SuccessMessage} from '../../reusable/SuccessMessage/SuccessMessage';
import FileUploadNewMultiple from './FileUploadNewMultiple';
import {MessageContainer, UploadContainer} from './MessageContainer';

const BulkUploadCoverArts = (props) => {
  const {project, setEnable} = props;
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const navigate = useNavigate();
  const [isComplete, setIsComplete] = useState(false);
  const [files, setFiles] = useState([]);
  const {isOpen, setIsOpen, close} = useModalHook(false);
  const {showToast} = useNotification();
  const [trackCount, setTrackCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const {cancel} = useUploadMetaFile({
    metadata: {
      user_id: currentUser.id,
      action: 'CREATE',
      type: 'bulk-upload',
    },
  });

  const onDropHandler = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length !== 0) {
      setTrackCount(acceptedFiles.length);
      setFiles(acceptedFiles);
      setIsOpen(true);
      return;
    }
    const files_rejected = rejectedFiles.map(({name}) => name);
    files_rejected.length === 1
      ? showToast(
          `The file type "${rejectedFiles[0].type}" is not supported`,
          'warning',
        )
      : showToast(
          `The audio formats for these tracks are not supported - "${files_rejected.join(
            '\n ',
          )}"`,
          'warning',
        );
  };

  const onCancelHandler = () => {
    cancel();
    setProgress(0);
    setMessage('Canceled');
  };
  const navigateToAlbum = () => {
    navigate(`/albums`);
  };

  return (
    <UploadContainer>
      {isComplete ? (
        <SuccessMessage count={trackCount} tag="cover" />
      ) : (
        <FileUploadNewMultiple
          accept="image/jpg,image/jpeg"
          isMultiple={true}
          onCancel={onCancelHandler}
          onChange={onDropHandler}
          errorMessage={errorMessage}
          isOpen={isOpen}
          close={close}
          files={files}
          setIsComplete={setIsComplete}
          setEnable={setEnable}
          project={project}
          name="cover-art"
          filetype="JPG"
        />
      )}
    </UploadContainer>
  );
};

export default BulkUploadCoverArts;
