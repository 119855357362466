import styled from '@emotion/styled';
import React from 'react';
import {useEffect} from 'react';
import useHistory from '../../../hooks/History/useHistory';
import useGetUserHistory from '../../../hooks/UserManagement/useGetUserHistory';
import HistorySkeltonLoading from '../../../components/reusable/HistorySkeltonLoading';
import NoRecords from '../../../components/reusable/NoRecords/NoRecords';
import UserCreateHistoryCard from '../../../components/reusable/history/UserCreateHistoryCard';
import UserUpdateHistoryCard from '../../../components/reusable/history/UserUpdateHistoryCard';

const UserHistoryContainer = styled.div``;

export default function UserHistory(props) {
  const {userId} = props;
  const {getUserHistory, isLoading} = useGetUserHistory({id: userId});

  const {formatHistoryData, historyData} = useHistory();

  useEffect(() => {
    formatHistoryData(getUserHistory, 'name');
  }, [getUserHistory]);

  const returnValues = (item, type) => {
    return item.field === 'roles'
      ? item[type].map((val) => val?.name?.replace('_', ' ')).join(', ')
      : item.field === 'type'
      ? item[type]?.name
      : item[type];
  };

  const renderList = (item) => {
    const oldVal = returnValues(item, 'oldValue');
    const newVal = returnValues(item, 'newValue');

    if (item.action === 'UPDATE') {
      return (
        <UserUpdateHistoryCard
          userName={item.userName}
          field={item.field}
          oldValue={oldVal}
          newValue={newVal}
          date={item?.date}
          email={item?.email}
          moreFields={['roles']}
        />
      );
    } else {
      if (item.newValue) {
        return (
          <UserCreateHistoryCard
            userName={item.userName}
            createdValue={item.newValue}
            date={item?.date}
            email={item?.email}
            action={item?.action}
          />
        );
      } else {
        return null;
      }
    }
  };

  if (isLoading) {
    return <HistorySkeltonLoading />;
  }

  return (
    <UserHistoryContainer>
      {historyData.length === 0 || Object.keys(historyData[0]).length === 0 ? (
        <NoRecords />
      ) : (
        historyData.sort((a, b) => b.id - a.id).map((item) => renderList(item))
      )}
    </UserHistoryContainer>
  );
}
