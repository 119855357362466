import styled from '@emotion/styled';
import {StatefulPopover} from 'baseui/popover';
import {PLACEMENT} from 'baseui/toast';
import React, {useEffect, useState} from 'react';
import {FaFileExcel, FaFileUpload} from 'react-icons/fa';
import {MdAddCircle} from 'react-icons/md';
import {useNavigate} from 'react-router';

import {TAGS} from '../../../constants/tags';
import useAlbumAdvanceFilter from '../../../hooks/AlbumsManagement/useAlbumAdvanceFilter';
import useGetAlbumFilterMeta from '../../../hooks/AlbumsManagement/useGetAlbumFilterMeta';
import useGetAlbums from '../../../hooks/AlbumsManagement/useGetAlbums';
import useCallDispatch from '../../../hooks/FIlter/useCallDispatch';
import useOnClickOutside from '../../../hooks/reusable/useOnClickOutside';
import useTablePageState from '../../../hooks/reusable/useTablePageState';
import SkeletonLoading from '../../SkeletonPage';
import {albumsColumnMeta} from '../../../tableColumns/AlbumsManagement/albumsColumnMetaData';
import {Button, NoRecords} from '../../../components/index';
import FlyMenu from '../../../components/reusable/FlyMenu/FlyMenu';
import LoadingMain from '../../../components/reusable/Loading/LoadingMain';
import AlbumNoRecords from '../../../components/reusable/NoRecords/AlbumNoRecords';
import Table from '../../../components/reusable/Table/TablePagination';

const BulkContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  width: 200px !important;
  margin-left: -65px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);

  ul {
    margin: 0;
    padding: 0px;
    display: flex;
    flex-direction: column;
    list-style-type: none;

    li {
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      line-height: 29px;
      &:hover {
        cursor: pointer;
      }
      padding: 15px 15px;
      label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }
      .export-option-container {
        transition: 0.5s all ease-in;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: #333;
        &:hover {
          color: #dc3831;
          transition: none;
        }
        .icon {
          margin-left: 20px;
          display: flex;
          svg {
            font-size: 16px;
          }
        }
      }
      .label {
        font-size: 14px;
        line-height: 19px;
      }
      &:hover {
        background-color: #f4f4fb;
        cursor: pointer;
      }
    }
  }
`;

const BulkComponent = (props) => {
  const {close, setDynamicTag, setIsOpen} = props;

  const exportData = [
    {
      label: 'Bulk Upload',
      key: 'bulk',
      icon: <FaFileExcel />,
    },
    {
      label: 'Manual Upload',
      key: 'manual',
      icon: <FaFileUpload />,
    },
  ];

  const mainActionHandler = (keys) => {
    if (keys === 'bulk') {
      setDynamicTag(TAGS.BULK_UPLOAD);
    } else if (keys === 'manual') {
      setDynamicTag(TAGS.MANUAL_UPLOAD);
    } else {
      setDynamicTag(TAGS.ALBUMS);
    }
    setIsOpen(true);
    close();
  };

  return (
    <BulkContainer>
      <ul>
        {exportData.map((l) => (
          <li
            key={l.key}
            onClick={() => {
              mainActionHandler(l.key);
            }}
            className="list"
          >
            <label>
              <div className="export-option-container">
                <div className="label">{l.label}</div>
                <div className="icon">{l.icon}</div>
              </div>
            </label>
          </li>
        ))}
      </ul>
    </BulkContainer>
  );
};

export default function Albums(props) {
  const [searchStr, setSearchStr] = useState('');
  const [dynamicTag, setDynamicTag] = useState(TAGS.ALBUMS);
  const {callDispatch} = useCallDispatch();
  const {filterData} = useGetAlbumFilterMeta();

  const actors = [];
  const actress = [];
  const directors = [];
  const artist = [];
  const albumNames = [];
  const label = [];
  const [actorsData, setActorsData] = useState([]);
  const [actressData, setActressData] = useState([]);
  const [directorData, setDirectorData] = useState([]);
  const [albumName, setAlbumName] = useState([]);
  const [artistName, setArtistName] = useState([]); //artist is singers
  const [labelName, setLableName] = useState([]);
  const [dataFilter, setDataFilter] = useState('all');
  const [allData, setAllData] = useState({
    actors: [],
    actress: [],
    director: [],
    artist: [],
    album: [],
    label: [],
    status: [],
    product_type: [],
    content_type: [],
  });
  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    resolvedData,
    setPage,
    page,
    isLastPage,
    totalCount,
    rangeB,
    rangeA,
    lastPage,
    setLimit,
    limit,
    sError,
    sIsError,
    sIsFetching,
    sIsLoading,
    isCustomLoading,
    refetch,
  } = useAlbumAdvanceFilter({searchStr, allData, dataFilter});

  useEffect(() => {
    // Specify how to clean up after this effect:
    return function cleanup() {
      callDispatch('CHANGE_UPC_DROP', 'upc_id', []);
      callDispatch('CHANGE_START_DATE_DROP', 'order_start_date', '');
      callDispatch('CHANGE_END_DATE_DROP', 'order_end_date', '');
      callDispatch('CHANGE_START_RELEASE_DATE_DROP', 'start_release_date', '');
      callDispatch('CHANGE_END_RELEASE_DATE_DROP', 'end_release_date', '');
      if (window.location.pathname.indexOf('/albums') === -1) {
        window.localStorage.removeItem('tabArr');
      }
    };
  }, []);

  useEffect(() => {
    filterData?.actors_name?.map((item, i) => {
      actorsData.push({
        id: i,
        value: item,
        isChecked: false,
        key: item,
      });
    });
    // setActorsData(actors);

    filterData?.actress_name?.map((item, i) => {
      actressData.push({
        id: i,
        value: item,
        isChecked: false,
        key: item,
      });
    });
    // setActressData(actress);

    filterData?.music_director?.map((item, i) => {
      directorData.push({
        id: i,
        value: item,
        isChecked: false,
        key: item,
      });
    });
    // setDirectorData(directors);

    filterData?.album_name?.map((item, i) => {
      albumName.push({
        id: i,
        value: item,
        isChecked: false,
        key: item,
      });
    });
    // setAlbumName(albumName);

    filterData?.singers?.map((item, i) => {
      artistName.push({
        id: i,
        value: item,
        isChecked: false,
        key: item,
      });
    });
    // setArtistName(artist);

    filterData?.label_name?.map((item, i) => {
      labelName.push({
        id: i,
        value: item,
        isChecked: false,
        key: item,
      });
    });
    // setLableName(label);
  }, [filterData]);

  const {
    isOpen,
    setNewFormTag,
    newFormTag,
    setIsOpen,
    setSelectedData,
    selectedData,
  } = useTablePageState();

  const albums = React.useMemo(() => data, [data]);
  const columnData = React.useMemo(() => albumsColumnMeta, [albumsColumnMeta]);

  if (isLoading) {
    return <SkeletonLoading />;
  }

  // if (resolvedData?.totalPage === 0) {
  //   return <AlbumNoRecords setDynamicTag={setDynamicTag} setIsOpen={setIsOpen}/>;
  // }

  return (
    <>
      <Table
        columns={columnData}
        data={albums ? albums : []}
        tag={TAGS.ALBUMS}
        setSidebarData={setSelectedData}
        onClickRow={setIsOpen}
        setPage={setPage}
        page={page}
        isLastPage={isLastPage}
        totalCount={totalCount}
        rangeB={rangeB}
        rangeA={rangeA}
        lastPage={lastPage}
        setLimit={setLimit}
        limit={limit}
        resolvedData={resolvedData}
        isLoading={isCustomLoading}
        isFetching={isFetching}
        isError={isError || sIsError}
        error={error}
        sError={sError}
        sIsError={sIsError}
        sIsFetching={sIsFetching}
        sIsLoading={sIsLoading}
        setSearchStr={setSearchStr}
        refetchHandler={refetch}
        actors={actorsData}
        actress={actressData}
        director={directorData}
        albumName={albumName}
        artist={artistName}
        label={labelName}
        setAllData={setAllData}
        allData={allData}
        setDataFilter={setDataFilter}
        dataFilter={dataFilter}
        actionBtn={
          <>
            <StatefulPopover
              dismissOnClickOutside
              focusLock={true}
              placement={PLACEMENT.bottomLeft}
              content={({close}) => (
                <BulkComponent
                  close={close}
                  setDynamicTag={setDynamicTag}
                  setIsOpen={setIsOpen}
                />
              )}
            >
              <div className={`export-btn-container`}>
                <Button
                  label="Create Album"
                  // onClick={mainActionHandler}
                  shape="round"
                  endEnhancer={() => <MdAddCircle />}
                />
              </div>
            </StatefulPopover>
          </>
        }
      />
      {isOpen && (
        <FlyMenu
          refetch={refetch}
          setSelectedData={setSelectedData}
          selectedData={selectedData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setDynamicTag={setDynamicTag}
          tag={dynamicTag}
          setNewFormTag={setNewFormTag}
          newFormTag={newFormTag}
          closeable={dynamicTag === TAGS.ALBUMS ? true : false}
        />
      )}
    </>
  );
}
