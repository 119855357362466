import * as Yup from 'yup';
import {lazy} from 'yup';

// const hostName =  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/;

export const dspManagementValidationSchema = Yup.object({
  dsp_name: Yup.string()
    .required('DSP Name is required')
    .min(2, 'Name is too short')
    .max(120, 'You have exceeded the max character length'),
  dsp_type: Yup.string().required('DSP type is required'),
  content_type: Yup.string().required('Content type is required'),
  point_of_contact: Yup.string()
    .required('Point of Contact is required')
    .min(3, 'Contact is too short')
    .max(120, 'You have exceeded the max character length'),
  email_poc: Yup.array()
    .nullable(false)
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(
      Yup.string()
        .required('Email ID (To) is required')
        .email(({value}) => `${value} is not a valid email`)
        .max(120, 'You have exceeded the max character length'),
    ),
  phone_poc: Yup.string()
    .matches(/^[0-9]\d{9}$/, {
      message: 'Please enter valid number',
      excludeEmptyString: false,
    })
    .max(10, 'You have exceeded the max character length'),
  delivery_type: Yup.string().required('Select delivery type'),
  description: Yup.string()
    .nullable(true)
    .notRequired()
    .max(240, 'You have exceeded the max character length'),
  email_cc: Yup.array()
    .nullable(false)
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(
      Yup.string()
        .required('Email ID (CC) is required')
        .email(({value}) => `${value} is not a valid email`)
        .max(120, 'You have exceeded the max character length'),
    ),
  languages: Yup.array().required('Required'),
});

export const publisherValidationSchema = Yup.object({
  dsp_name: Yup.string()
    .required('Required')
    .min(2, 'Name is too short')
    .max(120, 'You have exceeded the max character length'),
  dsp_type: Yup.string().required('Required'),
  content_type: Yup.string().required('Required'),
  point_of_contact: Yup.string()
    .required('Required')
    .min(3, 'Name is too short')
    .max(120, 'You have exceeded the max character length'),
  email_poc: Yup.array()
    .nullable(false)
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(
      Yup.string()
        .required('Email is required')
        .email(({value}) => `${value} is not a valid email`)
        .max(120, 'You have exceeded the max character length'),
    ),
  phone_poc: Yup.string()
    .matches(/^[0-9]\d{9}$/, {
      message: 'Please enter valid number',
      excludeEmptyString: false,
    })
    .max(10, 'You have exceeded the max character length'),
  description: Yup.string()
    .nullable(true)
    .notRequired()
    .max(240, 'You have exceeded the max character length'),
  email_cc: Yup.array()
    .nullable(false)
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(
      Yup.string()
        .required('Email is required')
        .email(({value}) => `${value} is not a valid email`)
        .max(120, 'You have exceeded the max character length'),
    ),
});

export const emailValidationSchema = Yup.object({
  delivery_email: Yup.array()
    .required()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(
      Yup.string()
        .required('Email ID is required')
        .email(({value}) => `${value} is not a valid email`)
        .max(120, 'You have exceeded the max character length'),
    ),
});

export const sftpValidationSchema = Yup.object({
  sftp_user_name: Yup.string()
    .required('SFTP username is required')
    .min(3, 'Username is too short')
    .max(120, 'You have exceeded the max character length'),
  sftp_password: Yup.string().required('SFTP password required'),
  url_file_upload: Yup.string()
    .required('File upload url required')
    .max(240, 'You have exceeded the max character length'),
  sftp_host: Yup.string()
    .matches(
      /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-@]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9])$/,
      {
        message: 'Please enter valid host.',
        excludeEmptyString: false,
      },
    )
    .required('Host required'),
  sftp_port: Yup.number()
    .positive()
    .required('SFTP Port required')
    .max(9999, 'You have exceeded the max character length'),
});

const returnCorrectValidationSchema = (dsp_type, delivery_type) => {
  if (dsp_type[0].value === 'publisher') {
    return publisherValidationSchema;
  } else {
    if (delivery_type[0].value === 'email') {
      const merged = merge(
        dspManagementValidationSchema,
        emailValidationSchema,
      );

      return merged;
    } else {
      const merged = merge(dspManagementValidationSchema, sftpValidationSchema);
      return merged;
    }
  }
};

export const validationSchema = lazy(({dsp_type, delivery_type}) =>
  returnCorrectValidationSchema(dsp_type, delivery_type),
);

function merge(...schemas) {
  const [first, ...rest] = schemas;

  const merged = rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first,
  );

  return merged;
}
