import styled from '@emotion/styled';
import {Skeleton} from 'baseui/skeleton';
import React from 'react';
import {CgImage} from 'react-icons/cg';
import {AiFillCaretDown} from 'react-icons/ai';

export default function SkeletonLoading() {
  return (
    <TableSkeleton>
      <div className="row part1">
        <div className="side1">
          <div className="img">
            <CgImage />
          </div>
          <div className="content">
            <div className="btn-sts">
              <Skeleton
                overrides={{
                  Root: {
                    style: {
                      borderRadius: '60px',
                      marginRight: '10px',
                    },
                  },
                }}
                width="83px"
                height="34px"
                animation
              />
              <Skeleton
                overrides={{
                  Root: {
                    style: {
                      borderRadius: '60px',
                    },
                  },
                }}
                width="105px"
                height="34px"
                animation
              />
            </div>
            <Skeleton
              overrides={{
                Root: {
                  style: {
                    borderRadius: '60px',
                    marginBottom: '8px',
                  },
                },
              }}
              width="383px"
              height="62px"
              animation
            />
            <Skeleton
              overrides={{
                Root: {
                  style: {
                    borderRadius: '60px',
                    marginBottom: '8px',
                  },
                },
              }}
              width="160px"
              height="21px"
              animation
            />
            <Skeleton
              overrides={{
                Root: {
                  style: {
                    borderRadius: '60px',
                    marginBottom: '8px',
                  },
                },
              }}
              width="160px"
              height="21px"
              animation
            />
            <Skeleton
              overrides={{
                Root: {
                  style: {
                    borderRadius: '60px',
                    marginBottom: '8px',
                  },
                },
              }}
              width="186px"
              height="21px"
              animation
            />
            <button>View / Edit Album</button>
          </div>
        </div>
        <div className="action">
          <button>
            Action <AiFillCaretDown />
          </button>
        </div>
      </div>

      {/* <div className="row row2">
        <Skeleton width="540px" height="38px" animation />
      </div> */}
      <div className="row row3">
        <Skeleton width="100%" height="60px" animation />
      </div>

      <div className="row row4">
        <TableRowSkelton />
        <TableRowSkelton />
        <TableRowSkelton />
        <TableRowSkelton />
        <TableRowSkelton />
        <TableRowSkelton />
        <TableRowSkelton />
        <TableRowSkelton />
      </div>
    </TableSkeleton>
  );
}
const TableSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;

  .part1 {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    .img {
      margin-right: 20px;
      width: 250px;
      height: 250px;
      background: #f4f4f4;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 50px;
      color: #cccccc;
    }
    .content {
      height: 250px;
      .btn-sts {
        display: flex;
        margin-bottom: 5px;
      }

      button {
        background: #f4f4f4;
        border-radius: 300px;
        width: 176px;
        height: 50px;
        color: #cccccc;
        font-size: 16px;
        border: none;
      }
    }
    .side1 {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .row {
    display: flex;
    // align-items: center;
    margin-bottom: 15px;
    .action {
      button {
        width: 100px;
        height: 40px;
        color: #cccccc;
        background: #f4f4f4;
        border-radius: 300px;
        font-size: 18px;
        border: none;
        margin-right: 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
  }
  .row1 {
    justify-content: space-between;
    padding-left: 30px;
    padding-top: 10px;
    padding-right: 30px;
  }
  .row2 {
    justify-content: flex-end;
  }
  .row4 {
    flex-direction: column;
    height: 500px;
    justify-content: space-between;
  }
`;

const TableRowSkelton = () => {
  return (
    <TableRowSkeletonContainer>
      <div className="sub-container">
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
        <Skeleton
          overrides={{
            Root: {
              style: {
                borderRadius: '50px',
              },
            },
          }}
          width="100px"
          height="15px"
          animation
        />
      </div>
    </TableRowSkeletonContainer>
  );
};
const TableRowSkeletonContainer = styled.div`
  display: flex;
  background-color: #fff;
  box-shadow: -4px 2px 10px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
  .sub-container {
    display: flex;
    width: 98%;
    justify-content: space-between;
  }
`;
