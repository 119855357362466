/** @format */

import axios from '../../config/Axios';
import {queryCache, useMutation} from 'react-query';

import {logError} from '../../errorlogger/logError';

const sendRequest = async (data) => {
  try {
    const response = await axios(`/product_unlock_requests`, {
      method: 'POST',
      data,
    });

    return response.data;
  } catch (error) {
    logError(error, 3);
    return error.response.data;
  }
};

const useSendRequest = () => {
  const [
    sendEditRequest,
    {status, data, error, isLoading, isSuccess},
  ] = useMutation(sendRequest);

  return {sendEditRequest, isLoading, status, error};
};

export default useSendRequest;
