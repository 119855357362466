import styled from '@emotion/styled';
import {TriangleLeft, TriangleRight} from 'baseui/icon';
import React, {useEffect, useState} from 'react';

import Button from '../Button/Button';
import MultiSelectBox from './MultiSelectBox';
// import useSelectCheckBox from './useSelectCheckBox';
// import useSelectedDataCheckBox from './useSelectedDataCheckBox';

export default function TransferListMultiSelect(props) {
  const {
    label,
    name,
    options,
    initialValue,
    setFieldValue,
    labelKey,
    valueKey,
    values,
    setFieldTouched,
    ...rest
  } = props;

  const [optionData, setOptionsData] = useState(options);

  const [searchStrRight, setSearchStrRight] = useState('');
  const [searchStrLeft, setSearchStrLeft] = useState('');

  const [filteredOptionData, setFilteredOptionData] = useState(null);
  const [filteredSelectedData, setFilteredSelectedData] = useState(null);

  const [selectAllRight, setSelectAllRight] = useState(false);
  const [isAddEnabled, setIsAddEnabled] = useState(false);
  const [isRemoveEnabled, setIsRemoveEnabled] = useState(false);

  const [selectAllLeft, setSelectAllLeft] = useState(false);

  const [selectedData, setSelectedData] = useState([]);

  useEffect(() => {
    if (initialValue.length) {
      let localOptions = [...options];

      initialValue.forEach((item) => {
        localOptions = localOptions.filter((option) => option.id !== item.id);
      });
      const localInitialValue = initialValue.map((item) => ({
        ...item,
        isChecked: false,
      }));

      setSelectAllRight(false);
      setSelectedData(localInitialValue);

      setOptionsData(localOptions);
    } else {
      setOptionsData(options);
    }
  }, [options, initialValue]);

  useEffect(() => {
    setFieldValue(name, selectedData);
  }, [selectedData, name]);

  useEffect(() => {
    if (searchStrLeft) {
      const localOptionData = optionData.filter((item) => {
        return item?.value?.toLowerCase().includes(searchStrLeft.toLowerCase());
      });

      setFilteredOptionData(localOptionData);
    } else {
      setFilteredOptionData(null);
    }
  }, [searchStrLeft]);

  useEffect(() => {
    if (searchStrRight) {
      const localSelectedData = selectedData.filter((item) =>
        item?.value?.toLowerCase().includes(searchStrRight.toLowerCase()),
      );
      setFilteredSelectedData(localSelectedData);
    } else {
      setFilteredSelectedData(null);
    }
  }, [searchStrRight]);

  const handleAllChecked = (event) => {
    const datas = optionData;

    setSelectAllRight(event.target.checked);
    setIsAddEnabled(event.target.checked);
    setOptionsData(
      datas.map((item) => {
        item.isChecked = event.target.checked;
        return item;
      }),
    );
  };

  const handleCheckbox = (event) => {
    let datas = optionData;
    let checkedOptionsCount = 0;
    datas = datas.map((item) => {
      if (item.value === event.target.value) {
        item.isChecked = event.target.checked;
        if (!isAddEnabled && event.target.checked) {
          setIsAddEnabled(true);
        }
      }
      if (item.isChecked) checkedOptionsCount += 1;
      return item;
    });
    if (!checkedOptionsCount) {
      setIsAddEnabled(false);
    }

    setOptionsData(datas);
  };

  const onAdd = () => {
    const a = optionData.filter((item) => item.isChecked);
    const b = optionData.filter((item) => !item.isChecked);
    const selectedD = [...selectedData, ...a].map((item) => {
      item.isChecked = false;
      return item;
    });
    setFieldTouched(name, true);
    setSelectAllRight(false);
    setSearchStrLeft('');
    setSelectedData(selectedD);
    setOptionsData(b);
    setIsAddEnabled(false);
  };

  const onRemove = () => {
    const a = selectedData.filter((item) => item.isChecked);
    const b = selectedData.filter((item) => !item.isChecked);
    const defaultD = [...optionData, ...a].map((item) => {
      item.isChecked = false;
      return item;
    });
    setFieldTouched(name, true);
    setSearchStrRight('');
    setSelectAllLeft(false);
    setSelectedData(b);
    setOptionsData(defaultD);
    setIsRemoveEnabled(false);
  };

  const handleCheckboxForSelectData = (event) => {
    let datas = selectedData;
    let checkedSelectedCount = 0;
    datas = datas.map((item) => {
      if (item.value === event.target.value) {
        item.isChecked = event.target.checked;
        if (!isRemoveEnabled && event.target.checked) {
          setIsRemoveEnabled(true);
        }
      }
      if (item.isChecked) checkedSelectedCount += 1;
      return item;
    });
    if (!checkedSelectedCount) {
      setIsRemoveEnabled(false);
    }
    setSelectedData(datas);
  };

  const handleAllSelectInSelected = (event) => {
    let datas = selectedData;
    setSelectAllLeft(event.target.checked);
    setIsRemoveEnabled(event.target.checked);

    datas = datas.map((item) => {
      // if (item.value === event.target.value)
      item.isChecked = event.target.checked;
      return item;
    });
    setSelectedData(datas);
  };

  return (
    <Wrapper>
      <label className="label">
        {label}
        <span style={{color: '#dc3832'}}> *</span>
      </label>
      <div className="whole">
        <MultiSelectBox
          data={filteredOptionData ? filteredOptionData : optionData}
          handleCheckbox={handleCheckbox}
          handleAllChecked={handleAllChecked}
          selectAllLeft={selectAllRight}
          searchSet={setSearchStrLeft}
          header={`Available ${name}`}
          searchVal={searchStrLeft}
          isEnabled={!isRemoveEnabled}
        />

        <div className="check-buttons">
          <div className="add-btn">
            <Button
              color="#34A139"
              type="button"
              margin="0.95rem 0"
              label="Add"
              size="mini"
              endEnhancer={() => <TriangleRight size={24} />}
              disabled={!isAddEnabled}
              background="rgba(52, 161, 57, 0.2)"
              onClick={onAdd}
            />
          </div>
          <Button
            color="#DC3831"
            type="button"
            margin="0.95rem 0"
            label="Remove"
            startEnhancer={() => <TriangleLeft size={24} />}
            disabled={!isRemoveEnabled}
            size="mini"
            background="rgba(220, 56, 49, 0.2)"
            onClick={onRemove}
          />
        </div>
        <MultiSelectBox
          data={filteredSelectedData ? filteredSelectedData : selectedData}
          handleCheckbox={handleCheckboxForSelectData}
          handleAllChecked={handleAllSelectInSelected}
          selectAllLeft={selectAllLeft}
          searchSet={setSearchStrRight}
          header={`Selected ${name}`}
          searchVal={searchStrRight}
          isEnabled={!isAddEnabled}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
margin-bottom:15px;
.label {
  margin-bottom: 0.35rem;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
}
    .whole{
          display:flex;
          justify-content:space-between;
          .all-select{
            display: flex;
            flex-direction: column;
            position: sticky;
            top: 34px;
            z-index: 9;
            background: white;
            align-items: center;
            padding: 10px 0px;
            justify-content: center;
            .search{
                display: flex;
                width: 90%;
             input[type='search']{
                width: 100%;
                margin-left: 0px;
                height: 28px;
                border: none;
                margin-bottom: 5px;
                background-color: rgb(239, 241, 243);
                border-radius: 50px;
                padding-left: 10px;
                z-index: 9;
                outline: none;
             }   
            }
            .check-all {
                display: flex;
                width:100%;
                padding-left: 12px;
                .container {
                  border-radius: 5px;
                  display: flex;
                  align-items: center;
                  // display: block;
                  position: relative;
                  padding-left: 30px;
               
                  font-size: 12px;
                  color: #8d8d8d;
                  -webkit-user-select: none;
                  -moz-user-select: none;
                  -ms-user-select: none;
                  user-select: none;
                  input {
                    position: absolute;
                    opacity: 0;
                  
                  }
      
                  /* Create a custom checkbox */
                  .check-mark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 15px;
                    width: 15px;
                    background-color: #eee;
                  }
                }
      
                /* Hide the browser's default checkbox */
                .container input {
                  position: absolute;
                  opacity: 0;
                 
                  height: 0;
                  width: 0;
                }
      
                /* Create a custom checkbox */
                .check-mark {
                  margin-top: 4px;
                  margin-left: 5px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 15px;
                  width: 15px;
                  background-color: #eee;
                  border-radius: 2px;
                  &:after {
                    content: '';
                    left: 5px;
                    top: 3px;
                    width: 3px;
                    height: 6px;
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                }
      
                /* On mouse-over, add a grey background color */
                .container:hover input ~ .check-mark {
                  background-color: #ccc;
                }
      
                /* When the checkbox is checked, add a blue background */
                .container input:checked ~ .check-mark {
                  background-color: #df3732;
                }
      
                /* Create the check-mark/indicator (hidden when not checked) */
                .check-mark:after {
                  content: '';
                  position: absolute;
                  display: none;
                }
      
                /* Show the check-mark when checked */
                .container input:checked ~ .check-mark:after {
                  display: block;
                }
              }
          }
     .check-buttons{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .add-btn{
            button{
                padding: 8px 28px;
                display: flex;
            }
        }
     }
     .disabled {
      filter: grayscale(1);
      cursor: not-allowed !important;
      pointer-events: none;
      opacity:0.6;
    }
     .left{
        height: 300px;
        width: 165px;
        overflow: auto;
        border-radius: 0.75rem;
        background: rgb(255, 255, 255);
        border: 1px solid rgb(234, 228, 228);

        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        ::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
        }
        .disabled {
          filter: grayscale(1);
          cursor: not-allowed;
          pointer-events: none;
        }
        .header{
            background: #de3731;
            padding: 5px 20px;
            color: #fff;
            font-size: 12px;
            position: sticky;
            top: 0;
            z-index: 1;
            display: flex;
    align-items: center;
    justify-content: center;
        }
        .search{
         
          input {
            font-size: 12px;
          }
        }
       
         .options{
            display: flex;
            flex-direction: column;
            height:94%;
            .nodata {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #8d8d8d;
              font-size:12px;
            }
            .container-checkbox {
                // background: #f8f8f8;
                border-bottom: 1px solid #EFEFEF;
                border-top: 1px solid #EFEFEF;
                // border-radius: 5px;
                padding: 5px 34px;
                display: flex;
                align-items: center;
                // display: block;
                position: relative;
                padding-left: 36px;
                margin-top: 1px;
                margin-bottom: 5px;
                cursor: pointer;
                font-size: 12px;
                color: #8d8d8d;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
               
                
                input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                }
                .checkmark:after {
                  content: '';
                  position: absolute;
                  display: none;
                }
              
                .checkmark {
                  margin-top: 10px;
                  margin-left: 12px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 15px;
                  width: 15px;
                  background-color: #eee;
                  border-radius: 2px;
                  &:after {
                    content: '';
                    left: 5px;
                    top: 3px;
                    width: 3px;
                    height: 6px;
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }
                }
                :hover input ~ .checkmark {
                  background-color: #ccc;
                }
                input:checked ~ .checkmark {
                  background-color: #df3732;
                }
                input:checked ~ .checkmark:after {
                  display: block;
                }
                input:checked ~ .option-name {
                  color: #dc3831;
                }
              }
            
            }
          }
         }
     }
      }
      
`;
