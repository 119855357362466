import React from 'react';

import RequestEditAlbums from '../../../components/AlbumsInReview/RequestEditAlbums';
import Tab from '../../../components/reusable/Tab/Tab';
import AlbumsInReview from '../AlbumsReview/AlbumsInReview';

const AlbumsApprovalManagement = () => {
  const [activeKey, setActiveKey] = React.useState<string | number>('0');

  return (
    <>
      <Tab
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        content={[
          {
            title: 'Product Approval',
            component: <AlbumsInReview />,
          },
          {
            title: 'Product Edit Request',
            component: <RequestEditAlbums />,
          },
        ]}
      />
    </>
  );
};

export default AlbumsApprovalManagement;
