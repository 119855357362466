import {useContext} from 'react';
import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {GlobalContext} from '../../Contexts/GlobalProvider';
import {logError} from '../../errorlogger/logError';

const useRevertIsrc = () => {
  const {state, dispatch}: any = useContext(GlobalContext);

  const revertISRCFn = async (isrc) => {
    try {
      const res = await axios(`/isrc/revert?isrc=${isrc}`, {
        method: 'GET',
      });
      dispatch({
        type: 'SET_TRACK_UPDATED',
        payload: {
          isTrackUpdated: false,
        },
      });
      dispatch({
        type: 'SET_ISRC',
        payload: {
          isrc: null,
        },
      });
      return res.data;
    } catch (error) {
      logError(error, 8);

      return error.response.data;
    }
  };

  return {revertISRCFn};
};

export default useRevertIsrc;
