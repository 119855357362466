import styled from '@emotion/styled';
import React, {useEffect, useState} from 'react';

import useCallDispatch from '../../hooks/FIlter/useCallDispatch';

const CheckBoxContainer = styled.div`
  // padding: 10px;
  color: #505f7a;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  // label {
  //   width: 100%;
  //   height: 100%;
  //   display: flex;
  //   align-items: center;

  //   cursor: pointer;
  //   input {
  //     margin-right: 10px;
  //   }
    .container-checkbox {
      display: flex;
      align-items: center;
      position: relative;
      // padding-left: 30px;
      // margin-bottom: 2.5px;
      // margin-top: 2.5px;
      padding-left: 40px;
      justify-content: flex-start;
      padding-top: 11.5px;
      padding-bottom: 11.5px;
      padding-right: 10px;
      cursor: pointer;
      font-size: 12px;
      color: #8d8d8d;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }
      .checkmark {
        margin-left: 13px;
        position: absolute;
        // top: 0;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: #e0e0e0;
        border-radius: 5px;
        &:after {
          content: '';
          left: 5px;
          top: 3px;
          width: 3px;
          height: 6px;
          border: solid white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
      :hover input ~ .checkmark {
        background-color: #ccc;
      }
      input:checked ~ .checkmark {
        background-color: #DC3831;
      }
      input:checked ~ .checkmark:after {
        display: block;
      }
      input:checked ~ .label{
        color: #DC3831;
      }
    }
    .image{
      display:flex;
      margin-right:8px;
      img{
        width:20px;
        height:15px;
      }
    }
  }
`;

const Checkbox = (props) => <input type="checkbox" {...props} />;

export default function StatusCheckBox(props) {
  const {
    setSelectedArray,
    selectedArray,
    selectedId,
    data,
    setSelectedId,
    setDropValue,
  } = props;
  const {callDispatch, state} = useCallDispatch();
  const [checked, setChecked] = useState(
    selectedArray.filter((d) => d.id === data.item?.id)[0]?.checked,
  );
  const status = [];
  useEffect(() => {
    if (state.order_status?.length > 0) {
      state.order_status?.map((state) => {
        if (state === 0) {
          status.push('Ordered');
        } else if (state === 1) {
          status.push('In Progress');
        } else if (state === 2) {
          status.push('Paused');
        } else if (state === 3) {
          status.push('Delivered');
        } else if (state === 4) {
          status.push('Error');
        }
      });
      const firstSelectedValue = status[0];
      const newDrop =
        status?.length > 1
          ? firstSelectedValue + ` & ${status?.length - 1} more`
          : firstSelectedValue;
      setDropValue(newDrop);
    } else {
      setDropValue('Order Status');
    }
  }, [state, status]);

  const onClickCheckbox = (event) => {
    event.preventDefault();

    const check = event.target.checked;
    if (check) {
      const arr = selectedId;
      arr.push(data?.item.id);
      setSelectedId(arr);
      callDispatch('CHANGE_STATUS_DROP', 'order_status', selectedId);
    } else {
      const arr2 = selectedId;
      setSelectedId(arr2.filter((a) => a !== data?.item.id));
      callDispatch(
        'CHANGE_STATUS_DROP',
        'order_status',
        arr2.filter((a) => a !== data?.item.id),
      );
    }
    setChecked(event.target.checked);

    const modifiedData = selectedArray.map((d) => {
      if (d.id === data.item?.id) {
        d.checked = check;
      }
      return d;
    });

    setSelectedArray(modifiedData);
  };

  return (
    <CheckBoxContainer>
      <label className="container-checkbox">
        <Checkbox
          onChange={onClickCheckbox}
          value={data.item?.label}
          checked={checked}
        />
        <span className="checkmark"></span>
        <span className="label">{data.item?.label}</span>
      </label>
    </CheckBoxContainer>
  );
}
