import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import styled from "@emotion/styled";
import { useStyletron } from "baseui";

const Input = (props) => {
	const { label, name, ...rest } = props;
	const [css, theme] = useStyletron();
	return (
		<Input.Container bgColor={theme.inputBg} className="form-control">
			<label>{label}</label>
			<Field id={name} name={name} {...rest} />
			<ErrorMessage component={TextError} name={name} />
		</Input.Container>
	);
};

Input.Container = styled.div`
	font-size: 14px;
	position: relative;
	display: block;
	padding-top: 15px;

	input {
		padding: 15px 0 15px 10px;
		display: block;
		width: 100%;
		border: none;
		font-size: 14px;
		outline: none;
		transition: all ease-in-out 0.5s !important;
		background-color: ${(props) => props.bgColor};

		label {
			position: absolute;
			display: block;
			top: 27px;
			left: 10px;
			transition: all 0.2s ease all !important;
			pointer-events: none;
			cursor: text;
		}
	}
`;

export default Input;
