export const roleData = {
  role_name: {
    Header: 'Role Name',
    accessor: 'name',
  },
  description: {
    Header: 'Description',
    accessor: 'description',
  },
};
