import styled from '@emotion/styled';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {AiOutlineCheckCircle, AiOutlineCloseCircle} from 'react-icons/ai';
import {BiMessageRoundedError} from 'react-icons/bi';
import {useLocation, useParams} from 'react-router';

import {Button, Tab} from '../../../components';
import DownloadZipModal from '../../../components/AlbumsInReview/DownloadZipModal';
import {tracksColumnMeta} from '../../../components/AlbumTracksApproval/tableData';
import FlyMenu from '../../../components/reusable/FlyMenu/FlyMenu';
import LoadingSpinnerTransparent from '../../../components/reusable/LoadingSpinnerTransparent/LoadingSpinnerTransparent';
import {TAGS} from '../../../constants/tags';
import useNotification from '../../../Contexts/useNotification';
import useGetAlbumsData from '../../../hooks/AlbumDetail/useGetAlbumsData';
import useGetSingleProductUnlockRequest from '../../../hooks/AlbumDetail/useGetSingleProductUnlockRequest';
import useStatusUpdateHandler from '../../../hooks/AlbumDetail/useStatusUpdateHandler';
import useProductUnlockRequest from '../../../hooks/AlbumsManagement/useProductUnlockRequest';
import useUpdateAlbum from '../../../hooks/AlbumsManagement/useUpdateAlbumStatus';
import usePublishAlbum from '../../../hooks/AlbumTrackApproval/usePublishAlbum';
import useCallDispatch from '../../../hooks/FIlter/useCallDispatch';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import useMusicPlayer from '../../../hooks/reusable/useMusicPlayer';
import useS3Key from '../../../hooks/reusable/useS3key';
import useTablePageState from '../../../hooks/reusable/useTablePageState';
import AlbumInfo from '../../AlbumsManagment/AlbumDetail/AlbumInfo';
import MusicPlayerNew from '../../AlbumsManagment/AlbumDetail/MusicPlayerNew';
import AlbumHistory from '../../AlbumsManagment/Albums/AlbumHistory/AlbumHistory';
import SkeletonLoading from '../../SkeletonAlbumDetail';
import AlbumApproval from './AlbumApproval';

const Container = styled.div<{hideModal}>`
  display: ${(props) => (props.hideModal ? 'none' : 'flex')};
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e6e2e2;
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        color: #f28300;
        font-size: 25px;
        margin-right: 5px;
      }
    }
    .btn {
      display: flex;
      justify-content: space-between;
      .css-erkj5p {
        margin-right: 10px;
      }
      .approve {
        padding: 12px 22px;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        border: none;
        align-items: center;
        border-radius: 25px;
        outline: none;
        appearance: none;
        background: #31a038;
        margin-right: 10px;
        cursor: pointer;
      }
      .decline {
        padding: 12px 22px;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        border: none;
        align-items: center;
        border-radius: 25px;
        outline: none;
        appearance: none;
        background: #dc3831;
        cursor: pointer;
      }
    }
  }
  .content {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    // line-height: 19px;

    color: #878787;
  }
`;

const EditRequestDetail = () => {
  const {albumId, requestId} = useParams();

  const [activeKey, setActiveKey] = React.useState<string | number>('0');
  const [tracks, setTracks] = useState(null);
  const [trackData, setTrackData] = useState(null);
  const [trackIsrc, setTrackIsrc] = useState(null);
  const [musicDirectors, setMusicDirectors] = useState([]);
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const [projectId, setProjectId] = useLocalStorage('projectId', null);
  const [requestComment, setRequestComment] = useState('');
  const [unlockStatus, setUnlockStatus] = useState('');

  const {getAlbumDetail, refetch, isLoading, isFetching} = useGetAlbumsData({
    albumId: albumId,
  });
  const {
    getProductUnlockRequest,
    refetch: requestRefecth,
    isLoading: requestLoad,
  } = useGetSingleProductUnlockRequest({
    requestId: requestId,
  });
  useEffect(() => {
    if (getProductUnlockRequest) {
      setRequestComment(getProductUnlockRequest[0]?.comment);
      setUnlockStatus(getProductUnlockRequest[0]?.status);
    }
  }, [getProductUnlockRequest]);
  const [collapsePlayer, setCollapsePlayer] = useState(false);

  const {
    publishAlbumMutation,
    isLoading: isPublishAlbumLoading,
    error: publishAlbumError,
  } = usePublishAlbum();

  const {
    updateAlbumMutation,
    isLoading: isUpdateAlbumLoading,
    error: updateAlbumError,
  } = useUpdateAlbum();

  useEffect(() => {
    const md = getAlbumDetail?.Track.map((track) => track?.music_director);
    const mergedArr = Array.prototype.concat.apply([], md);
    const unique = [...new Set(mergedArr)];
    setMusicDirectors(unique);
    setProjectId(getAlbumDetail?.project_id);
  }, [getAlbumDetail]);

  useEffect(() => {
    if (getAlbumDetail) {
      setTracks(getAlbumDetail.Track);
      const d = getAlbumDetail.Track.find(
        (track, i) => track.isrc === trackIsrc,
      );
      setTrackData(d);
    }
  }, [getAlbumDetail, trackIsrc]);

  const {
    status,
    productType,
    updateStatusHandler,
    albumLoading,
    trackLoading,
  } = useStatusUpdateHandler({data: getAlbumDetail?.Track});

  const {
    isOpen,
    setNewFormTag,
    newFormTag,
    setIsOpen,
    setSelectedData,
    selectedData,
  } = useTablePageState();

  const [toggleDspModal, setToggleDspModal] = useState(false);
  const [filter, setFilter] = React.useState([]);

  const {
    played,
    seeking,
    volume,
    handleSeekMouseDown,
    handleSeekChange,
    handleSeekMouseUp,
    handleVolumeChange,
    playerRef,
    handleStop,
    handlePlayPause,
    setPlayed,
    setCurrentTime,
    msToTime,
    setDuration,
    currentTime,
    duration,
    playing,
    handleMute,
    muted,
  } = useMusicPlayer({isrcCode: trackIsrc});
  const {callDispatch} = useCallDispatch();
  const {s3KeyGenerator} = useS3Key({projectId: getAlbumDetail?.project_id});
  const {showToast} = useNotification();
  const [dynamicTag, setDynamicTag] = useState(TAGS.TRACK_APPROVAL);
  const [isAlbumClick, setIsAlbumClick] = useState(false);
  const [published, setPublished] = useState(false);
  const [hideModal, setHideModal] = useState(null);
  const [approveLoad, setApproveLoad] = useState('');

  const publish = async () => {
    // Trigger
    const data = {
      project_id: getAlbumDetail?.project_id,
      album_id: [getAlbumDetail.id],
      group: false,
    };

    const res = await publishAlbumMutation(data);
    if (res?.status === 200) {
      setPublished(true);
    }
  };
  const {
    updateProductUnlockRequestMutation,
    isLoading: productRequestLoading,
    error,
  } = useProductUnlockRequest();
  const handleRequestApproval = async (status) => {
    const data = {
      id: requestId,
      status,
      data: {
        reviewed_by: currentUser?.id,
      },
    };
    const res = await updateProductUnlockRequestMutation(data);
    if (status === 'approve') {
      setApproveLoad('approve');
    } else if (status === 'decline') {
      setApproveLoad('decline');
    }
    if (res.status === 200) {
      refetch();
      requestRefecth();
      if (status === 'approve') {
        showToast('Request is approved', 'success');
      } else if (status === 'decline') {
        showToast('Request is declined', 'error');
      }
    }
    // trigger pipeline
    // if (status === 'approve') {
    //   setApproveLoad(true);
    // } else if (status === 'decline') {
    //   setDeclineLoad(true);
    // }
    // try {
    //   const response = await axios({
    //     method: 'PUT',
    //     url: `/product_unlock_requests/${requestId}/${status}`,
    //     data: {reviewed_by: currentUser?.id},
    //   });
    //   if (response.status === 200) {
    //     refetch();
    //     setHideModal(true);
    //     setDeclineLoad(false);
    //     setApproveLoad(false);
    //     if (status === 'approve') {
    //       showToast('Request is approved', 'success');
    //     } else if (status === 'decline') {
    //       showToast('Request is declined', 'error');
    //     }
    //   }
    //   return response.data;
    // } catch (error) {
    //   return error.response?.data;
    // }
  };
  const submitApproval = async () => {
    const albumData = {
      id: getAlbumDetail?.id,
      data: {
        status: 'approved',
        updated_by: currentUser.id,
      },
    };
    const response = await updateAlbumMutation(albumData);
    // Once the album status is approved, the publish endpoint gets triggered
    if (response.statusCode === 200) {
      publish();
    }
  };

  const content = [
    {
      title: `Track Details`,
      component: (
        <AlbumApproval
          currentUser={currentUser}
          columns={tracksColumnMeta}
          setIsOpen={setIsOpen}
          data={getAlbumDetail?.Track}
          setSelectedData={setSelectedData}
          setNewFormTag={setNewFormTag}
          tag={dynamicTag}
          getAlbumDetail={getAlbumDetail}
          setTrackIsrc={setTrackIsrc}
          handlePlayPause={handlePlayPause}
          playing={playing}
          duration={duration}
          trackIsrc={trackIsrc}
          hasCoverArtValue={getAlbumDetail?.cover_art ? true : false}
          status={status}
          productType={productType}
          setFilter={setFilter}
          isLoading={isLoading}
          refetch={refetch}
        />
      ),
    },
    {
      title: `History`,
      component: <AlbumHistory />,
    },
  ];

  const openAlbumForm = () => {
    if (getAlbumDetail) {
      setIsAlbumClick(true);
      setIsOpen(true);
      setSelectedData({original: getAlbumDetail});
    }
  };

  const changeTrack = (type) => {
    const index = tracks
      .sort((a, b) => {
        return a.track_order - b.track_order;
      })
      .findIndex((track, i) => track.isrc === trackIsrc);
    callDispatch('EXISTING_PROJECT_ID', 'existingProjectId', null);
    if (type === 'next') {
      if (index === tracks.length - 1) {
        setTrackIsrc(tracks[0].isrc);
        setTrackData(tracks[0]);
      } else {
        setTrackIsrc(tracks[index + 1].isrc);
        setTrackData(tracks[index + 1]);
      }
    } else {
      if (index === 0) {
        setTrackIsrc(tracks[tracks.length - 1].isrc);
        setTrackData(tracks[tracks.length - 1]);
      } else {
        setTrackIsrc(tracks[index - 1].isrc);
        setTrackData(tracks[index - 1]);
      }
    }
  };

  const openAddCoverArtHandler = () => {
    setIsOpen(true);
    setNewFormTag('add-coverart');
  };
  if (isLoading) {
    return <SkeletonLoading />;
  }

  return (
    <>
      <Container hideModal={unlockStatus !== 'PENDING'}>
        <div className="header">
          <div className="title">
            {' '}
            <BiMessageRoundedError /> Product edit request{' '}
          </div>
          <div className="btn">
            {/* <button
                className="approve"
                onClick={() => {
                  handleRequestApproval('approve');
                  setHideModal(true);
                }}
              >
                Approve
              </button> */}
            <Button
              // disabled={declineLoad}
              shape="round"
              isLoading={
                approveLoad === 'approve' ? productRequestLoading : false
              }
              label="Approve"
              background="#34A139"
              onClick={() => {
                handleRequestApproval('approve');
              }}
              endEnhancer={() => <AiOutlineCheckCircle size={16} />}
            />
            {/* <button
                className="decline"
                onClick={() => {
                  handleRequestApproval('decline');
                  setHideModal(true);
                }}
              >
                Decline
              </button> */}
            <Button
              // disabled={approveLoad}
              shape="round"
              isLoading={
                approveLoad === 'decline' ? productRequestLoading : false
              }
              label="Decline"
              onClick={() => handleRequestApproval('decline')}
              endEnhancer={() => <AiOutlineCloseCircle size={16} />}
            />
          </div>
        </div>
        <div className="content">{requestComment}</div>
      </Container>

      {(albumLoading ||
        isUpdateAlbumLoading ||
        isPublishAlbumLoading ||
        trackLoading) && <LoadingSpinnerTransparent />}

      <AlbumInfo
        isOpen={isOpen}
        getAlbumDetail={getAlbumDetail}
        musicDirectors={musicDirectors}
        // openFlyMenu={openFlyMenu}
        openAddCoverArtHandler={openAddCoverArtHandler}
        setToggleDspModal={setToggleDspModal}
        updateStatusHandler={updateStatusHandler}
        hasCoverArtValue={getAlbumDetail?.cover_art ? true : false}
        hasNoTracks={getAlbumDetail?.Track?.length === 0 ? true : false}
        // actionDropDownContent={
        //   <ActionButtonDropDownContentInApprove
        //     status={getAlbumDetail?.status}
        //     submitApproval={submitApproval}
        //     isShowPublish={filter?.length === getAlbumDetail?.Track?.length}
        //   />
        // }
        tag={dynamicTag}
        setIsAlbumClick={setIsAlbumClick}
        setIsOpen={setIsOpen}
        openAlbumForm={openAlbumForm}
        published={published}
        refetch={refetch}
      />
      <Tab
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        content={content}
      />

      {isOpen && (
        <FlyMenu
          refetch={refetch}
          setSelectedData={setSelectedData}
          newFormTag={newFormTag}
          selectedData={selectedData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setNewFormTag={setNewFormTag}
          setDynamicTag={setDynamicTag}
          // tag={dynamicTag}
          tag={isAlbumClick ? TAGS.ALBUM_APPROVAL : TAGS.TRACK_APPROVAL}
          setIsAlbumClick={setIsAlbumClick}
        />
      )}
      {toggleDspModal && (
        <DownloadZipModal
          id={getAlbumDetail?.id}
          showModal={toggleDspModal}
          onCloseHandler={() => setToggleDspModal(false)}
          sidebarRef={null}
        />
      )}

      {trackIsrc && (
        <MusicPlayerNew
          played={played}
          seeking={seeking}
          volume={volume}
          handleSeekMouseDown={handleSeekMouseDown}
          handleSeekChange={handleSeekChange}
          handleSeekMouseUp={handleSeekMouseUp}
          handleVolumeChange={handleVolumeChange}
          playerRef={playerRef}
          handleStop={handleStop}
          handlePlayPause={handlePlayPause}
          setPlayed={setPlayed}
          setCurrentTime={setCurrentTime}
          data={trackData}
          msToTime={msToTime}
          setDuration={setDuration}
          currentTime={currentTime}
          duration={duration}
          playing={playing}
          getAlbumDetail={getAlbumDetail}
          s3Key={s3KeyGenerator(
            'audio',
            trackIsrc,
            trackData?.Reused_Track
              ? trackData?.Reused_Track?.album?.project_id
              : getAlbumDetail?.project_id,
          )}
          muted={muted}
          handleMute={handleMute}
          isOpen={isOpen}
          setCollapsePlayer={setCollapsePlayer}
          collapsePlayer={collapsePlayer}
          changeTrack={changeTrack}
        />
      )}
    </>
  );
};
export default EditRequestDetail;
