import styled from '@emotion/styled';
import {StatefulPopover} from 'baseui/popover';
import React, {useState} from 'react';
import {BiChevronDown, BiChevronUp} from 'react-icons/bi';

export default function TabPopOver(props) {
  const {content, name} = props;

  const [isOpen, setIsOpen] = useState(false);
  return (
    <Container isOpen={isOpen}>
      <StatefulPopover
        content={content}
        focusLock
        returnFocus
        renderAll
        onOpen={() => setIsOpen(true)}
        onClose={() => {
          setIsOpen(false);
        }}
        overrides={{
          Inner: {
            style: () => ({
              maxHeight: '300px',
              minWidth: '200px',
              overflow: 'scroll',
              boxShadow: '0px 3px 8px rgb(0 0 0 / 15%)',
              borderRadius: '5px',
            }),
          },
        }}
      >
        <button className="btn">
          {name} {isOpen ? <BiChevronUp /> : <BiChevronDown />}
        </button>
      </StatefulPopover>
    </Container>
  );
}

const Container = styled.div<{isOpen}>`
  button {
    border: none;
    font-size: 13px;
    padding: 10px;
    margin-right: 5px;
    height: 14px;
    border-radius: 50px;
    color: ${(props) => (props.isOpen ? '#dc3831' : '#505f7a')};
    cursor: pointer;
    background: ${(props) =>
      props.isOpen ? 'rgba(220,56,49,0.1)' : '#F4F5F7'};
    padding: 10px;
    display: flex;
    align-items: center;
    svg {
      margin-left: 5px;
    }
  }
`;
