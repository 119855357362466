import styled from '@emotion/styled';
import React, {useEffect, useState} from 'react';
import {IoIosArrowDropdownCircle, IoIosArrowDropupCircle} from 'react-icons/io';
import {useLocation, useNavigate, useParams} from 'react-router';

import {capitalizeFirstLetter} from '../../../../helpers/capitalizeFirstLetter';
import {dateStringFormat} from '../../../../helpers/dateStringFormat';
import {subtractFromTwoArray} from '../../../../helpers/subtractFromTwoArray';
import useAlbumArtModal from '../../../../hooks/AlbumDetail/useAlbumArtModal';
import useGetAllCountries from '../../../../hooks/Countries/useGetAllCountries';
import useLocalStorage from '../../../../hooks/reusable/useLocalStorage';
import useS3Key from '../../../../hooks/reusable/useS3key';
import {COLUMN_LABELS} from '../../../../validation/CreateAlbum/FilmType';
import AlbumArt from '../../../../components/AlbumDetail/AlbumArt';
import AlbumArtModal from '../../../../components/AlbumDetail/AlbumArtModal';
import {territoryOptions} from '../../../../components/CreateProject/AlbumUpload/AlbumDetails/optionData';
// import {filmZone} from '../../CreateProject/AlbumUpload/AlbumDetails/optionData';

const AlbumsInReviewForm = ({selectedData}) => {
  const {albumId} = useParams();

  const navigate = useNavigate();
  const [status, setStatus] = React.useState('');
  const [projectId, setProjectId] = useLocalStorage('projectId', null);
  const [selectedValue, setSelectedValue] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const {
    onClickImageHandler,
    onCloseHandler,
    showImgModal,
  } = useAlbumArtModal();

  // const {listLabels} = useListLabels();
  // const {s3KeyGenerator} = useS3Key();
  const location = useLocation();
  const {countries, isLoading} = useGetAllCountries();

  useEffect(() => {
    if (selectedData?.country && countries.length > 0) {
      if (selectedData?.territory === 'exclude') {
        const getCountries = subtractFromTwoArray(
          countries,
          selectedData.country,
          true,
        );
        setSelectedValue(selectedData?.country);
      } else if (selectedData.territory === 'include') {
        const getCountries = subtractFromTwoArray(
          countries,
          selectedData.country,
          false,
        );
        // const selectedArray = getCountries.map((item)=> { return item.country}).join(",")
        setSelectedValue(getCountries);
      }
    }
  }, [selectedData, countries]);

  const onClickDetail = (project_id) => {
    setProjectId(project_id);
    if (location.pathname.includes('albums')) {
      navigate(`/albums/` + selectedData?.id);
    } else if (location.pathname.includes('album-approval')) {
      navigate(`/album-approval/` + selectedData?.id);
    }
  };

  React.useEffect(() => {
    if (selectedData?.status) {
      setStatus(selectedData?.status);
    }
  }, [selectedData]);

  return (
    <AlbumDetailContainer status={status}>
      <div className="album-art-container">
        <div className="album-art" onClick={onClickImageHandler}>
          <AlbumArt url={selectedData?.cover_art} />
        </div>
      </div>
      <div className="album_details">
        <fieldset>
          <legend>{COLUMN_LABELS.ALBUM_TYPE}</legend>
          <p>
            {selectedData?.album_type?.toLowerCase() === 'film'
              ? 'Film'
              : 'Non-Film'}
          </p>
        </fieldset>
        <fieldset>
          <legend>{COLUMN_LABELS.ALBUM_PRODUCT_TYPE}</legend>
          <p>{capitalizeFirstLetter(selectedData?.product_type)}</p>
        </fieldset>
        <fieldset>
          <legend>{COLUMN_LABELS.ALBUM_NAME}</legend>
          <p>{selectedData?.album_name}</p>
        </fieldset>
        <fieldset>
          <legend>{COLUMN_LABELS.UPC}</legend>
          <p>{selectedData?.upc}</p>
        </fieldset>
        {!!selectedData?.actors_name.length &&
          !!(selectedData?.actors_name[0] !== '') && (
            <fieldset>
              <legend>{COLUMN_LABELS.ACTOR_MALE_NAME}</legend>
              <p>{selectedData?.actors_name.join(', ')}</p>
            </fieldset>
          )}
        {!!selectedData?.actress_name.length &&
          !!(selectedData?.actress_name[0] !== '') && (
            <fieldset>
              <legend>{COLUMN_LABELS.ACTRESS_FEMALE_NAME}</legend>
              <p>{selectedData?.actress_name.join(', ')}</p>
            </fieldset>
          )}
        {!!selectedData?.album_director_name?.length &&
          !!(selectedData?.album_director_name[0] !== '') && (
            <fieldset>
              <legend>Album Director&apos;s Name</legend>
              <p>{selectedData?.album_director_name?.join(', ')}</p>
            </fieldset>
          )}
        {!!selectedData?.album_producer_name?.length &&
          !!(selectedData?.album_producer_name[0] !== '') && (
            <fieldset>
              <legend>Album Producer&apos;s Name</legend>
              <p>{selectedData?.album_producer_name?.join(', ')}</p>
            </fieldset>
          )}
        {!!selectedData?.banner_production_name?.length &&
          !!(selectedData?.banner_production_name[0] !== '') && (
            <fieldset>
              <legend>{COLUMN_LABELS.PRODUCTION_HOUSE_NAME}</legend>
              <p>{selectedData?.banner_production_name?.join(', ')}</p>
            </fieldset>
          )}
        {selectedData?.label_c_name && (
          <fieldset>
            <legend>{COLUMN_LABELS.C_LINE}</legend>
            <p>{selectedData?.label_c_name}</p>
          </fieldset>
        )}
        {selectedData?.p_line_name && (
          <fieldset>
            <legend>{COLUMN_LABELS.P_LINE}</legend>
            <p>{selectedData?.p_line_name}</p>
          </fieldset>
        )}
        <fieldset>
          <legend>{COLUMN_LABELS.ALBUM_LANGUAGE}</legend>
          <p>{capitalizeFirstLetter(selectedData?.language?.language)}</p>
        </fieldset>
        <fieldset>
          <legend>{COLUMN_LABELS.FILM_ZONE}</legend>
          <p>{selectedData?.film_zone}</p>
        </fieldset>
        <fieldset>
          <legend>Album Release Date</legend>
          <p>{dateStringFormat(selectedData?.album_release_date)}</p>
        </fieldset>
        {!selectedData?.territory ? null : (
          <fieldset className={showMore ? 'territary showmore' : 'territary'}>
            <legend>Territory</legend>
            <p className="t-head">
              {selectedData?.territory === 'worldwide'
                ? ' Worldwide'
                : selectedData?.territory === 'exclude'
                ? 'All countries except  '
                : selectedData?.territory === 'include'
                ? 'Only these countries '
                : ' '}
              {/* {capitalizeFirstLetter(selectedData?.territory)} */}
              {selectedData?.territory === 'worldwide' ? '' : ' :'}
            </p>
            {selectedValue.map((item) =>
              selectedValue[selectedValue.length - 1] === item ? (
                <p>{capitalizeFirstLetter(item.country)}</p>
              ) : (
                <p>{capitalizeFirstLetter(item.country)},</p>
              ),
            )}
            {selectedValue.length >= 3 && (
              <span className="moreless" onClick={() => setShowMore(!showMore)}>
                {showMore ? (
                  <IoIosArrowDropupCircle />
                ) : (
                  <IoIosArrowDropdownCircle />
                )}
              </span>
            )}
          </fieldset>
        )}
        {!albumId && (
          <div
            className="view_tracks"
            onClick={() => onClickDetail(selectedData?.project_id)}
          >
            <div>
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.1782 4.82175L23.0985 6.9015C25.3089 9.11761 26.5502 12.12 26.55 15.25C26.55 21.7695 21.2695 27.05 14.75 27.05C8.2305 27.05 2.95 21.7695 2.95 15.25C2.95 9.232 7.44875 4.276 13.275 3.55325V6.53275C9.086 7.24075 5.9 10.8693 5.9 15.25C5.9 20.1322 9.86775 24.1 14.75 24.1C19.6322 24.1 23.6 20.1322 23.6 15.25C23.6 12.8015 22.6117 10.589 21.004 8.996L18.9242 11.0758C19.9862 12.1525 20.65 13.6275 20.65 15.25C20.65 18.5098 18.0098 21.15 14.75 21.15C11.4903 21.15 8.85 18.5098 8.85 15.25C8.85 12.5065 10.738 10.2203 13.275 9.5565V12.713C12.39 13.2292 11.8 14.1585 11.8 15.25C11.8 16.8725 13.1275 18.2 14.75 18.2C16.3725 18.2 17.7 16.8725 17.7 15.25C17.7 14.1585 17.11 13.2145 16.225 12.713V0.5H14.75C6.608 0.5 0 7.108 0 15.25C0 23.392 6.608 30 14.75 30C22.892 30 29.5 23.392 29.5 15.25C29.5 11.179 27.848 7.4915 25.1782 4.82175Z"
                  fill="#C1C1C1"
                />
              </svg>
            </div>
            <span>View Tracks</span>
            <div>
              <svg
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 1.25L20 10.25L11 19.25M0 10.25H20H0Z"
                  stroke="#DC3831"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
        )}
      </div>

      <AlbumArtModal
        url={selectedData?.cover_art}
        showImgModal={showImgModal}
        onCloseHandler={onCloseHandler}
      />

      {/* <div className="awaiting_approval"> 2 tracks are waiting for approval</div> */}
    </AlbumDetailContainer>
  );
};

const AlbumDetailContainer = styled.div<{status}>`
  margin-top: 40px;

  .album-art-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .album-art {
      height: 200px;
      width: 200px;
      margin: 15px 0px;
      background-color: rgba(0, 0, 0, 0.1);
      pointer: cursor;
    }
  }

  .album_details {
    margin: 2rem 0;

    fieldset {
      margin-bottom: 0.75rem;
      border: 1px solid #c1c1c1;
      border-radius: 5px;
      legend {
        font-size: 0.85rem;
        font-weight: 400;
      }
      p {
        padding: 0.55rem;
        margin: 0;
        text-align: center;
        color: #dc3831;
      }
    }
    .territary {
      display: flex;
      flex-wrap: wrap;
      white-space: nowrap;
      align-items: center;
      max-width: 100%;
      overflow: hidden;
      height: 70px;
      justify-content: center;
      position: relative;

      &.showmore {
        height: auto;
        padding-bottom: 30px;
      }

      p {
        margin: 0;
        text-align: left;
        padding: 3px 10px;
        color: #dc3831;
      }
      .moreless {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        height: 25px;
        cursor: pointer;
        background: linear-gradient(360deg, #df373282, #00000000);
        color: #df3732;
        svg {
          font-size: 20px;
        }
        // background: linear-gradient(0deg, rgb(0 0 0 / 70%), rgb(0 0 0 / 5%));
      }
      .t-head {
        font-weight: bold;
      }
    }
    .view_tracks {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem;
      border: 1px solid #c1c1c1;
      border-radius: 5px;
      text-decoration: none;
      color: #dc3831;

      cursor: pointer;
    }
  }

  .album_footer {
    margin: 1.5rem 0;
  }
`;
export default AlbumsInReviewForm;
