import styled from '@emotion/styled';
import React, {useEffect, useState} from 'react';

import useAlbumArtModal from '../../../../hooks/AlbumDetail/useAlbumArtModal';
import useGetAlbumsData from '../../../../hooks/AlbumDetail/useGetAlbumsData';
import useS3Key from '../../../../hooks/reusable/useS3key';
import MusicPlayer from '../../AlbumDetail/MusicPlayerNew';
import TrackDetails from './TrackApprovalForm';
import AlbumArt from '../../../../components/AlbumDetail/AlbumArt';
import AlbumArtModal from '../../../../components/AlbumDetail/AlbumArtModal';
import TrackForm from './TrackForm';
import {UploadCoverArt} from '../../../../components/AlbumDetail/form/UploadCoverArt';

export default function AlbumDetailForm(props) {
  const {
    setIsOpen,
    useOnClickOutside,
    sidebarRef,
    onCloseHandler: onClose,
    selectedData,
    newFormTag,
    setNewFormTag,
    setIsModalOpen,
    setIsrcCode,
    setTrackCreated,
    refetchAlbum,
    isrcRef,
  } = props;
  const {getAlbumDetail, refetch} = useGetAlbumsData({
    albumId: selectedData?.album_id,
  });

  const [coverArtUrl, setCoverArtUrl] = useState(null);

  useEffect(() => {
    if (getAlbumDetail?.cover_art) {
      setCoverArtUrl(getAlbumDetail.cover_art);
    }
  }, [getAlbumDetail]);

  const {s3KeyGenerator} = useS3Key({projectId: getAlbumDetail?.project_id});
  const {
    onClickImageHandler,
    onCloseHandler,
    showImgModal,
  } = useAlbumArtModal();

  const renderForms = () => {
    if (newFormTag === 'add-coverart') {
      return (
        <UploadCoverArt
          refetchAlbum={refetchAlbum}
          setIsOpen={setIsOpen}
          setNewFormTag={setNewFormTag}
        />
      );
    } else if (
      (newFormTag === 'add-track' || !selectedData) &&
      !(!selectedData && newFormTag === 'add-coverart')
    ) {
      return (
        <TrackForm
          selectedData={null}
          setIsOpen={setIsOpen}
          setIsrcCode={setIsrcCode}
          setTrackCreated={setTrackCreated}
          refetchAlbums={refetchAlbum}
          isrcRef={isrcRef}
          newFormTag={newFormTag}
        />
      );
    } else if (
      (!['add-track', 'add-coverart'].includes(newFormTag) &&
        ['declined', 'pending', 'complete'].includes(selectedData?.status)) ||
      (getAlbumDetail?.status === 'declined' &&
        selectedData?.status === 'waiting_approval')
    ) {
      return (
        <React.Fragment>
          <TrackForm
            setIsOpen={setIsOpen}
            selectedData={selectedData}
            setIsrcCode={null}
            setTrackCreated={null}
            refetchAlbums={refetchAlbum}
          />
        </React.Fragment>
      );
    } else if (
      selectedData?.status === 'approved' ||
      selectedData?.status === 'waiting_approval'
    ) {
      return (
        <TrackDetails
          sidebarRef={null}
          setIsOpen={setIsOpen}
          selectedData={selectedData}
        />
      );
    } else return null;
  };

  return (
    <AlbumDetailForm.Container>
      {renderForms()}
      <AlbumArtModal
        url={coverArtUrl}
        showImgModal={showImgModal}
        onCloseHandler={onCloseHandler}
      />
    </AlbumDetailForm.Container>
  );
}

AlbumDetailForm.Container = styled.div`
  height: 100%;
  display: flex;
  margin: 40px 0;
  flex-direction: column;
  .album-details {
    display: flex;
    .col1 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      border-radius: 5px;
    }
    .col2 {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 10px;
      .title {
        font-size: 18px;
        font-weight: bold;
      }
      .artist {
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }
`;
