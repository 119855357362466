import React, {useState} from 'react';

const useFile = (initialValue = []) => {
  const [files, setFiles] = useState(initialValue);

  return {
    files,
    setFiles,
  };
};

export default useFile;
