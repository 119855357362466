import {Modal, ModalBody, ModalFooter, ModalHeader} from 'baseui/modal';
import React from 'react';
import Button from '../reusable/Button/Button';
import styled from '@emotion/styled';
import {IoMdClose} from 'react-icons/io';
import {useState} from 'react';
import {useEffect} from 'react';
// import Button from '../reusable/Button/Button';
const CheckState = styled.div<{checked}>`
  .container-checkbox {
    background: ${(props) =>
      props.checked ? 'rgba(220, 56, 49, 0.1)' : 'white'};
    border-radius: 5px;
    border: ${(props) =>
      props.checked ? '1px solid #dc3831' : '1px solid #ACACAC'};
    margin-right: 20px;
    // padding: 10px 30px;
    padding: 10px 50px 10px 20px;
    display: flex;
    align-items: center;
    // display: block;
    position: relative;
    // padding-left: 36px;
    // margin-top: 1px;
    // margin-bottom: 5px;
    cursor: pointer;
    font-size: 14px;
    color: #8d8d8d;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    .checkmark {
      // margin-top: 10px;
      // margin-left: 12px;
      position: absolute;
      // top: 0;
      right: 10px;
      height: 15px;
      width: 15px;
      // background-color: #eee;
      background-color: #cac9c9;
      border-radius: 50%;
      &:after {
        content: '';
        left: 5px;
        top: 3px;
        width: 3px;
        height: 6px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    :hover input ~ .checkmark {
      background-color: #ccc;
    }
    input:checked ~ .checkmark {
      background-color: #df3732;
    }
    input:checked ~ .checkmark:after {
      display: block;
    }
    input:checked ~ .title {
      color: #dc3831;
    }
  }
`;
const Container = styled.div`
  .text-area {
    width: 100%;
    height: 250px;
    background: #fbfbfb;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
    font-size: 14px;
    font-family: DM sans;
    font-style: normal;
    font-weight: normal;
    ::placeholder {
      font-family: DM sans;
      font-size: 14px;
      line-height: 16px;
      color: #c0c0c0;
    }
    :focus-visible {
      outline: none;
    }
  }
  .list {
    display: flex;
    justify-content: flex-start;
    margin-left: 25px;
    .box {
      margin-right: 50px;
      padding: 10px;
      border: 1px solid red;
      border-radius: 5px;
      vertical-align: middle;
    }
  }
`;

export default function RequestModal(props) {
  const {
    requestOpen,
    close,
    setRequestOpen,
    handleSendRequest,
    handleChange,
    editComment,
    setEditComment,
    setMetaData,
    metaData,
    isLoading,
  } = props;
  const [disable, setDisable] = useState(true);
  const List = [
    {id: 1, value: 'Track Details', name: 'track_details', checked: false},
    {id: 2, value: 'Album Details', name: 'album_details', checked: false},
    {id: 3, value: 'Cover Art', name: 'cover_art', checked: false},
    {id: 4, value: 'Track File', name: 'track_file', checked: false},
  ];
  const [checkboxItems, setCheckboxItems] = useState(List);
  const closeModal = () => {
    setRequestOpen(false);
  };

  useEffect(() => {
    if (metaData?.length > 0 && editComment?.length > 2) {
      setDisable(false);
    } else if (editComment?.length > 2 && metaData?.length === 0) {
      setDisable(true);
    } else if (metaData?.length > 0 && editComment?.length < 2) {
      setDisable(true);
    }
  }, [metaData, editComment]);
  useEffect(() => {
    const checkedItems = checkboxItems.filter(({checked}) => checked);
    setMetaData(checkedItems.map((item) => item.value));
  }, [checkboxItems]);
  return (
    <Modal
      closeable={false}
      isOpen={requestOpen}
      onClose={close}
      overrides={{
        Root: {
          style: {
            zIndex: 100,
          },
        },
        Dialog: {
          style: {
            borderRadius: '5px',
            width: '800px',
            background: 'white',
          },
        },
        DialogContainer: {
          style: {
            zIndex: 100,
            display: 'flex',
          },
        },
      }}
    >
      <ModalHeader>
        <div
          className="header"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            boxShadow: 'none',
          }}
        >
          <div>Product Edit Request</div>
          <div className="close">
            <IoMdClose
              style={{cursor: 'pointer'}}
              onClick={() => {
                closeModal();
                setEditComment('');
                setMetaData([]);
              }}
            />
          </div>
        </div>
      </ModalHeader>
      <Container>
        <div className="list">
          {checkboxItems.map((item, index) => {
            return (
              <CheckState checked={item.checked}>
                <label className="container-checkbox">
                  <input
                    type="checkbox"
                    value={item.name}
                    onChange={(e) => {
                      const newCheckboxes = [...checkboxItems];
                      newCheckboxes[index].checked = e.target.checked;
                      setCheckboxItems(newCheckboxes);
                      // setMetaData([...metaData, e.target.value]);
                    }}
                    checked={item.checked}
                  />
                  <span className="title">{item.value}</span>
                  <span className="checkmark"></span>
                </label>
              </CheckState>
            );
          })}
        </div>
        <ModalBody>
          <textarea
            placeholder="Product edit request description"
            className="text-area"
            value={editComment}
            onChange={(e) => {
              setEditComment(e.target.value);
            }}
          ></textarea>
        </ModalBody>
      </Container>
      <ModalFooter>
        <Button
          label="Send Request"
          disabled={disable}
          onClick={handleSendRequest}
          isLoading={isLoading}
        />
      </ModalFooter>
    </Modal>
  );
}
