/** @format */

import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Theme} from 'baseui/theme';
import {Form, Formik} from 'formik';
import React, {useContext, useEffect, useState} from 'react';

import {GlobalContext} from '../../Contexts/GlobalProvider';
import useCreateProject from '../../hooks/AlbumsManagement/useCreateProject';
import useGetSingleLabel from '../../hooks/LabelManagement/useGetSingleLabel';
import useListLabels from '../../hooks/Labels/useListLabels';
import useCheckForAdmin from '../../hooks/reusable/useCheckForAdmin';
import useLocalStorage from '../../hooks/reusable/useLocalStorage';
import {CustomThemeType} from '../../styles/theme/colors';
import {createProjectValidationSchema} from '../../validation/ProjectManagement/createProject';
import Button from '../reusable/Button/Button';
import FormikControl from '../reusable/Formik/FormikControl';
import LoadingSpinnerTransparent from '../reusable/LoadingSpinnerTransparent/LoadingSpinnerTransparent';

const generateName = () => (+new Date()).toString(36).slice(-7);

function SelectLabelComponent(props) {
  const {checkForAdmin} = useCheckForAdmin();
  const {setProject, tag} = props;
  const [css, theme] = useStyletron();
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const {state, dispatch}: any = useContext(GlobalContext);

  const {listLabels} = useListLabels();

  const [labelOptionsArray, setLabelOptionsArray] = useState([]);

  const {
    createProjectMutation,
    isLoading: isCreateProjectLoading,
    error: createProjectError,
  } = useCreateProject();

  const initialValues = {
    project_name: '',
    label: '',
  };

  useEffect(() => {
    if (listLabels) {
      const labelOptions = listLabels.data?.map((label) => ({
        value: label.label_name,
        id: label.id,
      }));

      setLabelOptionsArray(labelOptions);
    }
  }, [listLabels]);

  const onSubmit = async (values) => {
    // setIsSubmitted('pending');
    // const project = {
    //   project_name: generateName(),
    //   label_id: Number(values.label[0].id),
    //   user_id: currentUser.id,
    // };

    // const res = await createProjectMutation(project);
    // if (res?.statusCode === 201) {
    //   setProject(res.data);
    //   handleClick();
    //   setIsSubmitted('submitted');
    // }
    console.log('On submit');
  };

  const onChangeHandler = async (id, projectName) => {
    setIsSubmitted(false);
    const project = {
      is_admin_flow: checkForAdmin() && tag ? true : false,
      project_name: projectName ? projectName : generateName(),
      label_id: Number(id),
      user_id: currentUser.id,
    };
    const res = await createProjectMutation(project);

    if (res?.statusCode === 201) {
      setProject(res.data);
      setIsSubmitted(true);
    }
  };

  return (
    <FormStyle isSubmitted={isSubmitted} theme={theme}>
      {isCreateProjectLoading && <LoadingSpinnerTransparent />}

      <Formik
        initialValues={initialValues}
        validationSchema={createProjectValidationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form
            style={{
              padding: '12px 0.75rem',
            }}
          >
            {checkForAdmin() && tag && (
              <div className="upload-name-section">
                <FormikControl
                  control="baseinput"
                  type="text"
                  label="Upload Name"
                  name="project_name"
                  initialValue={formik.initialValues.project_name}
                  setFieldValue={formik.setFieldValue}
                  placeholder="Bulk Upload XYZ"
                  disabled={isSubmitted}
                />
              </div>
            )}
            <SelectLabelComponent.Label
              isSubmitted={isSubmitted}
              theme={theme}
              className="label-section"
            >
              <div className="sect1">
                <FormikControl
                  labelKey="value"
                  valueKey="id"
                  control="baseselect"
                  label="Select the label"
                  name="label"
                  options={labelOptionsArray}
                  onChange={(name, value) => {
                    dispatch({
                      type: 'SET_LABEL',
                      payload: {
                        label_name: value[0].value,
                        label_id: value[0].id,
                      },
                    });
                    formik.setFieldValue(name, value);
                    onChangeHandler(value[0].id, formik?.values?.project_name);
                  }}
                  value={formik.values.label}
                  disabled={isSubmitted}
                />
              </div>

              {/* <div className="sect2">
                <Button
                  label="Next"
                  type="submit"
                  isLoading={isCreateProjectLoading}
                  disabled={
                    !(formik.isValid && formik.dirty) ||
                    isSubmitted === 'submitted'
                  }
                />
              </div> */}
            </SelectLabelComponent.Label>
          </Form>
        )}
      </Formik>
    </FormStyle>
  );
}

const FormStyle = styled.div<{
  theme: Theme & CustomThemeType;
  isSubmitted?: boolean;
}>`
  .upload-name-section {
    pointer-events: ${(props) => (props.isSubmitted ? 'not-allowed' : '')};
    opacity: ${(props) => (props.isSubmitted ? 0.4 : '')};
  }
`;
SelectLabelComponent.Label = styled.div<{
  theme: Theme & CustomThemeType;
  isSubmitted?: boolean;
}>`
  display: flex;
  justify-content: space-between;

  align-items: center;
  color: ${(props) => props.theme.textColor};

  .sect1 {
    width: 100%;
  }

  .sect2 {
    display: flex;
    height: 100%;
    margin-top: 16px;
    justify-content: flex-end;
  }

  // disable label section
  pointer-events: ${(props) => (props.isSubmitted ? 'not-allowed' : '')};
  opacity: ${(props) => (props.isSubmitted ? 0.4 : '')};

  form {
    display: flex;
  }
`;

export default SelectLabelComponent;
