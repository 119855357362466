import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router';

import {TAGS} from '../../constants/tags';
import useGetEditRequests from '../../hooks/AlbumsApproval/useGetEditRequests';
import useGetInReviewAlbums from '../../hooks/AlbumsApproval/useGetInReviewAlbums';
import useLocalStorage from '../../hooks/reusable/useLocalStorage';
import useTablePageState from '../../hooks/reusable/useTablePageState';
import SkeletonLoading from '../../pages/SkeletonPage';
import {requestTableData} from '../../tableColumns/AlbumsInReview/requestTableData';
import FlyMenu from '../reusable/FlyMenu/FlyMenu';
import NoRecords from '../reusable/NoRecords/NoRecords';
import Table from '../reusable/Table/TablePagination';

const RequestEditAlbums = () => {
  const [searchStr, setSearchStr] = useState('');
  const navigate = useNavigate();
  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    resolvedData,
    setPage,
    page,
    isLastPage,
    totalCount,
    rangeB,
    rangeA,
    lastPage,
    setLimit,
    limit,
    isCustomLoading,
    refetch,
  } = useGetEditRequests({searchStr});

  const {
    isOpen,
    setNewFormTag,
    newFormTag,
    setIsOpen,
    setSelectedData,
    selectedData,
  } = useTablePageState();

  const albums = React.useMemo(() => data, [data]);
  const columnData = React.useMemo(() => requestTableData, [requestTableData]);

  if (isLoading) {
    return <SkeletonLoading />;
  }

  if (resolvedData?.totalPage === 0) {
    return <NoRecords btnLabel={null} onClick={null} page="approval" />;
  }
  const onClickRow = (cell) => {
    navigate(
      `/album-request/${cell.row.original?.id}/${cell?.row?.original?.album_id}`,
    );
  };

  return (
    <>
      <Table
        columns={columnData}
        data={albums ? albums : []}
        tag="request-edit"
        setSidebarData={setSelectedData}
        onClickRow={(row) => onClickRow(row)}
        setPage={setPage}
        page={page}
        isLastPage={isLastPage}
        totalCount={totalCount}
        rangeB={rangeB}
        rangeA={rangeA}
        lastPage={lastPage}
        setLimit={setLimit}
        limit={limit}
        resolvedData={resolvedData}
        isLoading={isCustomLoading}
        isFetching={isFetching}
        setSearchStr={setSearchStr}
        actionBtn={null}
        refetchHandler={refetch}
      />
      {/* 
      {isOpen && (
        <FlyMenu
          refetch={refetch}
          setSelectedData={setSelectedData}
          selectedData={selectedData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          tag={TAGS.ALBUM_APPROVAL}
          setNewFormTag={setNewFormTag}
          newFormTag={newFormTag}
        />
      )} */}
    </>
  );
};

export default RequestEditAlbums;
