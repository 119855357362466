/* eslint-disable no-octal-escape */
import styled from '@emotion/styled';
import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router';

import {sortStatusAlphabeticalOrderWithoutCase} from '../../tableColumns/AlbumsManagement/albumColumnHelper';
import {StatusBox} from '../../tableColumns/AlbumsManagement/albumsColumnMetaData';

export const SelectionRowWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .listhead {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  // Hide default browser input
  .listhead input {
    vertical-align: top;
    margin-right: 10px;
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  // Custom control
  .control_indicator {
    position: absolute;
    height: 20px;
    width: 20px;
    background: #e6e6e6;
    border-radius: 5px;
  }
  // Hover and focus
  .listhead:hover input ~ .control_indicatior,
  .listhead input:focus ~ .control_indicator {
    background: #ccc;
  }
  // Checked
  .listhead input:checked ~ .control_indicator {
    background: #dc3832;
  }
  // Hover when checked
  .listhead:hover input:not([disabled]):checked ~ .control_indicator,
  .listhead input:checked:focus ~ .control_indicator {
    background: #b31712;
  }
  // Hide default browser input
  .listhead input:disabled ~ .control_indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }
  .control_indicator:after {
    content: '';
    position: absolute;
    display: none; // Hide check
  }

  .listhead input:checked ~ .control_indicator:after {
    display: block; // Show check
  }

  .listhead-checkbox .control_indicator:after {
    left: 7.5px;
    top: 5px;
    width: 5px;
    height: 8px;
    border: solid #fff;
    border-width: 0px 2px 2px 0;
    transform: rotate(40deg);
  }

  // Disabled tick colour
  .listhead-checkbox input:disabled ~ .control_indicator:after {
    border-color: #7b7b7b;
  }
`;

export const IndeterminateHeaderCheckbox = React.forwardRef(
  (
    {
      indeterminate,
      isDisabled,
      data,
      toggleRowSelected,
      checked,
      onChange,
      selectedFlatRow,
      type,
      ...rest
    },
    ref,
  ) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    const [toggleState, setToggleState] = useState(false);
    const [localData, setLocalData] = useState(null);

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    React.useEffect(() => {
      if (selectedFlatRow.length === 0) {
        setToggleState(false);
      }
      if (localData) {
        if (selectedFlatRow.length === localData.length) {
          setToggleState(true);
        }
      }
    }, [selectedFlatRow, localData]);

    const onChangeHandler = () => {
      setToggleState(!toggleState);
      let inReviewData = [];
      data.map((d) => {
        if (d.original?.status === 'waiting_approval') {
          inReviewData.push(d);
        } else if (
          type === 'create_package' &&
          d?.original?.Order_management?.length === 0
        ) {
          inReviewData.push(d);
        }
      });
      setLocalData(inReviewData);

      if (inReviewData.length > 0) {
        inReviewData.map((data) => {
          if (!toggleState) {
            toggleRowSelected(data.id, true);
          } else {
            toggleRowSelected(data.id, false);
            setToggleState(false);
          }
        });
      }
    };

    return (
      <label className="listhead listhead-checkbox">
        <input
          disabled={isDisabled}
          checked={toggleState}
          ref={resolvedRef}
          {...rest}
          onChange={onChangeHandler}
          type="checkbox"
        />
        <div className="control_indicator"></div>
      </label>
    );
  },
);

export const IndeterminateCheckbox = React.forwardRef(
  ({indeterminate, isDisabled, onChange, ...rest}, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <label className="listhead listhead-checkbox">
        <input
          disabled={isDisabled}
          ref={resolvedRef}
          {...rest}
          type="checkbox"
        />
        <div className="control_indicator"></div>
      </label>
    );
  },
);

export const tracksColumnMeta = [
  {
    id: 'selection',
    width: 50,
    disableSortBy: true,
    Header: ({getToggleAllRowsSelectedProps, toggleRowSelected, ...rest}) => {
      const [hasValidStatus, setHasValidStatus] = useState(false);
      const location = useLocation();
      useEffect(() => {
        if (rest.data && rest.data?.length > 0) {
          let hasStatus = true;
          rest?.data.map((a) => {
            if (a.status.includes('waiting_approval')) {
              hasStatus = false;
            }
          });
          setHasValidStatus(hasStatus);
        }
      }, [rest.data]);

      return (
        <SelectionRowWrapper>
          {location.pathname.includes('/album-request/') ? null : (
            <IndeterminateHeaderCheckbox
              isDisabled={hasValidStatus}
              data={rest.filteredFlatRows}
              selectedFlatRow={rest.selectedFlatRows}
              toggleRowSelected={toggleRowSelected}
            />
          )}
        </SelectionRowWrapper>
      );
    },
    Cell: ({row}) => {
      const location = useLocation();
      return (
        <>
          {location.pathname.includes('/album-request/') ? null : (
            <SelectionRowWrapper
              onClick={
                row?.original?.status === 'approved' ||
                row?.original?.status === 'declined'
                  ? null
                  : () => row.toggleRowSelected()
              }
              style={
                row.isSelected
                  ? {
                      backgroundColor: '#dc383126',
                    }
                  : {}
              }
            >
              <IndeterminateCheckbox
                isDisabled={
                  row?.original?.status === 'approved' ||
                  row?.original?.status === 'declined'
                }
                {...row.getToggleRowSelectedProps()}
              />
            </SelectionRowWrapper>
          )}
        </>
      );
    },
  },
  {
    Header: 'Track Order',
    accessor: 'track_order',
  },
  {
    Header: 'Track Name',
    accessor: 'track_name',
  },
  {
    Header: 'Track Genre',
    accessor: 'track_genre',
  },
  {
    Header: 'UPC Code',
    accessor: 'track_upc',
  },
  {
    Header: 'ISRC Code',
    accessor: 'isrc',
  },

  {
    Header: 'Status',
    accessor: 'status',
    sortType: (rowA, rowB) => {
      return sortStatusAlphabeticalOrderWithoutCase(
        rowA.original.status,
        rowB.original.status,
      );
    },
    Cell: (data) => {
      return <StatusBox track="track" data={data} />;
    },
  },
];
