import styled from '@emotion/styled';
import {StatefulPopover} from 'baseui/popover';
import {PLACEMENT} from 'baseui/toast';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import {BiCaretDown, BiDetail, BiDownload} from 'react-icons/bi';
import {
  FaCheckCircle,
  FaInfoCircle,
  FaRegArrowAltCircleLeft,
} from 'react-icons/fa';
import {MdAddCircle, MdZoomOutMap} from 'react-icons/md';
import {RiEditCircleFill} from 'react-icons/ri';
import {TiWarning} from 'react-icons/ti';
import {useNavigate} from 'react-router';

import {Button} from '../../../components';
import AlbumArtModal from '../../../components/AlbumDetail/AlbumArtModal';
import Tooltip from '../../../components/reusable/ToolTip/ToolTip';
import {TAGS} from '../../../constants/tags';
import useAlbumArtModal from '../../../hooks/AlbumDetail/useAlbumArtModal';
import useGetAlbumsData from '../../../hooks/AlbumDetail/useGetAlbumsData';
import usePreviewLink from '../../../hooks/AlbumDetail/usePreviewLink';
import useGetImagePreviewLink from '../../../hooks/reusable/useGetImagePreviewLink';
import useS3Key from '../../../hooks/reusable/useS3key';
import backCircle from './backcircle.svg';
import brokenImg from './broken-image.svg';
import TitleImg from './picture.png';
import {StatusBox} from '../../../tableColumns/AlbumsManagement/albumsColumnMetaData';

const AlbumInfo = (props) => {
  const {
    getAlbumDetail,
    openAddCoverArtHandler,
    openFlyMenu,
    setToggleDspModal,
    hasNoTracks,
    hasCoverArtValue,
    isOpen,
    updateStatusHandler,
    actionDropDownContent,
    tag,
    setIsAlbumClick,
    setIsOpen,
    musicDirectors,
    openAlbumForm,
    published,
    refetch,
    hasBackButton,
    type,
  } = props;
  const {downloadLink} = useGetImagePreviewLink({
    upc: getAlbumDetail?.upc,
    projectId: getAlbumDetail?.project_id,
  });
  const [show, setShow] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const {
    onClickImageHandler,
    onCloseHandler,
    showImgModal,
  } = useAlbumArtModal();

  const mainalbum = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      setShow(false);
    }
  }, [isOpen]);

  const transitionBar = () => {
    if (
      window.scrollY >
      mainalbum.current.scrollTop + mainalbum.current.clientHeight
    ) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', transitionBar);
    return () => window.removeEventListener('scroll', transitionBar);
  }, []);
  useEffect(() => {
    if (published) {
      refetch();
    }
  }, [published]);

  return (
    <Container>
      {hasBackButton && (
        <div className="back-to-bulkadmin" onClick={() => navigate(-1)}>
          <img src={backCircle} alt="back" />
          Go back
        </div>
      )}
      {show && (
        <Header>
          <div className="detail">
            <div className="back" onClick={() => navigate(-1)}>
              <img src={backCircle} alt="back" />
            </div>
            <div className="title">{getAlbumDetail?.album_name}</div>
            <div className="info">
              <span>{`${getAlbumDetail?.Track.length ? 'By  ' : ''}`}</span>
              {getAlbumDetail?.album_director_name.length > 0 &&
                getAlbumDetail?.album_director_name?.join(' | ')}{' '}
              {musicDirectors?.join(' | ')}
            </div>
            <div className="time">
              <div className="timeinfo">
                {moment(getAlbumDetail?.created_at).format('DD/MM/YYYY')}
                <div className="dot"></div> {getAlbumDetail?.Track.length}{' '}
                Tracks
              </div>
            </div>
            <div>
              <StatusBox
                data={{value: getAlbumDetail?.status}}
                albumStatus={getAlbumDetail?.status}
              />
            </div>
          </div>
          <div className="buttoninfo">
            <div className="button">
              <button onClick={openAlbumForm}>View / Edit Album</button>
            </div>
          </div>
        </Header>
      )}
      <TitleCard>
        <div className="Card" ref={mainalbum}>
          <div className="album-detail-container">
            <div className="image">
              {getAlbumDetail?.cover_art ? (
                <img
                  alt="cover-art"
                  width="250px"
                  height="250px"
                  src={getAlbumDetail?.cover_art}
                />
              ) : (
                <div className="broken-container">
                  <img
                    alt="broken"
                    width="40px"
                    height="40px"
                    src={brokenImg}
                  />
                  <span style={{color: '#E1AAAA'}}>No coverart</span>
                </div>
              )}
              {getAlbumDetail?.status !== 'orderable' &&
                tag !== TAGS.TRACK_APPROVAL &&
                getAlbumDetail?.status !== 'waiting_approval' && (
                  <div
                    onClick={openAddCoverArtHandler}
                    className={
                      getAlbumDetail?.cover_art
                        ? 'img-footer o-reduce'
                        : 'img-footer'
                    }
                  >
                    <>
                      {getAlbumDetail?.cover_art ? (
                        <>
                          <span>Update Coverart</span>
                          <RiEditCircleFill />
                        </>
                      ) : (
                        <>
                          <span>Add Coverart</span>
                          <MdAddCircle />
                        </>
                      )}
                    </>
                  </div>
                )}
              <div className="icon" onClick={onClickImageHandler}>
                <MdZoomOutMap />
              </div>
            </div>
            <div className="card-info">
              <div className="status">
                <div className="album">{getAlbumDetail?.product_type}</div>
                <StatusBox
                  data={{value: getAlbumDetail?.status}}
                  albumStatus={getAlbumDetail?.status}
                />
                {getAlbumDetail?.migrated && <div className="migrated">M</div>}
              </div>
              <div className="title">{getAlbumDetail?.album_name}</div>
              <div className="info">
                <div className="label">{getAlbumDetail?.label?.label_name}</div>
                <span>{`${getAlbumDetail?.Track.length ? 'By  ' : ''}`}</span>
                {getAlbumDetail?.album_director_name.length > 0 &&
                  getAlbumDetail?.album_director_name?.join(' | ')}{' '}
                {getAlbumDetail?.album_director_name.length > 0 &&
                getAlbumDetail?.album_type?.toLowerCase() === 'film' &&
                musicDirectors.length > 0
                  ? ' | '
                  : null}
                {musicDirectors?.join(' | ')}
              </div>
              <div className="time">
                <div className="timeinfo">
                  <span className="release-date">
                    {moment(getAlbumDetail?.created_at).format('DD/MM/YYYY')}
                  </span>
                  <div className="dot"></div> {getAlbumDetail?.Track.length}{' '}
                  Tracks
                  {/* <div className="dot"></div> 30 Min */}
                </div>
              </div>
              <div className="btn-wrapper">
                <div className="button">
                  <button onClick={openAlbumForm}>
                    {getAlbumDetail?.status === 'orderable'
                      ? 'View Album Details'
                      : 'View / Edit Album'}
                    <BiDetail />
                  </button>
                </div>
                {getAlbumDetail?.status === 'orderable' &&
                  getAlbumDetail?.Order_management?.length > 0 &&
                  !type && (
                    <div className="button">
                      <button onClick={() => setToggleDspModal(true)}>
                        View DSP Delivery Details
                        <BiDownload />
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="action-container">
            <StatefulPopover
              onOpen={() => setOpenPopover(true)}
              onClose={() => setOpenPopover(false)}
              dismissOnClickOutside
              focusLock={true}
              placement={PLACEMENT.bottomRight}
              content={!published ? actionDropDownContent : null}
            >
              <div
                style={{
                  display: 'flex',
                }}
              >
                <Button
                  endEnhancer={() => <BiCaretDown />}
                  label="Action"
                  shape="round"
                  kind="tertiary"
                  size="large"
                  type="submit"
                  disabled={
                    published ||
                    (getAlbumDetail?.status === 'orderable' && hasBackButton)
                  }
                />
              </div>
            </StatefulPopover>
          </div>
        </div>
        <AlbumArtModal
          url={getAlbumDetail?.cover_art}
          showImgModal={showImgModal}
          onCloseHandler={onCloseHandler}
        />
      </TitleCard>
      <div className="middle"></div>
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  .back-to-bulkadmin {
    img {
      width: 20px;
      margin-right: 10px;
    }
    display: flex;
    background: white;
    justify-content: center;
    padding: 10px 15px;
    border-radius: 10px;
    margin-left: 10px;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: -77px;
  }
`;
const TitleCard = styled.div`
  width: 100%;

  .Card {
    display: flex;
    color: #333333;
    padding: 10px;
    line-height: 5.5;
    justify-content: space-between;
    margin-bottom: 25px;
    .album-detail-container {
      display: flex;
      align-items: center;
      justify-content: center;
      .image {
        position: relative;
        width: 250px;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f6d1d1;
        margin-right: 20px;
        .icon {
          position: absolute;
          top: 9px;
          right: 10px;
          background: rgb(0 0 0 / 60%);
          width: 25px;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          color: #ffffffb5;
          cursor: pointer;
          svg {
            display: flex;
          }
        }
        .broken-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          span {
            font-size: 12px;
          }
        }

        .img-footer {
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          height: 40px;
          background-color: #d21919;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;

          font-size: 14px;
          cursor: pointer;

          svg {
            margin-left: 4px;
          }
        }
        .o-reduce {
          background-color: rgba(210, 25, 25, 0.8);
        }
        img {
          background-color: #f6d1d1;
          filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.3));
        }
      }
      .card-info {
        .title {
          font-style: normal;
          font-weight: bold;
          font-size: 72px;
          line-height: 90px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          width: 100%;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          word-break: break-word;
        }
        .status {
          display: flex;
          align-items: center;
          .album {
            font-size: 14px;
            text-transform: uppercase;
            line-height: 24px;
            margin-right: 20px;
            border: 1px solid #dc3832;
            padding: 0px 15px;
            border-radius: 5px;
            color: #dc3832;
          }
          .migrated {
            background: #dc3831;
            color: #fff;
            height: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
            padding: 5px;
            border-radius: 5px;
          }
          .progress {
            background: #ffefb3;
            border-radius: 25px;
            color: #a05f00;
            font-size: 12.4px;
            width: 108px;
            height: 25px;
            line-height: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 18px;
            img {
              margin-left: 10px;
            }
          }
        }
        .info {
          font-size: 16px;
          line-height: 24px;
          font-style: normal;
          font-weight: normal;
          margin-bottom: 4px;
          .label {
            font-size: 16px;
          }
          span {
            color: #868686;
          }
        }
        .time {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #868686;
          .timeinfo {
            display: flex;
            .release-date {
            }
            .dot {
              width: 5px;
              height: 5px;
              background: gray;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              margin-top: 10px;
              margin-left: 10px;
              margin-right: 10px;
              background-color: #d21919;
            }
          }
        }
      }
      .btn-wrapper {
        display: flex;
        margin-top: 10px;
      }
      .button {
        + .button {
          margin-left: 10px;
        }
        button {
          display: flex;
          border-radius: 30px;
          border: 1px solid #d21919;
          background: transparent;
          color: #d21919;
          padding: 12px 14px;
          font-weight: normal;
          font-size: 16px;
          cursor: pointer;
          align-items: center;
          transition: all 0.25s ease-in;
          &:hover {
            background: #d21919;
            color: white;
          }
          svg {
            margin-left: 10px;
          }
        }
      }
    }
    .action-container {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      width: 200px;
    }
  }
  .middle {
    height: 200px;
  }
`;
const Header = styled.div`
width:100%;
height:100px;
background:white;
display:flex;
justify-content: space-between;
align-items: center;
position:fixed;
top:0;
left:76px;
z-index:99;
animation: mymove 0.5s;
animation-fill-mode: forwards;
box-shadow: 0px 4px 25px rgb(0 0 0 / 15%);
@keyframes mymove {
  from {top: -50px;}
  to {top: 0px; background-color: white;}
}

.buttoninfo{
    flex-basis: 40%;
    display: flex;
    justify-content: flex-end;
    margin-right: 90px;
    .button {
        button{
            border-radius: 30px;
            border: 1px solid #d21919;
            background: transparent;
            color: #d21919;
            width: 194px;
            height: 40px;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
           
            cursor: pointer;
        }
      }
}
.detail{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-basis: 70%;
    transition-timing-function: ease-in;
    transition: all 0.5s;
    .back{
        font-size:30px;
        display:flex;
        // margin-left: 20px;
        cursor:pointer;
    }
    .status {
        display: flex;
        .progress {
          background: #ffefb3;
          border-radius: 25px;
          color: #a05f00;
          font-size: 12px;
          width: 100px;
          height: 20px;
          line-height: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            margin-left: 10px;
          }
        }
      }
    .title {
          
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        // margin-left: 35px;
    }
    .info {
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
        font-weight: normal;
        width: 200px;
        overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    color:grey;
   
        span {
          color: #868686;
          border-left: 2px solid;
          padding-left: 25px;
        }       
      }
   .time{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #868686;
       .timeinfo{
        display:flex;
        width: max-content;
        margin-right:20px;
        .dot {
            width: 5px;
            height: 5px;
            background: gray;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            margin-top: 11px;
            margin-left: 10px;
            margin-right: 5px;
          }
       }
   }
  


`;
export default AlbumInfo;
