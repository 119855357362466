import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Theme} from 'baseui/theme';
import * as React from 'react';
import {BiFilter} from 'react-icons/bi';
import {RiFilterFill, RiFilterOffFill} from 'react-icons/ri';

import {CustomThemeType} from '../../../styles/theme/colors';

const CustomInputContainer = styled.input<{theme: Theme & CustomThemeType}>`
  border: 1px solid #dfe1e6;
  border-radius: 100px;
  outline: none;
  padding: 10px 10px 10px 15px;
  background: #ebecf1;
  min-width: 220px;
  color: ${(props) => props.theme.textColor};
  font-size: 14px;
  transition: all 0.05s ease-in;
  ::placeholder {
    color: #909090;
  }
  &:focus {
    background: white;
  }
  &:hover:not(:focus) {
    background: #f0f1f5;
  }
`;

interface SearchProps {
  placeholder: string;
  value?: string;
  onChangeHandler?: any;
}

const AlbumAdvancedSearch = (props) => {
  const {
    placeholder,
    value,
    onChangeHandler,
    toggleSearch,
    setToggleSearch,
    bubble,
    dataFilter,
  } = props;
  const [isShow, setIsShow] = React.useState(false);
  const [css, theme] = useStyletron();

  const onBlurInput = (e) => {
    setIsShow(true);
  };
  return (
    <SearchContainer toggle={toggleSearch}>
      <CustomInputContainer
        theme={theme}
        type="search"
        value={value || ''}
        onChange={onChangeHandler}
        onFocus={(e) => onBlurInput(e)}
        placeholder={`Search ${placeholder}`}
      />
      <div
        onClick={() => setToggleSearch(!toggleSearch)}
        className="advanced-btn-container"
      >
        <button>{toggleSearch ? <RiFilterOffFill /> : <RiFilterFill />}</button>
      </div>
      {bubble > 0 || dataFilter !== 'all' ? (
        <span className="bubble">
          {dataFilter !== 'all' ? bubble + 1 : bubble}
        </span>
      ) : null}
      {/* {isShow && value?.length < 2 ? (
        <div>Please enter at least 2 characters to search...</div>
      ) : null} */}
    </SearchContainer>
  );
};

const SearchContainer = styled.div<{toggle}>`
  display: flex;
  flex-direction: column;
  position: relative;
  .advanced-btn-container {
    width: auto;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      height: 85%;
      border-radius: 3px 100px 100px 3px;
      border: none;
      padding: 0 18px;
      background: ${(props) => (props.toggle ? '#ca332e' : '#dc3732')};
      color: white;
      outline: none;
      display: flex;
      margin-right: 4px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: ${(props) =>
        props.toggle ? '0 0 0 3px rgb(230 144 144 / 58%)' : ''};
    }
  }
  div {
    font-size: 10px;

    position: absolute;
    width: 100%;
  }
  .bubble {
    position: absolute;
    left: 95%;
    background: #3777d8;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    font-size: 10px;
    display: flex;
    color: #edf2f9;
    justify-content: center;
    align-items: center;
  }
`;

export default AlbumAdvancedSearch;
