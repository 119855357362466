import styled from '@emotion/styled';
import {Skeleton} from 'baseui/skeleton';
import React from 'react';

const Container = styled.div`
  margin-top: 20px;
  flex-direction: column;
  height: 80px;
  display: flex;
  justify-content: space-between;
  .row1 {
    display: flex;
    justify-content: space-between;
  }
  .border {
    width: 100%;
    height: 1px;
    background-color: #e6e6e6;
    margin-top: 20px;
  }
`;

const HistorySkeletonRow = () => {
  return (
    <Container>
      <div className="row1">
        <Skeleton width="300px" height="30px" animation />
        <Skeleton width="100px" height="20px" animation />
      </div>
      <div className="row2">
        <Skeleton width="250px" height="20px" animation />
      </div>

      <div className="border"></div>
    </Container>
  );
};

export default function HistorySkeltonLoading() {
  return (
    <>
      <HistorySkeletonRow />
      <HistorySkeletonRow />
      <HistorySkeletonRow />
    </>
  );
}
