import {useMutation} from 'react-query';

import axios from '../../config/Axios';
import useNotification from '../../Contexts/useNotification';
import {logError} from '../../errorlogger/logError';

const retrySftpFn = async (data) => {
  try {
    return await axios(`/dsp/sftp-delivery`, {
      method: 'POST',
      data: data,
    });
  } catch (error) {
    logError(error, 8);
  }
};

const useRetrySftpFnn = () => {
  const {showToast} = useNotification();

  const [retrySftpFnMutation, {status, data, error, isLoading}] = useMutation(
    retrySftpFn,
    {
      onSuccess: () => {
        showToast('Package sent successfully', 'success');
      },
    },
  );

  return {retrySftpFnMutation, isLoading, status, error};
};

export default useRetrySftpFnn;
