import React from 'react';

import useLocalStorage from '../../hooks/reusable/useLocalStorage';
import {sortStatusAlphabeticalOrderWithoutCase} from '../../tableColumns/AlbumsManagement/albumColumnHelper';
import {StatusBox} from '../../tableColumns/AlbumsManagement/albumsColumnMetaData';

export const tracksColumnMeta = [
  {
    Header: 'Track Order',
    accessor: 'track_order',
    sortDescFirst: true,
    sortType: (rowA, rowB) => {
      return sortByTrackOrder(rowA.original, rowB.original);
    },
  },
  {
    Header: 'Track Name',
    accessor: 'track_name',
  },
  {
    Header: 'Track Genre',
    accessor: 'track_genre',
  },
  {
    Header: 'UPC Code',
    accessor: 'track_upc',
  },
  {
    Header: 'ISRC Code',
    accessor: 'isrc',
  },

  {
    Header: 'Track Status',
    accessor: 'status',
    Cell: (data) => {
      const [albumStatus, setAlbumStatus] = useLocalStorage(
        'albumStatus',
        null,
      );

      return <StatusBox track="track" data={data} albumStatus={albumStatus} />;
    },
    sortType: (rowA, rowB) => {
      return sortStatusAlphabeticalOrderWithoutCase(
        rowA.original.status,
        rowB.original.status,
      );
    },
  },
];

const sortByTrackOrder = (row1, row2) => {
  let parsedTrackOrderR1 = parseInt(row1?.track_order);
  let parsedTrackOrderR2 = parseInt(row2?.track_order);

  if (parsedTrackOrderR1 < parsedTrackOrderR2) {
    return -1;
  }
  if (parsedTrackOrderR1 > parsedTrackOrderR2) {
    return 1;
  }
  return 0;
};
