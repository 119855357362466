export const manualOrderData = {
  download_btn: {
    Header: '',
    accessor: 'order_id',
    width: 60,
  },
  order_id: {
    Header: 'ORDER ID',
    accessor: 'order_name',
  },
  upc: {
    Header: 'UPC',
    accessor: 'upc',
  },
  dsp: {
    Header: 'DSP',
    accessor: 'dsp_name',
  },
  user_name: {
    Header: 'USER NAME',
    accessor: 'user_name',
  },
  product_name: {
    Header: 'PRODUCT NAME',
    accessor: 'product_name',
  },
  order_date: {
    Header: 'ORDER DATE',
    accessor: 'created_at',
  },
  last_updated_date: {
    Header: 'LAST UPDATED DATE',
    accessor: 'updated_at',
  },
  status: {
    Header: 'STATUS',
    accessor: 'order_status',
  },
};
