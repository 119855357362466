/* eslint-disable react-hooks/exhaustive-deps */
import {Form, Formik} from 'formik';
import React from 'react';

import useUpcForm from '../../../../hooks/UPCManagement/useUpcForm';
import {upcDetailsValidationSchema} from '../../../../validation/UPCManagement/upcDetails';
import FormikControl from '../../../../components/reusable/Formik/FormikControl';
import {SubmitButton} from '../../../../components/reusable/SubmitButton/SubmitButton';

export default function UpcForm(props) {
  const {setIsOpen, refetch, tag} = props;
  const {initialValues, onSubmit, isGenerateUpcLoading} = useUpcForm({
    setIsOpen,
    refetch,
    tag,
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={upcDetailsValidationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Form>
            <FormikControl
              control="baseinput"
              type="text"
              label="Batch Name"
              name="batch_name"
              initialValue={formik.initialValues.batch_name}
              setFieldValue={formik.setFieldValue}
              setFieldTouched={formik.setFieldTouched}
            />
            <FormikControl
              control="baseinput"
              type="text"
              label="No. of UPCs to generate"
              name="count"
              initialValue={formik.initialValues.count}
              setFieldValue={formik.setFieldValue}
              setFieldTouched={formik.setFieldTouched}
            />
            <SubmitButton
              disabled={formik.dirty && formik.isValid ? false : true}
              type="submit"
              label={`${
                isGenerateUpcLoading ? 'Generating UPC' : 'Generate UPC'
              } `}
              isLoading={isGenerateUpcLoading}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
