import React, {useState, useEffect} from 'react';
import usePreviewLink from '../../hooks/AlbumDetail/usePreviewLink';
import useS3Key from '../../hooks/reusable/useS3key';
import useS3keyForGroupId from '../../hooks/reusable/useS3keyForGroupId';
import {Button as BaseButton} from 'baseui/button';
import {BiDownload} from 'react-icons/bi';

const removeUnderScore = (str) => {
  return str?.toLowerCase()?.replace(' ', '_');
};

const returnS3Key = ({
  dsp,
  groupId = null,
  upc,
  s3KeyGenerator,
  s3KeyGeneratorForGroup,
}) => {
  // group_albums_id
  if (dsp.dsp_type === 'publisher') {
    if (groupId) {
      return s3KeyGeneratorForGroup(
        'output',
        groupId,
        removeUnderScore(dsp.dsp_name),
      );
    } else {
      return s3KeyGenerator('output', upc, removeUnderScore(dsp.dsp_name));
    }
  } else {
    if (groupId) {
      return s3KeyGeneratorForGroup(
        'zip',
        groupId,
        removeUnderScore(dsp.dsp_name),
      );
    } else {
      return s3KeyGenerator('zip', upc, removeUnderScore(dsp.dsp_name));
    }
  }
};

const DownloadPackageButton = ({row}) => {
  const projectId = row?.album[0]?.project_id;
  const dsp = row?.Album_dsp_id[0];
  const groupId = row?.album[0]?.group_albums_id;
  const upc = row?.album[0]?.upc;
  const {s3KeyGenerator} = useS3Key({projectId});
  const {s3KeyGeneratorForGroup} = useS3keyForGroupId({
    projectId,
  });
  const params = {
    projectId,
    dsp,
    groupId,
    upc,
    s3KeyGenerator,
    s3KeyGeneratorForGroup,
  };
  const s3Key = returnS3Key(params);
  const {downloadLink} = usePreviewLink({key: s3Key});
  const [zipUrl, setZipUrl] = useState(null);

  useEffect(() => {
    if (downloadLink) {
      setZipUrl(downloadLink);
    }
  }, [downloadLink]);

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <a href={zipUrl?.url}>
        <BaseButton
          size="mini"
          shape="circle"
          disabled={!(row?.order_status === 3)}
        >
          <BiDownload size={15} />
        </BaseButton>
      </a>
    </div>
  );
};

export default DownloadPackageButton;
