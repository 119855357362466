import {toaster} from 'baseui/toast';
import {createBrowserHistory} from 'history';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';

import albumicon from '../../assets/album.svg';
import approvedicon from '../../assets/approved.svg';
import pendingicon from '../../assets/pending.svg';
import trackicon from '../../assets/track.svg';
import {Tab} from '../../components';
import BulkAdminPackager from '../../components/BulkUploadAdminManagement/BulkAdminPackager';
import BulkSummaryCard from '../../components/BulkUploadAdminManagement/BulkSummaryCard';
import usePublishAlbum from '../../hooks/AlbumTrackApproval/usePublishAlbum';
import useGetSingleProject from '../../hooks/Project/useGetSingleProject';
import useLocalStorage from '../../hooks/reusable/useLocalStorage';
import {packagerColumn} from './tableDataPackager';

export default function BulkAdminPackagerPage() {
  const [activeKey, setActiveKey] = React.useState<string | number>('0');

  const {projectId} = useParams();
  const [isOpen, setIsOpen] = useState(null);
  const [selectedAlbumIds, setSelecedAlbumIds] = useState([]);
  const [selectedTracks, setSelectedTracks] = useState(0);
  const [filteredAlbums, setFilteredAlbums] = useState([]);

  const {
    publishAlbumMutation,
    isLoading: isPublishAlbumLoading,
    error: publishAlbumError,
  } = usePublishAlbum();

  const {
    getSingleProject,
    isLoading,
    isFetching,
    refetch,
  } = useGetSingleProject({
    id: projectId,
  });
  const navigate = useNavigate();

  const [pendingAlbums, setPendingAlbums] = useState([]);
  const [approvedAlbums, setApprovedAlbums] = useState([]);
  const [tracks, setTracks] = useState([]);
  const history = createBrowserHistory();
  const [selectedValue, setSelectedValue] = React.useState([]);
  const albumData = React.useMemo(() => filteredAlbums, [filteredAlbums]);
  const columnData = React.useMemo(() => packagerColumn, [packagerColumn]);

  useEffect(() => {
    const label_name = getSingleProject?.label?.label_name;
    const getAlbums = getSingleProject?.Album.map((albums) => {
      return {
        ...albums,
        label_name,
      };
    });
    setFilteredAlbums(getAlbums);
  }, [getSingleProject]);

  useEffect(() => {
    const ids = selectedValue.map((val) => val.original?.id);
    const tracks = selectedValue
      .map((item) => item?.original?.Track?.length)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    setSelectedTracks(tracks);
    setSelecedAlbumIds(ids);
  }, [selectedValue]);
  useEffect(() => {
    if (selectedTracks > 150) {
      toaster.show(
        `Select below 150 tracks per package. Selected tracks : ${selectedTracks}`,
        {
          autoFocus: true,
          closeable: true,
          kind: 'negative',
          autoHideDuration: 5000,
        },
      );
    }
  }, [selectedTracks]);

  useEffect(() => {
    if (getSingleProject?.Album?.length) {
      const approvedAlbums = getSingleProject?.Album?.filter(
        (item) => item.status === 'orderable',
      );
      setApprovedAlbums(approvedAlbums);
      const pendingAlbums = getSingleProject?.Album?.filter(
        (item) => item.status === 'incomplete',
      );
      setPendingAlbums(pendingAlbums);

      const tracks = getSingleProject?.Album.map(
        (item) => item?.Track?.length,
      ).reduce((accumulator, currentValue) => accumulator + currentValue);

      setTracks(tracks);
    }
  }, [getSingleProject]);

  const publish = async () => {
    const data = {
      project_id: parseInt(projectId),
      album_id: selectedAlbumIds,
      group: true,
    };

    const res = await publishAlbumMutation(data);

    if (res.status === 200) {
      refetch();
    }
  };

  const gotoOrderScreen = () => {
    navigate('/order-management', {
      state: {
        canNavigateToBulkOrders: true,
      },
      // replace: true,
    });
  };

  return (
    <div>
      <div
        className="status-container"
        style={{display: 'flex', justifyContent: 'space-between'}}
      >
        <BulkSummaryCard
          title="Upload summary"
          section1={{
            value: getSingleProject?.Album?.length,
            label: 'Albums',
            icon: <img src={albumicon} alt="albums-icons" />,
            bg: '#ffd7d7',
          }}
          section2={{
            value: tracks,
            label: 'Tracks',
            icon: <img src={trackicon} alt="tracks-icons" />,
            bg: '#ffd7d7',
          }}
        />
        <BulkSummaryCard
          title="Album status"
          section1={{
            value: approvedAlbums.length,
            label: 'Approved',
            icon: <img src={approvedicon} alt="approved-icons" />,
            bg: 'rgba(128, 226, 126, 0.7)',
          }}
          section2={{
            value: pendingAlbums.length,
            label: 'Pending',
            icon: <img src={pendingicon} alt="pending-icons" />,
            bg: 'rgba(255, 176, 76, 0.7)',
          }}
        />
      </div>

      <Tab
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        content={[
          {
            title: `Packager`,
            component: (
              <BulkAdminPackager
                setSelectedValue={setSelectedValue}
                columnData={columnData}
                albumData={albumData}
                setIsOpen={setIsOpen}
                selectedValue={selectedValue}
                selectedAlbumIds={selectedAlbumIds}
                selectedTracks={selectedTracks}
                isPublishAlbumLoading={isPublishAlbumLoading}
                publish={publish}
                filteredAlbums={filteredAlbums}
                gotoOrderScreen={gotoOrderScreen}
              />
            ),
          },
        ]}
      />
    </div>
  );
}
