import {Modal, ModalBody, ModalFooter, ModalHeader} from 'baseui/modal';
import React from 'react';
import Button from '../reusable/Button/Button';
import styled from '@emotion/styled';
import {IoIosAddCircle, IoMdClose} from 'react-icons/io';
import {ImMusic} from 'react-icons/im';
import {useState} from 'react';
import {useEffect} from 'react';
import {FaCheckCircle} from 'react-icons/fa';
import {RiCheckboxCircleFill} from 'react-icons/ri';
import useGetReuseIsrc from '../../hooks/AlbumDetail/useGetReuseIsrc';
import SearchIcon from '../../assets/search.svg';
import {IsrcSkeletonLoad} from './IsrcSkeletonLoad';

const Container = styled.div<{reuseIsrc}>`
  .search {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .result {
      position: absolute;
      margin-right: 5px;
      background: #d21919;
      border-radius: 100px;
      color: #fff;
      padding: 5px 10px;
    }
    input {
      width: 97%;
      padding: 10px;
      background: #f4f5f8;
      border-radius: 5px;
      outline: none;
      border: none;
      height: 30px;
      font-size: 22px;
      color: #333;
      caret-color: #d21919;
      ::placeholder {
        font-size: 18px;
      }
      ::-webkit-search-cancel-button {
        position: relative;
        font-size: 18px;
        right: ${(props) => (props.reuseIsrc ? '140px' : '')};
      }
    }
  }
  .hint {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #e4e4e4;
    flex-direction: column;
    margin-top: 17%;
    img {
      width: 60px;
    }
  }
  .container {
    max-height: 300px;
    overflow: auto;
    margin-top: 10px;
    padding: 5px;
    .track {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 10px;
      background: #ffffff;
      //   box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
      box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px,
        rgb(0 0 0 / 30%) 0px 1px 3px -1px;
      border-radius: 10px;
      align-items: center;
      .details {
        display: flex;
        align-items: center;
        padding: 5px;
        .img {
          width: 60px;
          height: 60px;
          background: #fbe8e8;
          border-radius: 10px;
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            font-size: 22px;
            color: #dc3831;
          }
        }
        .info {
          .info-detail {
            display: flex;
            align-items: center;
            .name {
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              color: #5c5c5c;
              border-right: 1px solid #d21919;
              padding-right: 10px;
              margin-right: 10px;
              height: 12px;
              display: flex;
              align-items: center;
            }
            .isrc {
              font-size: 12px;
              color: #a8a8a8;
            }
          }
          .sub-title {
            color: #a8a8a8;
            font-size: 14px;
          }
        }
      }
    }
  }
`;
const ButtonContainer = styled.div<{selected}>`
  button {
    background: ${(props) =>
      props.selected ? 'rgba(210, 25, 25, 0.1)' : '#e7e7e7'};
    border-radius: 5px;
    padding: 10px 15px;
    color: ${(props) => (props.selected ? '#D21919' : '#5c5c5c')};
    margin-right: 15px;
    cursor: pointer;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    svg {
      // margin-left: 5px;
      font-size: 16px;
    }
  }
`;
const ConfirmModal = styled.div`
  display: flex;
  justify-content: flex-end;
  .cancel {
    button {
      background: #f4f5f8;
      border-radius: 5px;
      border: none;
      width: 80px;
      margin-right: 15px;
      // height: 35px;
      color: #5c5c5c;
      cursor: pointer;
    }
  }
  .proceed {
    button {
      border-radius: 5px;
      border: none;
      background: #d21919;
      color: #fff;
      width: 80px;
      // height: 35px;
      cursor: pointer;
    }
  }
`;

export default function ReuseIsrcModal(props) {
  const {
    reuseIsrcOpen,
    close,
    setReuseIsrcOpen,
    setSearchStr,
    reuseIsrc,
    searchStr,
    setTrackId,
    handleReuseIsrc,
    trackDetail,
    setReuseIsrcOpen2,
    reuseIsrcOpen2,
    reuseIsrcLoad,
    searchLoad,
  } = props;
  const [confirm, setConfirm] = useState(false);
  const [isrc, setIsrc] = useState([]);
  const [isrcProceed, setIsrcProceed] = useState([]);
  const [selectedIsrc, setSelectedIsrc] = useState([]);
  // const [searchStr, setSearchStr] = useState('');
  // const {reuseIsrc, refetch, isFetching, isLoading} = useGetReuseIsrc({
  //   searchStr,
  // });
  //   const [checkboxItems, setCheckboxItems] = useState(List);
  const closeModal = () => {
    setReuseIsrcOpen(false);
    setIsrcProceed([]);
    setIsrcProceed([]);
    setSearchStr('');
  };

  const handleAddTrack = (item) => {
    setReuseIsrcOpen2(true);
    setSelectedIsrc(item.isrc);
    setTrackId(item.id);
  };

  const handleProceed = () => {
    setIsrcProceed((prev) => [...prev, selectedIsrc]);
    handleReuseIsrc();
  };

  return (
    <>
      <Modal
        closeable={false}
        isOpen={reuseIsrcOpen}
        onClose={close}
        overrides={{
          Root: {
            style: {
              zIndex: 100,
            },
          },
          Dialog: {
            style: {
              borderRadius: '5px',
              width: '750px',
              height: '460px',
              background: 'white',
            },
          },
          DialogContainer: {
            style: {
              zIndex: 100,
              display: 'flex',
            },
          },
        }}
      >
        <ModalHeader>
          <div
            className="header"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              boxShadow: 'none',
            }}
          >
            <div>Add existing track</div>
            <div className="close">
              <IoMdClose
                style={{cursor: 'pointer'}}
                onClick={() => {
                  closeModal();
                }}
              />
            </div>
          </div>
        </ModalHeader>
        <div className="list"></div>
        <ModalBody>
          <Container reuseIsrc={reuseIsrc}>
            <div className="search">
              {reuseIsrc ? (
                <div className="result">
                  Showing {reuseIsrc?.length} results
                </div>
              ) : null}
              <input
                type="search"
                value={searchStr}
                onChange={(e) => setSearchStr(e.target.value)}
                placeholder="Search for ISRC.."
              />
            </div>
            {searchLoad && (
              <div>
                <IsrcSkeletonLoad />
                <IsrcSkeletonLoad />
                <IsrcSkeletonLoad />
                <IsrcSkeletonLoad />
              </div>
            )}
            {!reuseIsrc && !searchLoad && (
              <div className="hint">
                <div>
                  <img src={SearchIcon} alt="searchIcon" />
                </div>
                <div>Search to see track results here</div>
              </div>
            )}
            {searchStr && reuseIsrc?.length === 0 && (
              <div className="hint">
                <div>
                  <img src={SearchIcon} alt="searchIcon" />
                </div>
                <div>No results found</div>
              </div>
            )}
            <div className="container">
              {reuseIsrc?.map((item, index) => {
                return (
                  <div className="track">
                    <div className="details">
                      <div className="img">
                        <ImMusic />
                      </div>
                      <div className="info">
                        <div className="info-detail">
                          <div className="name">{item.track_name}</div>
                          <div className="isrc">{item.isrc}</div>
                        </div>
                        <div className="sub-title">
                          {item.album?.album_name}
                        </div>
                      </div>
                    </div>
                    <ButtonContainer
                      selected={
                        isrcProceed.find((val) => val === item.isrc) ||
                        trackDetail.find((val) => val.isrc === item.isrc)
                      }
                    >
                      <Button
                        onClick={() => handleAddTrack(item)}
                        disabled={
                          isrcProceed.find((val) => val === item.isrc) ||
                          trackDetail.find((val) => val.isrc === item.isrc)
                        }
                        label={
                          isrcProceed.find((val) => val === item.isrc) ||
                          trackDetail.find((val) => val.isrc === item.isrc)
                            ? 'Added'
                            : 'Add track'
                        }
                        endEnhancer={() =>
                          isrcProceed.find((val) => val === item.isrc) ||
                          trackDetail.find((val) => val.isrc === item.isrc) ? (
                            <RiCheckboxCircleFill />
                          ) : (
                            <IoIosAddCircle />
                          )
                        }
                      />
                      {/* {isrcProceed.find((val) => val === item.isrc)
                          ? 'Added'
                          : 'Add track'}
                        {isrcProceed.find((val) => val === item.isrc) ? (
                          <RiCheckboxCircleFill />
                        ) : (
                          <IoIosAddCircle />
                        )}
                      </Button> */}
                    </ButtonContainer>
                  </div>
                );
              })}
            </div>
          </Container>
        </ModalBody>
        {/* <ModalFooter>
      <Button
        label="Send Request"
        disabled={disable}
        onClick={handleSendRequest}
        isLoading={isLoading}
      />
    </ModalFooter> */}
      </Modal>
      {reuseIsrcOpen2 && (
        <Modal
          closeable={false}
          isOpen={reuseIsrcOpen}
          onClose={close}
          overrides={{
            Root: {
              style: {
                zIndex: 100,
              },
            },
            Dialog: {
              style: {
                borderRadius: '5px',
                width: '500px',
                background: 'white',
              },
            },
            DialogContainer: {
              style: {
                zIndex: 100,
                display: 'flex',
              },
            },
          }}
        >
          <ModalHeader>
            <div
              className="header"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                boxShadow: 'none',
              }}
            >
              <div>
                Do you want to add {selectedIsrc} this track to this album?
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <ConfirmModal>
              <div className="cancel">
                <Button
                  label="Cancel"
                  onClick={() => {
                    setReuseIsrcOpen2(false);
                  }}
                />
              </div>
              <div className="proceed">
                <Button
                  label="Proceed"
                  onClick={() => handleProceed()}
                  isLoading={reuseIsrcLoad}
                />
              </div>
            </ConfirmModal>
          </ModalBody>
          {/* <ModalFooter>
      <Button
        label="Send Request"
        disabled={disable}
        onClick={handleSendRequest}
        isLoading={isLoading}
      />
    </ModalFooter> */}
        </Modal>
      )}
    </>
  );
}
