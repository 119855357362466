export const requestEditTable = {
  album_name: {
    Header: 'Album Name',
    accessor: 'album_name',
  },

  upc: {
    Header: 'UPC',
    accessor: 'upc',
  },
  meta_data: {
    Header: 'Meta Data',
    accessor: 'meta_data',
  },
  created_by: {
    Header: 'Created By',
    accessor: 'created_by',
  },
  product_request_status: {
    Header: 'Request Status',
    accessor: 'request_status',
  },
  created_on: {
    Header: 'Created On',
    accessor: 'created_at',
  },
  album_release_date: {
    Header: 'Album Release Date',
    accessor: 'album_release_date',
  },
  status: {
    Header: 'Status',
    accessor: 'status',
  },
  action_btn: {
    Header: '',
    accessor: 'complete',
  },
};
