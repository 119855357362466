import {useEffect} from 'react';

import {TAGS} from '../../constants/tags';
import useNotification from '../../Contexts/useNotification';
import useLocalStorage from '../reusable/useLocalStorage';
import useGenerateUpc from './useGenerateUpc';
import useUPC from './useUPC';

const useUpcForm = (props) => {
  const {setIsOpen, refetch: upcBatchRefetch} = props;
  const {showToast} = useNotification();
  const {
    generateUpcMutation,
    isLoading: isGenerateUpcLoading,
    error: generateUpcError,
  } = useGenerateUpc();
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const {getUpcCount, refetch} = useUPC({tag: TAGS.UPC});
  const upcCount = getUpcCount.not_assigned;

  useEffect(() => {
    if (generateUpcError) {
      setIsOpen(true);
    }
  }, [generateUpcError]);

  const initialValues = {
    batch_name: '',
    count: '',
  };

  const onSubmit = async (upcFormData) => {
    const data = {
      user_id: currentUser.id,
      batch_name: upcFormData.batch_name,
      count: Number(upcFormData.count),
    };

    if (upcFormData.count <= upcCount) {
      if (data) {
        const res = await generateUpcMutation({data});

        if (res?.error && res.error?.length > 0) {
          showToast(res?.error, 'error');
        } else {
          closeModalOnSuccess(res);
        }
      }
    } else {
      showToast(`Only ${upcCount} UPCs available in the system.`, 'warning');
    }
  };

  const closeModalOnSuccess = (res) => {
    if (res?.statusCode === 201) {
      setIsOpen(false);
      showToast(`UPC's Generated successfully`, 'success');
      refetch();
      upcBatchRefetch();
    }
  };

  return {
    initialValues,
    onSubmit,
    isGenerateUpcLoading,
  };
};

export default useUpcForm;
