import {useEffect} from 'react';
import {useQuery} from 'react-query';
import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const fetchAlbumDetails = async (key, {albumId}) => {
  if (albumId) {
    try {
      const res = await axios(`/album/${albumId}`, {
        method: 'GET',
      });
      return res.data?.data;
    } catch (error) {
      logError(error, 9);
      return error.response?.data;
    }
  }
};

const useGetAlbumsData = (props) => {
  const albumId = props;

  const {data: getAlbumDetail, refetch, isFetching, isLoading} = useQuery(
    ['singleAlbum', albumId],
    fetchAlbumDetails,
  );

  return {getAlbumDetail, refetch, isFetching, isLoading};
};

export default useGetAlbumsData;
