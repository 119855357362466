import {queryCache, useMutation} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const publishAlbumFn = async (data) => {
  try {
    return await axios(`/publish`, {
      method: 'POST',
      data: data,
    });
  } catch (error) {
    logError(error, 11);
  }
};

const usePublishAlbum = () => {
  const [publishAlbumMutation, {status, data, error, isLoading}] = useMutation(
    publishAlbumFn,
    {
      onSuccess: () => {
        queryCache.invalidateQueries('album');
        queryCache.invalidateQueries('albums');
        queryCache.invalidateQueries('album-approval');
      },
    },
  );

  return {publishAlbumMutation, isLoading, status, error};
};

export default usePublishAlbum;
