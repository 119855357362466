import React, {useReducer, useState} from 'react';

export const GlobalContext = React.createContext({
  type: null,
  uploadStatus: null,
  duplicate_upc: [],
  duplicate_isrc: [],
  duplicate_upc_count: null,
  error: '',
  fileId: '',
  invalid_upc: [],
  valid_upc: null,
  label_id: '',
  label_name: '',
  isAlbumUpdated: false,
  isTrackUpdated: false,
  upc: null,
  isrc: null,
  isRevertModal: false,
  bulkSelectTracks: null,
  trackIsrcToPlay: null,
  isPlaying: false,
  
    selectedDsp: [],
    upc_id:[],
    order_id: [],
    order_start_date: '',
    order_end_date: '',
    order_status: [],
    start_release_date: '',
    end_release_date: '',
  tempImg:{},
  existingProjectId:null,
  token:null
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_TRACK_ISRC':
      return {
        trackIsrcToPlay: action.payload.trackIsrcToPlay,
      };
    case 'SET_IS_PLAYING':
      return {
        isPlaying: action.payload.isPlaying,
      };
    case 'CHANGE_STATUS':
      return {
        ...state,
        uploadStatus: action.payload.file_status,
        duplicate_upc: action.payload.duplicate_upc,
        duplicate_upc_count: action.payload.duplicate_upc_count,
        duplicate_isrc: action.payload.duplicate_isrc,
        error: action.payload.error_message,
        valid_upc_count: action.payload.valid_upc_count,
        invalid_upc: action.payload.invalid_upc,
        type: action.payload.type,
        resource_link: action.payload.resource_link,
        filename: action.payload.filename,
      };
    case 'SET_FILEID':
      return {
        ...state,
        fileId: action.payload.fileId,
      };

    case 'SET_LABEL':
      return {
        ...state,
        label_name: action.payload.label_name,
        label_id: action.payload.label_id,
      };
    case 'SET_ALBUM_UPDATED':
      return {
        ...state,
        isAlbumUpdated: action.payload.isAlbumUpdated,
      };
    case 'SET_TRACK_UPDATED':
      return {
        ...state,
        isTrackUpdated: action.payload.isTrackUpdated,
      };
    case 'SET_UPC':
      return {
        ...state,
        upc: action.payload.upc,
      };
    case 'SET_ISRC':
      return {
        ...state,
        isrc: action.payload.isrc,
      };
    case 'SET_REVERT_MODAL':
      return {
        ...state,
        isRevertModal: action.payload.isRevertModal,
      };
    case 'SET_TRACKS':
      return {
        ...state,
        bulkSelectTracks: action.payload.bulkSelectTracks,
      };
      case 'CHANGE_DSP_DROP':
        return {
          ...state,
            selectedDsp: action.payload.selectedDsp
     
        };
  
      case 'CHANGE_UPC_DROP':
        return {
          ...state,
            upc_id: action.payload.upc_id
          
        };
      case 'CHANGE_ORDERID_DROP':
        return {
          ...state,
            order_id: action.payload.order_id
          
        };
      case 'CHANGE_START_DATE_DROP':
        return {
          ...state,
            order_start_date: action.payload.order_start_date
          
        };
        case 'CHANGE_END_DATE_DROP':
        return {
          ...state,
            order_end_date: action.payload.order_end_date
          
        };
        case 'CHANGE_STATUS_DROP':
          return {
            ...state,
              order_status: action.payload.order_status
            
          };
          case 'CHANGE_START_RELEASE_DATE_DROP':
        return {
          ...state,
            start_release_date: action.payload.start_release_date
          
        };
        case 'CHANGE_END_RELEASE_DATE_DROP':
        return {
          ...state,
            end_release_date: action.payload.end_release_date
          
        };
          case 'TEMP_IMG':
            return {
              ...state,
                tempImg: action.payload.tempImg
              
            };
          case 'EXISTING_PROJECT_ID':
              return {
                ...state,
                existingProjectId: action.payload.existingProjectId
                
              };
          case 'TOKEN_ID':
              return {
                ...state,
                token: action.payload.token
                  
                };

    default:
      return state;
  }
};

export default function GlobalProvider({children}) {
  const [state, dispatch] = useReducer(reducer, GlobalContext);

  return (
    <GlobalContext.Provider value={{state, dispatch}}>
      {children}
    </GlobalContext.Provider>
  );
}
