/** @format */

import * as React from 'react';

import {Table} from '../../../components';

interface Type {
  type: string;
  users: string[];
  roles: string[];
}

interface ColumnMetadata {
  Header: string;
  accessor: string;
}

interface GroupTableProps {
  data: Type[];
  columns: ColumnMetadata[];
  onClickRow: React.Dispatch<React.SetStateAction<boolean>>;
  setSidebarData?: React.Dispatch<React.SetStateAction<any>>;
  tag: string;
}

const GroupTable = ({
  data,
  columns,
  onClickRow,
  setSidebarData,
  tag,
}: GroupTableProps) => {
  const typeData = React.useMemo(() => data, [data]);
  const columnData = React.useMemo(() => columns, [columns]);
  return (
    <Table
      isSearching={null}
      isSearchingError={null}
      upcCount={null}
      isFetching={null}
      refetchHandler={null}
      setSidebarData={setSidebarData}
      columns={columnData}
      data={typeData}
      onClickRow={onClickRow}
      tag={tag}
      setIsOpen={null}
      setNewFormTag={null}
      loadMore={false}
      hasNextPage={false}
      mainActionHandler={null}
      footerComponent={null}
      setSearchStr={null}
    />
  );
};

export default GroupTable;
