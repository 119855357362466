import axios from 'axios';
import React, {useContext} from 'react';
import {usePaginatedQuery, useQuery} from 'react-query';
import {GlobalContext} from '../../Contexts/GlobalProvider';

import {logError} from '../../errorlogger/logError';
import usePaginatedData from '../reusable/usePaginatedData';
import useSearchAlbums from './useSearchAlbums';

const fetchAlbums = async (
  page,
  limit,
  actorsData,
  actressData,
  directorData,
  artistData,
  albumData,
  labelData,
  status,
  product_type,
  content_type,
  upc,
  start_date,
  endDate,
  start_release_date,
  end_release_date,
  migrated,
) => {
  try {
    // /page?page=${page}&limit=${limit}
    const response = await axios(
      `/album/advance-filter?${actorsData}${actressData}${directorData}${artistData}${albumData}${labelData}${status}${product_type}${content_type}${upc}${start_date}${endDate}${start_release_date}${end_release_date}${migrated}&page=${page}&limit=${limit}`,
      {
        method: 'GET',
      },
    );
    return response.data?.data;
  } catch (error) {
    logError(error, 4);

    return error.response?.data;
  }
};

const useAlbumAdvanceFilter = (props) => {
  const {searchStr, allData, dataFilter} = props;
  const {state}: any = useContext(GlobalContext);
  const {
    page,
    setPage,
    data,
    isLastPage,
    totalCount,
    rangeA,
    rangeB,
    lastPage,
    limit,
    setLimit,
    setPaginationDataFn,
    isCustomLoading,
  } = usePaginatedData();

  const {
    resolvedData: sResolvedData,
    isLoading: sIsLoading,
    isError: sIsError,
    error: sError,
    isFetching: sIsFetching,
  } = useSearchAlbums({
    str: searchStr,
    page,
    limit,
  });
  const upc =
    state?.upc_id?.length > 0
      ? `&upc=${state?.upc_id?.map((element) => element).join(',')}`
      : '';
  const actorsData =
    allData?.actors?.length > 0
      ? `&actors_name=${allData?.actors?.map((element) => element).join(',')}`
      : '';
  const actressData =
    allData?.actress?.length > 0
      ? `&actress_name=${allData?.actress?.map((element) => element).join(',')}`
      : '';
  const directorData =
    allData?.director?.length > 0
      ? `&music_director=${allData?.director
          ?.map((element) => element)
          .join(',')}`
      : '';
  const artistData =
    allData?.artist?.length > 0
      ? `&artist_name=${allData?.artist?.map((element) => element).join(',')}`
      : '';
  const albumData =
    allData?.album?.length > 0
      ? `&album_name=${allData?.album?.map((element) => element).join(',')}`
      : '';
  const labelData =
    allData?.label?.length > 0
      ? `&label_name=${allData?.label?.map((element) => element).join(',')}`
      : '';
  const status =
    allData?.status?.length > 0
      ? `&status=${allData?.status?.map((element) => element).join(',')}`
      : '';
  const product_type =
    allData?.product_type?.length > 0
      ? `&product_type=${allData?.product_type
          ?.map((element) => element)
          .join(',')}`
      : '';
  const content_type =
    allData?.content_type?.length > 0
      ? `&album_type=${allData?.content_type
          ?.map((element) => element)
          .join(',')}`
      : '';
  const start_date = !state?.order_start_date
    ? ''
    : state?.order_start_date
    ? `&start_date=${state?.order_start_date}`
    : state?.order_start_date === state?.order_end_date
    ? `&start_date=${state?.order_start_date}`
    : '';
  const endDate = !state?.order_end_date
    ? ''
    : state?.order_start_date === state?.order_end_date
    ? ''
    : `&end_date=${state?.order_end_date}`;
  const start_release_date = !state?.start_release_date
    ? ''
    : state?.start_release_date
    ? `&start_release_date=${state?.start_release_date}`
    : state?.start_release_date === state?.end_release_date
    ? `&start_release_date=${state?.start_release_date}`
    : '';
  const end_release_date = !state?.end_release_date
    ? ''
    : state?.start_release_date === state?.end_release_date
    ? ''
    : `&end_release_date=${state?.end_release_date}`;
      
  const migrated =
    dataFilter === 'all'
      ? ''
      : dataFilter === 'migrated'
      ? `&migrated=${true}`
      : `&migrated=${false}`;
  const {
    isLoading,
    isError,
    error,
    resolvedData,
    isFetching,
    refetch,
  } = usePaginatedQuery(
    [
      'albumsFilter',
      page,
      limit,
      actorsData,
      actressData,
      directorData,
      artistData,
      albumData,
      labelData,
      status,
      product_type,
      content_type,
      upc,
      start_date,
      endDate,
      start_release_date,
      end_release_date,
      migrated,
    ],
    () =>
      fetchAlbums(
        page,
        limit,
        actorsData,
        actressData,
        directorData,
        artistData,
        albumData,
        labelData,
        status,
        product_type,
        content_type,
        upc,
        start_date,
        endDate,
        start_release_date,
        end_release_date,
        migrated,
      ),
  );

  React.useEffect(() => {
    if (resolvedData && !searchStr) {
      setPaginationDataFn(resolvedData);
    }
  }, [resolvedData, searchStr]);

  React.useEffect(() => {
    if (sResolvedData) {
      setPaginationDataFn(sResolvedData);
    }
    if (searchStr === '') {
      if (resolvedData) {
        setPaginationDataFn(resolvedData);
      }
    }
  }, [sResolvedData]);

  return {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    resolvedData,
    setPage,
    page,
    isLastPage,
    totalCount,
    rangeB,
    rangeA,
    lastPage,
    setLimit,
    limit,
    sIsLoading,
    sIsError,
    sError,
    sIsFetching,
    isCustomLoading,
    refetch,
  };
};

export default useAlbumAdvanceFilter;
