import styled from '@emotion/styled';
import {Block} from 'baseui/block';
import {StatefulTooltip} from 'baseui/tooltip';
import React from 'react';

const CheckBoxComponent = (props) => {
  return (
    <>
      <StyledInput id={props.id} type="checkbox" {...props} />
      <StatefulTooltip
        content={() => <Block>{props.value}</Block>}
        focusLock
        returnFocus
        autoFocus
        overrides={{
          Body: {
            style: {
              zIndex: props.value?.length > 12 ? 999 : -1,
              maxWidth: '400px',
              textTransform: 'capitalize',
            },
          },
        }}
      >
        <label htmlFor={props.id}>{props.value}</label>
      </StatefulTooltip>
    </>
  );
};

const StyledInput = styled.input`
  --active: #ca332e;
  --active-inner: #fff;
  --focus: 2px rgb(230 144 144 / 58%);
  --border: #e1bbbb;
  --border-hover: #ca332e;
  --background: #fff;
  --disabled: #f6f8ff;
  --disabled-inner: #e1e6f9;

  -webkit-appearance: none;
  -moz-appearance: none;
  height: 16px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;

  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
  }
  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }
  &:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }
    & + label {
      cursor: not-allowed;
    }
  }
  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        --bc: var(--border-hover);
      }
    }
  }
  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  &:not(.switch) {
    width: 16px;
    &:after {
      opacity: var(--o, 0);
    }
    &:checked {
      --o: 1;
    }
  }
  & + label {
    font-size: 12px;
    color: rgb(102, 102, 102);
    user-select: none;
    line-height: 16px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 10px;
    white-space: nowrap;
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:not(.switch) {
    border-radius: 7px;
    &:after {
      width: 4px;
      height: 8px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 5px;
      top: 2px;
      transform: rotate(var(--r, 20deg));
    }
    &:checked {
      --r: 43deg;
    }
  }
  &.switch {
    width: 38px;
    border-radius: 11px;
    &:after {
      left: 2px;
      top: 2px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background: var(--ab, var(--border));
      transform: translateX(var(--x, 0));
    }
    &:checked {
      --ab: var(--active-inner);
      --x: 17px;
    }
    &:disabled {
      &:not(:checked) {
        &:after {
          opacity: 0.6;
        }
      }
    }
  }
`;

export default CheckBoxComponent;
