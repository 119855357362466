import axios from 'axios';
import {useState} from 'react';
import {useContext} from 'react';
import {queryCache} from 'react-query';
import {v4 as uuidv4} from 'uuid';

import {TOKEN, token} from '../config/Axios';
import {GlobalContext} from '../Contexts/GlobalProvider';
import useLocalStorage from '../hooks/reusable/useLocalStorage';
import useCreateUploadStatus from '../hooks/UploadStatus/useCreateUploadStatus';

const BASE_URL = process.env.REACT_APP_Base_URL;

const useUploadMetaFile = (props) => {
  const {metadata} = props;
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const {createUploadStatus, status, error} = useCreateUploadStatus();

  const [source, setSource] = useState(null);
  const CancelToken = axios.CancelToken;
  const {state, dispatch} = useContext(GlobalContext);
  const MAIN_TOKEN = token ? TOKEN : 'bearer '.concat(state?.token);

  const uploadFile = async (file, project_id, path_name, onUploadProgress) => {
    let path = '';
    let otherParam;
    const fileID = uuidv4();

    path_name === 'excel'
      ? (path = `${project_id}/${fileID}/input.xlsx`)
      : path_name === 'upc'
      ? (path = `${fileID}/${file.name}`)
      : project_id === ''
      ? (path = `${path_name}/${file.name}`)
      : (path = `${project_id}/${path_name}/${file.name}`);

    const type =
      metadata?.type === 'bulk-upload'
        ? 'bulk-upload'
        : project_id === ''
        ? 'upc'
        : 'manual-upload';

    const fileType = path_name === 'excel' ? 'input' : 'others';

    const data = {
      file_id: fileID, //uuid
      project_id: project_id,
      user_id: currentUser.id,
      filename: file.name,
      type: type, //upc or bulk_upload
    };

    const upcParams = {
      key: path,
      expireTime: 3600,
      type,
      fileType,
    };

    if (path_name === 'excel' && metadata?.territory && metadata?.country) {
      otherParam = {
        key: path,
        expireTime: 3600,
        type,
        fileType,
        territory: metadata.territory,
        country: JSON.stringify(metadata.country),
        access_token: MAIN_TOKEN,
      };
    } else {
      if (!metadata) {
        otherParam = {
          key: path,
          expireTime: 3600,
          type,
          fileType,
        };
      } else {
        otherParam = {
          key: path,
          expireTime: 3600,
          type,
          fileType,
          album_id: metadata.album_id,
          user_id: metadata.user_id,
          action: metadata.action,
          access_token: MAIN_TOKEN,
          track_id: metadata.track_id,
        };
      }
    }

    const params = project_id === '' ? upcParams : otherParam;

    if (path_name === 'excel' || path_name === 'upc') {
      const res = await createUploadStatus({data});

      if (res?.statusCode === 201) {
        const uploadStatusId = res?.data?.id;

        dispatch({
          type: 'SET_FILEID',
          payload: {
            fileId: uploadStatusId,
          },
        });

        try {
          const response = await axios(`${BASE_URL}/project/upload-url`, {
            method: 'GET',
            params,
          });

          const url = response.data?.data?.url;

          const source = CancelToken.source();
          setSource(source);
          const res = await axios.put(url, file, {
            onUploadProgress,
            cancelToken: source.token,
          });

          return res;
        } catch (error) {
          if (axios.isCancel(error)) {
            return error.message;
          }
          if (error?.response?.status === 500) {
            console.log('There was a problem with the server');
          } else {
            console.log(error?.response?.data?.msg);
          }
          return error;
        }
      } else {
        console.log('Something wrong');
      }
    } else {
      try {
        const response = await axios(`${BASE_URL}/project/upload-url`, {
          method: 'GET',
          params,
        });

        if (response.data?.statusCode === 200) {
          const url = response.data?.data?.url;

          const source = CancelToken.source();
          setSource(source);
          const res = await axios.put(url, file, {
            onUploadProgress,
            cancelToken: source.token,
          });

          if (res.status === 200) {
            queryCache.invalidateQueries('download_link');
          }

          return res;
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return error.message;
        }
        if (error?.response?.status === 500) {
          console.log('There was a problem with the server');
        } else {
          console.log(error?.response?.data?.msg);
        }
        return error;
      }
    }
  };

  const cancel = () => {
    if (source?.cancel) {
      source.cancel('error');
    }
  };

  return {
    uploadFile,
    cancel,
  };
};

export default useUploadMetaFile;
