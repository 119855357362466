import React, {useEffect, useState} from 'react';

import useTablePageState from '../../../hooks/reusable/useTablePageState';
import useListTypes from '../../../hooks/UserManagement/useListTypes';
import {roleColumnMeta} from '../../../tableColumns/UserManagement/tableData';
import LoadingMain from '../../../components/reusable/Loading/LoadingMain';
import {RoleTable} from '../tables';

const Role = () => {
  const {setIsOpen, setSelectedData} = useTablePageState();

  const {userTypes, isLoading} = useListTypes();
  const [roleData, setRoleData] = useState([]);

  useEffect(() => {
    if (userTypes) {
      setRoleData(userTypes[userTypes.length - 1].roles);
    }
  }, [userTypes]);
  return (
    <>
      {isLoading ? (
        <LoadingMain />
      ) : (
        <RoleTable
          columns={roleColumnMeta}
          data={roleData}
          onClickRow={setIsOpen}
          setSidebarData={setSelectedData}
          tag="role"
        />
      )}
    </>
  );
};

export default Role;
