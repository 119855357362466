/** @format */

import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Theme} from 'baseui/theme';
import {ErrorMessage, Field} from 'formik';
import React from 'react';

import {CustomThemeType} from '../../../styles/theme/colors';
import {Label} from './BaseInput';
import TextError from './TextError';

function Input(props) {
  const [css, theme] = useStyletron();
  const {label, name, isRequired, ...rest} = props;
  return (
    <Input.Container>
      <div className="input_item">
        <label htmlFor={name}>
          <Label data={{children: label}} isRequired={isRequired} />
        </label>
        <Field as="input" type="date" id={name} name={name} {...rest} />
      </div>
      <div className="right error">
        <ErrorMessage name={name} component={TextError} />
      </div>
    </Input.Container>
  );
}

Input.Container = styled.div<{theme: Theme & CustomThemeType}>`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.65rem;

  .input_item {
    justify-content: flex-start;
    align-items: flex-start;

    flex-direction: column;
    margin: 0 0 0.8rem 0;

    .left {
      flex: 1;
      text-align: right;
      label {
        margin: 0;
      }
    }

    .right {
      padding: 0.85rem 0;
    }
  }

  label {
    margin-bottom: 0.35rem;
    padding: 0;
    font-size: 0.85rem;
    font-weight: 600;
    color: ${(props) => props.theme.textColor};
  }

  input {
    font-size: 0.85rem;
    border-radius: 5px;
    padding: 0.85rem;
    background-color: rgb(239, 241, 243);
    border: none;
    width: -webkit-fill-available;

    &:focus {
      outline: none;
    }
  }
`;

export default Input;
