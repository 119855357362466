import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';

import {Tab} from '../../components';
import {tracksColumnMeta} from '../../components/AlbumDetail/tableData';
import DownloadZipModal from '../../components/AlbumsInReview/DownloadZipModal';
import FlyMenu from '../../components/reusable/FlyMenu/FlyMenu';
import LoadingSpinnerTransparent from '../../components/reusable/LoadingSpinnerTransparent/LoadingSpinnerTransparent';
import {TAGS} from '../../constants/tags';
import useBulkApproveAlbum from '../../hooks/AlbumDetail/useBulkApproveAlbum';
import useGetAlbumsData from '../../hooks/AlbumDetail/useGetAlbumsData';
import useStatusUpdateHandler from '../../hooks/AlbumDetail/useStatusUpdateHandler';
import useSendRequest from '../../hooks/AlbumsManagement/useSendRequest';
import useLocalStorage from '../../hooks/reusable/useLocalStorage';
import useMusicPlayer from '../../hooks/reusable/useMusicPlayer';
import useS3Key from '../../hooks/reusable/useS3key';
import useTablePageState from '../../hooks/reusable/useTablePageState';
import ActionButtonDropDownContent from '../../pages/AlbumsManagment/AlbumDetail/ActionButtonDropDownContent';
import Album from '../../pages/AlbumsManagment/AlbumDetail/Album';
import AlbumInfo from '../../pages/AlbumsManagment/AlbumDetail/AlbumInfo';
import MusicPlayerNew from '../../pages/AlbumsManagment/AlbumDetail/MusicPlayerNew';
import SkeletonAlbumDetail from '../../pages/SkeletonAlbumDetail';

const BulkUploadAdminAlbumDetails = () => {
  const {albumId} = useParams();
  const [activeKey, setActiveKey] = React.useState<string | number>('0');
  const [tracks, setTracks] = useState(null);
  const [trackData, setTrackData] = useState(null);

  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const [trackIsrc, setTrackIsrc] = useState(null);
  const [toggleDspModal, setToggleDspModal] = useState(false);
  const [musicDirectors, setMusicDirectors] = useState([]);

  const {getAlbumDetail, refetch, isFetching, isLoading} = useGetAlbumsData({
    albumId: albumId,
  });
  const [collapsePlayer, setCollapsePlayer] = useState(false);

  useEffect(() => {
    const md = getAlbumDetail?.Track.map((track) => track?.music_director);
    const mergedArr = Array.prototype.concat.apply([], md);
    const unique = [...new Set(mergedArr)];
    setMusicDirectors(unique);
  }, [getAlbumDetail]);

  useEffect(() => {
    if (getAlbumDetail) {
      setTracks(getAlbumDetail.Track);
      const d = getAlbumDetail.Track.find(
        (track, i) => track.isrc === trackIsrc,
      );
      setTrackData(d);
    }
  }, [getAlbumDetail, trackIsrc]);

  const {albumLoading, trackLoading, updateStatus} = useBulkApproveAlbum({
    data: getAlbumDetail?.Track,
  });

  const {
    isOpen,
    setNewFormTag,
    newFormTag,
    setIsOpen,
    setSelectedData,
    selectedData,
  } = useTablePageState();

  const {
    played,
    seeking,
    volume,
    handleSeekMouseDown,
    handleSeekChange,
    handleSeekMouseUp,
    handleVolumeChange,
    playerRef,
    handleStop,
    handlePlayPause,
    setPlayed,
    setCurrentTime,
    msToTime,
    setDuration,
    currentTime,
    duration,
    playing,
    handleMute,
    muted,
  } = useMusicPlayer({isrcCode: trackIsrc});

  const {s3KeyGenerator} = useS3Key({projectId: getAlbumDetail?.project_id});

  const [dynamicTag, setDynamicTag] = useState(TAGS.TRACKS);
  const [isAlbumClick, setIsAlbumClick] = useState(false);
  const [requestOpen, setRequestOpen] = useState(false);
  const [editComment, setEditComment] = useState('');
  const [metaData, setMetaData] = useState([]);
  const data = {
    album_id: getAlbumDetail?.id ? getAlbumDetail?.id : albumId,
    raised_by: currentUser.id,
    metadata: metaData,
    comment: editComment,
  };

  const {sendEditRequest, error} = useSendRequest();
  const content = [
    {
      title: `Track Details`,
      component: (
        <Album
          currentUser={currentUser}
          columns={tracksColumnMeta}
          setIsOpen={setIsOpen}
          data={getAlbumDetail?.Track}
          setSelectedData={setSelectedData}
          setNewFormTag={setNewFormTag}
          tag={dynamicTag}
          setTrackIsrc={setTrackIsrc}
          handlePlayPause={handlePlayPause}
          playing={playing}
          duration={duration}
          trackIsrc={trackIsrc}
          hasCoverArtValue={getAlbumDetail?.cover_art ? true : false}
          // status={status}
          // productType={productType}
          noAddTrack={true}
          refetchHandler={refetch}
          isFetching={isFetching}
        />
      ),
    },
  ];

  const openAddCoverArtHandler = () => {
    setIsOpen(true);
    setNewFormTag('add-coverart');
  };

  const requestModal = () => {
    setRequestOpen(true);
  };

  const openAlbumForm = () => {
    if (getAlbumDetail) {
      setIsAlbumClick(true);
      setIsOpen(true);
      setSelectedData({original: getAlbumDetail});
    }
  };

  const changeTrack = (type) => {
    const index = tracks
      .sort((a, b) => {
        return a.track_order - b.track_order;
      })
      .findIndex((track, i) => track.isrc === trackIsrc);

    if (type === 'next') {
      if (index === tracks.length - 1) {
        setTrackIsrc(tracks[0].isrc);
        setTrackData(tracks[0]);
      } else {
        setTrackIsrc(tracks[index + 1].isrc);
        setTrackData(tracks[index + 1]);
      }
    } else {
      if (index === 0) {
        setTrackIsrc(tracks[tracks.length - 1].isrc);
        setTrackData(tracks[tracks.length - 1]);
      } else {
        setTrackIsrc(tracks[index - 1].isrc);
        setTrackData(tracks[index - 1]);
      }
    }
  };
  if (isLoading) {
    return <SkeletonAlbumDetail />;
  }
  return (
    <>
      {(albumLoading || trackLoading) && <LoadingSpinnerTransparent />}

      <AlbumInfo
        isOpen={isOpen}
        getAlbumDetail={getAlbumDetail}
        musicDirectors={musicDirectors}
        // openFlyMenu={openFlyMenu}
        openAddCoverArtHandler={openAddCoverArtHandler}
        setToggleDspModal={setToggleDspModal}
        // updateStatusHandler={updateStatusHandler}
        hasCoverArtValue={getAlbumDetail?.cover_art ? true : false}
        hasNoTracks={getAlbumDetail?.Track?.length === 0 ? true : false}
        actionDropDownContent={
          <ActionButtonDropDownContent
            getAlbumDetail={getAlbumDetail}
            hasCoverArtValue={getAlbumDetail?.cover_art ? true : false}
            updateStatusHandler={updateStatus}
            hasNoTracks={getAlbumDetail?.Track?.length === 0 ? true : false}
            requestModal={requestModal}
            tag={TAGS.BULK_ADMIN}
          />
        }
        setIsAlbumClick={setIsAlbumClick}
        setIsOpen={setIsOpen}
        tag={dynamicTag}
        openAlbumForm={openAlbumForm}
        hasBackButton={true}
        type="bulk-admin"
      />
      <Tab
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        content={content}
      />

      {toggleDspModal && (
        <DownloadZipModal
          id={getAlbumDetail?.id}
          showModal={toggleDspModal}
          onCloseHandler={() => setToggleDspModal(false)}
          sidebarRef={null}
        />
      )}

      {isOpen && (
        <FlyMenu
          refetch={refetch}
          setSelectedData={setSelectedData}
          newFormTag={newFormTag}
          selectedData={selectedData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setNewFormTag={setNewFormTag}
          setDynamicTag={setDynamicTag}
          tag={isAlbumClick ? TAGS.EDIT_ALBUMS : TAGS.TRACKS}
          setIsAlbumClick={setIsAlbumClick}
          closeable={false}
        />
      )}

      {trackIsrc && (
        <MusicPlayerNew
          played={played}
          seeking={seeking}
          volume={volume}
          handleSeekMouseDown={handleSeekMouseDown}
          handleSeekChange={handleSeekChange}
          handleSeekMouseUp={handleSeekMouseUp}
          handleVolumeChange={handleVolumeChange}
          playerRef={playerRef}
          handleStop={handleStop}
          handlePlayPause={handlePlayPause}
          setPlayed={setPlayed}
          setCurrentTime={setCurrentTime}
          msToTime={msToTime}
          setDuration={setDuration}
          currentTime={currentTime}
          duration={duration}
          playing={playing}
          getAlbumDetail={getAlbumDetail}
          data={trackData}
          s3Key={s3KeyGenerator('audio', trackIsrc, null)}
          muted={muted}
          handleMute={handleMute}
          isOpen={isOpen}
          setCollapsePlayer={setCollapsePlayer}
          collapsePlayer={collapsePlayer}
          changeTrack={changeTrack}
        />
      )}
    </>
  );
};
export default BulkUploadAdminAlbumDetails;
