/** @format */
import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {FileUploader} from 'baseui/file-uploader';
import {ErrorMessage} from 'formik';
import React from 'react';
import {MdInfo} from 'react-icons/md';

import useNotification from '../../../Contexts/useNotification';
import useFile from '../../../hooks/Files/useFile';
import useModalHook from '../../../hooks/Modal/useModal';
import Icon from '../../../styles/ui-component/Icon';
import FilesModal from '../../CreateProject/BulkUpload/FilesModal/FilesModal';
import Button from '../Button/Button';
import TextError from './TextError';

const rootStyle = {
  style: () => ({
    width: '100%',
    borderRadius: '10px',
  }),
};

const dragNDrop = {
  style: () => ({
    width: '80%',
    height: '15rem',
    margin: '0 auto',
  }),
};

export default (props) => {
  const [css, theme] = useStyletron();
  const {
    label,
    name,
    accept,
    multiple,
    iconName,
    buttonName,
    initialValue,
    setFieldValue,
    setIsComplete,
    isShowButton,
    project,
    disabled,
    progress,
    cancelUpload,
    ...rest
  } = props;

  const {isOpen, setIsOpen, close} = useModalHook(false);
  const {files, setFiles} = useFile([]);
  const {showToast} = useNotification();

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        margin: '1rem',
      })}
    >
      <FilesModal
        isOpen={isOpen}
        close={close}
        files={files}
        setIsComplete={setIsComplete}
        project={project}
        name={name}
      />
      <FileUploader
        overrides={{
          FileDragAndDrop: dragNDrop,
          ContentMessage: {
            component: () => (
              <div>
                <Icon
                  className={css({display: 'block', margin: '0 auto'})}
                  name={iconName}
                  width="27"
                  height="34"
                  viewBox="0 0 27 34"
                  fill={props.fillColor}
                />
                <p
                  style={{
                    fontSize: '0.95rem',
                    fontWeight: '400',
                    margin: '0.75rem 0',
                    textAlign: 'center',
                  }}
                >
                  Drag and drop files here
                </p>
                <Container>
                  <MdInfo />
                  <p
                    style={{
                      fontSize: '0.85rem',
                      fontWeight: '400',
                      margin: '0.75rem 0',
                      textAlign: 'center',
                    }}
                  >
                    {iconName === 'audio'
                      ? 'Tracks must be renamed as ISRC.wav'
                      : 'Cover Art must be renamed as UPC.jpg'}
                  </p>
                </Container>
              </div>
            ),
          },
          Root: rootStyle,
        }}
        onCancel={cancelUpload}
        onDrop={(acceptedFiles, rejectedFiles) => {
          // handle file upload...
          if (acceptedFiles.length !== 0) {
            setFieldValue(`${name}`, acceptedFiles);
            setFiles(acceptedFiles);
            setIsOpen(true);
            return;
          }
          const files_rejected = rejectedFiles.map(({name}) => name);
          files_rejected.length === 1
            ? showToast(
                `The file type "${rejectedFiles[0].type}" is not supported`,
                'warning',
              )
            : showToast(
                `The audio formats for these tracks are not supported - "${files_rejected.join(
                  '\n ',
                )}"`,
                'warning',
              );
        }}
        id={name}
        name={name}
        accept={accept}
        onChange={(acceptedFiles) => {
          setFieldValue(`${name}`, acceptedFiles);
        }}
        hidden
        multiple={multiple}
      />
      {isShowButton
        ? !disabled && (
            <Footer theme={theme}>
              <div>
                <Button label="Upload UPC" type="submit" disabled={disabled} />
              </div>
            </Footer>
          )
        : null}
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
};

const Footer = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: flex-end;
  height: 55px;
  background: ${(props) => props.theme.bg && props.theme.bg};
  box-shadow: 0px -8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 3px;
  }
`;
