import React from 'react';

import {Tab} from '../../components';
import ISRC from '../ISRC-UPCManagement/IsrcManagement/Isrc';
import UPC from '../ISRC-UPCManagement/UpcManagement/Upc';

const ISRCUPCManagement = () => {
  const [activeKey, setActiveKey] = React.useState<string | number>('0');

  return (
    <div>
      <Tab
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        content={[
          {
            title: 'UPC Management',
            component: <UPC />,
          },
          {
            title: 'ISRC Management',
            component: <ISRC />,
          },
        ]}
      />
    </div>
  );
};

export default ISRCUPCManagement;
