import {useEffect, useState} from 'react';

import useNotification from '../../Contexts/useNotification';
import useLocalStorage from '../../hooks/reusable/useLocalStorage';
import useCreateUser from '../../hooks/UserManagement/useCreateUser';
import useGetSingleUser from '../../hooks/UserManagement/useGetUser';
import useListTypes from '../../hooks/UserManagement/useListTypes';
import useUpdateUser from '../../hooks/UserManagement/useUpdateUser';

const useUserForm = (props) => {
  const {selectedData, setIsOpen, refetch, setSelectedData} = props;

  const {showToast} = useNotification();
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const {getSingle, isLoading} = useGetSingleUser({
    id: selectedData ? selectedData.id : null,
  });

  const [roleIndex, setRoleIndex] = useState(null);
  const [userLoading, setUserLoading] = useState(true);
  const {
    createUserMutation,
    isLoading: isCreateUserLoading,
    error: createUserError,
  } = useCreateUser();

  const {
    updateUserMutation,
    isLoading: isUpdateUserLoading,
    error: updateUserError,
  } = useUpdateUser();

  const {roleOptions, isLoading: roleIsLoading} = useListTypes();

  useEffect(() => {
    setUserLoading(true);
    if (!isLoading && !roleIsLoading) {
      setUserLoading(false);
    }
  }, [isLoading, roleIsLoading]);

  useEffect(() => {
    if (updateUserError || createUserError) {
      setIsOpen(true);
    }
  }, [updateUserError, createUserError]);

  const initialValues = {
    type: getSingle ? [getSingle?.type] : [],
    roless: getSingle
      ? getSingle?.roles?.map(({id, name, label}) => ({id, name, label}))
      : [],
    name: getSingle ? getSingle?.name : '',
    phone: getSingle ? getSingle?.phone : '',
    email: getSingle ? getSingle?.email : '',
  };

  const onSubmit = async (userFormData) => {
    const typeId = userFormData.type[0].id;

    const roleIds = userFormData.roless.map((role) => role.id);

    if (typeId) {
      const data = {
        email: userFormData.email,
        name: userFormData.name,
        phone: userFormData.phone,
        roles: roleIds,
        type: typeId,
        user_id: currentUser.id,
        updated_by: currentUser && currentUser.id,
      };

      try {
        if (selectedData?.id) {
          const id = selectedData.id;
          const res = await updateUserMutation({data, id});
          if (res?.error && res.error?.length > 0) {
            showToast(res?.error, 'error');
          }
          closeModalOnSuccess(res);
        } else {
          delete data.updated_by;
          const res = await createUserMutation({data});
          if (res?.error && res.error?.length > 0) {
            showToast(res?.error, 'error');
          }
          closeModalOnSuccess(res);
        }
      } catch (error) {
        console.log({error});
      }
    }
  };

  const closeModalOnSuccess = (res) => {
    if (res?.statusCode === 200 || res?.statusCode === 201) {
      refetch();
      setSelectedData({});
      setIsOpen(false);
      showToast(
        `User ${selectedData ? `updated` : `added`} successfully`,
        'success',
      );
    }
  };

  return {
    isLoading,
    initialValues,
    onSubmit,
    getSingle,
    roleOptions,
    userLoading,
    roleIndex,
    setRoleIndex,
    isCreateUserLoading,
    isUpdateUserLoading,
  };
};

export default useUserForm;
