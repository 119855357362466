/** @format */

import React from 'react';

import BaseInput from '../Formik/BaseInput';
import BaseSelect from '../Formik/BaseSelect';
import BaseDatePicker from './BaseDatePicker';
import BaseDescField from './BaseDescField';
import BaseFile from './BaseFile';
import BaseMultiSelect from './BaseMultiSelect';
import CheckboxGroup from './CheckboxGroup';
import DetailsInput from './DetailsInput';
import DetailsMultiSelect from './DetailsMultiSelect';
import DetailsRadio from './DetailsRadio';
import DetailsSelect from './DetailsSelect';
import DrawerInput from './DrawerInput';
import DrawerInputWButton from './DrawerInputWButton';
import DrawerSelect from './DrawerSelect';
import DynamicInput from './DynamicInput';
import File from './File';
import Input from './Input';
import InputWithButton from './InputWithButton';
import LabelField from './LabelFIeld';
import MultipleInput from './MultipleInput';
import MultiSelect from './MultiSelect';
import RadioButtons from './RadioButtons';
import Select from './Select';
import TerritoryRadio from './TerritoryRadio';
import Textarea from './Textarea';
import TransferListMultiSelect from './TransferListMultiSelect';
import UserBaseSelect from './UserBaseSelect';
import UserMultiSelect from './UserMultiSelect';

function FormikControl(props) {
  const {control, ...rest} = props;
  switch (control) {
    case 'input':
      return <Input {...rest} />;
    case 'baseinput':
      return <BaseInput {...rest} />;
    case 'textarea':
      return <Textarea {...rest} />;
    case 'select':
      return <Select {...rest} />;
    case 'file':
      return <File {...rest} />;
    case 'baseselect':
      return <BaseSelect {...rest} />;
    case 'userbaseselect':
      return <UserBaseSelect {...rest} />;
    case 'baseMultiSelect':
      return <BaseMultiSelect {...rest} />;
    case 'radio':
      return <RadioButtons {...rest} />;
    case 'radioButtons':
      return <DetailsRadio {...rest} />;
    case 'checkbox':
      return <CheckboxGroup {...rest} />;
    case 'inputWithButton':
      return <InputWithButton {...rest} />;
    case 'multipleInput':
      return <MultipleInput {...rest} />;
    case 'dynamicInput':
      return <DynamicInput {...rest} />;
    case 'detailsInput':
      return <DetailsInput {...rest} />;
    case 'detailsSelect':
      return <DetailsSelect {...rest} />;
    case 'detailsMultiSelect':
      return <DetailsMultiSelect {...rest} />;
    case 'newMultiSelect':
      return <MultiSelect {...rest} />;
    case 'userMultiSelect':
      return <UserMultiSelect {...rest} />;
    case 'basefile':
      return <BaseFile {...rest} />;
    case 'baseDatePicker':
      return <BaseDatePicker {...rest} />;
    case 'labelField':
      return <LabelField {...rest} />;
    case 'baseDesc':
      return <BaseDescField {...rest} />;
    case 'drawerInput':
      return <DrawerInput {...rest} />;
    case 'drawerInputWButton':
      return <DrawerInputWButton {...rest} />;
    case 'drawerSelect':
      return <DrawerSelect {...rest} />;
    case 'territoryRadio':
      return <TerritoryRadio {...rest} />;
    case 'transferList':
      return <TransferListMultiSelect {...rest} />;
    default:
      return null;
  }
}

export default FormikControl;
