import styled from '@emotion/styled';
import moment from 'moment';
import React from 'react';
import {GrLinkNext} from 'react-icons/gr';
import {TAGS} from '../../../constants/tags';

import Tooltip from '../ToolTip/ToolTip';

const UserHistoryCardContainer = styled.div<{action}>`
  display: flex;
  font-size: 14px;
  padding: 20px 0px;
  border-bottom: 1px solid #efe7e7;
  .col1 {
    .dp {
      background-color: grey;
      width: 38px;
      height: 38px;
      border-radius: 100px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 100px;
      }
    }
  }
  .col2 {
    width: 100%;

    .row1 {
      display: flex;
      justify-content: space-between;
      .desc-container {
        display: flex;
      }
      .date-container {
        color: #a2a2a2;
      }
    }
  }
  .row2 {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .created {
      background: ${(props) =>
        props.action === 'DELETE' ? '#ffd3d3' : '#cfffd7'};
      border-radius: 3px;
      margin-right: 5px;
      span {
        color: ${(props) =>
          props.action === 'DELETE' ? '#dc3831' : '#038520'};
        padding: 15px;
        font-weight: bold;
      }
    }
    .arrow {
      margin: 0px 10px;
    }
  }
`;

export default function UserCreateHistoryCard(props) {
  const {userName, createdValue, date, email, action, tag} = props;
  const renderLabel = (tag) => {
    if (tag === TAGS.DSP) {
      return 'dsp';
    } else if (tag === TAGS.LABEL) {
      return 'label';
    } else {
      return 'user';
    }
  };
  return (
    <UserHistoryCardContainer action={action}>
      {/* <div className="col1">
        <div className="dp">
          <img src="https://i.pravatar.cc/300" alt="img" />
        </div>
      </div> */}
      <div className="col2">
        <div className="row1">
          <div className="desc-container">
            <Tooltip isOpen={true} content={email} direction="top">
              <strong>{userName}</strong>
            </Tooltip>
            &nbsp; {action === 'DELETE' ? 'disabled' : 'created'} &nbsp;
            <strong
              style={{
                color: '#DC3831',
              }}
            >
              {action === 'DELETE' ? '' : 'new'} {renderLabel(tag)}
            </strong>
          </div>
          <div className="date-container">
            {moment(date).format('MMMM Do YYYY, h:mm a')}
          </div>
        </div>
        <div className="row2">
          <div className="created">
            <span>{createdValue}</span>
          </div>
          has been {action === 'DELETE' ? 'disabled' : 'created'}
        </div>
      </div>
    </UserHistoryCardContainer>
  );
}
