export const typeData = {
  type_name: {
    Header: 'Type',
    accessor: 'name',
  },
  roles: {
    Header: 'Roles',
    accessor: 'roles',
  },
};
