import {useMutation} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const exportExcel = async (data) => {
  try {
    const res = await axios(`/export-excel`, {
      method: 'POST',
      data: data,
    });
    return res.data;
  } catch (error) {
    logError(error, 3);

    if (error.response) {
      return error.response.data;
    }
  }
};

const useExportExcel = () => {
  const [exportExcelFn, {status, data, error, isLoading}] = useMutation(
    exportExcel,
  );

  return {exportExcelFn, isLoading, error, status};
};

export default useExportExcel;
