import styled from '@emotion/styled';
import React from 'react';
import {TiWarning} from 'react-icons/ti';

import Tooltip from '../../../components/reusable/ToolTip/ToolTip';
import {TAGS} from '../../../constants/tags';

const ActionButtonDropDownContent = (props) => {
  const {
    getAlbumDetail,
    hasCoverArtValue,
    updateStatusHandler,
    hasNoTracks,
    close,
    requestModal,
    tag,
  } = props;

  const returnMessage = (
    status,
    hasCoverArtValue,
    hasNoTracks,
    groupIds,
    isAdmin,
  ) => {
    if (status === 'incomplete') {
      if (!hasCoverArtValue) {
        return 'Cover Art is not valid';
      }
      if (hasNoTracks) {
        return 'Tracks looks empty';
      }
    } else if (status === 'orderable') {
      return 'This album has already been published';
    } else if (status === 'waiting_approval') {
      return 'This album is in review';
    } else if (!groupIds && isAdmin && !tag) {
      return 'Approve this album in admin-uploads';
    } else {
      return null;
    }
  };
  const returnMessageForRequestEdit = (groupIds, isAdmin) => {
    if (!groupIds && isAdmin) {
      return 'This album created via bulk-admin not yet packaged';
    } else {
      return null;
    }
  };

  const onClickReleaseHandler = (status) => {
    if (tag === TAGS.BULK_ADMIN) {
      updateStatusHandler();
    } else {
      if (status === 'waiting_approval' || status === 'orderable') {
        return null;
      } else {
        updateStatusHandler('waiting_approval');
      }
    }
  };

  const requestEdit = (status) => {
    if (status !== 'orderable') {
      return null;
    } else {
      requestModal();
    }
  };

  return (
    <>
      {getAlbumDetail?.status !== 'orderable' && (
        <ActionContainer
          isDisabled={
            (getAlbumDetail?.project?.is_admin_flow &&
              !getAlbumDetail?.group_albums_id &&
              !tag) ||
            getAlbumDetail?.status === 'waiting_approval' ||
            getAlbumDetail?.status === 'orderable' ||
            getAlbumDetail?.status === 'incomplete' ||
            hasNoTracks ||
            !hasCoverArtValue
              ? true
              : false
          }
        >
          <ul>
            <li>
              <button
                className="new-relase-btn"
                disabled={
                  getAlbumDetail?.status === 'waiting_approval' ||
                  getAlbumDetail?.status === 'orderable' ||
                  !hasCoverArtValue
                    ? true
                    : false
                }
                onClick={() => onClickReleaseHandler(getAlbumDetail?.status)}
              >
                {returnMessage(
                  getAlbumDetail?.status,
                  hasCoverArtValue,
                  hasNoTracks,
                  getAlbumDetail?.group_albums_id,
                  getAlbumDetail?.project?.is_admin_flow,
                ) && (
                  <Tooltip
                    isOpen={true}
                    content={returnMessage(
                      getAlbumDetail?.status,
                      hasCoverArtValue,
                      hasNoTracks,
                      getAlbumDetail?.group_albums_id,
                      getAlbumDetail?.project?.is_admin_flow,
                    )}
                    direction="left"
                  >
                    <div className="info">
                      <TiWarning />
                    </div>
                  </Tooltip>
                )}

                <div className="new-release-text">
                  {tag === TAGS.BULK_ADMIN ? 'Approve' : 'Submit for approval'}
                </div>
              </button>
            </li>
          </ul>
        </ActionContainer>
      )}

      {getAlbumDetail?.status === 'orderable' && tag !== TAGS.BULK_ADMIN && (
        <ActionContainer2
          isDisabled={
            (getAlbumDetail?.status === 'orderable' &&
              getAlbumDetail?.project?.is_admin_flow === false &&
              tag !== TAGS.BULK_ADMIN) ||
            (getAlbumDetail?.status === 'orderable' &&
              getAlbumDetail?.project?.is_admin_flow &&
              getAlbumDetail?.group_albums_id &&
              tag !== TAGS.BULK_ADMIN)
              ? false
              : true
          }
        >
          <ul>
            <li>
              <button
                className="new-relase-btn"
                // disabled={
                //   getAlbumDetail?.status === 'waiting_approval' ||
                //   getAlbumDetail?.status === 'approved' ||
                //   !hasCoverArtValue
                //     ? true
                //     : false
                // }
                onClick={() => requestEdit(getAlbumDetail?.status)}
              >
                {returnMessageForRequestEdit(
                  getAlbumDetail?.group_albums_id,
                  getAlbumDetail?.project?.is_admin_flow,
                ) && (
                  <Tooltip
                    isOpen={true}
                    content={returnMessageForRequestEdit(
                      getAlbumDetail?.group_albums_id,
                      getAlbumDetail?.project?.is_admin_flow,
                    )}
                    direction="left"
                  >
                    <div className="info">
                      <TiWarning />
                    </div>
                  </Tooltip>
                )}
                <div className="new-release-text">Request for edit</div>
              </button>
            </li>
          </ul>
        </ActionContainer2>
      )}
    </>
  );
};

const ActionContainer = styled.div<{isDisabled}>`
  background-color: white;
  border-radius: 5px;
  .new-relase-btn {
    display: flex;
    align-items: center;

    background-color: ${(props) => (props.isDisabled ? 'whitesmoke' : 'white')};
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
    &:active {
      pointer-events: ${(props) => (props.isDisabled ? 'none' : '')};
    }

    .new-release-text {
      opacity: ${(props) => (props.isDisabled ? '0.5' : '1')};
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: center;
      color: hsl(11deg 100% 62%);

      svg {
        margin-right: 5px;
        font-size: 18px;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    flex-direction: column;
    border-radius: 5px;

    li {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      + li {
        border-top: 1px solid #f1f1f1;
      }
    }
    button {
      border-radius: 5px;
      border: none;
      cursor: pointer;
      background-color: white;
      padding: 12px;
      font-size: 14px;
      color: #333;
      width: inherit;
      display: flex;
      &:hover {
        background-color: #f4f4fb;
      }
    }
  }
`;

const ActionContainer2 = styled.div<{isDisabled}>`
  background-color: white;
  border-radius: 5px;
  .new-relase-btn {
    display: flex;
    align-items: center;

    background-color: ${(props) => (props.isDisabled ? 'whitesmoke' : 'white')};
    cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
    &:active {
      pointer-events: ${(props) => (props.isDisabled ? 'none' : '')};
    }
    .new-release-text {
      opacity: ${(props) => (props.isDisabled ? '0.5' : '1')};
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: center;
      color: hsl(11deg 100% 62%);

      svg {
        margin-right: 5px;
        font-size: 18px;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    flex-direction: column;
    border-radius: 5px;

    li {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      + li {
        border-top: 1px solid #f1f1f1;
      }
    }
    button {
      border-radius: 5px;
      border: none;
      cursor: pointer;
      background-color: white;
      padding: 12px;
      font-size: 14px;
      color: #333;
      width: inherit;
      display: flex;
      &:hover {
        background-color: #f4f4fb;
      }
    }
  }
`;

export default ActionButtonDropDownContent;
