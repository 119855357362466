import styled from '@emotion/styled';
import {DatePicker} from 'baseui/datepicker';
import moment from 'moment';
import React, {useEffect} from 'react';
import {BiChevronDown, BiSearch} from 'react-icons/bi';
import {BsArrowRight} from 'react-icons/bs';
import {apiDate} from '../../../../helpers/covertDate';
import useCallDispatch from '../../../../hooks/FIlter/useCallDispatch';
const ReleaseDateFormContainer = styled.div<{screen}>`
  width: ${(props) => (props.screen === 'expandable' ? '100%' : 'auto')};
  padding: 15px;
  background: #ffffff;
  box-shadow: ${(props) =>
    props.screen === 'expandable' ? 'none' : '0px 3px 8px rgba(0, 0, 0, 0.15)'};
  border-radius: 5px;
  .title {
    font-size: ${(props) => (props.screen === 'expandable' ? '16px' : '14px')};
    font-weight: ${(props) =>
      props.screen === 'expandable' ? 'bold' : 'normal'};
    color: #505f7a;
  }
  .date-container {
    display: flex;
    align-items: center;
    .middle-arrow {
      position: relative;
      margin-left: 15px;
      margin-right: 15px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 7px;
    }
  }
  .btn {
    margin-left: 300px;
    font-size: 14px;
    font-weight: normal;
    color: #505f7a;
    :hover {
      color: #dc3831;
      cursor: pointer;
    }
  }
`;

const DatePickerContainer = styled.div<{isStartDateActive; isEndDateActive}>`
  background: ${(props) =>
    props.isStartDateActive
      ? 'rgba(220,56,49,0.1)'
      : props.isEndDateActive
      ? 'rgba(220,56,49,0.1)'
      : '#fff'};
  border: 0.8px solid #eaeaea;
  border-radius: 5px;
  width: 220px;
  padding: 10px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 15px;
  &:hover {
    background-color: #f9e9e9;
  }
  .date {
    margin-right: 10px;
    color: ${(props) =>
      props.isStartDateActive
        ? '#dc3831'
        : props.isEndDateActive
        ? '#dc3831'
        : ''}
  }
  .label {
    font-size: 13px;
    color: #dc3831;
    margin-right: 10px;
    font-weight:${(props) =>
      props.isStartDateActive
        ? '800'
        : props.isEndDateActive
        ? '800'
        : 'normal'}
  .arrow-container {
    svg {
      color: #b6b8b7;
    }
  }
`;

const DatePickerView = (props) => {
  const {label, data, state} = props;
  const [formatedDate, setFormattedDate] = React.useState(
    moment(data.value).format('DD-MM-YYYY'),
  );
  return (
    <DatePickerContainer
    isStartDateActive={state?.start_release_date && label === 'Starts on'}
    isEndDateActive={state?.end_release_date && label === 'Ends on'}
    onClick={data.onFocus}
    >
      <div className="label">{label}</div>
      <div className="date">{formatedDate}</div>
      <div className="arrow-container">
        <BiChevronDown />
      </div>
    </DatePickerContainer>
  )
};

export default function ProductReleaseDateForm(props) {
  const {
    startValue,
    setStartValue,
    endValue,
    setEndValue,
    setDropValue,
    screen,
    tag,
  } = props;
  const {callDispatch, state} = useCallDispatch();
  const reset = () => {
    setStartValue([new Date()]);
    setEndValue([new Date()]);
    callDispatch('CHANGE_START_RELEASE_DATE_DROP', 'start_release_date', '');
    callDispatch('CHANGE_END_RELEASE_DATE_DROP', 'end_release_date', '');
  };
  return (
    <ReleaseDateFormContainer screen={screen}>
      <span className="title">{`Select Album Release Date`}</span>
      <span className="btn" onClick={reset}>
        Reset
      </span>
      <div className="date-container">
        <DatePicker
          value={startValue}
          overrides={{
            Input: {
              component: (props) => (
                <DatePickerView state={state} data={props} label="Starts on" />
              ),
            },
            Popover: {
              props: {
                overrides: {
                  Body: {
                    style: () => ({
                      zIndex: '2',
                    }),
                  },
                },
              },
            },
          }}
          onChange={({date}) => {
            const formattedDate = apiDate(date);
            setStartValue(Array.isArray(date) ? date : [date]);

            callDispatch(
                'CHANGE_START_RELEASE_DATE_DROP',
                'start_release_date',
                formattedDate,
            );
          }}
        />
        <div className="middle-arrow">
          <BsArrowRight />
        </div>
        <DatePicker
          value={endValue}
          overrides={{
            Input: {
              component: (props) => (
                <DatePickerView data={props} state={state} label="Ends on" />
              ),
            },
            Popover: {
              props: {
                overrides: {
                  Body: {
                    style: () => ({
                      zIndex: '2',
                    }),
                  },
                },
              },
            },
          }}
          onChange={({date}) => {
            const formattedDate = apiDate(date);
            setEndValue(Array.isArray(date) ? date : [date]);
            callDispatch(
                'CHANGE_END_RELEASE_DATE_DROP',
                'end_release_date',
                formattedDate,
            );
          }}
        />
      </div>
    </ReleaseDateFormContainer>
  );
}