export const isrcData = {
  batch_name: {
    Header: 'Batch Name',
    accessor: 'batch_name',
  },
  isrc_count: {
    Header: 'ISRC Count',
    accessor: 'isrc_count',
  },
  created_at: {
    Header: 'Created On',
    accessor: 'created_at',
  },
  copy_isrc: {
    Header: '',
    accessor: 'isrc',
  },
  download_isrc: {
    Header: '',
    accessor: 'isrc_download',
  },
};
