import styled from '@emotion/styled';
import React, {useState} from 'react';
import {IoMdArrowRoundDown} from 'react-icons/io';
import {MdAddCircle} from 'react-icons/md';

import {TAGS} from '../../../constants/tags';
import useTablePageState from '../../../hooks/reusable/useTablePageState';
import useGetUpcBatches from '../../../hooks/UPCManagement/useGetUpcBatches';
import useUPC from '../../../hooks/UPCManagement/useUPC';
import SkeletonLoading from '../../../pages/SkeletonPage';
import {upcColumnMeta} from '../../../tableColumns/UPCManagement/tableData';
import Button from '../../../components/reusable/Button/Button';
import FlyMenu from '../../../components/reusable/FlyMenu/FlyMenu';
import NoRecords from '../../../components/reusable/NoRecords/NoRecords';
import Table from '../../../components/reusable/Table/TablePagination';

const UPC = () => {
  const [searchStr, setSearchStr] = useState('');

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    resolvedData,
    setPage,
    page,
    isLastPage,
    totalCount,
    rangeB,
    rangeA,
    lastPage,
    setLimit,
    limit,
    sError,
    sIsError,
    sIsFetching,
    sIsLoading,
    isCustomLoading,
    refetch,
  } = useGetUpcBatches({searchStr});

  const {getUpcCount} = useUPC({tag: TAGS.UPC});

  const {
    isOpen,
    setNewFormTag,
    newFormTag,
    setIsOpen,
    setSelectedData,
    selectedData,
  } = useTablePageState();

  const upc = React.useMemo(() => data, [data]);
  const columnData = React.useMemo(() => upcColumnMeta, [upcColumnMeta]);

  const openUploadUpc = () => {
    setIsOpen(true);
    setNewFormTag('upc-upload');
  };

  const openGenrateUpc = () => {
    setIsOpen(true);
    setNewFormTag('upc-generate');
  };

  if (isLoading) {
    return <SkeletonLoading />;
  }

  if (resolvedData?.totalPage === 0) {
    return (
      <NoRecords
        btnLabel={['Generate UPC', 'Upload UPC']}
        onClick={[openGenrateUpc, openUploadUpc]}
        page="upc"
      />
    );
  }

  return (
    <>
      <Table
        columns={columnData}
        data={upc ? upc : []}
        tag={TAGS.UPC}
        setSidebarData={setSelectedData}
        onClickRow={setIsOpen}
        setPage={setPage}
        page={page}
        isLastPage={isLastPage}
        totalCount={totalCount}
        rangeB={rangeB}
        rangeA={rangeA}
        lastPage={lastPage}
        setLimit={setLimit}
        limit={limit}
        resolvedData={resolvedData}
        isLoading={isCustomLoading}
        isFetching={isFetching}
        isError={isError || sIsError}
        error={error}
        sError={sError}
        sIsError={sIsError}
        sIsFetching={sIsFetching}
        sIsLoading={sIsLoading}
        setSearchStr={setSearchStr}
        refetchHandler={refetch}
        actionBtn={
          <ButtonContainer>
            <a
              className="download"
              href="https://divo-source-staging.s3.ap-south-1.amazonaws.com/UPC_Blank.xlsx"
            >
              <span>Download Template</span>
              <span>
                <IoMdArrowRoundDown />
              </span>
            </a>
            <Button
              disabled={getUpcCount?.not_assigned === 0}
              label="Generate UPC"
              onClick={openGenrateUpc}
              shape="round"
              endEnhancer={() => <MdAddCircle />}
            />
            <span
              style={{
                margin: 5,
              }}
            ></span>
            <Button
              label="Upload UPC"
              onClick={openUploadUpc}
              shape="round"
              endEnhancer={() => <MdAddCircle />}
            />
          </ButtonContainer>
        }
      />

      {isOpen && (
        <FlyMenu
          refetch={refetch}
          setSelectedData={setSelectedData}
          selectedData={selectedData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          tag={TAGS.UPC}
          setNewFormTag={setNewFormTag}
          newFormTag={newFormTag}
        />
      )}
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .download {
    margin-right: 0.95rem;
    margin-left: 0.45rem;
    display: flex;
    text-decoration: none;
    font-weight: 650;
    font-size: 14px;
    color: black;
  }
`;

export default UPC;
