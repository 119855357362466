import {Card} from 'baseui/card';
import React from 'react';

import {TAGS} from '../../../../constants/tags';
import {borderNone} from '../../../../styles/base-ui-overrides/common';
import UpcForm from './UPCForm';
import UpcUpload from './UpcUpload';

const UPCManagementForms = (props) => {
  const {newFormTag, setIsOpen, refetch} = props;
  return (
    <div>
      <Card
        overrides={{
          Root: {
            style: ({$theme}) => {
              return {
                backgroundColor: $theme.bgDefault,
                ...borderNone,
              };
            },
          },
          Body: {
            style: {
              display: 'flex',
              justifyContent: 'flex-end',
            },
          },
        }}
      ></Card>

      {newFormTag === 'upc-upload' && (
        <UpcUpload setIsOpen={setIsOpen} refetch={refetch} />
      )}
      {newFormTag === 'upc-generate' && (
        <UpcForm setIsOpen={setIsOpen} refetch={refetch} tag={TAGS.UPC} />
      )}
    </div>
  );
};

export default UPCManagementForms;
