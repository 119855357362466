import moment from 'moment';
import React, {useEffect} from 'react';
import {useState} from 'react';
import {useParams} from 'react-router';

import {NoRecords} from '../../../../components';
import {albumHistoryTableData} from './tableData';
import TablePagination from '../../../../components/reusable/Table/TablePagination';
import {TAGS} from '../../../../constants/tags';
import {formatDate, hrFormatDate} from '../../../../helpers/covertDate';
import {subtractFromTwoArray} from '../../../../helpers/subtractFromTwoArray';
import useGetAlbumHistory from '../../../../hooks/AlbumHistory/useGetAlbumHistory';
import useGetAllCountries from '../../../../hooks/Countries/useGetAllCountries';
import useListLanguages from '../../../../hooks/Language/useListLanguage';
export default function AlbumHistory() {
  const {albumId} = useParams();
  const [history, setHistory] = useState([]);
  const {listLanguages} = useListLanguages();
  const {countries} = useGetAllCountries();
  const [country, setCountry] = useState(countries);
  const [language, setLanguage] = useState(listLanguages);
  const {
    getAlbumHistory,
    refetch,
    isFetching,
    isLoading,
    isError,
  } = useGetAlbumHistory({
    albumId: albumId,
  });

  const returnValues = (data, key) => {
    if (data) {
      if (key === 'instruments') {
        if (data[key]) {
          return 'Yes';
        } else {
          return 'No';
        }
      }

      if (Array.isArray(data[key]) && key === 'album_director_name') {
        return data[key].join(', ');
      }
      if (Array.isArray(data[key]) && key === 'actress_name') {
        return data[key].join(', ');
      }
      if (Array.isArray(data[key]) && key === 'actors_name') {
        return data[key].join(', ');
      }
      if (Array.isArray(data[key]) && key === 'album_producer_name') {
        return data[key].join(', ');
      }
      if (Array.isArray(data[key]) && key === 'banner_production_name') {
        return data[key].join(', ');
      }
      if (Array.isArray(data[key]) && key === 'music_director') {
        return data[key].join(', ');
      }
      if (Array.isArray(data[key]) && key === 'singers') {
        return data[key].join(', ');
      }
      if (key === 'language_id') {
        const item = listLanguages?.find(
          (item) => item?.id === data?.language_id,
        );
        return item?.language;
      }
      if (Array.isArray(data[key]) && key === 'countries') {
        const getCountry = subtractFromTwoArray(data?.countries, country, true);
        const mapCountry = getCountry.map((item) => item.country);
        return mapCountry.join(', ');
      }
      if (key === 'album_release_date') {
        return formatDate(data[key]);
      }

      if (key === 'status') {
        return null;
      }

      return data[key];
    } else {
      return null;
    }
  };

  const returnDesc = (data, key, type) => {
    if (key === 'status' && type === 'album' && data) {
      if (data[key] === 'orderable') {
        return `Published`;
      }
      if (data[key] === 'waiting_approval') {
        return `Sent for approval`;
      }
      if (data[key] === 'declined') {
        return `Album declined`;
      }
    }
    if (key === 'status' && type === 'track' && data) {
      if (data[key] === 'approved') {
        return `Track Approved`;
      }
      if (data[key] === 'declined') {
        return `Track declined`;
      }
    }
    if (key === 'status' && type === 'request' && data) {
      if (data[key] === 'APPROVED') {
        return `Edit request approved`;
      }
      if (data[key] === 'DECLINED') {
        return `Edit request declined`;
      }
    }
    if (key === 'status') {
      return null;
    }
    return `${key}`;
  };

  useEffect(() => {
    if (getAlbumHistory?.length > 0) {
      const historyArr = [];
      getAlbumHistory?.map((prodHist) => {
        if (
          prodHist.action === 'CREATE' &&
          prodHist.on_model === 'ALBUM' &&
          !prodHist.curr['cover-art']
        ) {
          const obj = {
            timestamp: hrFormatDate(prodHist.timestamp),
            description: 'Creation',
            prev: null,
            curr: null,
            username: prodHist?.updated_by?.name,
            email: prodHist?.updated_by?.email,
            code: prodHist?.album_updated?.upc,
          };
          historyArr.push(obj);
        } else if (
          prodHist.action === 'CREATE' &&
          prodHist.on_model === 'TRACK' &&
          !prodHist.curr['cover-art'] &&
          prodHist.track_updated?.Reused_Track === null
        ) {
          const currentValues = JSON.parse(prodHist.curr);
          const obj = {
            timestamp: hrFormatDate(prodHist.timestamp),
            description: `Creation`,
            prev: null,
            curr: null,
            username: prodHist?.updated_by?.name,
            email: prodHist?.updated_by?.email,
            code: prodHist?.track_updated?.isrc,
          };
          historyArr.push(obj);
        } else if (
          prodHist.action === 'CREATE' &&
          prodHist.on_model === 'TRACK' &&
          !prodHist.curr['cover-art'] &&
          prodHist.track_updated?.Reused_Track !== null
        ) {
          const currentValues = JSON.parse(prodHist.curr);
          const obj = {
            timestamp: hrFormatDate(prodHist.timestamp),
            description: `Added existing track`,
            prev: null,
            curr: null,
            username: prodHist?.updated_by?.name,
            email: prodHist?.updated_by?.email,
            code: prodHist?.track_updated?.isrc,
          };
          historyArr.push(obj);
        } else if (
          prodHist.action === 'UPDATE' &&
          prodHist.on_model === 'ALBUM' &&
          !prodHist.curr['cover-art']
        ) {
          const currentValuesObj = JSON.parse(prodHist.curr);

          const previousValuesObj = JSON.parse(prodHist.prev);

          Object.keys(currentValuesObj).map((key) => {
            const obj = {
              timestamp: hrFormatDate(prodHist.timestamp),
              description: returnDesc(currentValuesObj, key, 'album'),
              prev: returnValues(previousValuesObj, key),
              curr: returnValues(currentValuesObj, key),
              username: prodHist?.updated_by?.name,
              email: prodHist?.updated_by?.email,
              code: prodHist?.album_updated?.upc,
            };
            historyArr.push(obj);
          });
        } else if (
          prodHist?.curr !== null &&
          prodHist?.curr['cover-art'] === 'CREATE'
        ) {
          const obj = {
            timestamp: hrFormatDate(prodHist.timestamp),
            description: 'Cover art Image Added',
            prev: null,
            curr: null,
            username: prodHist?.updated_by?.name,
            email: prodHist?.updated_by?.email,
            code: prodHist?.album_updated?.upc,
          };
          historyArr.push(obj);
        } else if (
          prodHist?.curr !== null &&
          prodHist?.curr['cover-art'] === 'UPDATE'
        ) {
          const obj = {
            timestamp: hrFormatDate(prodHist.timestamp),
            description: 'Cover art Image Updated',
            prev: null,
            curr: null,
            username: prodHist?.updated_by?.name,
            email: prodHist?.updated_by?.email,
            code: prodHist?.album_updated?.upc,
          };
          historyArr.push(obj);
        } else if (
          prodHist?.curr !== null &&
          prodHist.curr['track'] === 'UPDATE'
        ) {
          const obj = {
            timestamp: hrFormatDate(prodHist.timestamp),
            description: 'Track Updated',
            prev: null,
            curr: null,
            username: prodHist?.updated_by?.name,
            email: prodHist?.updated_by?.email,
            code: prodHist?.track_updated?.isrc, //trackUpdateIsrc
          };
          historyArr.push(obj);
        } else if (
          prodHist.action === 'UPDATE' &&
          prodHist.on_model === 'TRACK' &&
          !prodHist.curr['cover-art'] &&
          !prodHist.curr['track']
        ) {
          const currentValuesObj = JSON.parse(prodHist.curr);
          const previousValuesObj = JSON.parse(prodHist.prev);
          Object.keys(currentValuesObj).map((key) => {
            const obj = {
              timestamp: hrFormatDate(prodHist.timestamp),
              description: returnDesc(currentValuesObj, key, 'track'),
              prev: returnValues(previousValuesObj, key),
              curr: returnValues(currentValuesObj, key),
              username: prodHist?.updated_by?.name,
              email: prodHist?.updated_by?.email,
              code: prodHist?.track_updated?.isrc,
            };
            historyArr.push(obj);
          });
        } else if (
          prodHist.action === 'UPDATE' &&
          prodHist.on_model === 'PRODUCT_UNLOCK_REQUEST'
        ) {
          const currentValuesObj = JSON.parse(prodHist.curr);
          const previousValuesObj = JSON.parse(prodHist.prev);
          Object.keys(currentValuesObj).map((key) => {
            const obj = {
              timestamp: hrFormatDate(prodHist.timestamp),
              description: returnDesc(currentValuesObj, key, 'request'),
              prev: null,
              curr: null,
              username: prodHist?.updated_by?.name,
              email: prodHist?.updated_by?.email,
              code: prodHist?.album_updated?.upc,
            };
            historyArr.push(obj);
          });
        } else if (
          prodHist.action === 'CREATE' &&
          prodHist.on_model === 'PRODUCT_UNLOCK_REQUEST'
        ) {
          const obj = {
            timestamp: hrFormatDate(prodHist.timestamp),
            description: 'Edit request raised',
            prev: null,
            curr: null,
            username: prodHist?.updated_by?.name,
            email: prodHist?.updated_by?.email,
            code: prodHist?.album_updated?.upc,
          };
          historyArr.push(obj);
        }
      });

      setHistory(historyArr.filter((item) => item.description));
    } else {
      setHistory([]);
    }
  }, [getAlbumHistory]);

  return (
    <>
      <TablePagination
        columns={albumHistoryTableData}
        data={history ? history : []}
        tag={TAGS.PRODUCT_HISTORY}
        setSidebarData={null}
        onClickRow={null}
        setPage={null}
        page={null}
        isLastPage={null}
        totalCount={null}
        rangeB={null}
        rangeA={null}
        lastPage={null}
        setLimit={null}
        limit={null}
        resolvedData={null}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        error={null}
        sError={null}
        sIsError={null}
        sIsFetching={null}
        sIsLoading={null}
        setSearchStr={null}
        refetchHandler={refetch}
      />
    </>
  );
}
