import {useContext, useEffect, useState} from 'react';
const useSelectedItems = (props) => {
  const {data, label, selectedDspData, setPage, selectedId} = props;
  const [dropValue, setDropValue] = useState(label);
  const [selectedArray, setSelectedArray] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      const setData = data.map((d) => ({...d}));

      for (const index in setData) {
        if (selectedDspData.indexOf(setData[index].label) !== -1) {
          setData[index].checked = true;
        }
        if (selectedId.indexOf(setData[index].id) !== -1) {
          setData[index].checked = true;
        }
      }

      setSelectedArray(setData);
      setPage(1);
    }
  }, [data, selectedDspData]);

  return {
    selectedArray,
    setSelectedArray,
    setDropValue,
    dropValue,
  };
};

export default useSelectedItems;
