import {useEffect} from 'react';

import useNotification from '../../Contexts/useNotification';
import useLocalStorage from '../reusable/useLocalStorage';
import useGenerateIsrc from './useGenerateIsrc';

const useIsrcForm = (props) => {
  const {setIsOpen, refetch} = props;
  const {showToast} = useNotification();
  const {
    generateIsrcMutation,
    isLoading: isGenerateIsrcLoading,
    error: generateIsrcError,
  } = useGenerateIsrc();
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);

  useEffect(() => {
    if (generateIsrcError) {
      setIsOpen(true);
    }
  }, [generateIsrcError]);

  const initialValues = {
    batch_name: '',
    count: '',
  };

  const onSubmit = async (isrcFormData) => {
    const data = {
      user_id: currentUser.id,
      batch_name: isrcFormData.batch_name,
      count: Number(isrcFormData.count),
    };

    if (data) {
      const res = await generateIsrcMutation({data});
      closeModalOnSuccess(res);
      if (res?.error && res.error?.length > 0) {
        showToast(res?.error, 'error');
      }
    }
  };

  const closeModalOnSuccess = (res) => {
    if (res.statusCode === 201) {
      setIsOpen(false);
      refetch();
      showToast(`ISRC Generated successfully`, 'success');
    }
  };

  return {
    initialValues,
    onSubmit,
    isGenerateIsrcLoading,
  };
};

export default useIsrcForm;
