/** @format */
import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {FileUploader} from 'baseui/file-uploader';
import React, {useState} from 'react';
import {MdInfo} from 'react-icons/md';

import useValidateInputFile from '../../../hooks/CreateAlbum/useValidateExcel';
import useModal from '../../../hooks/Modal/useModal';
import Icon from '../../../styles/ui-component/Icon';
import ErrorMessageModal from '../../CreateAlbum/ErrorMessageModal';
import Button from '../Button/Button';
import LoadingProcessing from '../LoadingProcessing/LoadingProcessing';

const rootStyle = {
  style: () => ({
    width: '100%',
    borderRadius: '10px',
  }),
};

const dragNDrop = {
  style: () => ({
    width: '80%',
    height: '15rem',
    margin: '0 auto',
  }),
};

const BaseSingleFile = (props) => {
  const {setIsOpen, isOpen, close} = useModal();
  const [msg, setMsg] = useState(null);
  const {readExcel} = useValidateInputFile();
  const [loading, setLoading] = useState(false);
  const [css, theme] = useStyletron();
  const {
    accept,
    multiple,
    iconName,
    setIsComplete,
    isShowButton,
    disabled,
    setFile,
    cancelUpload,
    handleUpload,
    tag,
    ...rest
  } = props;

  const handleChangeUpload = async (file) => {
    setFile(file[0]);
    try {
      setLoading(true);
      await readExcel(file[0]);
      setLoading(false);
      handleUpload(file[0]);
    } catch (error) {
      setLoading(true);
      setMsg(error);
      setLoading(false);
      setIsOpen(true);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingProcessing />
      ) : (
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            margin: '1rem',
          })}
        >
          <FileUploader
            overrides={{
              FileDragAndDrop: dragNDrop,
              ContentMessage: {
                component: () => (
                  <div>
                    <Icon
                      className={css({display: 'block', margin: '0 auto'})}
                      name={iconName}
                      width="27"
                      height="34"
                      viewBox="0 0 27 34"
                      fill={props.fillColor}
                    />
                    <p
                      style={{
                        fontSize: '0.95rem',
                        fontWeight: '400',
                        margin: '0.75rem 0',
                        textAlign: 'center',
                      }}
                    >
                      Drag and drop files here
                    </p>
                    {tag === 'input' && (
                      <Container>
                        <MdInfo />
                        <p
                          style={{
                            fontSize: '0.85rem',
                            fontWeight: '400',
                            margin: '0.75rem 0',
                            textAlign: 'center',
                          }}
                        >
                          Input file must be renamed as input.xlsx
                        </p>
                      </Container>
                    )}
                  </div>
                ),
              },
              Root: rootStyle,
            }}
            onCancel={cancelUpload}
            onDrop={(acceptedFiles) => handleChangeUpload(acceptedFiles)}
            accept={accept}
            onChange={(acceptedFiles) => handleChangeUpload(acceptedFiles)}
            multiple={multiple}
          />
          {isOpen && !loading && (
            <ErrorMessageModal
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              close={close}
              msg={msg}
            />
          )}
          {isShowButton
            ? !disabled && (
                <Footer theme={theme}>
                  <div>
                    <Button
                      label="Upload UPC"
                      type="submit"
                      disabled={disabled}
                    />
                  </div>
                </Footer>
              )
            : null}
        </div>
      )}
    </>
  );
};

const Footer = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: flex-end;
  height: 55px;
  background: ${(props) => props.theme.bg && props.theme.bg};
  box-shadow: 0px -8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 1;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 3px;
  }
`;

export default BaseSingleFile;
