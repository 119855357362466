import styled from '@emotion/styled';
import React, {useEffect, useState} from 'react';

import usePreviewLink from '../../../hooks/AlbumDetail/usePreviewLink';
import useS3Key from '../../../hooks/reusable/useS3key';
import Button from '../../../components/reusable/Button/Button';
import NoRecords from '../../../components/reusable/NoRecords/NoRecords';
import Table from '../../../components/reusable/Table/Table';
import TracksTable from '../../../components/AlbumDetail/tables/TracksTable';

export default function Album(props) {
  const {
    columns,
    setIsOpen,
    setSelectedData,
    setNewFormTag,
    data,
    currentUser,
    setTrackIsrc,
    tag,
    playing,
    handlePlayPause,
    duration,
    trackIsrc,
    hasCoverArtValue,
    productType,
    status,
    reuseIsrcModalOpen,
    getAlbumDetail,
    noAddTrack,
    refetchHandler,
    isFetching,
  } = props;

  const trackData = React.useMemo(() => data, [data]);
  const columnData = React.useMemo(() => columns, [columns]);

  const openAddTrackHandler = () => {
    setIsOpen(true);
    setNewFormTag('add-track');
  };

  const openAddCoverArtHandler = () => {
    setIsOpen(true);
    setNewFormTag('add-coverart');
  };

  const openNewFormSidebarHandler = () => {
    setIsOpen(true);
    setNewFormTag(true);
  };

  return (
    <>
      {data?.length === 0 ? (
        <NoRecords
          page="tracks"
          btnLabel="Add Track"
          onClick={openNewFormSidebarHandler}
          reuseIsrcModalOpen={reuseIsrcModalOpen}
        />
      ) : (
        <Table
          setSelectedValue={null}
          columns={columnData}
          data={trackData ? trackData : []}
          onClickRow={setIsOpen}
          setSidebarData={setSelectedData}
          tag={tag}
          setIsOpen={setIsOpen}
          setNewFormTag={setNewFormTag}
          hasNextPage={false}
          setSearchStr={null}
          setTrackIsrc={setTrackIsrc}
          playing={playing}
          duration={duration}
          trackIsrc={trackIsrc}
          handlePlayPause={handlePlayPause}
          getAlbumDetail={getAlbumDetail}
          noAddTrack={noAddTrack}
          refetchHandler={refetchHandler}
          isFetching={isFetching}
          mainActionHandler={[
            openAddTrackHandler,
            openAddCoverArtHandler,
            status,
            productType,
            reuseIsrcModalOpen,
          ]}
          footerComponent={null}
        />
      )}
    </>
  );
}

export const FooterButton = ({
  status,
  updateStatusHandler,
  albumLoading,
  trackLoading,
  trackData,
  hasCoverArtValue,
}) => {
  return (
    <>
      {trackData?.length !== 0 && (
        <Button
          disabled={
            status === 'waiting_approval' ||
            status === 'approved' ||
            !hasCoverArtValue
              ? true
              : false
          }
          onClick={() =>
            status === 'waiting_approval' || status === 'approved'
              ? null
              : updateStatusHandler('waiting_approval')
          }
          type="submit"
          isLoading={albumLoading || trackLoading}
          label={`${
            status === 'incomplete'
              ? !hasCoverArtValue
                ? `Invalid Cover Art`
                : `Submit For Approval`
              : status === 'approved'
              ? `This album has been published`
              : status === 'declined'
              ? 'Re-Submit For Approval'
              : `Waiting for Approval`
          }  `}
        />
      )}
    </>
  );
};
