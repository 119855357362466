/** @format */

import styled from '@emotion/styled';
import {Block} from 'baseui/block';
import {StatefulTooltip} from 'baseui/tooltip';
import React from 'react';
import {FaCheckCircle, FaInfoCircle} from 'react-icons/fa';
import {IoMdCloseCircle} from 'react-icons/io';
import {useNavigate} from 'react-router';
import {capitalizeFirstLetter} from '../../helpers/capitalizeFirstLetter';

import {formatDate} from '../../helpers/covertDate';
import useLocalStorage from '../../hooks/reusable/useLocalStorage';
import {
  sortAlphabeticalOrderWithoutCase,
  sortStatusAlphabeticalOrderWithoutCase,
} from '../AlbumsManagement/albumColumnHelper';
import {requestEditTable} from '../AlbumsManagement/requestEditTable';
import {ButtonContainer} from '../AlbumsManagement/styled-components/TableComponents';
import {TextWrap} from '../components/TableComponents';
const Check = styled.div``;
const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    padding: 6px 12px;

    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    svg {
      margin-left: 10px;
    }

    background: ${(props) =>
      props.status === 'Pending'
        ? '#FEEFB3'
        : props.status === 'Declined'
        ? '#FFD2D2'
        : '#DFF2BF'};
    color: ${(props) =>
      props.status === 'Pending'
        ? '#9F6000'
        : props.status === 'Declined'
        ? '#D8000C'
        : '#4F8A10'};
    line-height: 0.25rem;
  }
`;

export const requestTableData = [
  {
    Header: 'Album Name',
    accessor: 'album_name', // accessor is the "key" in the data
    Cell: (data) => {
      return <span>{data?.row?.original?.album?.album_name}</span>;
    },
    sortType: (rowA, rowB) => {
      return sortAlphabeticalOrderWithoutCase(
        rowA.original?.album?.album_name,
        rowB.original?.album?.album_name,
      );
    },
  },
  {
    Header: requestEditTable.upc.Header,
    accessor: requestEditTable.upc.accessor, // accessor is the "key" in the data
    Cell: (data) => {
      return <span>{data?.row?.original?.album?.upc}</span>;
    },
  },

  {
    Header: requestEditTable.created_by.Header,
    accessor: requestEditTable.created_by.accessor,
    Cell: (data) => {
      return (
        <StatefulTooltip
          content={() => <Block>{data.row.original?.raised_by?.email}</Block>}
          focusLock
          returnFocus
          autoFocus
          overrides={{
            Body: {
              style: {
                zIndex: 999,
                maxWidth: '400px',
                textTransform: 'capitalize',
              },
            },
          }}
        >
          <span>{data.row.original?.raised_by?.name}</span>
        </StatefulTooltip>
      );
    },
  },
  {
    Header: requestEditTable.created_on.Header,
    accessor: requestEditTable.created_on.accessor,
    Cell: (data) => {
      return <span> {data && formatDate(data.value)} </span>;
    },
  },
  // {
  //   Header: requestEditTable.status.Header,
  //   accessor: requestEditTable.status.accessor,
  //   Cell: (data) => {
  //     const status = data?.row?.original?.status?.toLowerCase()

  //     return <StatusBox data={data} status={capitalizeFirstLetter(status)}/>;
  //   },
  //   sortType: (rowA, rowB) => {
  //     return sortStatusAlphabeticalOrderWithoutCase(
  //       rowA.original.status,
  //       rowB.original.status,
  //     );
  //   },
  // },
  {
    Header: requestEditTable.product_request_status.Header,
    accessor: requestEditTable.product_request_status.accessor,
    Cell: (data) => {
      const status = data?.row?.original?.status?.toLowerCase();
      const capFirst = capitalizeFirstLetter(status);
      return <StatusBox value={capFirst} />;
    },
    sortType: (rowA, rowB) => {
      return sortStatusAlphabeticalOrderWithoutCase(
        rowA.original.status,
        rowB.original.status,
      );
    },
  },
  {
    Header: requestEditTable.meta_data.Header,
    accessor: requestEditTable.meta_data.accessor,
    Cell: (data) => {
      const length = data.row.original?.metadata?.map((item) => item).join(',');
      return (
        <StatefulTooltip
          content={() => (
            <Block>
              {data.row.original?.metadata?.map((item) => item).join(',')}
            </Block>
          )}
          focusLock
          returnFocus
          autoFocus
          overrides={{
            Body: {
              style: {
                zIndex: length?.length > 25 ? 999 : -1,
                maxWidth: '400px',
                textTransform: 'capitalize',
              },
            },
          }}
        >
          <span>
            {data.row.original?.metadata?.map((item) => item).join(' , ')}
          </span>
        </StatefulTooltip>
      );
    },
  },
  // {
  //   Header: '',
  //   accessor: 'complete',
  //   disableSortBy: true,
  //   Cell: (data) => {
  //     const navigate = useNavigate();
  //     const [projectId, setProjectId] = useLocalStorage('projectId', null);

  //     const onClickDetail = (data) => {
  //       navigate(`/album-request/${data?.row?.original?.album_id}`, {
  //         state: {
  //           comment: data?.row.original?.comment,
  //           requestId: data.row.original?.id,
  //           requestStatus: data.row.original?.status,
  //         },
  //       });
  //       setProjectId(data?.row?.original?.project_id);
  //     };
  //     return (
  //       <ButtonContainer>
  //         <button onClick={() => onClickDetail(data)}>View Tracks </button>
  //       </ButtonContainer>
  //     );
  //   },
  // },
];
const StatusBox = (props) => {
  const {value} = props;
  return (
    <>
      <StatusContainer status={value}>
        <span>
          {value === 'Pending'
            ? 'Pending'
            : value === 'Declined'
            ? 'Declined'
            : value === 'Auto_approved'
            ? 'Auto approved'
            : 'Approved'}

          {value === 'Pending' ? (
            <FaInfoCircle />
          ) : value === 'Declined' ? (
            <IoMdCloseCircle />
          ) : (
            <FaCheckCircle />
          )}
        </span>
      </StatusContainer>
    </>
  );
};
