import styled from '@emotion/styled';
import {ModalBody} from 'baseui/modal';
import React from 'react';

import BaseModal from '../reusable/Modal/BaseModal';
import AlbumArt from './AlbumArt';

interface AlbumModalProp {
  showImgModal: boolean;
  onCloseHandler: any;
  url: string;
}

const AlbumArtModal = ({showImgModal, onCloseHandler, url}: AlbumModalProp) => {
  return (
    <BaseModal page="album-art" isOpen={showImgModal} close={onCloseHandler}>
      <ModalBody style={{backgroundColor: 'white'}}>
        <div style={{width: '100%', height: '85%'}}>
          <AlbumArt url={url} />
        </div>
      </ModalBody>
    </BaseModal>
  );
};

export default AlbumArtModal;
