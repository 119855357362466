import React, {useEffect, useState} from 'react';

import {
  IndeterminateCheckbox,
  IndeterminateHeaderCheckbox,
  SelectionRowWrapper,
} from '../../components/AlbumTracksApproval/tableData';
import {formatDate} from '../../helpers/covertDate';
import {StatusBox} from '../../tableColumns/AlbumsManagement/albumsColumnMetaData';
import {AlbumNameWrapper} from '../../tableColumns/AlbumsManagement/styled-components/TableComponents';
import {ImageContainer} from './tableData';
export const packagerColumn = [
  {
    id: 'selection',
    width: 50,
    disableSortBy: true,
    Header: ({getToggleAllRowsSelectedProps, toggleRowSelected, ...rest}) => {
      const [headerCheckBox, setHeaderCheckBox] = useState(false);
      useEffect(() => {
        if (rest.data && rest.data?.length > 0) {
          let hasStatus = true;
          rest?.data.map((a) => {
            if (a?.Order_management?.length === 0) {
              hasStatus = false;
            }
          });
          setHeaderCheckBox(hasStatus);
        }
      }, [rest.data]);
      return (
        <SelectionRowWrapper>
          <IndeterminateHeaderCheckbox
            isDisabled={headerCheckBox}
            data={rest.filteredFlatRows}
            selectedFlatRow={rest.selectedFlatRows}
            toggleRowSelected={toggleRowSelected}
            type="create_package"
          />
        </SelectionRowWrapper>
      );
    },
    Cell: ({row, ...rest}) => {
      const [hasValidStatus, setHasValidStatus] = useState(false);

      useEffect(() => {
        if (row?.original?.Order_management?.length > 0) {
          setHasValidStatus(true);
        }
      }, [row]);

      const toggleRow = () => {
        if (hasValidStatus) {
          return null;
        } else {
          return row.toggleRowSelected();
        }
      };

      return (
        <SelectionRowWrapper
          onClick={() => toggleRow()}
          style={
            row.isSelected
              ? {
                  backgroundColor: '#dc383126',
                }
              : {}
          }
        >
          <IndeterminateCheckbox
            isDisabled={hasValidStatus}
            {...row.getToggleRowSelectedProps()}
          />
        </SelectionRowWrapper>
      );
    },
  },
  {
    Header: 'Album Name',
    accessor: 'album_name',
    Cell: (data) => {
      return (
        <AlbumNameWrapper>
          <ImageContainer imgurl={data.row?.original?.cover_art} />
          <div> {data && data.value} </div>
        </AlbumNameWrapper>
      );
    },
  },

  {
    Header: 'UPC',
    accessor: 'upc',
  },
  {
    Header: 'Label',
    accessor: 'label_name',
  },
  {
    Header: 'Content Type',
    accessor: 'album_type',
  },
  {
    Header: 'Product Type',
    accessor: 'product_type',
  },
  {
    Header: 'Created On',
    accessor: 'created_at',
    Cell: (data) => {
      return <span> {data && formatDate(data.value)} </span>;
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (data) => {
      return <StatusBox data={data} />;
    },
  },
];
// export const StatusBox = (props) => {
//   const {data, albumStatus, status} = props;
//   console.log('daaaa', props);

//   return (
//     <>
//       <StatusContainer status={status} albumStatus={albumStatus}>
//         <span>
//           {status === 'Pending'
//             ? 'Pending'
//             : status === 'waiting_approval' && albumStatus !== 'declined'
//             ? 'In Review'
//             : status === 'waiting_approval' && albumStatus === 'declined'
//             ? 'Pending'
//             : status === 'declined'
//             ? 'Declined'
//             : 'Approved'}

//           {status === 'Pending' ? (
//             <FaInfoCircle />
//           ) : status === 'waiting_approval' ? (
//             <FaInfoCircle />
//           ) : status === 'declined' ? (
//             <IoMdCloseCircle />
//           ) : (
//             <FaCheckCircle />
//           )}
//         </span>
//       </StatusContainer>
//     </>
//   );
// };
