import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const fetchSingleProject = async (key, projectId) => {
  if (projectId) {
    try {
      const res = await axios(`/project/${projectId}`, {
        method: 'GET',
      });

      return res.data?.data;
    } catch (error) {
      logError(error, 12);
    }
  }
};

const useGetSingleProject = (props) => {
  const {id} = props;

  const {data: getSingleProject, isLoading, isFetching, refetch} = useQuery(
    ['singleProject', id],
    fetchSingleProject,
  );

  return {
    getSingleProject,
    isLoading,
    isFetching,
    refetch,
  };
};

export default useGetSingleProject;
