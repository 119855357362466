import React, {useRef} from 'react';
import useCallDispatch from './useCallDispatch';

const useAddItemsByPressingEnter = (props) => {
  const {textAreaRef, setDropValue, tag} = props;
  const [value, setValue] = React.useState('');
  const [items, setItems] = React.useState([]);
  const {callDispatch, state} = useCallDispatch();

  const onKeyPressHandler = (evt) => {
    if (
      (evt.key && ['Enter', 'Tab', ','].includes(evt.key)) ||
      evt.type === 'blur'
    ) {
      evt.preventDefault();

      textAreaRef.current.focus();
      const val = value.split(/[( .+)(,.+)(\n.+)]+/).filter((d) => d);

      if (val.length) {
        if (tag === 'upc') {
          if (state.upc_id) {
            const itemsData = [...state.upc_id, ...val];
            const uniqueUpc = itemsData.filter((c, index) => {
              //controll repeated upc
              return itemsData.indexOf(c) === index;
            });
            callDispatch('CHANGE_UPC_DROP', 'upc_id', uniqueUpc);
          } else {
            callDispatch('CHANGE_UPC_DROP', 'upc_id', val);
          }
        }
        if (tag === 'order_id') {
          if (state.order_id) {
            const itemsData = [...state.order_id, ...val];
            const uniqueOrderId = itemsData.filter((c, index) => {
              //controll repeated order_id's
              return itemsData.indexOf(c) === index;
            });
            callDispatch('CHANGE_ORDERID_DROP', 'order_id', uniqueOrderId);
          } else {
            callDispatch('CHANGE_ORDERID_DROP', 'order_id', val);
          }
        }
      }

      setValue('');
    }
  };

  const onChangeText = (event) => {
    event.preventDefault();
    setValue(event.target.value);
  };

  const onRemoveItem = (index) => {
    if (tag === 'upc') {
      const updatedItem = state?.upc_id?.filter((item, i) => index !== i);
      callDispatch('CHANGE_UPC_DROP', 'upc_id', updatedItem);
    } else if (tag === 'order_id') {
      const updatedItem = state?.order_id?.filter((item, i) => index !== i);
      callDispatch('CHANGE_ORDERID_DROP', 'order_id', updatedItem);
    }
  };

  return {
    onChangeText,
    onRemoveItem,
    onKeyPressHandler,
    value,
    items,
    state,
  };
};

export default useAddItemsByPressingEnter;
