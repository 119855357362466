export const TAGS = {
  USER: 'user',
  ALBUMS: 'albums',
  EDIT_ALBUMS: 'edit_albums',
  ISRC: 'isrc',
  LABEL: 'label',
  TRACKS: 'tracks',
  UPC: 'upc',
  ALBUM_APPROVAL: 'album_approval',
  TRACK_APPROVAL: 'track_approval',
  DSP: 'dsp',
  ROLE: 'role',
  TYPE: 'type',
  BULK_UPLOAD: 'bulk_upload',
  MANUAL_UPLOAD: 'manual_upload',
  ORDER_MANAGEMENT: 'order_management',
  PRODUCT_HISTORY: 'product_history',
  BULK_ADMIN: 'bulk_admin',
  BULK_ADMIN_PACKAGER: 'bulk_admin_packager',
};
