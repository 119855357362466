/** @format */

import axios from 'axios';
import {queryCache, useMutation} from 'react-query';

import {logError} from '../../errorlogger/logError';
const BASE_URL = process.env.REACT_APP_Base_URL;

const createAlbum = async (data) => {
  try {
    const response = await axios(`${BASE_URL}/album/`, {
      method: 'POST',
      data,
    });
    return response.data;
  } catch (error) {
    logError(error, 3);

    return error.response?.data;
  }
};

const useCreateAlbum = () => {
  const [
    createAlbumMutation,
    {status, data, error, isLoading, isSuccess},
  ] = useMutation(createAlbum, {
    onSuccess: () => {
      queryCache.invalidateQueries('albums');
    },
  });

  return {createAlbumMutation, isLoading, status, error};
};

export default useCreateAlbum;
