import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const useValidateIsrc = () => {
  const validateISRCFn = async (isrc) => {
    try {
      const res = await axios(`/isrc/validation?isrc=${isrc}`, {
        method: 'GET',
      });
      return res?.data;
    } catch (error) {
      logError(error, 8);
      return error?.response?.data;
    }
  };

  return {validateISRCFn};
};

export default useValidateIsrc;
