import {BaseProvider} from 'baseui';
import React, {useEffect} from 'react';
import {ReactQueryDevtools} from 'react-query-devtools';
import {BrowserRouter as Router} from 'react-router-dom';
import {Routes} from 'react-router-dom';
import {Route} from 'react-router-dom';
// Base UI
import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';

import BulkUploadAdminAlbumDetails from './components/BulkUploadAdminManagement/BulkUploadAdminAlbumDetails';
import BulkUploadNew from './components/CreateProject/BulkUpload/BulkUploadForm';
import LoadingMain from './components/reusable/Loading/LoadingMain';
import BaseModal from './components/reusable/Modal/BaseModal';
import GlobalProvider from './Contexts/GlobalProvider';
import NotificationProvider from './Contexts/NotificationProvider';
import ErrorBoundary from './errorlogger/ErrorBoundary';
import useLocalStorage from './hooks/reusable/useLocalStorage';
import useLogin from './hooks/reusable/useLogin';
import useGetSingleUser from './hooks/UserManagement/useGetUser';
import DashboardLayout from './layouts/DashboardLayout';
import {
  AlbumDetailNew as AlbumDetailNewPage,
  AlbumsInReview as AlbumsInReviewPage,
  Approval as ApprovalPage,
  ArtistLabelManagement as ArtistLabelManagementPage,
  Dashboard as DashboardPage,
  DSPManagement as DSPManagementPage,
  ISRCUPCManagement as ISRCUPCManagementPage,
  Login as LoginPage,
  ProjectManagement as ProjectManagementPage,
  Report as ReportPage,
  UserManagement as UserManagementPage,
} from './pages';
import EditRequestDetail from './pages/AlbumsReviewManagement/AlbumsReviewDetail/EditRequestDetail';
import BulkAdminPackagerPage from './pages/BulkUploadAdminManagement/BulkAdminPackagerPage';
import BulkUploadAdminManagement from './pages/BulkUploadAdminManagement/BulkUploadAdminManagement';
import OrderManagement from './pages/OrderManagement/OrderManagement';
import TokenError from './pages/TokenError/TokenError';
import THEME, {darkTheme, lightTheme} from './styles/theme/index';

// configure styletron for Base UI to work
const engine = new Styletron();

const App = (): JSX.Element => {
  const [theme, setTheme] = React.useState(THEME.light);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isAlbum, setIsAlbum] = React.useState(false);
  const [isTrack, setIsTrack] = React.useState(false);
  const [expiry, setExpiry] = useLocalStorage('expiry', null);

  const {
    isLoggedIn,
    setIsLoggedIn,
    toastMsg,
    validateUserLoading,
    currentUser,
    onSuccess,
    onFailure,
  } = useLogin();

  useEffect(() => {
    if (expiry) {
      if (Math.round(Date.now() / 1000) >= expiry) {
        window.location.href = '/';
        window.localStorage.clear();
      }
    } else {
      window.localStorage.clear();
    }
  }, [expiry]);

  const {getSingle, isLoading, error, isError} = useGetSingleUser({
    id: currentUser ? currentUser.id : null,
  });

  if (getSingle?.message) {
    return <TokenError setIsLoggedIn={setIsLoggedIn} />;
  }

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={theme === THEME.light ? lightTheme : darkTheme}>
        <NotificationProvider>
          <Router>
            <ErrorBoundary>
              <Routes>
                {isLoggedIn ? (
                  <Route
                    path="/"
                    element={
                      <DashboardLayout
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        setTheme={setTheme}
                        theme={theme}
                        setIsLoggedIn={setIsLoggedIn}
                        currentUser={currentUser}
                        isAlbum={isAlbum}
                        isTrack={isTrack}
                        setIsAlbum={setIsAlbum}
                        setIsTrack={setIsTrack}
                      />
                    }
                  >
                    <Route path="" element={<DashboardPage />} />
                    <Route
                      path="user-management"
                      element={<UserManagementPage />}
                    />
                    <Route path="albums" element={<ProjectManagementPage />} />
                    <Route
                      path="artist-label"
                      element={<ArtistLabelManagementPage />}
                    />
                    <Route path="reports" element={<ReportPage />} />
                    <Route
                      path="upc-isrc-management"
                      element={<ISRCUPCManagementPage />}
                    />
                    <Route
                      path="dsp-management"
                      element={<DSPManagementPage />}
                    />

                    <Route
                      path="albums/:albumId"
                      element={<AlbumDetailNewPage />}
                    />
                    <Route
                      path="album-approval"
                      element={<AlbumsInReviewPage />}
                    />
                    <Route
                      path="album-approval/:albumId"
                      element={<ApprovalPage />}
                    />
                    <Route
                      path="album-request/:requestId/:albumId"
                      element={<EditRequestDetail />}
                    />
                    <Route
                      path="order-management"
                      element={<OrderManagement />}
                    />
                    <Route
                      path="bulk-admin/:projectId"
                      element={<BulkUploadAdminManagement />}
                    />
                    <Route
                      path="bulk-admin/:projectId/:albumId"
                      element={<BulkUploadAdminAlbumDetails />}
                    />
                    <Route
                      path="bulk-admin/:projectId/package-manager"
                      element={<BulkAdminPackagerPage />}
                    />
                  </Route>
                ) : validateUserLoading ? (
                  <LoadingMain />
                ) : (
                  <Route
                    path="/"
                    element={
                      <LoginPage
                        toastMsg={toastMsg}
                        onFailure={onFailure}
                        onSuccess={onSuccess}
                        currentUser={currentUser}
                      />
                    }
                  />
                )}
              </Routes>
            </ErrorBoundary>
          </Router>
        </NotificationProvider>
      </BaseProvider>
      <ReactQueryDevtools initialIsOpen />
    </StyletronProvider>
  );
};

export default App;
