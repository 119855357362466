import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const fetchSingleProductUnlockRequest = async (key, {requestId}) => {
  console.log('request', requestId);
  if (requestId) {
    try {
      const res = await axios(`/product_unlock_requests/${requestId}`, {
        method: 'GET',
      });
      return res.data?.data;
    } catch (error) {
      logError(error, 17);
      return error.response?.data;
    }
  }
};

const useGetSingleProductUnlockRequest = (props) => {
  const requestId = props;
  const {
    data: getProductUnlockRequest,
    refetch,
    isFetching,
    isLoading,
  } = useQuery(['singleRequest', requestId], fetchSingleProductUnlockRequest);

  return {getProductUnlockRequest, refetch, isFetching, isLoading};
};

export default useGetSingleProductUnlockRequest;
