import styled from '@emotion/styled';
import React from 'react';

import {NoRecords} from '../../../components';

export default function ArtistManagement() {
  return (
    <Container>
      <NoRecords btnLabel={null} onClick={null} page="approval" />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
