import styled from '@emotion/styled';
import {Form, Formik} from 'formik';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';

import {Button} from '../../../../components';
import {
  genre,
  musicals,
} from '../../../../components/CreateProject/AlbumUpload/AlbumDetails/optionData';
import FormikControl from '../../../../components/reusable/Formik/FormikControl';
import useNotification from '../../../../Contexts/useNotification';
import {logError} from '../../../../errorlogger/logError';
import {getTrackOrder} from '../../../../helpers/getTrackOrder';
import useUploadMetaFile from '../../../../helpers/useUploadMetaFile';
import useGetAlbumsData from '../../../../hooks/AlbumDetail/useGetAlbumsData';
import useReUpload from '../../../../hooks/AlbumDetail/useReUpload';
import useUpdateTrack from '../../../../hooks/AlbumDetail/useUpdateTrack';
import useCreateTrack from '../../../../hooks/AlbumsManagement/useCreateTrack';
import useGenerateIsrc from '../../../../hooks/ISRCManagement/useGenerateIsrc';
import useLocalStorage from '../../../../hooks/reusable/useLocalStorage';
import {COLUMN_LABELS} from '../../../../validation/CreateAlbum/FilmType';
import {
  trackDetailsUpdateValidationSchema,
  trackDetailsValidationSchema,
} from '../../../../validation/ProjectManagement/trackDetails';
import TrackFileUpload from '../../../UploadComponent/TrackFileUpload';
import UploadCardTrack from '../../../UploadComponent/UploadCardTrack';
export default function TrackForm(props) {
  const {
    selectedData,
    setIsOpen,
    setIsrcCode,
    setTrackCreated,
    refetchAlbums,
    isrcRef,
    newFormTag,
  } = props;
  console.log(
    '🚀 ~ file: TrackForm.tsx ~ line 38 ~ TrackForm ~ selectedData',
    selectedData,
  );
  const {albumId} = useParams();

  const albumData = JSON.parse(window.localStorage.getItem('track_album_data'));

  const [currentISRC, setCurrentISRC] = useLocalStorage('currentISRC', null);
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);
  const [isValidApiCalled, setIsValidApiCalled] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const {getAlbumDetail, refetch} = useGetAlbumsData({
    albumId: albumId,
  });
  useEffect(() => {
    if (newFormTag) {
      refetchAlbums();
      refetch();
    }
  }, [newFormTag]);
  const [successFiles, setSuccessFiles] = useState([]);
  const {uploadFile} = useUploadMetaFile({
    metadata: {
      album_id: albumId ? albumId : selectedData?.album_id,
      user_id: currentUser.id,
      track_id: selectedData ? selectedData.id : null,
      action: selectedData ? 'UPDATE' : 'CREATE',
    },
  });
  const project_id = getAlbumDetail?.project_id;

  const {showToast} = useNotification();

  const {
    generateIsrcMutation,
    isLoading: isGenerateIsrcLoading,
    error: generateIsrcError,
  } = useGenerateIsrc();

  const {
    createTrackMutation,
    isLoading: isCreateTrackLoading,
    error: createTrackError,
  } = useCreateTrack();

  const {
    updateTrackMutation,
    trackLoading: isUpdateTrackLoading,
    trackError: updateTrackError,
  } = useUpdateTrack();

  const {
    setProgressInfo,
    setImageLoaded,
    progressInfo,
    imageLoaded,
  } = useReUpload();

  useEffect(() => {
    if (updateTrackError || createTrackError) {
      setIsOpen(true);
    }
  }, [updateTrackError, createTrackError]);

  // useEffect(() => {
  //   if (selectedData && progressInfo === 100) {
  //     setIsOpen(false);
  //   }
  // }, [selectedData, progressInfo]);

  const getIsrc = async () => {
    try {
      const generateRandom = () => (+new Date()).toString(36).slice(-7);
      const data = {
        user_id: albumData?.user_id,
        batch_name: 'sickomode-' + generateRandom(),
        count: 1,
      };
      const res = await generateIsrcMutation({data});
      isrcRef.current = res?.data[0];
      setCurrentISRC(res?.data[0]);
      setIsValidApiCalled(true);
      setIsValid(true);

      if (res?.error) {
        return res;
      }
      return res?.data[0];
    } catch (error) {
      logError(error, 7);
    }
  };

  const updateTrackData = async (data) => {
    const {track_id, user_id, album_id, track_order: track_num, ...rest} = data;
    const track_order = Number(track_num);
    const trackData = {
      id: track_id,
      data: {
        user_id: user_id,
        album_id: album_id,
        track_order,
        ...rest,
      },
    };

    try {
      const res = await updateTrackMutation(trackData);
      if (res.error && res.error?.length > 0) {
        showToast(res.error, 'error');
      }
      closeModalOnSuccess(res);
    } catch (error) {
      logError(error, 12);
      return error.response?.data;
    }
  };

  const createValues = {
    track_name: selectedData ? selectedData.track_name : '',
    isrc: '',
    singers: selectedData?.singers?.length ? selectedData.singers : [''],
    lyricists: selectedData?.lyricists?.length ? selectedData.lyricists : [''],
    music_director: selectedData?.music_director?.length
      ? selectedData.music_director
      : [''],
    track_genre: selectedData
      ? genre.filter((gen) => gen.id === selectedData.track_genre)
      : '',
    instruments: '',
  };

  const updateValues = {
    track_name: selectedData ? selectedData.track_name : '',
    track_order: selectedData ? selectedData.track_order : '',
    singers: selectedData?.singers?.length ? selectedData.singers : [''],
    lyricists: selectedData?.lyricists?.length ? selectedData.lyricists : [''],
    music_director: selectedData?.music_director?.length
      ? selectedData.music_director
      : [''],
    track_genre: selectedData
      ? genre.filter((gen) => gen.id === selectedData.track_genre)
      : '',
    instruments: selectedData
      ? musicals.filter((music) => music.id === selectedData.instruments)
      : '',
  };

  const makeResponseData = (type, values) => {
    let res;
    const album_id = selectedData ? selectedData?.album_id : albumData.album_id;
    const user_id = selectedData ? selectedData?.user_id : albumData.user_id;
    const track_upc = albumData.track_upc;
    const track_id = selectedData?.id;
    const isrc = selectedData ? selectedData.isrc : values.isrc;
    const track_genre = values?.track_genre[0]?.value;
    const instruments = values?.instruments[0]?.id;
    const updated_by = currentUser.id;

    const commonValues = {
      track_file_name: values.isrc,
      lyricists: values.lyricists,
      music_director: values.music_director,
      singers: values.singers,
      instruments,
      track_name: values.track_name,
      album_id,
      track_genre,
      track_upc,
      isrc,
    };

    const createTrackData = {
      ...commonValues,
      track_order: getTrackOrder(getAlbumDetail.Track),
      user_id: user_id,
    };

    const updateTrackData = {
      ...commonValues,
      track_id,
      track_order: values.track_order,
      updated_by,
    };

    if (type === 'create') {
      res = createTrackData;
    } else {
      res = updateTrackData;
    }
    return res;
  };

  const createTrackFn = async (data) => {
    try {
      const res = await createTrackMutation(data);

      setTrackCreated(true);
      if (res?.error && res?.error.length > 0) {
        showToast(res?.error, 'error');
      }
      closeModalOnSuccess(res);
    } catch (error) {
      console.log(error);
    }
  };

  const updateTrackFn = async (data) => {
    try {
      const res = await updateTrackData(data);

      if (res?.error && res?.error.length > 0) {
        showToast(res?.error, 'error');
      }
      closeModalOnSuccess(res);
    } catch (error) {
      console.log(error);
    }
  };

  const closeModalOnSuccess = (res) => {
    if (res.statusCode === 200 || res.statusCode === 201) {
      const toastStr = res.statusCode === 200 ? 'updated' : 'created';
      setIsOpen(false);
      showToast(`Track ${toastStr} successfully`, 'success');
    }
  };

  const onSubmit = async (formikData) => {
    const type = selectedData?.id ? 'update' : 'create';

    const data = makeResponseData(type, formikData);

    if (selectedData?.id) {
      updateTrackFn(data);
    } else {
      createTrackFn(data);
    }
  };

  const setDisabled = (formik): boolean => {
    if (selectedData) {
      return Object.keys(formik.touched).length === 0 &&
        formik.touched.constructor === Object
        ? true
        : false;
    } else {
      return formik.dirty && formik.isValid && progressInfo === 100
        ? false
        : true;
    }
  };

  const setOrder = (formik) => {
    return selectedData
      ? formik.initialValues.track_order
      : getTrackOrder(getAlbumDetail?.Track);
  };

  const onFileSelected = async (files, isrc) => {
    setSuccessFiles(files);
    const file = files[0];
    if (['audio/x-wav', 'audio/wav'].includes(file.type)) {
      // const new_file = rename_file(file, isrc);
      const new_file = new File([file], isrc.trim() + '.wav');
      try {
        await uploadFile(
          new_file,
          selectedData?.Reused_Track
            ? selectedData.Reused_Track?.album?.project_id
            : project_id,
          'audio',
          (event) => {
            setProgressInfo(Math.round((100 * event.loaded) / event.total));
          },
        );
        refetchAlbums();
        refetch();
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <TrackFormWrapper>
      <Formik
        initialValues={selectedData ? updateValues : createValues}
        validationSchema={
          selectedData
            ? trackDetailsUpdateValidationSchema
            : trackDetailsValidationSchema
        }
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form style={{width: '100%'}}>
            <FormikControl
              control="baseinput"
              type="text"
              name="track_name"
              label={COLUMN_LABELS.SONG_NAME}
              placeholder="e.g Sound of music"
              initialValue={formik.initialValues.track_name}
              setFieldValue={formik.setFieldValue}
              setFieldTouched={formik.setFieldTouched}
            />
            {selectedData && (
              <div style={{pointerEvents: 'none', opacity: '0.7'}}>
                <FormikControl
                  control="baseinput"
                  name="isrc"
                  label={COLUMN_LABELS.ISRC}
                  type="text"
                  initialValue={selectedData?.isrc}
                  setFieldValue={formik.setFieldValue}
                  setFieldTouched={formik.setFieldTouched}
                />
              </div>
            )}
            <div style={{pointerEvents: 'none', opacity: '0.7'}}>
              <FormikControl
                control="baseinput"
                name="track_order"
                label={COLUMN_LABELS.SONG_ORDER}
                type="text"
                initialValue={setOrder(formik)}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
              />
            </div>
            {!selectedData && (
              <div
                style={
                  progressInfo === 100 || progressInfo >= 1
                    ? {pointerEvents: 'none', opacity: '0.7'}
                    : {}
                }
              >
                <FormikControl
                  control="drawerInputWButton"
                  name="isrc"
                  label="ISRC *"
                  placeholder="Generate ISRC"
                  setIsrcCode={setIsrcCode}
                  value={formik.values.isrc}
                  generateCode={() => getIsrc()}
                  setFieldValue={formik.setFieldValue}
                  setIsValid={setIsValid}
                  setIsValidApiCalled={setIsValidApiCalled}
                />
              </div>
            )}
            <FormikControl
              control="dynamicInput"
              name="singers"
              placeholder="e.g Jane Doe"
              label={COLUMN_LABELS.SINGER_ARTIST_NAME}
              buttonName="Add Singer"
              setFieldTouched={formik.setFieldTouched}
              setFieldValue={formik.setFieldValue}
            />
            <FormikControl
              control="dynamicInput"
              name="lyricists"
              label={COLUMN_LABELS.LYRICIST_NAME}
              placeholder="e.g Jane Doe"
              buttonName="Add Lyricist"
              setFieldTouched={formik.setFieldTouched}
              setFieldValue={formik.setFieldValue}
            />
            <FormikControl
              labelKey="value"
              valueKey="id"
              control="baseselect"
              label={COLUMN_LABELS.MUSICAL_INSTRUMENTS}
              isSearchable={false}
              name="instruments"
              placeholder="Select Musical Instruments"
              options={musicals}
              value={formik.values.instruments}
              onChange={(name, value) => {
                formik.setFieldTouched(name, true);
                formik.setFieldValue(name, value);
              }}
            />
            <FormikControl
              control="dynamicInput"
              buttonName="Add Director"
              placeholder="e.g John Doe"
              name="music_director"
              label="Music Director"
              type="text"
              setFieldTouched={formik.setFieldTouched}
              setFieldValue={formik.setFieldValue}
            />
            <FormikControl
              labelKey="value"
              valueKey="id"
              control="baseselect"
              label={COLUMN_LABELS.SONG_GENRE}
              name="track_genre"
              placeholder="Select Genre"
              isSearchable={false}
              options={genre}
              value={formik.values.track_genre}
              onChange={(name, value) => {
                formik.setFieldTouched(name, true);
                formik.setFieldValue(name, value);
              }}
            />

            <p className="trackfile">
              Track File <span> *</span>
            </p>
            <TrackFileUpload
              onDropHandler={onFileSelected}
              setFieldTouched={formik.setFieldTouched}
              isrcCode={
                selectedData ? selectedData?.isrc : formik?.values?.isrc
              }
              progressInfo={progressInfo}
            />

            {successFiles.length
              ? successFiles.map((dataItem, i) => (
                  <UploadCardTrack
                    label="WAV"
                    name={
                      selectedData ? selectedData?.track_name : dataItem.name
                    }
                    key={i}
                    progressInfo={progressInfo}
                    page="tracks"
                    size={dataItem.size}
                  />
                ))
              : null}

            <Button
              disabled={setDisabled(formik)}
              // disabled={
              //   setDisabled(formik) ||
              //   (Object.keys(formik.touched).length === 0 &&
              //     formik.touched.constructor === Object)
              // }
              isLoading={isCreateTrackLoading || isUpdateTrackLoading}
              type="submit"
              label={selectedData ? 'Update Track' : 'Add Track'}
            />
          </Form>
        )}
      </Formik>
    </TrackFormWrapper>
  );
}

const TrackFormWrapper = styled.div`
  .audio-file-container {
    .audio-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;

      width: 100%;
    }
  }
  .trackfile {
    margin: 0;
    display: flex;
    font-size: 14px;
    align-items: center;
    margin: 4px 0;
    color: #333333;
    font-weight: bold;
    span {
      margin-left: 5px;
      color: #dc3832;
    }
  }

  .audio-file-upload {
    margin: 8px 0;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px;
    .row1 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .sect1 {
      width: 60%;

      .icon {
        width: 50px;
        height: 50px;
        background: rgba(0, 202, 202, 0.25);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: #00caca;
          font-size: 20px;
        }
      }
      .track-data {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        line-height: 18px;
        span {
          small {
            font-size: 12px;
            background: #f7e8cc;
            color: #d88e00;
            padding: 5px;
          }
          &:nth-of-type(1) {
            font-weight: bold;
          }
          &:nth-of-type(2) {
            font-size: 14px;
            margin-top: 5px;
          }
        }
      }
    }

    .sect1,
    .sect2 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .sect2 {
      justify-content: flex-end;
      width: 40%;
    }
    .success {
      display: flex;
      width: 100%;
      background: #e1f1e1;
      color: #34a139;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      border-radius: 5px;
    }
  }
  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;
