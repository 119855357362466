import styled from '@emotion/styled';
import React from 'react';
import {useContext} from 'react';
import {BiSearch} from 'react-icons/bi';
import {GlobalContext} from '../../Contexts/GlobalProvider';
import useCallDispatch from '../../hooks/FIlter/useCallDispatch';
const Reset = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  color: #505f7a;
  .selected {
    margin-left: 10px;
  }
  .btn {
    margin-right: 10px;
    :hover {
      color: #dc3831;
      cursor: pointer;
    }
  }
`;
const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
`;
export default function OrderDropDownSearch(props) {
  const {
    tag,
    handleChange,
    searchTerm,
    setSelectedDspData,
    setSearchTerm,
  } = props;
  const {callDispatch, state} = useCallDispatch();

  const reset = () => {
    callDispatch('CHANGE_DSP_DROP', 'selectedDsp', []);
    setSelectedDspData([]);
    setSearchTerm('');
  };
  const renderPlaceholder = (tag) => {
    if (tag === 'dsp') {
      return 'DSP(s)';
    } else if (tag === 'order_status') {
      return 'Order Status';
    } else {
      return null;
    }
  };
  return (
    <Container>
      {tag === 'dsp' && (
        <div className="input-container">
          <input
            type="text"
            autoFocus
            placeholder={`Find ${renderPlaceholder(tag)}`}
            value={searchTerm}
            onChange={(event) => handleChange(event)}
          />
          <BiSearch />
        </div>
      )}
      {tag === 'dsp' && state.selectedDsp?.length > 0 && (
        <div className="input-container">
          <Reset>
            {state.selectedDsp?.length === 1 ? (
              <span className="selected">
                {state.selectedDsp?.length} item selected
              </span>
            ) : (
              <span className="selected">
                {state.selectedDsp?.length} items selected
              </span>
            )}
            <span className="btn" onClick={reset}>
              Reset
            </span>
          </Reset>
        </div>
      )}
    </Container>
  );
}
