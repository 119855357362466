import {useQuery} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const fetchUserHistory = async (key, userId) => {
  if (userId) {
    try {
      const res = await axios(`/user_history/${userId}`, {
        method: 'GET',
      });

      return res.data?.data;
    } catch (error) {
      logError(error, 2);

      return error.response?.data;
    }
  }
};

const useGetUserHistory = (props) => {
  const {id} = props;
  const {data: getUserHistory, isLoading, error, isError} = useQuery(
    ['userHistory', id],
    fetchUserHistory,
    {
      enabled: id,
    },
  );

  return {
    getUserHistory,
    isLoading,
    error,
    isError,
  };
};

export default useGetUserHistory;
