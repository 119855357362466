import styled from '@emotion/styled';
import {StatefulMenu} from 'baseui/menu';
import {PLACEMENT, StatefulPopover} from 'baseui/popover';
import React, {useEffect, useRef, useState} from 'react';
import {BiChevronDown, BiSearch} from 'react-icons/bi';
import useSelectedItems from '../../hooks/FIlter/useSelectedItems';
import DspCheckBox from './DspCheckBox';
import StatusCheckBox from './StatusCheckBox';
import OrderDropDownSearch from './OrderDropDownSearch';
import OrderReleaseDateForm from './OrderReleaseDateForm';
import OrderUpcForm from './OrderUpcForm';
import OrderIdForm from './OrderIdForm';

const Container = styled.div<{isOpen; type; hasValue; expandWindow}>`
  .drop-dwn {
    background: ${(props) =>
      props.isOpen
        ? 'rgba(220, 56, 49, 0.1)'
        : props.type === 'list' && props.hasValue
        ? 'rgb(37, 56, 88)'
        : props.type === 'list'
        ? '#fff'
        : props.hasValue
        ? 'rgb(37, 56, 88)'
        : 'transparent'};
    border: none;
    font-size: 14px;
    line-height: 14px;
    padding: 10px;
    margin-right: 5px;
    border-radius: 50px;
    color: ${(props) =>
      props.isOpen ? '#DC3831' : props.hasValue ? '#fff' : '#505f7a'};
    cursor: pointer;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-left: 5px;
    }
    :focus,
    input:focus {
      outline: none;
    }
  }
`;

const CustomListContainer = styled.div`
  // max-height: 300px;
  // min-width: 200px;
  background-color: white;
  // overflow: scroll;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: relative;
  font-size: 14px;
  .input-container {
    position: sticky;
    top: 0;
    border-bottom: 0.5px solid rgba(80, 95, 122, 0.2);
    input {
      border: none;
      padding: 13px 17px;
      font-size: 14px;

      width: -webkit-fill-available;
      :focus {
        outline: none;
      }
      ::placeholder {
        color: #929292;
        opacity: 1;
      }
    }
    svg {
      position: absolute;
      top: 14px;
      right: 14px;
      color: #929292;
    }
  }
`;

const CustomList = (props) => {
  const {
    tag,
    onChangeText,
    onKeyPressHandler,
    onRemoveItem,
    value,
    items,
    textAreaRef,
    setDate,
    startValue,
    setStartValue,
    endValue,
    setEndValue,
    setDropValue,
    handleChange,
    searchTerm,
    setSelectedDspData,
    setSearchTerm,
  } = props;

  const renderFormByTag = (tag) => {
    if (tag === 'order_date') {
      return (
        <OrderReleaseDateForm
          setDate={setDate}
          startValue={startValue}
          setStartValue={setStartValue}
          endValue={endValue}
          setEndValue={setEndValue}
          setDropValue={setDropValue}
          tag={tag}
        />
      );
    } else if (tag === 'upc') {
      return (
        <OrderUpcForm
          onChangeText={onChangeText}
          onKeyPressHandler={onKeyPressHandler}
          onRemoveItem={onRemoveItem}
          value={value}
          items={items}
          textAreaRef={textAreaRef}
          setDropValue={setDropValue}
          tag={tag}
        />
      );
    } else if (tag === 'order_id') {
      return (
        <OrderIdForm
          onChangeText={onChangeText}
          onKeyPressHandler={onKeyPressHandler}
          onRemoveItem={onRemoveItem}
          value={value}
          items={items}
          textAreaRef={textAreaRef}
          setDropValue={setDropValue}
          tag={tag}
        />
      );
    } else {
      return (
        <CustomListContainer>
          <OrderDropDownSearch
            tag={tag}
            handleChange={handleChange}
            searchTerm={searchTerm}
            setSelectedDspData={setSelectedDspData}
            setSearchTerm={setSearchTerm}
          />
          {props.data?.children}
        </CustomListContainer>
      );
    }
  };
  return renderFormByTag(tag);
};
const CustomListItem = (props) => {
  const {
    type,
    setSelectedArray,
    items,
    setDropValue,
    selectedArray,
    tag,
    setSelectedDspData,
    selectedDspData,
    selectedId,
    setSelectedId,
    setSearchTerm,
  } = props;

  const renderListsByType = (tag) => {
    if (tag === 'dsp') {
      return (
        <DspCheckBox
          setSelectedArray={setSelectedArray}
          items={items}
          data={props.data}
          setDropValue={setDropValue}
          selectedArray={selectedArray}
          onClickHandler={props.data?.onClick}
          setSelectedDspData={setSelectedDspData}
          selectedDspData={selectedDspData}
          setSearchTerm={setSearchTerm}
        />
      );
    } else if (tag === 'order_status') {
      return (
        <StatusCheckBox
          setSelectedArray={setSelectedArray}
          items={items}
          data={props.data}
          setDropValue={setDropValue}
          selectedArray={selectedArray}
          onClickHandler={props.data?.onClick}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
        />
      );
    } else {
      return null;
    }
  };

  return renderListsByType(tag);
};

export default function OrderFilterDropDown(props) {
  const {
    data,
    label,
    type,
    tag,
    setIsSelected,
    expandWindow,
    handleChange,
    searchTerm,
    setSearchTerm,
    selectedDspData,
    setSelectedDspData,
    setPage,
    selectedId,
    setSelectedId,
  } = props;
  const textAreaRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const [startValue, setStartValue] = React.useState([new Date()]);
  const [endValue, setEndValue] = React.useState([new Date()]);
  const [date, setDate] = useState('');

  const {
    dropValue,
    selectedArray,
    setDropValue,
    setSelectedArray,
  } = useSelectedItems({
    data,
    label,
    selectedDspData,
    setPage,
    selectedId,
  });

  const onSelectMenu = (item) => {
    setDropValue(item.label);
    if (item.label) {
      setIsSelected(true);
    }
  };
  const onSelectCheckbox = (item) => {
    console.log(
      '🚀 ~ file: ManualOrderFilterDropDown.tsx ~ line 161 ~ onSelectCheckbox ~ item',
      item,
    );
  };

  return (
    <>
      <Container
        expandWindow={expandWindow}
        hasValue={dropValue.includes(':') || !dropValue.includes(label)}
        isOpen={isOpen}
        type={type}
      >
        <StatefulPopover
          renderAll
          onOpen={() => setIsOpen(true)}
          onClose={() => {
            setIsOpen(false);
            setSearchTerm('');
          }}
          overrides={{
            Inner: {
              style: () => ({
                maxHeight: '300px',
                minWidth: '200px',
                overflow: 'scroll',
                boxShadow: '0px 3px 8px rgb(0 0 0 / 15%)',
                borderRadius: '5px',
              }),
            },
          }}
          focusLock
          placement={PLACEMENT.bottomLeft}
          content={({close}) => (
            <StatefulMenu
              items={data}
              onItemSelect={(v) => {
                if (type === 'checkbox') {
                  onSelectCheckbox(v.item);
                } else {
                  onSelectMenu(v.item);
                  close();
                }
              }}
              overrides={{
                List: {
                  component: (props) => (
                    <CustomList
                      data={props}
                      type={type}
                      tag={tag}
                      setSelectedArray={setSelectedArray}
                      setDate={setDate}
                      startValue={startValue}
                      setStartValue={setStartValue}
                      endValue={endValue}
                      setEndValue={setEndValue}
                      setDropValue={setDropValue}
                      handleChange={handleChange}
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                      selectedArray={selectedArray}
                      textAreaRef={textAreaRef}
                      setSelectedDspData={setSelectedDspData}
                    />
                  ),
                },
                ListItem: {
                  component: (props) => (
                    <CustomListItem
                      items={data}
                      data={props}
                      type={type}
                      setDropValue={setDropValue}
                      setSelectedArray={setSelectedArray}
                      selectedArray={selectedArray}
                      tag={tag}
                      setSelectedDspData={setSelectedDspData}
                      selectedDspData={selectedDspData}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      setSearchTerm={setSearchTerm}
                    />
                  ),
                },
              }}
            />
          )}
        >
          <button className="drop-dwn">
            {dropValue}
            <BiChevronDown />
          </button>
        </StatefulPopover>
      </Container>
    </>
  );
}
