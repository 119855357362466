import {Upload} from 'baseui/icon';
import * as FileSaver from 'file-saver';
import React from 'react';
import * as XLSX from 'xlsx';

import {Button} from '../components/';

export const downloadCsv = (type, textArray, fileName) => {
  let csvContent = 'data:text/csv;charset=utf-8,';

  if (type === 'upc') {
    textArray.forEach((row) => {
      csvContent += row.upc + '\r\n';
    });
  } else if (type === 'currentPage') {
    textArray.forEach((row) => {
      csvContent += JSON.stringify(row);
    });
  } else {
    textArray.forEach((row) => {
      csvContent += row + '\r\n';
    });
  }

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', fileName + '.csv');
  document.body.appendChild(link);

  link.click();
  link.remove();
};

export const exportToExcel = (csvData, fileName) => {
  const wb = XLSX.utils.book_new();

  const ws = XLSX.utils.json_to_sheet(csvData);
  XLSX.utils.book_append_sheet(wb, ws);
  var wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
  FileSaver.saveAs(
    new Blob([wbout], {type: 'application/octet-stream'}),
    `${fileName}.xlsx`,
  );
};
