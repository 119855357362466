import React, {useEffect, useState} from 'react';

import {TAGS} from '../../../constants/tags';
import useGetInReviewAlbums from '../../../hooks/AlbumsApproval/useGetInReviewAlbums';
import useTablePageState from '../../../hooks/reusable/useTablePageState';
import SkeletonLoading from '../../SkeletonPage';
import {albumsApprovalColumnMeta} from '../../../tableColumns/AlbumsInReview/tableData';
import FlyMenu from '../../../components/reusable/FlyMenu/FlyMenu';
import NoRecords from '../../../components/reusable/NoRecords/NoRecords';
import Table from '../../../components/reusable/Table/TablePagination';

const AlbumsInReview = () => {
  const [searchStr, setSearchStr] = useState('');

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    resolvedData,
    setPage,
    page,
    isLastPage,
    totalCount,
    rangeB,
    rangeA,
    lastPage,
    setLimit,
    limit,
    sError,
    sIsError,
    sIsFetching,
    sIsLoading,
    isCustomLoading,
    refetch,
  } = useGetInReviewAlbums({searchStr});

  const {
    isOpen,
    setNewFormTag,
    newFormTag,
    setIsOpen,
    setSelectedData,
    selectedData,
  } = useTablePageState();

  const albums = React.useMemo(() => data, [data]);
  const columnData = React.useMemo(() => albumsApprovalColumnMeta, [
    albumsApprovalColumnMeta,
  ]);

  if (isLoading) {
    return <SkeletonLoading />;
  }

  if (resolvedData?.totalPage === 0) {
    return <NoRecords btnLabel={null} onClick={null} page="approval" />;
  }

  return (
    <>
      <Table
        columns={columnData}
        data={albums ? albums : []}
        tag={TAGS.ALBUM_APPROVAL}
        setSidebarData={setSelectedData}
        onClickRow={setIsOpen}
        setPage={setPage}
        page={page}
        isLastPage={isLastPage}
        totalCount={totalCount}
        rangeB={rangeB}
        rangeA={rangeA}
        lastPage={lastPage}
        setLimit={setLimit}
        limit={limit}
        resolvedData={resolvedData}
        isLoading={isCustomLoading}
        isFetching={isFetching}
        isError={isError || sIsError}
        error={error}
        sError={sError}
        sIsError={sIsError}
        sIsFetching={sIsFetching}
        sIsLoading={sIsLoading}
        setSearchStr={setSearchStr}
        actionBtn={null}
        refetchHandler={refetch}
      />

      {isOpen && (
        <FlyMenu
          refetch={refetch}
          setSelectedData={setSelectedData}
          selectedData={selectedData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          tag={TAGS.ALBUM_APPROVAL}
          setNewFormTag={setNewFormTag}
          newFormTag={newFormTag}
        />
      )}
    </>
  );
};

export default AlbumsInReview;
