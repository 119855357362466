import React from 'react';
import {Skeleton} from 'baseui/skeleton';
import styled from '@emotion/styled';

const SkeletonLoading = styled.div`
  .skel-track {
    display: flex;
    width: 702px;
    justify-content: space-between;
    margin-top: 10px;
    background: #ffffff;
    box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px,
      rgb(0 0 0 / 30%) 0px 1px 3px -1px;
    border-radius: 10px;
    align-items: center;
    .skel-details {
      display: flex;
      align-items: center;
      padding: 5px;
      .skel-info {
        margin-left: 10px;
        .skel-info-detail {
          display: flex;
          align-items: center;
          padding-bottom: 7px;
        }
      }
    }
    .skel-btn {
      margin-right: 15px;
    }
  }
`;

export const IsrcSkeletonLoad = () => {
  return (
    <SkeletonLoading>
      <div className="skel-track">
        <div className="skel-details">
          <div className="skel-img">
            <Skeleton
              overrides={{
                Root: {
                  style: () => ({
                    borderRadius: '10px',
                  }),
                },
              }}
              width="60px"
              height="60px"
              animation
            />
          </div>
          <div className="skel-info">
            <div className="skel-info-detail">
              <Skeleton width="170px" height="20px" animation />
            </div>
            <div className="skel-sub-title">
              <Skeleton width="140px" height="20px" animation />
            </div>
          </div>
        </div>
        <div className="skel-btn">
          <Skeleton
            overrides={{
              Root: {
                style: () => ({
                  borderRadius: '5px',
                }),
              },
            }}
            width="100px"
            height="35px"
            animation
          />
        </div>
      </div>
    </SkeletonLoading>
  );
};
