import {queryCache, useMutation} from 'react-query';

import axios from '../../config/Axios';
import {logError} from '../../errorlogger/logError';

const updateTrackFn = async (request) => {
  const {id, data} = request;

  if (id) {
    try {
      return await axios({
        method: 'PUT',
        url: `/track/update-status/${id}`,
        data: data,
      });
    } catch (error) {
      logError(error, 12);
    }
  }
};

const useUpdateTrackStatus = () => {
  const [
    updateTracStatusMutation,
    {status: trackStatus, isLoading: trackStatusLoading, error: trackError},
  ] = useMutation(updateTrackFn, {
    onSuccess: () => {
      queryCache.invalidateQueries('track');
      queryCache.invalidateQueries('album');
      queryCache.invalidateQueries('albums');
      queryCache.invalidateQueries('singleAlbum');
    },
  });

  const updateTrackStatusFn = async (data) => {
    const trackData = {
      id: data.trackId,
      data: {
        status: data.data?.status,
        updated_by: data?.data.updated_by,
      },
    };
    try {
      const res = await updateTracStatusMutation(trackData);
    } catch (error) {
      logError(error, 12);
    }
  };

  return {updateTrackStatusFn, trackStatusLoading};
};

export default useUpdateTrackStatus;
