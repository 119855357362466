import styled from '@emotion/styled';
import React, {useContext, useEffect, useState} from 'react';
import {Button as BaseButton} from 'baseui/button';
import {GlobalContext} from '../../Contexts/GlobalProvider';
import useCallDispatch from '../../hooks/FIlter/useCallDispatch';
import Search from '../../components/reusable/Search';
// import FilterExpandedComponent from './FilterExpandedComponent';
import OrderFilterDropDown from './OrderFilterDropDown';
import {BiRefresh} from 'react-icons/bi';

export default function OrderFilter(props) {
  const {
    filterData,
    handleChange,
    searchTerm,
    setSearchString,
    searchString,
    setPage,
    setSearchTerm,
    isFetching,
    refetchHandler,
    type,
  } = props;
  const {callDispatch, state} = useCallDispatch();
  const [searchVal, setSearchVal] = useState('');
  const [isSelected, setIsSelected] = useState(false);
  const [toggleReset, setToggleReset] = useState(false);
  const [expandWindow, setExpandWindow] = useState(false);
  const [selectedDspData, setSelectedDspData] = useState([]); // selectedDsp
  const [selectedId, setSelectedId] = useState([]); // orderStatusId
  const [disable, setDisable] = useState(true);

  const onChangeSearch = (e) => {
    setSearchString(e.target.value);
  };
  const resetHandler = () => {
    callDispatch('CHANGE_DSP_DROP', 'selectedDsp', []);
    callDispatch('CHANGE_UPC_DROP', 'upc_id', []);
    callDispatch('CHANGE_ORDERID_DROP', 'order_id', []);
    callDispatch('CHANGE_START_DATE_DROP', 'order_start_date', '');
    callDispatch('CHANGE_END_DATE_DROP', 'order_end_date', '');
    callDispatch('CHANGE_STATUS_DROP', 'order_status', []);
    setSelectedDspData([]);
    setSelectedId([]);
    setSearchString('');
  };
  useEffect(() => {
    if (type === 'group' || type === 'single') {
      resetHandler();
    }
  }, [type]);
  useEffect(() => {
    if (
      state?.selectedDsp?.length > 0 ||
      state.upc_id?.length > 0 ||
      state.order_id?.length > 0 ||
      state.order_start_date ||
      state.order_end_date ||
      state.order_status?.length > 0 ||
      searchString?.length > 0
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [state, searchString]);
  return (
    <ManualFilterContainer expandWindow={expandWindow} isSelected={isSelected}>
      <div className="sub-container">
        <div className="wrapper-container">
          <div className="search-container">
            {type === 'group' ? null : (
              <Search
                value={searchString}
                onChangeHandler={onChangeSearch}
                placeholder={`product name`}
              />
            )}
          </div>
          {filterData?.map((d) => {
            return (
              <OrderFilterDropDown
                tag={d.tag}
                type={d.type}
                data={d.data}
                label={d.label}
                toggleReset={toggleReset}
                handleChange={handleChange}
                searchTerm={searchTerm}
                setSelectedDspData={setSelectedDspData}
                selectedDspData={selectedDspData}
                setSelectedId={setSelectedId}
                selectedId={selectedId}
                setPage={setPage}
                setSearchTerm={setSearchTerm}
              />
            );
          })}
          <div>
            <button
              className={disable ? 'disable-btn' : 'reset-btn'}
              onClick={resetHandler}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
      {refetchHandler ? (
        <div className={`refresh-btn`}>
          <BaseButton size="mini" onClick={refetchHandler} shape="circle">
            <span className={`svg-container ${isFetching && 'rotating'}`}>
              <BiRefresh size={20} />
            </span>
          </BaseButton>
        </div>
      ) : null}
    </ManualFilterContainer>
  );
}

const ManualFilterContainer = styled.div<{isSelected: any; expandWindow: any}>`
  display: flex;
  align-items: ${(props) => (props.expandWindow ? 'flex-start' : 'center')};
  justify-content: space-between;
  width: 100%;
  transition: all 0.2s ease-in;
  height: auto;
  .sub-container {
    display: flex;
    margin: 10px 0;
  }
  .filter-icon {
    position: absolute;
    right: 30px;
    display: flex;
    margin-top: 2px;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    transition: all 0.1s ease-in;
    padding: 5px;
    color: #dc3831;
    // border-radius: 100px;
    // color: rgb(37, 56, 88);
    // background: rgba(37, 56, 88, 0.2);
    // &:hover {
    //   background: rgb(37, 56, 88);
    //   color: white;
    // }
  }
  .wrapper-container {
    display: flex;
    align-items: center;
    // pointer-events: ${(props) => (props.isSelected ? 'auto' : 'none')};
    // opacity: ${(props) => (props.isSelected ? 1 : 0.4)};
    .search-container {
      margin-left: 30px;
      margin-right: 6px;
    }
  }
  .svg-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .refresh-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
  }
  .reset-btn {
    // padding: 10px 25px;
    // color: rgb(37, 56, 88);
    // border-radius: 100px;
    // border: none;
    // background: rgba(37, 56, 88, 0.2);
    // cursor: pointer;
    // &:focus {
    //   outline: none;
    // }
    padding: 9px 22px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    border: none;
    align-items: center;
    margin-left: 6px;
    border-radius: 25px;
    outline: none;
    appearance: none;
    cursor: pointer;
    background: #dc3831;
    :focus {
      outline: none;
      box-shadow: 0 0 0 5px rgb(80 96 122 / 10%);
    }
  }
  .disable-btn {
    padding: 9px 22px;
    color: #272727;
    font-size: 14px;
    font-weight: 700;
    border: none;
    align-items: center;
    margin-left: 6px;
    border-radius: 25px;
    outline: none;
    appearance: none;
    cursor: pointer;
    background: #dddddd;
    opacity: 0.65;
    cursor: not-allowed;
  }
`;
