import styled from '@emotion/styled';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {BiMessageRoundedError} from 'react-icons/bi';
import {useLocation, useParams} from 'react-router';

import {Tab} from '../../../components';
import DownloadZipModal from '../../../components/AlbumsInReview/DownloadZipModal';
import {tracksColumnMeta} from '../../../components/AlbumTracksApproval/tableData';
import FlyMenu from '../../../components/reusable/FlyMenu/FlyMenu';
import LoadingSpinnerTransparent from '../../../components/reusable/LoadingSpinnerTransparent/LoadingSpinnerTransparent';
import {TAGS} from '../../../constants/tags';
import useGetAlbumsData from '../../../hooks/AlbumDetail/useGetAlbumsData';
import useStatusUpdateHandler from '../../../hooks/AlbumDetail/useStatusUpdateHandler';
import useUpdateAlbum from '../../../hooks/AlbumsManagement/useUpdateAlbumStatus';
import usePublishAlbum from '../../../hooks/AlbumTrackApproval/usePublishAlbum';
import useCallDispatch from '../../../hooks/FIlter/useCallDispatch';
import useLocalStorage from '../../../hooks/reusable/useLocalStorage';
import useMusicPlayer from '../../../hooks/reusable/useMusicPlayer';
import useS3Key from '../../../hooks/reusable/useS3key';
import useTablePageState from '../../../hooks/reusable/useTablePageState';
import SkeletonLoading from '../../../pages/SkeletonAlbumDetail';
import AlbumInfo from '../../AlbumsManagment/AlbumDetail/AlbumInfo';
import MusicPlayerNew from '../../AlbumsManagment/AlbumDetail/MusicPlayerNew';
import AlbumHistory from '../../AlbumsManagment/Albums/AlbumHistory/AlbumHistory';
import ActionButtonDropDownContentInApprove from '../AlbumsReviewDetail/ActionButtonDropDownContentInApprove';
import AlbumApproval from './AlbumApproval';

const Container = styled.div<{hideModal}>`
  display: ${(props) => (props.hideModal ? 'none' : 'flex')};
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #333333;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e6e2e2;
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        color: #f28300;
        font-size: 25px;
        margin-right: 5px;
      }
    }
    .btn {
      display: flex;
      justify-content: space-between;
      .approve {
        padding: 12px 22px;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        border: none;
        align-items: center;
        border-radius: 25px;
        outline: none;
        appearance: none;
        background: #31a038;
        margin-right: 10px;
        cursor: pointer;
      }
      .decline {
        padding: 12px 22px;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        border: none;
        align-items: center;
        border-radius: 25px;
        outline: none;
        appearance: none;
        background: #dc3831;
        cursor: pointer;
      }
    }
  }
  .content {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    color: #878787;
  }
`;

const AlbumApprovalDetail = () => {
  const {albumId} = useParams();
  // const {state} = useLocation();
  // const {comment, requestId, requestStatus}: any = state;
  const [activeKey, setActiveKey] = React.useState<string | number>('0');
  const [tracks, setTracks] = useState(null);
  const [trackData, setTrackData] = useState(null);
  const [trackIsrc, setTrackIsrc] = useState(null);
  const [musicDirectors, setMusicDirectors] = useState([]);
  const [currentUser, setCurrentUser] = useLocalStorage('currentUser', null);

  const {getAlbumDetail, refetch, isLoading, isFetching} = useGetAlbumsData({
    albumId: albumId,
  });

  const [collapsePlayer, setCollapsePlayer] = useState(false);

  const {
    publishAlbumMutation,
    isLoading: isPublishAlbumLoading,
    error: publishAlbumError,
  } = usePublishAlbum();

  const {
    updateAlbumMutation,
    isLoading: isUpdateAlbumLoading,
    error: updateAlbumError,
  } = useUpdateAlbum();

  useEffect(() => {
    const md = getAlbumDetail?.Track.map((track) => track?.music_director);
    const mergedArr = Array.prototype.concat.apply([], md);
    const unique = [...new Set(mergedArr)];
    setMusicDirectors(unique);
  }, [getAlbumDetail]);

  useEffect(() => {
    if (getAlbumDetail) {
      setTracks(getAlbumDetail.Track);
      const d = getAlbumDetail.Track.find(
        (track, i) => track.isrc === trackIsrc,
      );
      setTrackData(d);
    }
  }, [getAlbumDetail, trackIsrc]);

  const {
    status,
    productType,
    updateStatusHandler,
    albumLoading,
    trackLoading,
  } = useStatusUpdateHandler({data: getAlbumDetail?.Track});

  const {
    isOpen,
    setNewFormTag,
    newFormTag,
    setIsOpen,
    setSelectedData,
    selectedData,
  } = useTablePageState();

  const [toggleDspModal, setToggleDspModal] = useState(false);
  const [filter, setFilter] = React.useState([]);

  const {
    played,
    seeking,
    volume,
    handleSeekMouseDown,
    handleSeekChange,
    handleSeekMouseUp,
    handleVolumeChange,
    playerRef,
    handleStop,
    handlePlayPause,
    setPlayed,
    setCurrentTime,
    msToTime,
    setDuration,
    currentTime,
    duration,
    playing,
    handleMute,
    muted,
  } = useMusicPlayer({isrcCode: trackIsrc});

  const {s3KeyGenerator} = useS3Key({projectId: getAlbumDetail?.project_id});
  const {callDispatch} = useCallDispatch();
  const [dynamicTag, setDynamicTag] = useState(TAGS.TRACK_APPROVAL);
  const [isAlbumClick, setIsAlbumClick] = useState(false);
  const [published, setPublished] = useState(false);
  const [hideModal, setHideModal] = useState(false);

  const publish = async () => {
    const data = {
      project_id: getAlbumDetail?.project_id,
      album_id: [getAlbumDetail.id],
      group: false,
    };

    const res = await publishAlbumMutation(data);
    if (res?.status === 200) {
      setPublished(true);
    }
  };
  // const handleRequestApproval = async (status) => {
  //   // trigger pipeline
  //   try {
  //     const response = await axios({
  //       method: 'PUT',
  //       url: `/product_unlock_requests/${requestId}/${status}`,
  //       data: {reviewed_by: currentUser?.id},
  //     });
  //     return response.data;
  //   } catch (error) {
  //     return error.response?.data;
  //   }
  // };
  const submitApproval = async () => {
    const albumData = {
      id: getAlbumDetail?.id,
      data: {
        status: 'orderable',
        updated_by: currentUser.id,
      },
    };
    const response = await updateAlbumMutation(albumData);
    // Once the album status is approved, the publish endpoint gets triggered
    if (response.statusCode === 200) {
      publish();
      refetch();
    }
  };

  const content = [
    {
      title: `Track Details`,
      component: (
        <AlbumApproval
          currentUser={currentUser}
          columns={tracksColumnMeta}
          setIsOpen={setIsOpen}
          data={getAlbumDetail?.Track}
          setSelectedData={setSelectedData}
          setNewFormTag={setNewFormTag}
          tag={dynamicTag}
          getAlbumDetail={getAlbumDetail}
          setTrackIsrc={setTrackIsrc}
          handlePlayPause={handlePlayPause}
          playing={playing}
          duration={duration}
          trackIsrc={trackIsrc}
          hasCoverArtValue={getAlbumDetail?.cover_art ? true : false}
          status={status}
          productType={productType}
          setFilter={setFilter}
          isLoading={isLoading}
          refetch={refetch}
        />
      ),
    },
    {
      title: `History`,
      component: <AlbumHistory />,
    },
  ];

  const openAlbumForm = () => {
    if (getAlbumDetail) {
      setIsAlbumClick(true);
      setIsOpen(true);
      setSelectedData({original: getAlbumDetail});
    }
  };

  const changeTrack = (type) => {
    const index = tracks
      .sort((a, b) => {
        return a.track_order - b.track_order;
      })
      .findIndex((track, i) => track.isrc === trackIsrc);
    callDispatch('EXISTING_PROJECT_ID', 'existingProjectId', null);
    if (type === 'next') {
      if (index === tracks.length - 1) {
        setTrackIsrc(tracks[0].isrc);
        setTrackData(tracks[0]);
      } else {
        setTrackIsrc(tracks[index + 1].isrc);
        setTrackData(tracks[index + 1]);
      }
    } else {
      if (index === 0) {
        setTrackIsrc(tracks[tracks.length - 1].isrc);
        setTrackData(tracks[tracks.length - 1]);
      } else {
        setTrackIsrc(tracks[index - 1].isrc);
        setTrackData(tracks[index - 1]);
      }
    }
  };

  const openAddCoverArtHandler = () => {
    setIsOpen(true);
    setNewFormTag('add-coverart');
  };
  if (isLoading) {
    return <SkeletonLoading />;
  }

  return (
    <>
      {/* {!comment ? null : (
        <Container hideModal={hideModal}>
          <div className="header">
            <div className="title">
              {' '}
              <BiMessageRoundedError /> Product edit request{' '}
            </div>
            <div className="btn">
              <button
                className="approve"
                onClick={() => {
                  handleRequestApproval('approve');
                  setHideModal(true);
                }}
              >
                Approve
              </button>
              <button
                className="decline"
                onClick={() => {
                  handleRequestApproval('decline');
                  setHideModal(true);
                }}
              >
                Decline
              </button>
            </div>
          </div>
          <div className="content">{comment}</div>
        </Container>
      )} */}
      {(albumLoading ||
        isUpdateAlbumLoading ||
        isPublishAlbumLoading ||
        trackLoading) && <LoadingSpinnerTransparent />}

      <AlbumInfo
        isOpen={isOpen}
        getAlbumDetail={getAlbumDetail}
        musicDirectors={musicDirectors}
        // openFlyMenu={openFlyMenu}
        openAddCoverArtHandler={openAddCoverArtHandler}
        setToggleDspModal={setToggleDspModal}
        updateStatusHandler={updateStatusHandler}
        hasCoverArtValue={getAlbumDetail?.cover_art ? true : false}
        hasNoTracks={getAlbumDetail?.Track?.length === 0 ? true : false}
        actionDropDownContent={
          <ActionButtonDropDownContentInApprove
            status={getAlbumDetail?.status}
            submitApproval={submitApproval}
            isShowPublish={filter?.length === getAlbumDetail?.Track?.length}
          />
        }
        tag={dynamicTag}
        setIsAlbumClick={setIsAlbumClick}
        setIsOpen={setIsOpen}
        openAlbumForm={openAlbumForm}
        published={published}
        refetch={refetch}
      />
      <Tab
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        content={content}
      />

      {isOpen && (
        <FlyMenu
          refetch={refetch}
          setSelectedData={setSelectedData}
          newFormTag={newFormTag}
          selectedData={selectedData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setNewFormTag={setNewFormTag}
          setDynamicTag={setDynamicTag}
          // tag={dynamicTag}
          tag={isAlbumClick ? TAGS.ALBUM_APPROVAL : TAGS.TRACK_APPROVAL}
          setIsAlbumClick={setIsAlbumClick}
        />
      )}
      {toggleDspModal && (
        <DownloadZipModal
          id={getAlbumDetail?.id}
          showModal={toggleDspModal}
          onCloseHandler={() => setToggleDspModal(false)}
          sidebarRef={null}
        />
      )}

      {trackIsrc && (
        <MusicPlayerNew
          played={played}
          seeking={seeking}
          volume={volume}
          handleSeekMouseDown={handleSeekMouseDown}
          handleSeekChange={handleSeekChange}
          handleSeekMouseUp={handleSeekMouseUp}
          handleVolumeChange={handleVolumeChange}
          playerRef={playerRef}
          handleStop={handleStop}
          handlePlayPause={handlePlayPause}
          setPlayed={setPlayed}
          setCurrentTime={setCurrentTime}
          data={trackData}
          msToTime={msToTime}
          setDuration={setDuration}
          currentTime={currentTime}
          duration={duration}
          playing={playing}
          getAlbumDetail={getAlbumDetail}
          s3Key={s3KeyGenerator(
            'audio',
            trackIsrc,
            trackData?.Reused_Track
              ? trackData?.Reused_Track?.album?.project_id
              : null,
          )}
          muted={muted}
          handleMute={handleMute}
          isOpen={isOpen}
          setCollapsePlayer={setCollapsePlayer}
          collapsePlayer={collapsePlayer}
          changeTrack={changeTrack}
        />
      )}
    </>
  );
};
export default AlbumApprovalDetail;
