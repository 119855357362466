export const copyToClipboard = (type, textArray) => {
  let values;
  if (type === 'upc') {
    values = textArray.map((row) => row.upc);
  } else {
    values = textArray.map((row) => row);
  }
  const copyable_text = values.join('\r\n');
  const textField = document.createElement('textarea');
  textField.innerHTML = copyable_text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();

  alert('Copied: \n' + textField.value);
};
