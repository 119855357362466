import styled from '@emotion/styled';

import image from '../../../assets/placeholder.jpg';

export const CoverArt = styled.div<{imgSrc}>`
  width: 35px;
  height: 35px;
  border-radius: 5px;
  margin-right: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => (props.imgSrc ? props.imgSrc : image)});
`;

export const AlbumNameWrapper = styled.div`
  display: -webkit-inline-box;
  align-items: center;
  margin-top: 6px;
`;

export const StatusContainer = styled.div<{status; albumStatus}>`
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    padding: 6px 12px;

    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    svg {
      margin-left: 10px;
    }

    background: ${(props) =>
      props.status === 'waiting_approval' && props.albumStatus !== 'declined'
        ? '#BDE5F8'
        : props.status === 'complete'
        ? '#Fcd396'
        : props.status === 'incomplete'
        ? '#FEEFB3'
        : props.status === 'pending'
        ? '#FEEFB3'
        : props.status === 'waiting_approval' &&
          props.albumStatus === 'declined'
        ? '#FEEFB3'
        : props.status === 'declined'
        ? '#FFD2D2'
        : '#DFF2BF'};
    color: ${(props) =>
      props.status === 'waiting_approval' && props.albumStatus !== 'declined'
        ? '#00529B'
        : props.status === 'complete'
        ? '#a16100'
        : props.status === 'incomplete'
        ? '#9F6000'
        : props.status === 'pending'
        ? '#9F6000'
        : props.status === 'waiting_approval' &&
          props.albumStatus === 'declined'
        ? '#9F6000'
        : props.status === 'declined'
        ? '#D8000C'
        : '#4F8A10'};
    line-height: 0.25rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    border: none;
    padding: 6px 12px;
    background-color: white;
    appearance: none;
    cursor: pointer;
    border: 1px solid #dc3831;
    color: #dc3831;
    font-size: 14px;
    outline: none;
    border-radius: 5px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  }
`;
