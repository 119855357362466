import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {TAGS} from '../../../../constants/tags';

import useHistory from '../../../../hooks/History/useHistory';
import useGetLabelHistory from '../../../../hooks/LabelManagement/useGetLabelHistory';
import HistorySkeltonLoading from '../../../../components/reusable/HistorySkeltonLoading';
import NoRecords from '../../../../components/reusable/NoRecords/NoRecords';
import UserCreateHistoryCard from '../../../../components/reusable/history/UserCreateHistoryCard';
import UserUpdateHistoryCard from '../../../../components/reusable/history/UserUpdateHistoryCard';

export default function LabelHistory(props) {
  const {labelId} = props;

  const {getLabelHistory, isLoading} = useGetLabelHistory({id: labelId});

  const {formatHistoryData, historyData} = useHistory();

  useEffect(() => {
    formatHistoryData(getLabelHistory, 'label_name');
  }, [getLabelHistory]);

  const returnValues = (item, type) => {
    return item.field === 'dsps'
      ? item[type].map((val) => val?.dsp_name?.replace('_', ' ')).join(', ')
      : item.field === 'contract_start_date'
      ? moment(item[type]).format('MMM Do YY')
      : item.field === 'contract_end_date'
      ? moment(item[type]).format('MMM Do YY')
      : item[type];
  };

  const renderList = (item) => {
    const oldVal = returnValues(item, 'oldValue');
    const newVal = returnValues(item, 'newValue');

    if (item.action === 'UPDATE') {
      return (
        <UserUpdateHistoryCard
          userName={item.userName}
          field={item.field}
          oldValue={oldVal}
          newValue={newVal}
          date={item?.date}
          email={item?.email}
          moreFields={['dsps']}
        />
      );
    } else {
      if (item.newValue) {
        return (
          <UserCreateHistoryCard
            userName={item.userName}
            createdValue={item.newValue}
            date={item?.date}
            email={item?.email}
            tag={TAGS.LABEL}
          />
        );
      } else {
        return null;
      }
    }
  };

  if (isLoading) {
    return <HistorySkeltonLoading />;
  }

  return (
    <div>
      {historyData.length === 0 || Object.keys(historyData[0]).length === 0 ? (
        <NoRecords />
      ) : (
        historyData.length > 0 && historyData.map((item) => renderList(item))
      )}
    </div>
  );
}
