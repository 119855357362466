/** @format */

import styled from '@emotion/styled';
import {useStyletron} from 'baseui';
import {Theme} from 'baseui/theme';
import {ErrorMessage, Field} from 'formik';
import React from 'react';

import {CustomThemeType} from '../../../styles/theme/colors';
import DetailsError from './DetailsError';

function Label(props) {
  const [css, theme] = useStyletron();

  const {label, name, options, ...rest} = props;

  return (
    <Label.Container>
      <p>Label</p>
      <div className="right">
        <Field as="select" id={name} name={name} {...rest}>
          {options?.map((option) => {
            return (
              <option key={option.value} value={option.id}>
                {option.value}
              </option>
            );
          })}
        </Field>
      </div>
      <ErrorMessage name={name} component={DetailsError} />
    </Label.Container>
  );
}

Label.Container = styled.div<{theme: Theme & CustomThemeType}>`
  /* display: flex;
  flex-direction: column;

  .input_item {
    margin: 0.3rem 0.75rem;
    justify-content: center;
    align-items: center;


    .left {
      flex: 1;
      text-align: right;
      label {
        margin: 0;
      }
    }

    .right {
      flex: 2;
    }
  }

  .upc_code {
    display: flex;
    align-items: center;
  }

  label {
    margin: 0 1.5rem;
    font-size: 0.85rem;
    font-weight: 600;
    color: ${(props) => props.theme.textColor};
  }

  option {
    background: ${(props) => props.theme.bg};
    border-radius: 5px;
    color: ${(props) => props.theme.textColor};

    &:hover {
      background: ${(props) => props.theme.bg};
      color: #000;
      box-shadow: inset 20px 20px #00f;
    }

    &:focus {
      border-color: gray;
      outline: none;
    }
  } */

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #ddd;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    margin-right: 2rem;
    padding: 1rem;
    padding-right: 2rem;
    width: 40vw;

    &:focus {
      outline: none;
    }
  }
`;

export default Label;
